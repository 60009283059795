import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, AddressValidatingShipment, Carrier, CarrierListResponseBody, Label, ModelPackage,
  PurchaseLabelRequestBody, RateShipmentRequestBody, RateShipmentResponseBody, Shipment,
  WarehouseListResponseBody, Warehouse, WarehouseRequestBody, CreateShipmentsRequestBody,
   CreateShipmentsResponseBody, PurchaseLabelWithoutShipmentRequestBody, VoidLabelResponseBody } from 'app/model/shipEngine/models';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { PrintFns, PrintFile, pdfPrefix } from 'app/shared/print-fns';
import { ShippingBox } from 'app/model/entities/shipping-box';
import { firstValueFrom } from 'rxjs';

const shipApi = environment.apiRoot + 'api/Shipping/';
const downloadPrefix = 'https://api.shipengine.com/v1/downloads/';

/** link to carrier site to track packages */
const trackingLinks = {
  'fed': 'https://www.fedex.com/fedextrack/?tracknumbers=',
  'ups': 'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=',
  'usp': 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1='
}

export interface ShippingCarrier {
  code: string;
  name: string;
  services: {
    code: string,
    name: string
  } [];
  packages: {
    code: string,
    name: string,
    description: string
  } [];
}

@Injectable({providedIn: 'root'})
export class ShippingService {

  // cached list of carriers and shippingCarrier structs
  private carriers: Carrier[];
  shippingCarriers: ShippingCarrier[];

  // TODO: pull this from DB
  unitecMainAddress = <Address> {
    name: 'Unitec Distribution Systems',
    address_line1: '289 East Green Street',
    city_locality: 'Westminster',
    state_province: 'MD',
    postal_code: '21157',
    country_code: 'US',
    phone: '714-781-4565', // TODO: this is a fake number
  };
  mainWarehouse: Warehouse;

  constructor(private _http: HttpClient) {

  }

  private httpGet<T>(url: string, options?: any): Promise<T> {
    return firstValueFrom(this._http.get<T>(url, options)).then(r => this.checkError(r));
  }

  private httpPost<T>(url: string, body: any, options?: any): Promise<T> {
    return firstValueFrom(this._http.post<T>(url, body, options)).then(r => this.checkError(r));
  }

  private checkError(r: any) {
    // {"errors":[{"error_code":"unauthorized","error_source":"shipengine","path":"/v1/labels","method":"POST","message":"Access denied.","error_type":"security"}],"request_id":"01d8c35b-6c3b-4468-b539-f083614a0330"}
    if ((r as any).errors?.length) {
      const err = (r as any).errors[0];
      throw new Error(`Error from ${err.error_source}: ${err.message}`);
    }
    return r;
  }

  async getLabel(shipmentId: string) {
    const sp = PrintFns.getSelectedPrinters();
    const format = (sp && sp.labelFormat) || 'pdf';
    const body = <PurchaseLabelWithoutShipmentRequestBody>{
      is_return_label: false,
      // label_download_type: PurchaseLabelRequestBody.LabelDownloadTypeEnum.Inline,
      label_download_type: PurchaseLabelRequestBody.LabelDownloadTypeEnum.Url,
      label_format: format, // PurchaseLabelRequestBody.LabelFormatEnum.Zpl,
    };
    return this.httpPost<Label>(shipApi + 'GetLabel/' + shipmentId, body);
  }

  /** Download a label from ShipEngine and print it */
  async printTestLabel() {
    const sp = PrintFns.getSelectedPrinters();
    const format = (sp && sp.labelFormat) || 'pdf';
    const body = <PurchaseLabelRequestBody>{
      is_return_label: false,
      // label_download_type: PurchaseLabelRequestBody.LabelDownloadTypeEnum.Inline,
      label_download_type: PurchaseLabelRequestBody.LabelDownloadTypeEnum.Url,
      label_format: format, // PurchaseLabelRequestBody.LabelFormatEnum.Zpl,
      shipment: {
        service_code: "ups_ground",
        ship_to: {
          "name": "Jane Doe",
          "address_line1": "525 S Winchester Blvd",
          "city_locality": "San Jose",
          "state_province": "CA",
          "postal_code": "95128",
          "country_code": "US",
          "address_residential_indicator": "yes"
        },
        ship_from: this.unitecMainAddress,
        packages: [
          {
            weight: {
              value: 20,
              unit: "ounce"
            },
            dimensions: {
              height: 6,
              width: 12,
              length: 24,
              unit: "inch"
            }
          }
        ]
      }
    };
    const label = await this.httpPost<Label>(shipApi + 'GetLabelByAddress', body);

    let labelDownload = label.label_download.href;
    labelDownload = (labelDownload && labelDownload[label.label_format]) || labelDownload;
    const prints: PrintFile[] = [{ url: labelDownload, name: 'Test', data: null }];

    // download data if QZ is not available to do it when printing
    const hasQZ = await PrintFns.startQZ();
    if (!hasQZ) {
      await this.downloadFileBlobs(prints);
    }

    await PrintFns.printFiles(prints, 'labelPrinter');
  }

  async voidLabel(labelId: string) {
    return this.httpPost<VoidLabelResponseBody>(shipApi + 'VoidLabel/' + labelId, {});
  }

  /** Get the Blob data from the urls in the printFiles, and populate the `data` property */
  async downloadFileBlobs(printFiles: PrintFile[]): Promise<any> {
    const promises: Promise<Blob>[] = [];
    printFiles.forEach(async pf => {
      const mime = PrintFns.isZplUrl(pf.url) ? 'application/zpl' : 'application/pdf';
      const url = this.convertUrl(pf.url);
      promises.push(this.httpGet<Blob>(url, {
        responseType: 'blob' as 'json'
      }).then(resp =>
        pf.data = new Blob([resp], { type: mime })));
    });
    return Promise.all(promises);
  }

  /** Make ShipEngine URL go through our API, to prevent CORS errors */
  private convertUrl(url: string) {
    const shipApi = environment.apiRoot + 'api/Shipping/';
    return shipApi + 'download?path=' + this.trimUrl(url);
  }

  /** Make ShipEngine download url into relative url */
  private trimUrl(url: string) {
    if (url.startsWith(downloadPrefix)) {
      url = url.substring(downloadPrefix.length);
    }
    return url;
  }

  /** Get link to carrier site to track packages */
  getTrackingLink(box: ShippingBox) {
    let service = box.shipment.serviceApiIdentifier;
    const track = box.trackingLabelApiIdentifier;
    if (!service || !track) {
      return null;
    }
    service = service.substring(0, 3);
    const site = trackingLinks[service];
    return site ? site + track : null;
  }

  async getRates(shipTo: Address, packages: ModelPackage[], carrierId: string, serviceCode: string  ) {
    const mainWarehouse = await this.getMainWarehouse();
    let packageTypes = [ ...new Set(packages.map(p => p.package_code)).keys() ];
    if (packageTypes.length === 0 || (packageTypes.length === 1 && !packageTypes[0])) {
      packageTypes = undefined;
    }
    const body = <RateShipmentRequestBody>{
      rate_options: {
        carrier_ids: [ carrierId ],
        package_types: packageTypes
      },
      shipment: <AddressValidatingShipment>{
        ship_to: shipTo,
        warehouse_id: mainWarehouse.warehouse_id,
        carrier_id: carrierId,
        service_code: serviceCode,
        packages: packages,
        validate_address: AddressValidatingShipment.ValidateAddressEnum.NoValidation,
      } ,
    };
    // API returns a of the rates for the carrier even though we specify the carrier code.
    return this.httpPost<RateShipmentResponseBody>(shipApi + 'GetRates', body);
  }

  async getShipment(shipmentId: string) {
    return this.httpGet<Shipment>(shipApi + 'GetShipment/' + shipmentId);
  }

  async getMainWarehouse() {
    if (this.mainWarehouse != null) {
      return this.mainWarehouse;
    }
    const warehouses = await this.getWarehouses();
    const mainWarehouse = _.find(warehouses, w => w.name === 'Unitec-Main');
    if (mainWarehouse) {
      this.mainWarehouse = mainWarehouse;
    } else {
      this.mainWarehouse = await this.createWarehouse('Unitec-Main', this.unitecMainAddress );
    }
    return this.mainWarehouse;
  }

  private async createWarehouse(name: string, originAddress: Address, returnAddress?: Address) {
    const body = <Warehouse>{
      name: name,
      origin_address: originAddress,
      return_address: returnAddress
    };
    return this.httpPost<WarehouseRequestBody>(shipApi + 'CreateWarehouse', body);
  }

  private async getWarehouses() {
    const warehouseListResponse = await this.httpGet<WarehouseListResponseBody>(shipApi + 'GetWarehouses');
    const warehouses = warehouseListResponse.warehouses;
    return warehouses;
  }

  /** Gets carrier information including services and package types, and caches it. */
  public async getCarriers() {
    if (this.carriers) {
      return this.carriers;
    }
    const carrierListResponse = await this.httpGet<CarrierListResponseBody>(shipApi + 'GetCarriers');
    this.carriers = carrierListResponse.carriers;
    return this.carriers;
  }

  /** @param carrierId se-126906 or similar */
  public async getCarrierServices(carrierId: string) {
    const carriers = await this.getCarriers();
    const carrier = carriers.find(c => c.carrier_id === carrierId);
    return carrier;
    // same as
    // return this.httpGet<CarrierListServicesResponseBody>(shipApi + 'GetCarrierServices/' + carrierId);
  }

  async getShippingCarriers() {
    if (this.shippingCarriers != null) {
      return this.shippingCarriers;
    }
    const carriers = await this.getCarriers();
    this.shippingCarriers = carriers.map( c => {
      return {
        code: c.carrier_id,
        name: c.friendly_name,
        services: c.services.map( s => {
          return {
            code: s.service_code,
            name: s.name
          };
        }),
        packages: c.packages.map(p => {
          return {
            code: p.package_code,
            name: p.name,
            description: p.description
          };
        }),
      };
    });
    return this.shippingCarriers;
  }

  getCarrierDisplayName(carrierCode: string) {
    if (this.shippingCarriers == null) {
      return carrierCode;
    }
    const sc = _.find(this.shippingCarriers, c => c.code === carrierCode);
    return sc ? sc.name : carrierCode;
  }

  getServiceDisplayName(carrierCode: string, serviceCode: string) {
    if (this.shippingCarriers == null) {       return carrierCode;   }
    const sc = _.find(this.shippingCarriers, c => c.code === carrierCode);
    if (!sc) { return serviceCode; }
    const svc = _.find(sc.services, s => s.code === serviceCode);
    return svc ? svc.name : serviceCode;
  }

  getLabelOld(shipFrom: Address, shipTo: Address, packages: ModelPackage[], serviceCode: string) {
    const body = <PurchaseLabelRequestBody>{
      is_return_label: false,
      label_download_type: PurchaseLabelRequestBody.LabelDownloadTypeEnum.Url,
      label_format: PurchaseLabelRequestBody.LabelFormatEnum.Pdf,
      shipment: <Shipment>{
        ship_from: shipFrom,
        ship_to: shipTo,
        packages: packages,
        service_code: serviceCode
      }
    };

    return this.httpPost<Label>(shipApi + 'GetLabel', body);
  }

  async getRatesOld(shipFrom: Address, shipTo: Address, packages: ModelPackage[], carrierIds?: string[]) {
    if (!carrierIds) {
      // ['se-126906', 'se-126888'] UPS and USPS
      const carriers = await this.getCarriers();
      carrierIds = carriers.map(c => c.carrier_id);
    }
    const body = <RateShipmentRequestBody>{
      rate_options: {
        carrier_ids: carrierIds
      },
      shipment: <AddressValidatingShipment>{
        ship_from: shipFrom,
        ship_to: shipTo,
        packages: packages,
        validate_address: AddressValidatingShipment.ValidateAddressEnum.NoValidation
      }
    };
    return this.httpPost<RateShipmentResponseBody>(shipApi + 'GetRates', body);
  }

// Works but not needed
  // async createShipment(shipTo: Address, packages: ModelPackage[], carrierId: string, serviceCode: string, package_code?: string ) {
  //   const mainWarehouse = await this.getMainWarehouse();
  //   const body = <CreateShipmentsRequestBody> {
  //     shipments: [ <AddressValidatingShipment>{
  //       ship_to: shipTo,
  //       warehouse_id: mainWarehouse.warehouse_id,
  //       carrier_id: carrierId,
  //       service_code: serviceCode,
  //       packages: packages,
  //       validate_address: AddressValidatingShipment.ValidateAddressEnum.NoValidation,

  //     } ],
  //   };
  //   return this.httpPost<CreateShipmentsResponseBody>(shipApi + 'CreateShipments', body);
  // }

  // Works but not needed
  // async getRatesForShipment(shipmentId: string, carrierId: string) {
  //   const body = {
  //     shipment_id: shipmentId,
  //     rate_options: {
  //       carrier_ids: [
  //         carrierId
  //       ]
  //     }
  //   };
  //   return this.httpPost<RateShipmentResponseBody>(shipApi + 'GetRates', body);
  // }

}
