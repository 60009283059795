// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ActiveStatus } from './active-status';
import { Addon } from './addon';
import { AddonBin } from './addon-bin';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class AddonStation extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static TailoringStationId = 1;
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  activeStatusId: number;
  defaultPriorityOrder: number;
  name: string;
  activeStatus: ActiveStatus;
  addonBinCurrentAddonStations: AddonBin[];
  addonBinPendingAddonStations: AddonBin[];
  addons: Addon[];
}

