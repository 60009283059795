import { Location } from '@angular/common';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import * as Sentry from "@sentry/angular-ivy";

import { environment } from '../../environments/environment';
import { mapObject } from './map-object';
import { MessageBus } from './message-bus';
import { ToastTypes } from './toast.service';
import { UserWithRoles } from 'app/admin/identity-data';

export class AuthUser {
  loginName: string;
  displayName: string;
  initials: string;
  email: string;
  sessionMinutes: number;
  roles: string[];

  // 11 roles for now
  isUserAdmin: boolean;
  isAccountAdmin: boolean;
  isShippingAdmin: boolean;
  isProductAdmin: boolean;
  isAccountingAdmin: boolean;
  isInventoryAdmin: boolean;
  isPullStaff: boolean;
  isPullAdmin: boolean;
  isReceiveAdmin: boolean;
  isBinProcessAdmin: boolean;
  isAddonStationAdmin: boolean;

  updateRoles() {
    this.isUserAdmin = this.roles.includes('UserAdmin');
    this.isAccountAdmin = this.roles.includes('AccountAdmin');
    this.isProductAdmin = this.roles.includes('ProductAdmin');
    this.isShippingAdmin = this.roles.includes('ShippingAdmin');
    this.isAccountingAdmin = this.roles.includes('AccountingAdmin');
    this.isInventoryAdmin = this.roles.includes('InventoryAdmin');
    this.isPullStaff =  this.roles.includes('PullStaff');
    this.isPullAdmin =  this.roles.includes('PullAdmin');
    this.isReceiveAdmin =  this.roles.includes('ReceiveAdmin');
    this.isBinProcessAdmin =  this.roles.includes('BinProcessAdmin');
    this.isAddonStationAdmin =  this.roles.includes('AddonStationAdmin');
  }

  static fromUserWithRole(u: UserWithRoles) {
    const au = new AuthUser();
    au.loginName = u.User.UserName,
    au.displayName = u.User.UserName;

    au.initials = u.Initials;
    au.roles = u.Roles;
    au.email = u.User.Email;
    
    au.updateRoles();
    return au;
  }
}

/**
 * Authenticate user and get user profile data from server.
 * Note that cookies must be supported - @see ./auth-request-options.ts
 */
@Injectable({providedIn: 'root'})
export class AuthService {
  private _user: AuthUser;
  redirectUrl: string;
  
  constructor(private _http: HttpClient, private _router: Router, private _location: Location,
    private _idle: Idle, private _keepalive: Keepalive, private _messageBus: MessageBus) {
  }

  getUser(): AuthUser {
    return this._user;
  }

  

  /** Login and populate _user by building AuthUser from server data */
  async login(userName: string, password: string, persist: boolean): Promise<boolean> {
    const url = environment.apiRoot + 'api/Login/login';
    const creds = { userName: userName, password: password, persist: persist };
    const res = await this._http.post(url, creds).toPromise();

    this.setUser(res);
    this._messageBus.notify({ message: 'login' });
    if (this._user.displayName) {
      this.setKeepAlive();
      this.navigate();
    }
    return true;
  }

  private setUser(res: any) {
    const user = new AuthUser();
    // mapObject does not create an AuthUser instance
    const likeUser = mapObject<AuthUser>(<any> res);
    // updates user
    Object.assign(user, likeUser);
    user.updateRoles();

    this._user = user;
    Sentry.setUser({ displayName: user.displayName, email: user.email, username: user.loginName });
  }

  

  private navigate() {
    const dest = this.redirectUrl || '/home';
    if (this._user) {
      this._router.navigateByUrl(dest);
    } else {
      this.redirectUrl = this.redirectUrl || this._router.url;
      if (this.redirectUrl === '/login') { this.redirectUrl = '/'; }
      this._router.navigateByUrl('/login');
    }
  }

  async logout() {
    this._router.navigateByUrl('/login');
    this._user = null;
    this._idle.stop();
    this._messageBus.notify({ message: 'logout' });
    const url = environment.apiRoot + 'api/Login/Logout';
    try {
      await this._http.get(url).toPromise();
      this.navigate();
    } catch (e) {
      console.log('Unable to logout.');
      this.navigate();
    }
  }

  async isLoggedIn(): Promise<boolean> {
    if (this._user) {
      return true;
    }
    
    // first see if the user is still logged in on the server.
    try {
      const res = await this.getUserFromBackend();
      return true;
    } catch (e) {
      // log error if not a 401 (Unauthorized) or 405 (Method not allowed) error.
      if (e.status !== 401 && e.status !== 405) {
          console.log('login check received unknown error: ' + e.status);
      }
      return false;
    }

  }

  /** Populate _user by building AuthUser from server data */
  private async getUserFromBackend(): Promise<AuthUser> {
    const url = environment.apiRoot + 'api/Login/GetLoggedInUser';
    const res = await this._http.get(url).toPromise();

    this.setUser(res);
    this.setKeepAlive();
    return this._user;
  }

  setKeepAlive() {
    // Monitor the app for user activity and send session keepalive requests to the server
    // See https://github.com/HackedByChinese/ng2-idle-example

    const idle = this._idle;
    const keepalive = this._keepalive;

    if (idle.isRunning() || !this._user) { return; }

    if (idle.getInterrupts() && idle.getInterrupts().length) {
      // already configured, just start
      idle.watch();
      return;
    }

    // sets an idle timeout equal to the server session timeout.
    idle.setIdle(this._user.sessionMinutes * 60);
    // sets a timeout grace period.  After idle + timeout, the user will be considered timed out.
    idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      this.logout();
    });
    idle.onTimeoutWarning.subscribe((countdown) => {
      if (countdown % 10 === 0) {
        this._messageBus.notify({
          message: 'You will be logged out in ' + countdown + ' seconds.  Click to keep your session alive.',
          type: ToastTypes.Warning
        });
      }
    });

    // sets the ping interval - how often to ping the server to keep the server session alive
    keepalive.interval(60);

    // set the ping request
    const url = environment.apiRoot + 'api/Login/KeepAlive';
    const req = new HttpRequest('GET', url, { withCredentials: true });
    keepalive.request(req);

    // start idle watching
    idle.watch();
  }

}
