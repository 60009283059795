import { Component, Input, OnInit } from '@angular/core';
import { Entity } from 'breeze-client';
import { is } from '@babel/types';

@Component({
  selector: 'app-address-component',
  templateUrl: './address.component.html'
})
export class AddressComponent implements OnInit {
  _isReadonly: boolean;
  @Input() entity: Entity;
  @Input() prefix: string;
  @Input()
  set isReadonly(isReadonly: boolean) {
    this._isReadonly = isReadonly;
  }
  get isReadonly() {
    return this._isReadonly;
  }
  constructor() {

  }

  ngOnInit() {
    if (this.isReadonly == null) {
      this.isReadonly = true;
    }
  }

  hasField(partialName: string) {
    const ea = this.entity && this.entity.entityAspect;
    if (ea == null) {
      return;
    }
    const propName = this.prefix + partialName;
    return this.entity.entityType.dataProperties.some(dp => dp.name === propName);
  }

  getErrorMessage(partialName: string) {
    if (this.isReadonly) {
      return null;
    }
    const propName = this.prefix + partialName;
    const ea = this.entity && this.entity.entityAspect;
    if (ea == null) {
      return;
    }
    const errors = ea.getValidationErrors(propName);
    if (errors.length === 0) {
      return;
    }
    return errors.map(e => e.errorMessage).join('; ');
  }
}
