import { GetRowIdParams, GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ProductNeededForEOQ } from 'app/model/entities/-product-type-summary';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { UtilFns } from 'app/shared/util-fns';
import { takeUntil } from 'rxjs/operators';
import { ProductType, Product, Manufacturer, PoDetail, PoHeader } from '../model/entities/entity-model';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { DateFns } from 'app/shared/date-fns';

@Component({
  selector: 'app-purchase-eoq',
  templateUrl: './purchase-eoq.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class PurchaseEOQComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('createPoCell') createPoCell: TemplateRef<any>;

  poHeaders: PoHeader[];
  issEOQGridOptions: GridOptions;
  issEOQs: ProductNeededForEOQ[];

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.issEOQGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onIssEOQGridReady,
      onRowSelected: this.onRowSelected,
      getRowId: this.getIssEOQRowId,
      stopEditingWhenCellsLoseFocus: true,
    });
    this.issEOQs = await this.dbQueryService.getProductsNeededForEOQ();

    this.isPageReady = true;
  }

  canDeactivate() {
    this.uow.clearEntities(ProductType);
    this.uow.clearEntities(Product);
    this.uow.clearEntities(Manufacturer);
    return true;
  }
  
  onIssEOQGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'product.productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Features', field: 'product.featureChoicesExtract', filter: 'agTextColumnFilter' },
      // { headerName: 'EOQ Size', field: 'eOQSize', filter: 'agTextColumnFilter' },
      // { headerName: 'Reorder Size', field: 'reorderSize', filter: 'agTextColumnFilter' },
      { headerName: 'EOQ Qty', field: 'eOQQty', filter: 'agNumberColumnFilter' },
      { headerName: 'Reorder Point Qty', field: 'reorderPointQty', filter: 'agNumberColumnFilter' },
      { headerName: 'In Inventory', field: 'invCount', filter: 'agNumberColumnFilter' },
      { headerName: 'Stock Orders In process', field: 'poQty', filter: 'agNumberColumnFilter' },
      
      { ...AgFns.createCellButtonProps('Create PO', this.createPoCell), minWidth: 60 },
      { headerName: 'Style/Size Id', field: 'productId', filter: 'agNumberColumnFilter' },
    ];
    const sortModel = [
      { colId: 'product.productType.manufacturer.name', sort: 'asc' },
      { colId: 'product.productType.style', sort: 'asc' },
      { colId: 'eOQSize', sort: 'asc' }
    ] as ISortModel;
    
    AgFns.initGrid(this.issEOQGridOptions, colDefs, sortModel, true);
  }
  
  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/purchase-eoq']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.issEOQGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const issEOQ = e.data as ProductNeededForEOQ;
    if (!issEOQ) {
      return;
    }
  }

  canCreatePo(issEOQ: ProductNeededForEOQ) {
    return (issEOQ.reorderPointQty >= (issEOQ.invCount + issEOQ.poQty)) && !issEOQ['wasJustOrdered'];
  }

  async createPo(issEOQ: ProductNeededForEOQ) {
    // might want to order this many instead of EOQQty
    const qty = (issEOQ.eOQQty - (issEOQ.invCount + issEOQ.poQty));

    issEOQ['wasJustOrdered'] = true; // shortcut that avoids needing to requery right away
    issEOQ.poQty += issEOQ.eOQQty;

    const now = new Date();
    const manuf = issEOQ.product.productType.manufacturer;
 
    const pohStruct = {
      shippingWarehouseId: 1, // TODO: magic number for unitec
      manufacturerId: manuf.id,
      poStatusId: PoStatusCode.NewUnposted,
      poDate: DateFns.startOfDay(now),
      crtnTs: now
    };
    const poh = this.uow.createEntity(PoHeader, pohStruct)

    const leadDays = manuf.leadTimeDays;
    const podStruct = {} as PoDetail;
    podStruct.poHeaderId = poh.id;
    podStruct.productId = issEOQ.productId;
    podStruct.poDetailTypeId = 'STOCK';
    podStruct.orderQty = issEOQ.eOQQty; 
    podStruct.crtnTs = now;
    podStruct.expectedShipDate = DateFns.startOfDay(DateFns.dateAdd(now, leadDays, 'days'));
    const pod = this.uow.createEntity(PoDetail, podStruct);
    
    const sr = await this.uow.manager.saveChanges([poh, pod]);
    this.issEOQGridOptions.api.redrawRows();

    this.dialogService.toast('Purchase order created');

  }

  getIssEOQRowId(params: GetRowIdParams) {
    const s = params.data as ProductNeededForEOQ;
    return '' + s?.productId;
  }
  
}
