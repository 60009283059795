/**
 * ShipEngine API
 * ShipEngine is the world's best multi-carrier shipping API, with more than 20 supported shipping carriers.  You can easily get and compare shipping rates, print labels, track your shipments, and validate addresses anywhere in the world.  ### Worldwide Major Carrier Support Access the top global carriers in minutes—instead of weeks. Simply [connect your existing carrier accounts](https://docs.shipengine.com/docs/setup-a-carrier) in the API dashboard, and then [begin creating labels](https://docs.shipengine.com/docs/use-a-specific-service).  ### Real-Time Tracking [Track packages](https://docs.shipengine.com/docs/track-a-package) across any of our 20+ supported carrier accounts and create tracking events to keep your customers up-to-date. Easily integrate real-time tracking information for shipments into your app, email, or SMS.  ### Get Rates Across Carriers Make sure you ship as cost-effectively as possible by [quickly comparing rates](https://docs.shipengine.com/docs/get-shipping-rates) using the ShipEngine Rates API. As long as you have the carrier connected to your account, you'll be able to see and compare different rates and services.  ### Global Address Validation No matter your shipping volume, failed deliveries and address change surcharges cut into your bottom line and damage perception with customers. Our address validation services ensure your packages make it to the right place the first time. [Learn how to leverage our address validation services here.](https://docs.shipengine.com/docs/address-validation)  ShipEngine supports address validation for over 180 countries, including the United States, Canada, Great Britain, Australia, Germany, France, Norway, Spain, Sweden, Israel, Italy, and more. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: sales@shipengine.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { AdvancedShipmentOptions } from './advancedShipmentOptions';
import { InternationalShipmentOptions } from './internationalShipmentOptions';
import { ModelPackage } from './package';
import { TagResponseBody } from './tagResponseBody';
import { Weight } from './weight';


export interface AddressValidatingShipment { 
    /**
     * The possible validate address values
     */
    validate_address?: AddressValidatingShipment.ValidateAddressEnum;
    /**
     * A string that uniquely identifies the shipment
     */
    shipment_id?: string;
    /**
     * A string that uniquely identifies the carrier
     */
    carrier_id?: string;
    service_code?: string;
    /**
     * A string that uniquely identifies the external shipment
     */
    external_shipment_id?: string;
    ship_date?: Date;
    created_at?: Date;
    modified_at?: Date;
    /**
     * The possible shipment status values
     */
    shipment_status?: AddressValidatingShipment.ShipmentStatusEnum;
    ship_to?: Address;
    ship_from?: Address;
    /**
     * A string that uniquely identifies the warehouse
     */
    warehouse_id?: string;
    return_to?: Address;
    /**
     * The possible confirmation values
     */
    confirmation?: AddressValidatingShipment.ConfirmationEnum;
    customs?: InternationalShipmentOptions;
    advanced_options?: AdvancedShipmentOptions;
    /**
     * The possible insurance provider values
     */
    insurance_provider?: AddressValidatingShipment.InsuranceProviderEnum;
    tags?: Array<TagResponseBody>;
    packages?: Array<ModelPackage>;
    total_weight?: Weight;
}
export namespace AddressValidatingShipment {
    export type ValidateAddressEnum = 'no_validation' | 'validate_only' | 'validate_and_clean';
    export const ValidateAddressEnum = {
        NoValidation: 'no_validation' as ValidateAddressEnum,
        ValidateOnly: 'validate_only' as ValidateAddressEnum,
        ValidateAndClean: 'validate_and_clean' as ValidateAddressEnum
    };
    export type ShipmentStatusEnum = 'pending' | 'processing' | 'label_purchased' | 'cancelled';
    export const ShipmentStatusEnum = {
        Pending: 'pending' as ShipmentStatusEnum,
        Processing: 'processing' as ShipmentStatusEnum,
        LabelPurchased: 'label_purchased' as ShipmentStatusEnum,
        Cancelled: 'cancelled' as ShipmentStatusEnum
    };
    export type ConfirmationEnum = 'none' | 'delivery' | 'signature' | 'adult_signature' | 'direct_signature';
    export const ConfirmationEnum = {
        None: 'none' as ConfirmationEnum,
        Delivery: 'delivery' as ConfirmationEnum,
        Signature: 'signature' as ConfirmationEnum,
        AdultSignature: 'adult_signature' as ConfirmationEnum,
        DirectSignature: 'direct_signature' as ConfirmationEnum
    };
    export type InsuranceProviderEnum = 'none' | 'shipsurance' | 'carrier' | 'third_party';
    export const InsuranceProviderEnum = {
        None: 'none' as InsuranceProviderEnum,
        Shipsurance: 'shipsurance' as InsuranceProviderEnum,
        Carrier: 'carrier' as InsuranceProviderEnum,
        ThirdParty: 'third_party' as InsuranceProviderEnum
    };
}
