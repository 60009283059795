import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from 'app/shared/auth-guard.service';
import { CanDeactivateGuard } from 'app/shared/can-deactivate-guard.service';
import { EntityManagerProviderGuard } from 'app/shared/entity-manager-guard';
import { SharedModule } from 'app/shared/shared.module';
import { ChartInvoiceDelayComponent } from './chart-invoice-delay.component';
import { ChartInvoiceStatsComponent } from './chart-invoice-stats.component';
import { ChartJoStatsComponent } from './chart-jo-stats.component';
import { ChartManufInventoryComponent } from './chart-manuf-inventory.component';
import { ChartsContainerComponent } from './charts-container.component';
import { EntityIssueComponent } from './entity-issue.component';
import { HomeComponent } from './home.component';
import { JoStatsDialogComponent } from './jo-stats-dialog.component';
import { ProximityApiComponent } from './proximity-api.component';
import { ReferencesComponent } from './references.component';
import { InProcessComponent } from './in-process.component';

const routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
  },
  {
    path: 'references',
    component: ReferencesComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
  },
  {
    path: 'in-process',
    component: InProcessComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
  },
  {
    path: 'entity-issue',
    component: EntityIssueComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      icon: 'report_problem'
    }
  },
  {
    path: 'proximity-api',
    component: ProximityApiComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      icon: 'report_problem'
    }
  },
  {
    path: 'charts-container',
    component: ChartsContainerComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      icon: 'pie_chart'
    }
  },
];

@NgModule({
  declarations: [
    HomeComponent,
    ReferencesComponent,
    InProcessComponent,
    EntityIssueComponent,
    ChartInvoiceDelayComponent,
    ChartInvoiceStatsComponent,
    ChartManufInventoryComponent,
    ChartJoStatsComponent,
    ChartsContainerComponent,
    JoStatsDialogComponent,
    ProximityApiComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule
  ],
  exports: [
    // HomeComponent,
    // EntityIssueComponent
  ]
})

export class HomeModule {
}
