// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ProxSubmission } from './prox-submission';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ProxCombineItem extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  crtnTs: Date;
  error: string;
  fromEntityId: number;
  proxSubmissionId: number;
  tableName: string;
  toEntityId: number;
  proxSubmission: ProxSubmission;
}

