// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ItemReplacement } from './item-replacement';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ItemReplacementType extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: string;
  isManufacturerRelated: string;
  name: string;
  itemReplacements: ItemReplacement[];
}

