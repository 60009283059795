// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { InvoiceDetail } from './invoice-detail';
import { ItemDetail } from './item-detail';
import { JoDetailAddon } from './jo-detail-addon';
import { JoHeader } from './jo-header';
import { JoNote } from './jo-note';
import { PoDetail } from './po-detail';
import { Product } from './product';
import { ReturnRequestDetail } from './return-request-detail';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
import { EntityTypeAnnotation, EntityPropertyAnnotation } from '../entity-type-annotation';
import { Validator } from 'breeze-client';
import { ItemDetailStatusCode } from '../enums/item-detail-status-code';
/// </code-import>

export class JoDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      new EntityPropertyAnnotation('orderQty', {
        validators: [ BaseEntity.greaterThanZeroValidator ]
      }),
      new EntityPropertyAnnotation('unitPriceAmt', {
        displayName: 'Price/Unit',
        validators: [ BaseEntity.greaterThanZeroValidator ]
      }),

    ];

    return new EntityTypeAnnotation({
      validators: [  ],
      propertyAnnotations: propAnnotations
    });

  }

  // conceptual Note interface
  getHeader() {
    return this.joHeader;
  }

  getNotes() {
    return this.joNotes;
  }
  //

  // getItemDetailsAppliedQty() was renamed getItemDetailsRcvdQty

  getItemDetailsRcvdQty() {
    // count of itemDetails marked with for this jod
    return this.itemDetails && this.itemDetails.filter(itd => itd.itemDetailStatusId <= ItemDetailStatusCode.Shipped).length;
  }

  getItemDetailsVoucheredQty() {
    // count of itemDetails marked with for this jod
    return this.itemDetails && this.itemDetails.filter(itd => itd.itemDetailStatusId == ItemDetailStatusCode.InProcessVoucher).length;
  }

  getItemDetailsShippedQty() {
    // count of itemDetails marked with for this jod
    return this.itemDetails && this.itemDetails.filter(itd => itd.itemDetailStatusId === ItemDetailStatusCode.Shipped).length;
  }

  getItemDetailsDamagedQty() {
    return this.itemDetails && this.itemDetails.filter(itd => itd.itemDetailStatusId === ItemDetailStatusCode.DamagedOrLost).length;
  }

  getUnshippedQty() {
    return (this.orderQty - this.cancelQty) - this.getItemDetailsShippedQty();
  }

  // Note - next 2 methods require poDetails, poDetails.poDetailsCancel and poDetails.itemDetails

  // This value should be 0 for all Open Complete JO's
  getRemainingQty() {
    // Ignore returnQty for this purpose
    //          Ordered                             Already pulled/rcvd                Open PO                        Held
    return (this.orderQty - this.cancelQty) - (this.getItemDetailsRcvdQty() + this.getPoDetailsRemainingQty() + this.getPoDetailsHeldQty());
  }

  getPoDetailsRemainingQty() {
    return this.poDetails && _.sum(this.poDetails.map(pod => pod.getRemainingQty()));
  }

  getPoDetailsRemainingUnshippedQty() {
    return this.poDetails && _.sum(this.poDetails.map(pod => pod.getRemainingUnshippedQty()));
  }

  getPoDetailsHeldQty() {
    return this.poDetails && _.sum(this.poDetails.map(pod => pod.heldQty));
  }

  // requires all mods to have been queried
  get unitModsAmt() {
    return _.sum(this.joDetailAddons.map(jodemb => jodemb.unitPriceAmt ?? 0));
  }


  get unitTotalAmt() {
    return (this.unitPriceAmt || 0) + (this.unitCustomAmt || 0) + (this.unitOversizeAmt || 0) + this.unitModsAmt;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  cancelQty: number;
  expectedShipDate: Date;
  joHeaderId: number;
  orderQty: number;
  productId: number;
  proximityJoDetailId: string;
  returnQty: number;
  rowVersion: any;
  unitCustomAmt: number;
  unitOversizeAmt: number;
  unitPriceAmt: number;
  unitReissueAmt: number;
  invoiceDetails: InvoiceDetail[];
  itemDetails: ItemDetail[];
  joDetailAddons: JoDetailAddon[];
  joHeader: JoHeader;
  joNotes: JoNote[];
  poDetails: PoDetail[];
  product: Product;
  returnRequestDetails: ReturnRequestDetail[];
}

