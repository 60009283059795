import { GridOptions } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AgFns } from 'app/shared/ag-fns';
import { BusyService } from 'app/shared/busy.service';
import { DateFns } from 'app/shared/date-fns';
import { RRuleFns } from 'app/shared/rrule-fns';
import { Options, RRule } from 'rrule';

@Component({
  templateUrl: './event-schedule-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class EventScheduleDialogComponent {

  rrule: RRule;
  // rruleOptions: Partial<Options>;
  rruleString: string; // RFC string
  rruleText: string; // natural language string
  rruleDates: Object[] = [];
  rruleGridOptions : GridOptions;
  hasEnd: boolean;
  
  isPageReady = false;

  static async show(matDialog: MatDialog, data: string) {
    return await matDialog
      .open(EventScheduleDialogComponent, {
        disableClose: true,
        height: '570px',
        width: '650px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<string>,
    private router: Router, public busyService: BusyService ) {

    this.rruleString = data;
  
    this.rrule = RRule.fromString(data);
    // this.rrule = this.fixRule(this.rrule)
    
    this.hasEnd = this.rrule.options.count != null || this.rrule.options.until != null;

    this.prepare();
  }

  prepare() {
    this.rruleGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onRruleGridReady,
      getRowId: (params) => params.data?.date
    });
    
    this.calcDates();
    this.isPageReady = true;
  }

  calcDates() {
    if (this.rrule == null) {
      this.rruleGridOptions.api?.setRowData([]);
      return;
    }
    
    try {
      this.rruleText = RRuleFns.toText(this.rrule);
    } catch (e) {
      this.rruleGridOptions.api?.setRowData([]);
      return;
    }
    
    // rrule.all returns an infinite list if no count or untilDate
    const dates = this.rrule.all( (dt, i) => this.hasEnd ? true : i < 10);
    this.rruleDates = dates.map( dt => {
      // needed because rrule works best with UTC dates so we need to get back to current date
      dt = new Date( dt.getTime() + Math.abs(dt.getTimezoneOffset()*60000) );
      return {
        date: dt,
        dow:  RRuleFns.jsWeekDays[dt.getDay()],
      };
    });
    this.rruleGridOptions.api?.setRowData([]);
    this.rruleGridOptions.api?.setRowData(this.rruleDates);
    
  }

  onRruleGridReady() {
    AgFns.attachGridState(this.rruleGridOptions);
    // this.rruleGridOptions.getRowId = (params) => { return params.data.rawDt?.getTime().toString(); }
    const colDefs = [
      { headerName: 'Date', field: 'date' },
      { headerName: 'Day of Week', field: 'dow' },
    ];
    AgFns.updateColDefs(colDefs);
    this.rruleGridOptions.api.setColumnDefs(colDefs);
    AgFns.sizeColumnsToFit(this.rruleGridOptions);
    
  }

  async ok() {
    this.dialogRef.close(null);
  }


  fixRule(rrule: RRule) {
    var options = rrule.origOptions;
    if (options.interval == null) {
      options.interval = 1;
    }
    if (options.freq === RRule.MONTHLY) {
      options.bymonthday = options.bysetpos == null ? (options.bymonthday || 1) : null;
      options.bysetpos = options.bymonthday == null ? (options.bysetpos || 1) : null;
      if (options.bysetpos != null && (options.byweekday == null || (options.byweekday as []).length === 0)) {
        options.byweekday = [ RRule.MO ];
      }
    } else if (options.freq === RRule.WEEKLY) {
      options.bymonthday = null;
      options.bysetpos = null;
      if (options.byweekday?.[0] == null) {
        options.byweekday = [RRule.MO];
      }
    } else if (options.freq === RRule.DAILY) {
      options.byweekday = [];
      options.bymonthday = null;
      options.bysetpos = null;
    }
    // otherwise dtStart includes the timepart of the date.
    options.dtstart = options.dtstart || this.normalizeToUTC(this.getCurrentDate());
    const newRrule = new RRule( options);
    return newRrule;
  }

  getCurrentDate() {
    const dt = new Date();
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
  }

  normalizeToUTC(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0 , 0));
  }

  // forceUTCToTZDate(dt) {
  //   return new Date( dt.getTime() + Math.abs(dt.getTimezoneOffset()*60000) );
  // }
  
  // forceUTCNoOffset(date: Date) {
  //   return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0 , 0));
  // }

  
  
}
