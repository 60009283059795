import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FuseSidebarComponent } from '@fuse/components/sidebar/sidebar.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AuthService } from 'app/shared/auth.service';
import { BarcodeService } from 'app/shared/barcode.service';
import { EmailService } from 'app/shared/email.service';
import { LabelFormat, PrintFns, SelectedPrinters } from 'app/shared/print-fns';
import { ShippingService } from 'app/shipping/shipping.service';
import { environment } from 'environments/environment';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'printer-setup',
  templateUrl: './printer-setup.component.html',
})
export class PrinterSetupComponent implements OnInit, OnDestroy {
  barcodePrinter: string;
  invoicePrinter: string;
  labelPrinter: string;
  labelFormat: LabelFormat;
  labelFormats: LabelFormat[] = ['pdf', 'zpl'];
  clientPrinters: any[];
  errorMessage: string = "Connecting to QZ printers...";
  emailStatus: string = '';
  qbStatus: string = '';
  showLink = false;
  panel = 'themeOptionsPanel';
  protected onDestroy = new Subject<void>();

  constructor(private fuseSidebarService: FuseSidebarService, private shippingService: ShippingService,
    private barcodeService: BarcodeService,   private authService: AuthService, 
    private httpClient: HttpClient, private injector: Injector) {
  }

  ngOnInit() {
    // get the list of printers after the panel is opened the first time
    const sidebar: FuseSidebarComponent = this.fuseSidebarService.getSidebar(this.panel);
    sidebar.openedChanged.pipe(takeUntil(this.onDestroy)).subscribe(isOpen => {
      if (isOpen) {
        this.getPrinterNames();

        // get previously chosen printers
        const sp = PrintFns.getSelectedPrinters() || {} as SelectedPrinters;
        this.barcodePrinter = sp.barcodePrinter;
        this.invoicePrinter = sp.invoicePrinter;
        this.labelPrinter = sp.labelPrinter;
        this.labelFormat = sp.labelFormat;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  async getPrinterNames() {
    const started = await PrintFns.startQZ();
    if (started) {
      this.clientPrinters = await PrintFns.getInstalledPrinters();
      if (!this.clientPrinters || !this.clientPrinters.length) {
        this.errorMessage = 'No printers found!'
      }
      this.errorMessage = '';
    } else {
      this.errorMessage = 'QZ Tray (print manager) could not start!';
      this.showLink = true;
    }
  }

  save() {
    // save selected printer names in local storage
    const sp: SelectedPrinters = {
      barcodePrinter: this.barcodePrinter,
      invoicePrinter: this.invoicePrinter,
      labelPrinter: this.labelPrinter,
      labelFormat: this.labelFormat
    };
    PrintFns.saveSelectedPrinters(sp);
  }

  close(): void {
    this.fuseSidebarService.getSidebar(this.panel).toggleOpen();
  }

  testBarcode() {
    this.barcodeService.printTestBarcode();
  }

  async testShippingLabel() {
    this.shippingService.printTestLabel();
  }

  async testEmail() {
    const emailAddress = this.authService.getUser()?.email;
    this.emailStatus = `To: ${emailAddress}`;
    const emailService = this.injector.get(EmailService);
    const status = await emailService.sendTestEmail(emailAddress);
    this.emailStatus = `To: ${emailAddress}  Status: ${status}`;
  }

  async testQuickBooks() {
    const url = environment.apiRoot + 'api/quickbooks/QueryCompanyInfo';
    const result = await firstValueFrom(this.httpClient.get<any>(url));
    this.qbStatus = result?.companyNameField;
  }
}
