<app-uni-dialog  [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div class="h3 mb-12">with Purchase Order: {{ destPoHeader.id}}</div>
  <div class="content-card" >
    <span *ngIf="poHeaders.length == 0">
      <div uni-flex-row class="mb-12">
        <div class="h3">'{{ data.manufacturer.name }}' only has a single unposted purchase order.</div>
      </div>
    </span>
    <span *ngIf="poHeaders.length > 0">
      <div class="h3 mb-8">Unposted Purchase Orders for: {{data.manufacturer.name }} </div>
      <app-ag-grid style="height: 260px;" [gridOptions]="poGridOptions" [rowData]="poHeaders"></app-ag-grid>
    </span>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <button *ngIf="isPageReady && poHeaders.length > 0" mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>
