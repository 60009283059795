import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GridOptions } from '@ag-grid-community/core';
import { AgFns } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { PoHeader } from 'app/model/entities/entity-model';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { UtilFns } from 'app/shared/util-fns';
import { DateFns } from 'app/shared/date-fns';

@Component({
  templateUrl: './combine-purchase-orders-dialog.component.html'
})
export class CombinePurchaseOrdersDialogComponent {
  title: string;
  poGridOptions: GridOptions;
  poHeaders: PoHeader[];
  shouldCombine = '_shouldCombine';
  destPoHeader: PoHeader;
  isPageReady = false;

  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(CombinePurchaseOrdersDialogComponent, {
        disableClose: true,
        height: '480px',
        width: '600px',
        data: data
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CombinePurchaseOrdersDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, private dialogService: DialogService) {
    this.title = 'Select purchase orders to combine';
    this.destPoHeader = this.data.destPoHeader;

    this.prepare();
  }

  async prepare() {
    this.poGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPoGridReady,
    });

    await this.getPurchaseOrders();
    this.isPageReady = true;
  }

  async getPurchaseOrders() {
    if (this.destPoHeader.poStatusId !== PoStatusCode.NewUnposted) {
      await this.dialogService.showOkMessage('Invalid Operation',
       'You cannot combine onto a purchaseOrder that has a status of: ' + this.destPoHeader.poStatus.name);
       this.dialogRef.close(null);
       return;
    }
    const poHeaders = await this.dbQueryService.getUnpostedPoHeadersForManufacturer(this.data.manufacturer.id);
    this.poHeaders = poHeaders.filter(po => po.id !== this.destPoHeader.id);
    this.poHeaders.forEach( po => po[this.shouldCombine] = null);
  }

  onPoGridReady() {
    const colDefs = [
      { headerName: 'Purchase Order', field: 'id', filter: 'agNumberColumnFilter' },
      { headerName: 'Manufacturer', field: 'manufacturer.name', filter: 'agTextColumnFilter', },
      { headerName: 'PO Date', field: 'poDate', filter: 'agDateColumnFilter' },
      { headerName: 'Combine', field: this.shouldCombine, cellRenderer: AgCheckboxCellComponent },
    ];
    AgFns.initGrid(this.poGridOptions, colDefs);
    AgFns.sizeColumnsToFit(this.poGridOptions);
  }

  async ok() {
    this.poGridOptions.api.stopEditing();
    
    await this.dbQueryService.checkRowVersion(this.destPoHeader);

    const combinedPos = this.poHeaders.filter(po => po[this.shouldCombine]);
    if (combinedPos.length === 0) {
      this.dialogService.showOkMessage('Select at least one', 'You must select at least one other Purchase order to combine.');
      return false;
    }

    combinedPos.slice().forEach(po => {
      // move poDetails to dest
      po.poNotes.slice().forEach(pon => pon.poHeaderId = this.destPoHeader.id);
      po.poDetails.slice().forEach(pod => { 
        pod.poHeaderId = this.destPoHeader.id;
        pod.modTs = new Date();
      });
      // then delete po
      po.entityAspect.setDeleted();
    });

    // poke crtn ts by 1 second for Optimistic concurrency
    this.destPoHeader.crtnTs = DateFns.pokeDate(this.destPoHeader.crtnTs);

    await this.dbSaveService.saveChanges();
    this.dialogService.toast((combinedPos.length + 1) + ' purchase orders combined');
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
