<app-uni-dialog title="Select Account {{ ( this.shouldShowReissueAccountsOnly ? ' - ( Reissue Accounts Only)' : '') }}" 
  [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <app-ag-grid [gridOptions]="accountGridOptions" ></app-ag-grid>
</ng-template>

<ng-template #footerTemplate>
  <button [disabled]="selectedAccount == null" mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>