export enum ReturnRequestDetailStatusCode {
  AwaitingReceipt =	0,
  ReceivedInquiryPending = 1,
  ReceivedRepairPending = 2,
  ReturnedToCustomerRepaired	= 3,
  ReturnedToCustomerRejected 	= 4,
  ReceivedCreditApplied = 5,
  ReceivedAccountOwned = 6
  
}
