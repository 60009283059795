import { agCharts } from '@ag-grid-enterprise/charts';
import { Location } from '@angular/common';
import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AgCartesianSeriesTooltipRendererParams, AgPieSeriesTooltipRendererParams } from 'ag-charts-community';
import { Account } from 'app/model/entities/entity-model';

import { DbQueryService } from 'app/shared/db-query.service';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';


@Component({
  selector: 'app-chart-manuf-inventory',
  templateUrl: './chart-manuf-inventory.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ChartManufInventoryComponent extends DomainBaseComponent implements OnInit {
  @Input() account: Account;
  title: string;
  options: any;
  
  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,
    protected location: Location,
    protected dbQueryService: DbQueryService,
  ) {
    super(domainService);

  }

  async ngOnInit() {
    await this.processChartData();
    this.isPageReady = true;
  }

     //this will called when data is passed from parent to child.
  async ngOnChanges(val){
    this.isPageReady = false;
    await this.processChartData();
    this.isPageReady = true;  
  }

  private async processChartData() {
    // this.title = this.getTitle();
    let data = await this.dbQueryService.getTopManufacturersInInventory();
    const topCount = _.sum(data.map(d => d.count));

    const totalCount = await this.dbQueryService.getItemCountInInventory();
    data = data.concat( { manufacturerId: -1, manufacturerName: 'All others', count: totalCount - topCount });
    
    this.options = {
      title: {
        text: this.getTitle(),
      },
      data: data,
      series: [{
        type: 'pie',
        angleKey: 'count',
        labelKey: 'manufacturerName',
        labelName: 'Manufacturer',
        tooltip: { renderer: this.renderer.bind(this) },
      },
      
      ],
    };
  }


  getTitle() {
    return `Inventory Counts by Manufacturer`;
  }

  renderer(params: AgPieSeriesTooltipRendererParams) {
    const value = params.angleValue;
    
    return {
      title: params.datum.manufacturerName,
      content: params.angleValue + ' Items'
    };
  }

  


  
}
