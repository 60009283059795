import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { Entity } from 'breeze-client';
import { DbSaveService } from './db-save.service';
import { DialogService } from './dialog.service';

export interface AddressDialogComponentData {
  entity: Entity;
  addressTypeName: string;
  addressPrefix: string;
  editable: boolean;
}

@Component({
  templateUrl: './address-dialog.component.html'
})
export class AddressDialogComponent {
  inputData: AddressDialogComponentData;

  static async show(matDialog: MatDialog, data: AddressDialogComponentData) {
    const r = await matDialog.open(AddressDialogComponent, {
        disableClose: true,
        height: '450px',
        width: '600px',
        data: data
      })
      .afterClosed().toPromise();
    return <boolean> r;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: AddressDialogComponentData, public dialogRef: MatDialogRef<AddressDialogComponent>,
    private dbSaveService: DbSaveService, private dialogService: DialogService) {
    this.inputData = data;
  }

  canSave() {
    return !this.inputData.entity.entityAspect.entityState.isUnchanged();
  }

  async saveChanges() {
    const entity = this.inputData.entity;
    if (!entity.entityAspect.validateEntity()) {
      const vErrors = entity.entityAspect.getValidationErrors();
      // remove it because its got errors and it will be recreated if they reedit it.
      const errMsg = vErrors.map(ve => ve.errorMessage).join('<br>');
      await this.dialogService.showOkMessage('Validation Error(s)', errMsg);
      return false;
    }
    this.dbSaveService.saveSelectedChanges([this.inputData.entity], true);
    this.dialogRef.close(true);
  }

  cancelChanges() {
    this.inputData.entity.entityAspect.rejectChanges();
    this.dialogRef.close(false);
  }

}
