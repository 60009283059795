import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';


export interface AgButtonProps {
  calcLabel?: (item: any) => string;
  label?: string;
  icon?: string;
  canDisplay?: (item: any) => boolean;
  calcDisabled?: (item: any) => boolean;
  calcClass?: (item: any) => string;
  fixWidth?: number;
}

@Component({
  selector: 'uni-button-renderer',
  template: `
    <ng-container *ngIf="!params.icon">
      <button [class]="params.calcClass && params.calcClass(params.node.data)"
         *ngIf="canDisplayCore(params.node.data)" (click)="onClick($event)"  [disabled]="params.calcDisabled(params.node.data)" >{{ getLabel(params.node.data)}}  </button>
    </ng-container>
    <ng-container *ngIf="params.icon">
      <button   class="ag-icon"  
        *ngIf="canDisplayCore(params.node.data)" (click)="onClick($event)" [attr.aria-label]="getLabel(params.node.data)">
        <mat-icon>{{params.icon}}</mat-icon>
      </button>
    </ng-container>
    `
})
export class ButtonRendererComponent implements ICellRendererAngularComp {

  params;
  styleClass!: string;

  defaultParams = {
    label: '',
    icon: '',
    canDisplay: () => true ,
    calcDisabled: () => false
  }


  agInit(params: object): void {
    this.params = { ...this.defaultParams, ...params };

  }

  refresh(params?: object): boolean {
    return true;
  }

  canDisplayCore(item: any) {
    if (this.params.canDisplay) {
      return this.params.canDisplay(item);
    } else {
      return true;
    }
  }

  getLabel(item: any) {
    if (this.params.calcLabel) {
      return this.params.calcLabel(item);
    } else {
      return this.params.label;
    }
  }

  onClick($event) {
    
    if (this.params.onClick instanceof Function) {
          const data = this.params.node.data;
      
      // pass the actual row being clicked, all the params and the original event ( so that it 'stopPropogation' can be called on it)
      this.params.onClick(data, this.params, $event);

    }
  }
}