// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';

/// <code-import> Place custom imports between <code-import> tags
export enum MigrationType {
  None,
  Pending,
  Complete,
  Reverting,
  Error
}

import { ProductType } from './product-type';
/// </code-import>

export class _MigrationProductTypeChange extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getMigrationType(productType: ProductType, migrationProductTypeChange: _MigrationProductTypeChange ) {
    if (migrationProductTypeChange == null) {
      return MigrationType.None;
    }
    if (migrationProductTypeChange.toOrigFeatureName.startsWith('XXX')) {
      return MigrationType.Reverting;
    }
    if ( migrationProductTypeChange) {
      if ( productType.productTypeFeatureMaps.some(ptfm => ptfm.feature._origName == migrationProductTypeChange.fromOrigFeatureName)) {
        return MigrationType.Pending;
      };
      
      if (productType.productTypeFeatureMaps.some(ptfm => ptfm.feature._origName == migrationProductTypeChange.toOrigFeatureName)) {
        return MigrationType.Complete;
      };

      return MigrationType.Error;
    }
    return MigrationType.None;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  productTypeId: number;
  fromOrigFeatureName: string;
  toOrigFeatureName: string;
}

