import { RowSelectedEvent, GetRowIdParams, GridOptions } from '@ag-grid-community/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { PoManufacturerBackorder } from 'app/model/entities/-po-backorder';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgFns, GridState, ISortModel } from 'app/shared/ag-fns';
import { AgMatDatepickerComponent } from 'app/shared/ag-mat-datepicker.component';
import { UtilFns } from 'app/shared/util-fns';
import { takeUntil } from 'rxjs/operators';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { DateFns } from 'app/shared/date-fns';


@Component({
  selector: 'app-manuf-backorders',
  templateUrl: './manuf-backorders.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ManufBackordersComponent extends DomainBaseComponent implements OnInit { 
  pombGridOptions: GridOptions;
  pombItems: PoManufacturerBackorder[];
  shouldShowIdColumns = true;

  manufacturerGuid: string;
  currentPomb: PoManufacturerBackorder;
  
  podToPombMap = new Map<number, PoManufacturerBackorder>();

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog, router: Router) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
 }

  async updateFromContext() {
    this.manufacturerGuid = this.route.snapshot.params['guid'];
    

    this.pombGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPombGridReady,
      onRowSelected: this.onRowSelected,
      onCellValueChanged: this.onCellValueChanged,
      getRowId: (p) => p.data?.poDetailId,
      components: { datePicker: AgMatDatepickerComponent },
      rowGroupPanelShow: 'always',
      stopEditingWhenCellsLoseFocus: true,
      suppressDragLeaveHidesColumns: false,
      suppressMakeColumnVisibleAfterUnGroup: false,
    })
    AgFns.captureGridRouteParams(this.pombGridOptions, this.route, 'manufGuid');

    await this.requery();
    this.isPageReady = true;
  }

  async requery() {
    var pombs: PoManufacturerBackorder[];
    try {
      pombs = <PoManufacturerBackorder[]> <any> await this.dbQueryService.getPoDetailManufacturerBackorders(this.manufacturerGuid);
    } catch (e) {
      this.router.navigate(['/login'], {skipLocationChange: false});
      return;
    }
    
    // group the rows and sum the quantities
    
    // this.podToPombMap.clear();
    // const displayItems = _.sortBy(this.podItems, this.calcKey);
    const itemMap = {};
    // each pomb will actually represents multiple pods with the same calcKey ( see below ) value.
    pombs.forEach(pomb => {
      const key = this.calcKey(pomb);
      if (itemMap[key]) {
        itemMap[key].orderQty += pomb.orderQty;
        itemMap[key].recievedQty += pomb.receivedQty;
        itemMap[key].canceledQty += pomb.canceledQty;
        itemMap[key]['podIds'].push(pomb.poDetailId);
      } else {
        pomb['podIds'] = [pomb.poDetailId]
        pomb._isChecked = false;
        itemMap[key] = pomb;
      }
      // this.podToPombMap.set(pomb.podId, pomb);
    });
    this.pombItems = Object.values(itemMap);
    this.currentPomb = null;
    
    this.pombGridOptions?.api?.refreshCells();
  }

  calcKey(item: PoManufacturerBackorder) {
    return item.poDetail.poHeaderId.toString() + '/' + item.product.productType.style + item.product.featureChoicesExtract + item.poDetail.expectedShipDate;
  }

  async canDeactivate() {
    this.stopEditing();
    if (this.uow.hasChanges()) {
      const ynResult = await this.dialogService.askYesNo('Changes have not been saved', 'Do you want to save before exiting?');
      if (ynResult.index == 0) {
        await this.saveChanges();
      } else {

      }
    }
    return true;
  }

  // This method is called AFTER ngAfterViewInit - this is important
  // because the ColDef's here reference TemplateRef's above that will not be resolved until here.
  onPombGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Checked', field: '_isChecked', cellRenderer: AgCheckboxCellComponent },
      { headerName: 'PO Header Id', field: 'poDetail.poHeaderId', filter: 'agNumberColumnFilter', enableRowGroup: true },
      { headerName: 'Style', field: 'product.productType.style', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Features', field: 'product.featureChoicesExtract', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Description', field: 'product.productType.description', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Order Qty', field: 'orderQty', filter: 'agNumberColumnFilter' },
      { headerName: 'Received Qty', field: 'receivedQty' },
      { headerName: 'Canceled Qty', field: 'canceledQty' },
      { headerName: 'Manufacturer Expected Date', field: 'poDetail.expectedShipDate', editable: true, cellStyle: AgFns.setEditableStyle, filter: 'agDateColumnFilter', cellEditor: "datePicker" },
      { headerName: 'Manuf Last Updated Ts', field: 'poDetail.expectedShipDateModTs', filter: 'agDateColumnFilter' },
    ];

    const sortModel = [
        { colId: 'poDetail.poHeaderId', sort: 'asc'},
        { colId: 'product.productType.style', sort: 'asc' },
      ] as ISortModel;
    
    // next line is needed by the buildDatasource method
    AgFns.initGrid(this.pombGridOptions, colDefs, sortModel);
    AgFns.applyGridRouteParams(this.pombGridOptions);
  }


  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/manuf-backorders']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.pombGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  getTitle() {
    if (!this.pombItems?.length) { return; }
    return this.pombItems[0].product.productType.manufacturer.name;
  }

  stopEditing() {
    this.pombGridOptions.api?.stopEditing();
  }

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const pomb = e.data as PoManufacturerBackorder;
    if (!pomb) {
      return;
    }
    this.currentPomb = pomb;
  }

  async onCellValueChanged(event) {
    const colId = event?.column?.colId
    if (colId == 'poDetail.expectedShipDate') {
      this.currentPomb.poDetail.expectedShipDateModTs = new Date(Date.now());
    }
  }
  
  async saveChanges() {
    const sr = await this.dbSaveService.saveChanges();
    this.pombGridOptions?.api?.refreshCells();
    this.dialogService.toast(`${sr.entities.length} records(s) saved`);
  }

  async cancelChanges() {
    this.dbSaveService.rejectChanges();
    await this.requery();
  }
  
}
