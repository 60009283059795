<app-uni-dialog [parent]="this" [ignoreBusy]=true
 [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div class="mb-12" [innerHTML]="data.message"></div>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button class="btn btn-basic" *ngFor="let buttonName of data.buttons, let i=index" (click)="select(i)">{{data.buttons[i]}}</button>
</ng-template>

