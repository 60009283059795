<app-uni-page [parent]="this"
  title="Job Order - Pull to Work in Process - Batch Id: {{ joPullBatchId }} - {{ isBatchDone ? 'Open/Process Complete' : 'Open/Process Incomplete'}}"
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>

  <div *ngIf="!isPageReady" class="content-card" style="flex-grow: 1">
    <mat-spinner class="mt-12 ml-12" diameter="50"></mat-spinner>
  </div>

  <as-split unit="percent" direction="vertical" *ngIf="isPageReady">
    <as-split-area [size]="30">
      <app-ag-grid [gridOptions]="johGridOptions" [rowData]="joHeaders"></app-ag-grid>
      <ng-template #deleteJoHeaderFromBatchCell let-row>
        <button *ngIf="canDeleteJoHeaderFromBatch(row)" (click)="deleteJoHeaderFromBatch(row)">Delete</button>
      </ng-template>
    </as-split-area>
    
    <as-split-area [size]="70">
      <app-ag-grid [gridOptions]="jodGridOptions" [rowData]="joDetails" ></app-ag-grid>
      <ng-template #applyItemDetailsCell let-row>
        <span *ngIf="!isBatchDone">
          <button class="mr-4" [disabled]="row.getRemainingQty() < 1" (click)="applyItemDetails(row)">Apply</button>
          <button *ngIf="row.getItemDetailsRcvdQty() > 0" (click)="unApplyItemDetails(row)">View/Undo</button>
        </span>
        <span *ngIf="isBatchDone">N/A</span>
      </ng-template>
      <ng-template #viewDocCell let-row>
        <button *ngIf="hasAddonDocs(row)" (click)="viewAddonDocs(row)">View Documents</button>
      </ng-template>

    </as-split-area>
  </as-split>

</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button mat-raised-button (click)="changeBatch()">
      <span>Change Batch</span>
    </button>
    <button *ngIf="!isBatchDone" mat-raised-button (click)="markBatchComplete()">
      <span>Mark Batch Complete</span>
    </button>
    <button *ngIf="isBatchDone" mat-raised-button (click)="uncompleteBatch()">
      <span>Uncomplete Batch</span>
    </button>
    <button  [disabled]="!canPrintItemDetailBarcodes()" mat-raised-button (click)="printItemDetailBarcodes()">
      <span>Print Barcodes</span>
    </button>
    <mat-checkbox  class="ml-8" [(ngModel)]="shouldPrintBarcodes" (ngModelChange)="updateLocation()" name="shouldPrintBarcodes">Automatically Print Barcodes</mat-checkbox>
  </div>
  <div>
    <button mat-raised-button (click)="goAddonBins()">
      <span>Addon Bins</span>
    </button>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>

  </div>
</ng-template>