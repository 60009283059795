<app-uni-page  [parent]="this" title="Return Requests" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>

  <as-split *ngIf="isPageReady" unit="percent" direction="vertical">
    <as-split-area [size]="50">
      <app-ag-grid [gridOptions]="rrGridOptions"></app-ag-grid>
    </as-split-area>

    <as-split-area [size]="50">
      <div style="display: flex; flex-direction: column; height: 100%">
        <h3 class="ml-12 mb-4 mt-4">Return Request Details</h3>
        <div class="content-card mb-0 theme-default" style="flex: 1 1 auto;">
          <app-ag-grid [gridOptions]="rrdGridOptions" [rowData]="returnRequestDetails"></app-ag-grid>
          <!-- <ng-template #rradButtonsCell let-row>
            <button (click)="editReturn(row)">Edit</button>
          </ng-template>
          <ng-template #rradPrintButtonsCell let-row>
            <button *ngIf="canPrintRepairBarcodes(row)" (click)="printRepairBarcodes(row)">Print Repair
              Label(s)</button>
            <button *ngIf="canPrintBarcodes(row)" (click)="printBarcodes(row)">Print BarCode(s)</button>
          </ng-template> -->
        </div>
      </div>
    </as-split-area>
  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
  </div>
  <button mat-raised-button (click)="goBack()">
    <span>Go Back</span>
  </button>
</ng-template>