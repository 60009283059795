import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ItemDetailAddon, AddonBin, ItemDetail, AddonStation, Addon } from 'app/model/entities/entity-model';
import { AddonBinStatusCode } from 'app/model/enums/addon-bin-status-code';
import { AgFns } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { FileViewDialogComponent, FileViewDialogData } from 'app/shared/file-view-dialog.component';
import { NavFns } from 'app/shared/nav-fns';
import { UnitOfWork } from 'app/shared/unit-of-work';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { AddonBinSplitDialogComponent } from './addon-bin-split-dialog.component';
import { BarcodeService } from 'app/shared/barcode.service';

@Component({
  selector: 'app-addon-bin',
  templateUrl: './addon-bin.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class AddonBinComponent extends DomainBaseComponent {

  shouldShowClosedBins: boolean;
  addonStations: AddonStation[];
  selectedAddonStationId: number = 0;

  addonBins: AddonBin[];
  addonBinGridOptions: GridOptions;
  selectedAddonBin: AddonBin;

  addons: Addon[];
  addonGridOptions: GridOptions;

  itemDetailGridOptions: GridOptions;
  itemDetails: ItemDetail[];

  interval: any;
  refreshOptions: any[];
  refreshSeconds = 0;

  constructor(
    public uow: UnitOfWork,
    public barcodeService: BarcodeService,
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    private matDialog: MatDialog
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  setupRefresh() {
    this.updateLocation();
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.refreshSeconds == 0) return;
    this.interval = setInterval(() => {
      if (this.isPageReady) {
        this.refreshGrids(); 
      }
   }, this.refreshSeconds * 1000);
  }

  canDeactivate() {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.uow.clearEntities(AddonBin);
    this.uow.clearEntities(ItemDetailAddon);

    return true;
  }

  async updateFromContext() {
    const qparams =  this.route.snapshot.queryParams;
    this.refreshSeconds = +(qparams['refresh'] ?? 0);
    this.setupRefresh();
    this.selectedAddonStationId = +(qparams['stationId'] ?? 0);
    this.shouldShowClosedBins =  !!(+(qparams['showClosed'] ?? 0));

    this.refreshOptions = [
      { name: 'Only on entering page', refreshSeconds: 0 },
      { name: 'Every 10 Seconds', refreshSeconds: 10 },
      { name: 'Every 30 Seconds', refreshSeconds: 30 },
      { name: 'Every 60 Seconds', refreshSeconds: 60 },
      { name: 'Every 90 Seconds', refreshSeconds: 90 },
    ]

    this.addonBinGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAddonBinGridReady,
      onRowSelected: this.onAddonBinRowSelected,
      onFilterChanged: this.onFilterChanged,
    });
    AgFns.captureGridRouteParams(this.addonBinGridOptions, this.route, 'id');

    this.addonGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAddonGridReady,
    });

    this.itemDetailGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItemDetailGridReady,
    }, { detailProperty: 'itemDetailAddons' });

    this.addonStations = await this.dbQueryService.getAll(AddonStation);
    this.addonStations.unshift({ id: 0, name: '[ All ]'} as AddonStation )

    this.isPageReady = true;

    this.refreshGrids();
  }

  onAddonBinGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Addon Bin Id', field: 'id', filter: 'agNumberColumnFilter' },
      {
        ...AgFns.createButtonProps('Process', this.onProcessBin.bind(this),
          { label: 'Process', }),
        maxWidth: 150
      },
      {
        ...AgFns.createButtonProps('Split', this.onSplitBin.bind(this),
          { label: 'Split', canDisplay: this.canSplitOrCombineBin.bind(this), }),
        maxWidth: 110
      },
      // Removed for now - per Jeff 5/12/2024
      // {
      //   ...AgFns.createButtonProps('Combine', this.onCombineBin.bind(this),
      //     { label: 'Combine', canDisplay: this.canSplitOrCombineBin.bind(this), }),
      //   maxWidth: 160
      // },
      { headerName: 'Status', field: 'addonBinStatus.name', filter: 'agTextColumnFilter' },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { headerName: 'Priority Order', field: 'priorityOrder', filter: 'agNumberColumnFilter', 
        editable: this.isColumnEditable, cellStyle: (p) => this.isColumnEditable(p) && AgFns.setEditableStyle(p) },
      { headerName: 'Current Station', field: 'currentAddonStation.name', filter: 'agTextColumnFilter' },
      { headerName: 'Current Station Status', field: 'currentAddonBinStationStatus.name', filter: 'agTextColumnFilter' },
      { headerName: 'Pending Station', field: 'pendingAddonStation.name', filter: 'agTextColumnFilter' },
      { headerName: 'Job Order (specific)', field: 'joHeaderId', filter: 'agNumberColumnFilter' },
      {
        ...AgFns.createButtonProps('Reprint Bin Label', this.onPrintBinLabel.bind(this),
          { label: 'Reprint', }),
        maxWidth: 170
      },
      { headerName: 'Grouping Key', field: 'aggregateGroupingKey' },
    ];

    const sortModel = [
      { colId: 'priorityOrder', sort: 'asc' as const },
      { colId: 'account.accountName', sort: 'asc' as const },
      { colId: 'aggregateGroupingKey', sort: 'asc' as const },
    ];
    AgFns.initGrid(this.addonBinGridOptions, colDefs, sortModel);

    AgFns.applyGridRouteParams(this.addonBinGridOptions);

  }

  isColumnEditable(p: any) {
    return p.data.addonBinStatusId != AddonBinStatusCode.Closed;
  }

  onAddonGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Addon', field: 'nameAndLocation' },
      { headerName: 'Station', field: 'addonStation.name' },
      { headerName: 'Instructions', field: 'instructions' },
      {
        ...AgFns.createButtonProps('Images', this.onViewAddonImages.bind(this),
          { label: 'Images',  calcClass: (row) => this.hasAddonImages(row) ? 'btn-has-data' : '' }),
        maxWidth: 150
      },
      {
        ...AgFns.createButtonProps('Docs', this.onViewAddonDocs.bind(this),
          { label: 'Docs',  calcClass: (row) => this.hasAddonDocs(row) ? 'btn-has-data' : '' }),
        maxWidth: 150
      }
    ];

    const sortModel = [
      { colId: 'addonStation.name', sort: 'asc' as const },
      { colId: 'nameAndLocation', sort: 'asc' as const },
    ];

    AgFns.initGrid(this.addonGridOptions, colDefs, sortModel);

  }

  onItemDetailGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Item Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      {
        headerName: 'JO Header Id',
        ...NavFns.createIdCellClickedNavProps('joDetail.joHeaderId', this.router, '/job-orders'), width: 100,
      },
      { headerName: 'Jo Detail Id', field: 'joDetail.id' },
    ];

    const sortModel = [
      { colId: 'itemDetail.id', sort: 'asc' as const },
    ];

    this.updateItdMasterDetail(this.itemDetailGridOptions);
    AgFns.initGrid(this.itemDetailGridOptions, colDefs, sortModel);

  }

  updateItdMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Addon', field: 'addon.nameAndLocation' },
      { headerName: 'Station', field: 'addon.addonStation.name' },
      { headerName: 'Instructions', field: 'addon.instructions' },
      { headerName: 'Addl. Info', field: 'additionalInfo' },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const itd = params.data as ItemDetail;
        var itdAddons = _.sortBy(itd.itemDetailAddons, itda => itda.addon.addonStation.name, itda => itda.addon.nameAndLocation);
        if (this.selectedAddonStationId != 0) {
          itdAddons = itdAddons.filter(x => x.addon.addonStationId == this.selectedAddonStationId);
        }
        params.successCallback(itdAddons);
      },
    };
  }

  async onAddonBinRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const addonBin = e.data as AddonBin;
    if (!addonBin) {
      return;
    }
    this.selectedAddonBin = addonBin;
    this.itemDetails = await this.dbQueryService.getItemDetailsForAddonBin(addonBin.id)
    const addons = _.flatMap(this.itemDetails, x => x.itemDetailAddons.map(y => y.addon));
    this.addons = Array.from(new Set(addons));
    this.AgFns.refreshGrid(this.addonGridOptions, this.addons);
    this.AgFns.refreshGrid(this.itemDetailGridOptions, this.itemDetails);

    this.updateLocation();
  }

  async onSplitBin(addonBin: AddonBin) {
    const r = await AddonBinSplitDialogComponent.show(this.matDialog, { addonBin: addonBin });
    if (r) {
      await this.refreshGrids(addonBin);
    }
  }

  public async refreshAndUpdate(addonBin?: AddonBin) {
    await this.refreshGrids()
    this.updateLocation();
  }

  public async refreshGrids(addonBin?: AddonBin) {
    addonBin = addonBin ?? this.selectedAddonBin;
    this.addonBins = await this.dbQueryService.getAddonBins(this.shouldShowClosedBins);
    if (this.selectedAddonStationId != 0) {
      this.addonBins = this.addonBins.filter(aob => 
        aob.pendingAddonStationId == this.selectedAddonStationId || aob.currentAddonStationId == this.selectedAddonStationId )
    }
    AgFns.refreshGrid(this.addonBinGridOptions, this.addonBins);
    if (this.addonBins.includes(addonBin)) {
      this.itemDetails = await this.dbQueryService.getItemDetailsForAddonBin(addonBin.id);
    } else {
      this.selectedAddonBin = null;
      this.itemDetails = [];
    }
    this.AgFns.refreshGrid(this.itemDetailGridOptions, this.itemDetails);
    if (this.selectedAddonBin == null) {
      AgFns.selectFirstRow(this.addonBinGridOptions);
    }
  }

  canSplitOrCombineBin(addonBin: AddonBin) {
    return addonBin.addonBinStatusId <= AddonBinStatusCode.Open;
  }

  async onPrintBinLabel(addonBin: AddonBin) {
    this.itemDetails = await this.dbQueryService.getItemDetailsForAddonBin(addonBin.id)
    let addons = _.flatMap(this.itemDetails, x => x.itemDetailAddons.map(y => y.addon));
    addons = Array.from(new Set(addons));
    let grps: {addonBin: AddonBin, addons: Addon[]}[] = [];
    grps.push({addonBin, addons});
    this.barcodeService.printAddonBinLabels(grps, true);
  }

  onProcessBin(addonBin: AddonBin) {
    this.router.navigate(['./addon-bin', addonBin.id]);
  }

  onFilterChanged() {
    this.clearCurrentSelection();
  }

  clearCurrentSelection() {
    this.updateLocation();
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/addon-bins'], {
      queryParams: {
        refresh: this.refreshSeconds,
        stationId: this.selectedAddonStationId,
        showClosed: this.shouldShowClosedBins ? 1 : 0
      }
    });
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.addonBinGridOptions, this.selectedAddonBin?.id.toString());
    this.domainService.location.replaceState(url);
  }

  hasAddonDocs(addon: Addon) {
    return addon.addonDocMaps.length > 0;
  }

  async onViewAddonDocs(addon: Addon) {
    const data: FileViewDialogData = {
      title: 'View Addon Documents',
      docs: addon.addonDocMaps.map(x => x.addonDoc)
    };
    await FileViewDialogComponent.show(this.matDialog, data);

  }

  hasAddonImages(addon: Addon) {
    return addon.addonDocMaps.length > 0;
  }

  async onViewAddonImages(addon: Addon) {
    const data: FileViewDialogData = {
      title: 'View Addon Images',
      docs: addon.addonImages.map(x => x.image)
    };
    await FileViewDialogComponent.show(this.matDialog, data);

  }

  async cancelChanges() {
    this.addonBinGridOptions.api.stopEditing();
    this.uow.rollback();
    this.addonBinGridOptions.api.redrawRows();
  }

  async saveChanges() {
    this.addonBinGridOptions.api.stopEditing();
    const sr = await this.dbSaveService.saveChanges();
    AgFns.refreshGrid(this.addonBinGridOptions, this.addonBins);
    this.dialogService.toast('Changes saved');
  }


// Needs work if we revisit it later. 
  // async onCombineBin(addonBin: AddonBin) {
  //   const aobs = this.addonBins.filter(x =>
  //     x.accountId == addonBin.accountId
  //     && x.aggregateGroupingKey == addonBin.aggregateGroupingKey
  //     && x.joHeaderId == null && x.id != addonBin.id);
  //   if (aobs.length == 0) {
  //     this.dialogService.showOkMessage('Nothing to combine', 'There are no Addon Bins that can be combined with this one.')
  //     return;
  //   }

  //   for (const aob of aobs) {
  //     const itemDetails = await this.dbQueryService.getItemDetailsForBin(aob.id)
  //     // this works because of line above
  //     const itdas = _.flatMap(itemDetails, itd => itd.itemDetailAddons);
  //     itdas.forEach(x => x.addonBinId = addonBin.id);
  //   }
  //   await this.dbSaveService.saveChanges();

  //   aobs.forEach(aob => {
  //     aob.entityAspect.setDeleted();
  //   });
  //   await this.dbSaveService.saveChanges();
  //   this.refreshGrids(addonBin);
  //   this.dialogService.showOkMessage('AddonBins were combined', `There were ${aobs.length} Addon Bins that were combined into this one`);
  // }
}
