import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgFns } from 'app/shared/ag-fns';
import { takeUntil } from 'rxjs/operators';
import { Account } from '../model/entities/entity-model';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { EditAccountDialogComponent } from './edit-account-dialog.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AccountComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('accButtonsCell') accButtonsCell: TemplateRef<any>;

  accGridOptions: GridOptions;

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    
    this.accGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAccGridReady,
      onRowSelected: this.onRowSelected,
      onFilterChanged: this.onFilterChanged,
      rowModelType: 'serverSide',
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.updateLocation();
        }
      }
    });
    AgFns.captureGridRouteParams(this.accGridOptions, this.route, 'id');
    
    this.isPageReady = true;
  }

  async canDeactivate() {
    this.uow.clearEntities(Account);
    return true;
  }

  // This method is called AFTER ngAfterViewInit - this is important
  // because the ColDef's here reference TemplateRef's above that will not be resolved until here.
  onAccGridReady(evt: any) {
    const colDefs = [
      {  ...AgFns.createCellButtonProps('Edit', this.accButtonsCell) },
      { headerName: 'Name', field: 'accountName', filter: 'agTextColumnFilter' },
      { headerName: 'Is Active', field: 'isActive', editable: false, cellRenderer: AgCheckboxCellComponent },
      { headerName: 'Start Date', field: 'startDate', filter: 'agDateColumnFilter' },
      { headerName: 'Invoice Doc Submit Type', field: 'invoiceDocSubmitType.name' , filter: 'agTextColumnFilter' },
      { headerName: 'Invoice Email', field: 'invoiceEmail', filter: 'agTextColumnFilter' },
      { headerName: 'Due in # of days', field: 'dueDateNumDays', filter: 'agNumberColumnFilter' },
      { headerName: 'Reissuance Type', field: 'reissuanceType.name', filter: 'agTextColumnFilter'  },
      { headerName: 'Allow Partial Shipments', field: 'allowPartialShipments', editable: false, cellRenderer: AgCheckboxCellComponent },
      { headerName: 'Tax ID', field: 'taxId', filter: 'agTextColumnFilter' },
      { headerName: 'GL Contact Id', field: 'gLContactId', filter: 'agTextColumnFilter' },
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter' },
      { headerName: 'Proximity Id', field: 'proximityAccountId', filter: 'agTextColumnFilter' },
      { headerName: 'Lagacy Id', field: 'legacyAccountId', filter: 'agTextColumnFilter' },
    ];

    
    const sortModel = [
        { colId: 'accountName', sort: 'asc' as const },
      ];
    
    // next line is needed by the buildDatasource method
    AgFns.initGrid(this.accGridOptions, colDefs, sortModel);
    
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.accGridOptions);
  }

  updateDatasource() {
    const gridApi = this.accGridOptions.api;
    if (gridApi == null) {
      return;
    }

    const ds = AgFns.buildDatasource(() => this.dbQueryService.createAccountQuery(null));
    gridApi.setServerSideDatasource(ds);

    AgFns.applyGridRouteParams(this.accGridOptions);
  }

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    let account = e.data;
    if (!account) {
      return;  
    }
    this.updateLocation(account.id);
  }

  
  onFilterChanged() {
    this.updateLocation();
  }
  
  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/accounts']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.accGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  async editAccount(account: Account) {
    await EditAccountDialogComponent.show(this.matDialog, {
      account: account
    });
    this.accGridOptions.api.redrawRows();
  }
  
}
