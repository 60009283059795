import { GridOptions } from '@ag-grid-community/core';
import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ItemDetail, JoDetail } from 'app/model/entities/entity-model';
import { AddonBinStationStatusCode } from 'app/model/enums/addon-bin-station-status-code';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { ProcessStatusCode } from 'app/model/enums/process-status-code';
import { AgFns } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';

export interface UnapplyJodDialogComponentData {
  joDetail: JoDetail; // assumes that its 'itemDetails' property is already populated.
}

@Component({
  templateUrl: './unapply-jod-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class UnapplyJodDialogComponent {
  @ViewChild('unapplyCell') unapplyCell: TemplateRef<any>;
  joDetail: JoDetail;
  itemDetails: ItemDetail[];
  itdGridOptions: GridOptions;
  isPageReady = false;

  static async show(matDialog: MatDialog, data: UnapplyJodDialogComponentData) {
    return await matDialog
      .open(UnapplyJodDialogComponent, {
        disableClose: true,
        height: '510px',
        width: '830px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: UnapplyJodDialogComponentData, public dialogRef: MatDialogRef<UnapplyJodDialogComponent>,
     private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, private dialogService: DialogService   ) {

    this.joDetail = data.joDetail;
    this.prepare();
  }

  async prepare() {
    
    this.itdGridOptions = AgFns.initGridOptions( this, {
      onGridReady: this.onItdGridReady,
    });
    // make sure all of the itemDetail data is available.
    await this.dbQueryService.getItemDetailsByJoDetailId(this.joDetail.id);
    this.itemDetails = this.joDetail.itemDetails;
    this.isPageReady = true;
  }

  onItdGridReady() {
    const colDefs = [
      { ...AgFns.createCellButtonProps('Unapply', this.unapplyCell)  },
      { headerName: 'Id', field: 'id', filter: 'agTextColumnFilter' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'product.productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'product.productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Features', field: 'product.featureChoicesExtract', filter: 'agTextColumnFilter' },
    ];

    AgFns.initGrid(this.itdGridOptions, colDefs);
    AgFns.sizeColumnsToFit(this.itdGridOptions);
  }

  async unapplyItemDetail(itemDetail: ItemDetail) {
    this.unapplyOne(itemDetail);
    await this.saveAndUpdateUI();
  }

  async unapplyAll() {
    const itds = this.joDetail.itemDetails.slice();
    for (var itd of itds) {
      await this.unapplyOne(itd);
    };
    await this.saveAndUpdateUI();
  }

  private async saveAndUpdateUI() {
    await this.dbSaveService.saveChanges();
    this.itemDetails = this.joDetail.itemDetails;
    this.itdGridOptions.api.setRowData(this.itemDetails);
  }


  private async unapplyOne(itd: ItemDetail) {
    const ok = itd.itemDetailStatusId === ItemDetailStatusCode.InProcessPendingAddon 
      || itd.itemDetailStatusId == ItemDetailStatusCode.InProcessPendingShipPrep;
    if (!ok) return;
    if (itd.itemDetailAddons.some(x => x.addonBinStationStatusId != AddonBinStationStatusCode.Pending )) {
      await this.dialogService.showOkMessage('Unable to unapply', 'This item detail is currently part of an Addon or Ship Prep. workflow');
      return;
    }
    itd.joDetailId = null;
    itd.itemDetailStatusId = ItemDetailStatusCode.InInventory;
    // remove all itemDetailAddons 
    itd.itemDetailAddons.slice().forEach(itda => {
      itda.entityAspect.setDeleted();
    })
    
  }

  close() {
    this.dialogRef.close(null);
  }

}
