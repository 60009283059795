import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { captureEvent, Event as SentryEvent, Exception } from '@sentry/browser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SentryHttpInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError(response => {
        // log to Sentry
        if (response instanceof HttpErrorResponse && response.status >= 400 && response.status !== 401) {
          captureEvent(this.getEvent(request, response), { captureContext: { level: 'error' } });
        }
        return throwError(response)
      })
    );
  }

  /** Convert to Event for Sentry logging */
  getEvent(request: HttpRequest<any>, response: HttpErrorResponse) {
    const msg = `Http request failed: ${request.method} ${request.urlWithParams} ${response.status} ${response.statusText} ${response.message}`;
    const ex: Exception = {
      module: 'server',
      type: 'HttpErrorResponse',
      value: response.error
    };
    const event: SentryEvent = {
      message: msg,
      exception: {
        values: [ex]
      }
    };
    return event;
  }

}
