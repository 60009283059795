<app-uni-page  [parent]="this" title="Job Orders Audit - {{ title }}" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>

  <div class="content-card" style="height: 25vh;">
    <app-ag-grid *ngIf="showMode=='all'" [gridOptions]="johGridOptions" ></app-ag-grid>
    <app-ag-grid *ngIf="showMode=='err'" [gridOptions]="johErrGridOptions" ></app-ag-grid>
    <ng-template #editJohNotesCell let-row>
      <button [ngClass]="{'btn-has-data': hasJohNotes(row)}" (click)="editJohNotes(row)">Notes</button>
    </ng-template>
    <div class="mt-4"></div>
  </div>
  <div class="content-card" style="height: 30vh;">
    <app-ag-grid #gridJoDetails [gridOptions]="jodGridOptions" [rowData]="joDetails"></app-ag-grid>
    <ng-template #editJodNotesCell let-row>
      <button [ngClass]="{'btn-has-data': hasJodNotes(row)}" (click)="editJodNotes(row)">Notes</button>
    </ng-template>
  </div>

  <div class="content-card" style="height: 30vh;">
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="Purchase Order Details">
        <app-ag-grid [gridOptions]="podGridOptions" [rowData]="poDetails" ></app-ag-grid>
        <ng-template #editPodNotesCell let-row>
          <button [ngClass]="{'btn-has-data': hasPodNotes(row)}" (click)="editPodNotes(row)">Notes</button>
        </ng-template>
      </mat-tab>
      <mat-tab label="Cancelled Purchase Order Details">
        <app-ag-grid [gridOptions]="podcGridOptions" [rowData]="poDetailCancels"></app-ag-grid>
      </mat-tab>
      <mat-tab label="Item Details">
        <app-ag-grid [gridOptions]="itdGridOptions" [rowData]="itemDetails"></app-ag-grid>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <mat-button-toggle-group [(value)]="showMode" class="mt-8 ml-12" aria-label="Show">
      <mat-button-toggle [value]="'all'">Show all Job Order Headers</mat-button-toggle>
      <mat-button-toggle [value]="'err'">Calculate Inconsistent Job Order Headers</mat-button-toggle>
    </mat-button-toggle-group>
    <button class="ml-4"  mat-raised-button (click)="clearFilters()">
      <span>Clear all filters</span>
    </button>
    <span class="ml-24">{{message}}</span>
  </div>
  <button mat-raised-button (click)="goBack()">
    <span>Go Back</span>
  </button>
  
</ng-template>