// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { AddonImage } from './addon-image';
import { ManufacturerImage } from './manufacturer-image';
import { ProductTypeImage } from './product-type-image';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Image extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  // To adhere to the IDoc interface
  get doc(): any {
    return this.data;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  data: any;
  fileName: string;
  isPrimary: boolean;
  isThumbnail: boolean;
  mimeType: string;
  addonImages: AddonImage[];
  manufacturerImages: ManufacturerImage[];
  productTypeImages: ProductTypeImage[];
}

