import { GridOptions, RowDoubleClickedEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FeatureChoice, ProductType, Product, Manufacturer, Feature } from 'app/model/entities/entity-model';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DialogService } from 'app/shared/dialog.service';
import { UtilFns } from 'app/shared/util-fns';

export interface FeatureFinderDialogComponentData {
  // only one or the other of the first two - or neither.
  excludedFeatureIds?: number[];
  includedFeatureIds?: number[];
  rowSelection?: 'single' | 'multiple' // default is single
}


@Component({
  templateUrl: './feature-finder-dialog.component.html'
})
export class FeatureFinderDialogComponent {
  featureGridOptions: GridOptions;
  features: Feature[];

  static async show(matDialog: MatDialog, data: FeatureFinderDialogComponentData) {
    const r = await matDialog.open(FeatureFinderDialogComponent, {
      disableClose: true,
      height: '530px',
      width: '800px',
      data: data
    })
      .afterClosed().toPromise();
    return <Feature[]> r;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: FeatureFinderDialogComponentData, public dialogRef: MatDialogRef<FeatureFinderDialogComponent>,
    private dbQueryService: DbQueryService, private dialogService: DialogService) {
    this.prepare();

  }

  async prepare() {
    this.featureGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onFeatureGridReady,
      onRowDoubleClicked: this.onFeatureRowDoubleClick,
      rowSelection: this.data?.rowSelection ?? 'single',
      stopEditingWhenCellsLoseFocus: true,
    });

    this.features = await this.dbQueryService.getFeatures(true);
    if (this.data.excludedFeatureIds) {
      var excludedIds = new Set(this.data.excludedFeatureIds);
      this.features = this.features.filter(x => !excludedIds.has(x.id));
    }

    if (this.data.includedFeatureIds) {
      var includedIds = new Set(this.data.includedFeatureIds);
      this.features = this.features.filter(x => includedIds.has(x.id));
    }

    AgFns.refreshGrid(this.featureGridOptions, this.features);
  }

  onFeatureGridReady(evt: any) {
    const allowCbSelection = this.data?.rowSelection != 'single'
    const colDefs = [
      { headerName: 'Name', field: 'name', filter: 'agTextColumnFilter', checkboxSelection: allowCbSelection, headerCheckboxSelection: allowCbSelection },
    ];
    const sortModel = [
      { colId: 'name', sort: 'asc' },
    ] as ISortModel;
    AgFns.initGrid(this.featureGridOptions, colDefs, sortModel);
    
  }

  onFeatureRowDoubleClick(e: RowDoubleClickedEvent) {
    if (!e.node.isSelected()) {
      return;
    }
    const fc = e.data as Feature;
    this.dialogRef.close([fc]);
  }

  ok() {
    const r = this.featureGridOptions.api.getSelectedRows()
    this.dialogRef.close(r);
  }

  cancel() {
    this.dialogRef.close([]);
  }

}
