import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { AuthService } from 'app/shared/auth.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { AgFns, GridOverlay, ISortModel } from 'app/shared/ag-fns';
import { ProductType } from 'app/model/entities/product-type';
import { ProductTypeAlt, ItemDetail, Feature, FeatureChoice, _MigrationFeatureChoiceChange, _MigrationProductTypeChange } from 'app/model/entities/entity-model';
import * as _ from 'lodash';
import { UtilFns } from 'app/shared/util-fns';
import { FeatureFinderDialogComponent } from './feature-finder-dialog.component';
import { FeatureChoiceFinderDialogComponent } from './feature-choice-finder-dialog.component';
import { MigrationType } from 'app/model/entities/migration-product-type-change';

export interface ProductTypeFeatureChangeDialogComponentData {
  productTypeId: number;
  
}

class FcPair {
  migration?: _MigrationFeatureChoiceChange;
  fromFc: FeatureChoice;
  toFc?: FeatureChoice;
}

@Component({
  templateUrl: './product-type-feature-change-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class ProductTypeFeatureChangeDialogComponent {

  productType: ProductType;
  features: Feature[];
  fromFeature: Feature;
  toFeature: Feature;

  migrationProductTypeChange: _MigrationProductTypeChange;
  migrationFeatureChoiceChanges: _MigrationFeatureChoiceChange[];
  
  fcPairGridOptions: GridOptions;
  fcPairs: FcPair[];
  isPageReady = false;


  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(ProductTypeFeatureChangeDialogComponent, {
        disableClose: true,
        height: '570px',
        width: '1000px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: ProductTypeFeatureChangeDialogComponentData, public dialogRef: MatDialogRef<ProductTypeFeatureChangeDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService,
    private uow: UnitOfWork, private authService: AuthService, private dialogService: DialogService,
    private matDialog: MatDialog
  ) {
    this.prepare();

  }

  async prepare() {
    this.fcPairGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onFcPairGridReady,
      onRowSelected: this.onFcPairSelected,
      getRowId: (p) => {
        return p.data?.fromFc?.id
      },
      rowModelType: 'clientSide',
      rowSelection: 'single',
    });

    this.productType = await this.dbQueryService.getProductType(this.data.productTypeId);
    this.features = await this.dbQueryService.getFeatures(false);

    this.fromFeature = this.productType.productTypeFeatureMaps.find(f => f.featureId).feature;

    this.migrationProductTypeChange = await this.dbQueryService.getMigrationProductTypeChange(this.productType.id);

    const migrationType = _MigrationProductTypeChange.getMigrationType(this.productType, this.migrationProductTypeChange);
    if (migrationType != MigrationType.Pending && migrationType != MigrationType.None) {
      await this.dialogService.showOkMessage("Change is already complete", 'Change has already been completed - you cannot make another');
      this.dialogRef.close(false);
      return;
    }


    if (this.migrationProductTypeChange) {
      this.toFeature = this.features.find(f => f._origName == this.migrationProductTypeChange.toOrigFeatureName);
    }
    this.migrationFeatureChoiceChanges = await this.dbQueryService.getMigrationFeatureChoiceChanges(this.fromFeature._origName);

    this.updateFcPairs();    

    this.isPageReady = true;
  }

  updateFcPairs() {
    // const fromFeatureChoices = this.fromFeature.featureChoices;
    const fromFeatureChoicesLocal = _.uniq(_.flatMap(this.productType.products, p => p.productFeatureChoices.map(pfc => pfc.featureChoice)));

    const toFeatureChoices = this.toFeature?.featureChoices || [] as FeatureChoice[];
    const mfccs = this.migrationFeatureChoiceChanges.filter(mftc => mftc.toOrigFeatureName == this.toFeature?._origName );
        
    this.fcPairs = fromFeatureChoicesLocal.map(fromFc => {
      const mfcc = mfccs.find(mfcc => mfcc.fromOrigFeatureChoiceName == fromFc._origChoiceValue );
      if (mfcc != null) {
        return  {
          migration: mfcc,
          fromFc: fromFc,
          toFc: toFeatureChoices.find(toFc => toFc._origChoiceValue == mfcc.toOrigFeatureChoiceName)
        } as FcPair;
      } else {
        return  {
          fromFc: fromFc,
          toFc: toFeatureChoices.find(toFc => toFc.choiceValue == fromFc.choiceValue)
        } as FcPair;
      }
    });

    AgFns.refreshGrid(this.fcPairGridOptions, this.fcPairs);
  }
  
  // OLD CODE 
  // updateFcPairs() {
  //   const fromFeatureChoices = this.fromFeature.featureChoices;
  //   const fromFeatureChoicesLocal = _.uniq(_.flatMap(this.productType.products, p => p.productFeatureChoices.map(pfc => pfc.featureChoice)));

  //   const toFeatureChoices = this.toFeature?.featureChoices || [] as FeatureChoice[];
  //   const migrationFeatureChoiceChangesLocal = this.migrationFeatureChoiceChanges.filter(mftc => mftc.toOrigFeatureName == this.toFeature?._origName );
  //   if (migrationFeatureChoiceChangesLocal.length > 0) {
  //     this.fcPairs = migrationFeatureChoiceChangesLocal.map(x => {
  //       const fcPair = {
  //         migration: x,
  //         fromFc: fromFeatureChoices.find(fc => fc._origChoiceValue == x.fromOrigFeatureChoiceName),
  //         toFc: toFeatureChoices.find(fc => fc._origChoiceValue == x.toOrigFeatureChoiceName) 
  //       } as FcPair;
  //       return fcPair;
  //     });
  //     this.fcPairs = this.fcPairs.filter(fcp => fcp.fromFc != null)
  //   } else {
  //     this.fcPairs = [];
  //   }
    
  //   fromFeatureChoicesLocal.forEach(fromFc => {
  //     if (!this.fcPairs.find(fcp => fcp.fromFc == fromFc)) {
  //       const fcPair =  {
  //         fromFc: fromFc,
  //         toFc: toFeatureChoices.find(toFc => toFc.choiceValue == fromFc.choiceValue)
  //       } as FcPair;
        
  //       this.fcPairs.push( fcPair );
  //     }
  //   });

  //   AgFns.refreshGrid(this.fcPairGridOptions, this.fcPairs);
  // }

  async onFcPairGridReady(evt: any) {

    const colDefs = [
      { headerName: 'From Feature Choice', field: 'fromFc.choiceValue',  },
      { headerName: 'To Feature Choice', field: 'toFc.choiceValue' },
      { ...AgFns.createButtonProps('', this.selectToFeatureChoice.bind(this), { label: 'Select to Feature Choice' }) },
    ];
    const sortModel = [
      { colId: 'fromFc.displayOrder', sort: 'asc' },
      { colId: 'fromFc.choiceValue', sort: 'asc' },
    ] as ISortModel;

    // next line is needed by the buildDatasource method
    AgFns.initGrid(this.fcPairGridOptions, colDefs, sortModel);
    await UtilFns.wait(0);
    
  }

  async selectToFeature() {
    const features = await FeatureFinderDialogComponent.show(this.matDialog, 
      { excludedFeatureIds: [this.fromFeature.id] }
    );
    if (this.features.length == 0) return;
    this.toFeature = features[0];
    
    this.updateFcPairs();
  }

  async selectToFeatureChoice(fcPair: FcPair) {

    const excludedFeatureChoiceIds = this.fcPairs.map(x => x.toFc?.id).filter(x => x != null);
    const fcs = await FeatureChoiceFinderDialogComponent.show(this.matDialog, 
      { 
        featureId: this.toFeature.id,
        excludedFeatureChoiceIds: [] , // excludedFeatureChoiceIds,
        rowSelection: 'single'
       }
    );
    if (fcs.length == 0) return;
    fcPair.toFc = fcs[0];
    this.fcPairGridOptions.api.refreshCells();
  }

  
  async onFcPairSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const its = e.data as ProductType;
    if (!its) {
      return;
    }
  }

  async ok() {
    const isOk = this.fcPairs.every(fcp => fcp.toFc != null);
    if (!isOk) {
      await this.dialogService.showOkMessage('Incomplete', 'Every from Feature choice must have a corresponding to Feature choice');
      return false;
    }
    if (this.migrationProductTypeChange != null ) {
      this.migrationProductTypeChange.toOrigFeatureName = this.toFeature._origName;
    } else {
      const mfcc = this.uow.createEntity(_MigrationProductTypeChange, {
        productTypeId: this.productType.id,
        fromOrigFeatureName: this.fromFeature._origName,
        toOrigFeatureName: this.toFeature._origName,
      })
    }
    this.fcPairs.forEach(fcp => {
      if (fcp.migration != null) {
        // this may or may not be a change
        fcp.migration.toOrigFeatureChoiceName = fcp.toFc._origChoiceValue;
      } else if (fcp.toFc != null) {
        const mfcc = this.uow.createEntity(_MigrationFeatureChoiceChange, {
          fromOrigFeatureName: this.fromFeature._origName,
          toOrigFeatureName: this.toFeature._origName,
          fromOrigFeatureChoiceName: fcp.fromFc._origChoiceValue,
          toOrigFeatureChoiceName: fcp.toFc._origChoiceValue
        })
      }
    })
    const sr = await this.dbSaveService.saveChanges();
    const r = sr.entities.length;

    this.dialogRef.close(true);
  }

  async cancel() {
    this.dialogRef.close(false);
  }

  async undo() {
    if (this.migrationProductTypeChange != null) {
      this.migrationProductTypeChange.entityAspect.setDeleted();
      // DO NOT remove featureChoiceChanges because these may apply to other products. 
      // const migrationFeatureChoiceChangesLocal = this.migrationFeatureChoiceChanges.filter(mftc => mftc.toOrigFeatureName == this.toFeature?._origName );
      // migrationFeatureChoiceChangesLocal.forEach(x => {
      //   x.entityAspect.setDeleted();
      // })
      await this.dbSaveService.saveChanges();
      
    }
    this.toFeature = null;
    this.fcPairs = [];
      
  }

}
