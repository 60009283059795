// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ActiveStatus } from './active-status';
import { ItemDetail } from './item-detail';
import { JoDetail } from './jo-detail';
import { PoDetail } from './po-detail';
import { ProductEOQ } from './product-eoq';
import { ProductFeatureChoice } from './product-feature-choice';
import { ProductType } from './product-type';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

export class Product extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getFirstFeatureChoice() {
    return _.first(this.productFeatureChoices).featureChoice;
  }

  getFeatureChoicesExtract() {
    // Sort featureChoices by productTypeFeatureMaps.displayOrder if available otherwise by featureName
    const ptfms = this.productType.productTypeFeatureMaps;
    var pfcs = []; 
    if (ptfms.length > 0) {
      pfcs = _.sortBy(this.productFeatureChoices, x => ptfms.find(y => y.featureId == x.featureChoice.featureId)?.displayOrder);
    } else {
      pfcs = _.sortBy(this.productFeatureChoices, x => x.featureChoice?.feature?.displayFeatureName() || x.featureChoice.displayOrder) 
    }
    
    return pfcs.map(pfc => pfc.featureChoice.choiceValue).join(';');
  }

  getDisplayFeatureChoicesExtract() {
    return this.productFeatureChoices.map(pfc => pfc.featureChoice?.feature?.displayFeatureName() + ': ' + pfc.featureChoice.choiceValue).join(';');
  }
  // for binding
  get featureChoicesExtract()  {
    return this.getFeatureChoicesExtract();
  }

  // Requires that all products and productFeatureChoices have been queries for this product's productType
  getReorderProduct() {
    if (!this.productType.allowReorderFeatureChoices) {
      return this;
    }
    const reorderFeatureChoiceIds = this.productFeatureChoices.map(pfc => pfc.featureChoice.reorderFeatureChoiceId ?? pfc.featureChoiceId);
    // find a matching product 
    const reorderProduct = this.productType.products.find(p => p != this && p.productFeatureChoices.every(pfc => reorderFeatureChoiceIds.includes(pfc.featureChoiceId)));
    if (reorderProduct != null) {
      return reorderProduct;
    } else {
      return this;
    }
  }

  getSortOrder() {
    const pt = this.productType;
    if (pt == null) return 0;
    // find ptf with lowest display order
    const ptf = _.first(_.sortBy(pt.productTypeFeatureMaps, ptf => ptf.displayOrder));
    // find featureChoice for this feature
    const pfc = this.productFeatureChoices.find(pfc => pfc.featureChoice.featureId == ptf?.featureId)
    // this is the feature ww will sort on.
    // TODO: later we can add multi-feature sort - instead of just first feature sort.
    return pfc?.featureChoice.displayOrder;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  activeStatusId: number;
  modTs: Date;
  modUserInit: string;
  pAD_KEY: number;
  productTypeId: number;
  rowVersion: any;
  activeStatus: ActiveStatus;
  itemDetails: ItemDetail[];
  joDetails: JoDetail[];
  poDetailOrigProducts: PoDetail[];
  poDetailProducts: PoDetail[];
  productEOQ: ProductEOQ;
  productFeatureChoices: ProductFeatureChoice[];
  productType: ProductType;
}

