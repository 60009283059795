
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from 'app/shared/auth-guard.service';
import { EntityManagerProviderGuard } from 'app/shared/entity-manager-guard';
import { ShippingComponent } from './shipping.component';
import { SharedModule } from 'app/shared/shared.module';

const routes = [
  {
    path: 'shipping',
    component: ShippingComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
  },

];

@NgModule({
  declarations: [
    ShippingComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
  ],
  exports: [

  ]
})

export class ShippingModule {
}
