// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ActiveStatus } from './active-status';
import { Addon } from './addon';
import { FeatureChoice } from './feature-choice';
import { ProductTypeFeatureMap } from './product-type-feature-map';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Feature extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  public displayFeatureName() {
    if (this.docName) {
      return this.docName
    } else {
      return this.name
    }
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  activeStatusId: number;
  docName: string;
  mismatchAddonIsCrossAccount: boolean;
  mismatchAddonNameAndLocation: string;
  name: string;
  _origName: string;
  activeStatus: ActiveStatus;
  addons: Addon[];
  featureChoices: FeatureChoice[];
  productTypeFeatureMaps: ProductTypeFeatureMap[];
}

