// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { JoHeader } from './jo-header';
import { ManifestGroup } from './manifest-group';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Manifest extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  manifestName: string;
  nextProcessDate: Date;
  proximityManifestId: string;
  rRule: string;
  shouldIncludeInvoices: boolean;
  joHeaders: JoHeader[];
  manifestGroups: ManifestGroup[];
}

