import { Injectable, NgZone } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ToastConfig, ToastService } from './toast.service';
import { ValidationSaveErrorsDialogComponent } from './validation-save-errors-dialog.component';
import { YesNoDialogComponent, YesNoResult } from './yes-no-dialog.component';
import { GlobalErrorDialogComponent } from './global-error-dialog.component';
import { Entity } from 'breeze-client';
import { EditGenericEntityDialogComponent, GenericEntityDialogParams } from 'app/inventory/edit-generic-entity-dialog.component';

@Injectable({providedIn: 'root'})
export class DialogService {
  constructor(private dialog: MatDialog, private toastService: ToastService, private ngZone: NgZone) {
  }

  /** 0 = Save, 1 = Don't Save, 2 = Cancel (Remain on page) */
  promptToSave(): Promise<YesNoResult> {
    return this.askYesNo('You have unsaved changes', 'Click <b>Save</b> to save your changes.<br>' +
      'Click <b>Don\'t Save</b> to discard your changes.<br>Click <b>Cancel</b> to do nothing.',
    ['Save', 'Don\'t Save', 'Cancel']);
  }

  async showGenericDialog(data: GenericEntityDialogParams ) {
    return await this.dialog
      .open(EditGenericEntityDialogComponent, {
        disableClose: true,
        height: '575px',
        width: '850px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  async askYesNo(title: string, message: string, buttons?: string[]): Promise<YesNoResult> {
    return this.dialog.open(YesNoDialogComponent, {
      data: {
        title,
        message,
        buttons
      }
    }).afterClosed().toPromise();
  }

  async showGlobalError(error: any) {
    // need ngZone because this is running outside normal zone
    return this.ngZone.run(() => {
      return this.dialog.open(GlobalErrorDialogComponent, {
        disableClose: true,
        height: '700px',
        width: '900px',
        data: {
          error: error
        }
      }).afterClosed().toPromise();
    });
  }

  async showOkMessage(title: string, message: string ) {
    return await this.dialog.open(YesNoDialogComponent, {
      data: {
        title,
        message,
        buttons: ['Ok']
      }
    }).afterClosed().toPromise();
  }

  async showSaveValidationErrors(entityErrors: any[]) {
    return await this.dialog.open(ValidationSaveErrorsDialogComponent, {
      data: {
        title: 'Validation Errors - Not Saved',
        entityErrors: entityErrors
      }
    }).afterClosed().toPromise();
  }

  
  toast(config: ToastConfig | string) {
    if (typeof config === 'string') {
      config = {
        message: config
      };
    }
    this.toastService.show(config);
  }

}
