// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { InvoiceDetail } from './invoice-detail';
import { InvoiceStatus } from './invoice-status';
import { ItemDetail } from './item-detail';
import { JoHeader } from './jo-header';
import { ManifestGroupInvoice } from './manifest-group-invoice';
import { ShipBin } from './ship-bin';
import { Shipment } from './shipment';

/// <code-import> Place custom imports between <code-import> tags
import { InvoiceStatusCode } from '../enums/invoice-status-code';
import * as _ from 'lodash';
/// </code-import>

export class InvoiceHeader extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get isVoucher() {
    return this.invoiceStatusId === InvoiceStatusCode.Voucher
      || this.invoiceStatusId === InvoiceStatusCode.VoucherLabeled
      || this.invoiceStatusId === InvoiceStatusCode.VoidError;
  }

  // requires that InvoiceDetails and each accompanying JoDetail and mods be available
  calcExtendedAmt() {
    if (!this.invoiceDetails) { return null; }
    return _.sum(this.invoiceDetails.map(invd => (invd.joDetail.unitTotalAmt * invd.shipQty) || 0));
  }

  calcExtendedReissueAmt() {
    if (!this.invoiceDetails) { return null; }
    return _.sum(this.invoiceDetails.map(invd => (invd.joDetail.unitReissueAmt * invd.redistributeQty) || 0));
  }

  calcExtendedQty() {
    if (!this.invoiceDetails) { return null; }
    return _.sum(this.invoiceDetails.map(invd => invd.shipQty || 0));
  }

  calcExtendedReissueQty() {
    if (!this.invoiceDetails) { return null; }
    return _.sum(this.invoiceDetails.map(invd => invd.redistributeQty || 0));
  }

  get hasFreight() {
    if (this.joHeader.doesAccountPayFreight === false) {
      return false;
    } else if (this.shipment?.primaryInvoiceHeaderId === this.id) {
      return this.shipment?.freightAmt != null;
    } else {
      return true;
    }
  }

  get hasShippingLabel() {
    return this.shipment?.labelApiIdentifier != null;
  }

  // requires shippingBox and shipment to have been queried
  calcShipFreightAmt() {
    // always use migrated freight for any data migrated from old posted invoices.
    if ( this.wasMigrated) {
      return this.freightAmt;
    } else if (this.joHeader.doesAccountPayFreight === false) {
      return 0;
    } else if (this.shipment?.primaryInvoiceHeaderId === this.id) {
      return this.shipment.freightAmt;
    } else {
      return 0;
    }
  }

  // requires shippingBox and shipment and related shippingBoxes to have been queried
  get shipFreightInfo() {
    
    if (this.wasMigrated) {
      return 'Invoice contains freight cost migrated from the old system.'
    }
    if (this.shipment == null) {
      return 'Invoice does not yet include freight.';
    }
    if (this.joHeader.doesAccountPayFreight === false) {
      return 'Freight is not changed on this invoice.'
    }
    if (this.shipment.primaryInvoiceHeaderId === this.id) {
      const relatedInvoices = this.shipment.invoiceHeaders;
      if (relatedInvoices.length > 1) {
        const invoiceIds = relatedInvoices.map(inv => inv.id).join(', ');
        return 'Invoice includes freight from invoices: ' + invoiceIds;
      } else {
        return 'Invoice includes freight for this invoice alone';
      }
    } else {
      return 'The freight for this invoice appears on Invoice: ' + this.shipment.primaryInvoiceHeaderId;
    }
  }

  calcTotalAmt() {
    return this.calcExtendedAmt() + this.calcExtendedReissueAmt() + this.taxAmt + this.orderHandlingAmt
      + this.calcShipFreightAmt();
  }

  get isPosted() {
    return this.invoiceStatusId === InvoiceStatusCode.InvoicePosted;
  }

  get isNotPosted() {
    return !this.isPosted;
  }

  get isSubInvoice() {
    if (this.shipment == null) {
      return null;
    } else {
      return this.shipment.primaryInvoiceHeaderId !== this.id;
    }
  }
  
  // assumes that shipment and shipment.invoiceHeaders have been fetched
  get isPrimaryInvoice() {
    return this.id === this.shipment?.primaryInvoiceHeaderId;
  }
  set isPrimaryInvoice(value) {
    if (!this.shipment) {
      return;
    }
    if (value) {
      this.shipment.primaryInvoiceHeaderId = this.id;
    } else if (this.id === this.shipment.primaryInvoiceHeaderId) {
      // idiom for exit after first success
      this.shipment.invoiceHeaders.some(inv => {
        const ok = this.id !== inv.id;
        if (ok) {
          this.shipment.primaryInvoiceHeaderId = inv.id;
        }
        return ok;
      });
      
    }
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: string;
  crtnTs: Date;
  dueDate: Date;
  freightAmt: number;
  gLInvoiceId: string;
  invoiceDate: Date;
  invoiceStatusId: string;
  joHeaderId: number;
  orderHandlingAmt: number;
  proxSoloSubmissionStatusId: number;
  rowVersion: any;
  shipBinId: number;
  shipExpirationDate: Date;
  shipTs: Date;
  shipmentId: number;
  shippingExt: string;
  shippingTelephone: string;
  subtotalAmt: number;
  taxAmt: number;
  totalAmt: number;
  wasMigrated: boolean;
  invoiceDetails: InvoiceDetail[];
  invoiceStatus: InvoiceStatus;
  itemDetails: ItemDetail[];
  joHeader: JoHeader;
  manifestGroupInvoices: ManifestGroupInvoice[];
  shipBin: ShipBin;
  shipment: Shipment;
}

