<app-uni-page  [parent]="this" title="Accounts"
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  
    <app-ag-grid [gridOptions]="accGridOptions"  ></app-ag-grid>
    <ng-template #accButtonsCell let-row>
      <button (click)="editAccount(row)">Edit</button>
    </ng-template>
  
</ng-template>

<ng-template #footerTemplate >
  <div class="left-buttons">
    
  </div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>
