import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { AgFns } from 'app/shared/ag-fns';
import * as _ from 'lodash';
import { Account } from 'app/model/entities/entity-model';
import { RowDoubleClickedEvent } from '@ag-grid-community/core';
import { UtilFns } from 'app/shared/util-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DialogService } from 'app/shared/dialog.service';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { ActiveStatusCode } from 'app/model/enums/active-status-code';

export interface AccountFinderDialogData {
  shouldShowReissueAccountsOnly?: boolean;
}

@Component({
  templateUrl: './account-finder-dialog.component.html'
})
export class AccountFinderDialogComponent {

  UtilFns = UtilFns;
  shouldShowReissueAccountsOnly: boolean;
  accountGridOptions: GridOptions;
  selectedAccount: Account;

  static async show(matDialog: MatDialog, data: AccountFinderDialogData) {
    const r = await matDialog.open(AccountFinderDialogComponent, {
      disableClose: true,
      height: '530px',
      width: '800px',
      data: data
    })
      .afterClosed().toPromise();
    return <Account>r;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: AccountFinderDialogData, public dialogRef: MatDialogRef<AccountFinderDialogComponent>,
    private dbQueryService: DbQueryService, private dialogService: DialogService) {
    this.shouldShowReissueAccountsOnly = data.shouldShowReissueAccountsOnly ?? false;
    this.prepare();
  }

  async prepare() {
    this.accountGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      onRowSelected: this.onRowSelected,
      onRowDoubleClicked: this.onRowDoubleClick,
      rowModelType: 'serverSide',
      singleClickEdit: true,
      stopEditingWhenCellsLoseFocus: true,
    });
  }

  onGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Name', field: 'accountName' },
      { headerName: 'Is Active', field: 'isActive', editable: false, cellRenderer: AgCheckboxCellComponent },
      { headerName: 'Start Date', field: 'startDate', filter: 'agDateColumnFilter' },
      { headerName: 'Tax ID', field: 'taxId', filter: 'agTextColumnFilter' },
      { headerName: 'Invoice Email', field: 'invoiceEmail', filter: 'agNumberColumnFilter' },
    ];

    const sortModel = [
      { colId: 'accountName', sort: 'asc' as const },
    ];
    AgFns.initGrid(this.accountGridOptions, colDefs, sortModel);
    this.updateDatasource();
  }

  updateDatasource() {
    const gridApi = this.accountGridOptions && this.accountGridOptions.api;
    if (gridApi == null) {
      return;
    }

    let q = this.dbQueryService.createAccountQuery(this.shouldShowReissueAccountsOnly);

    const ds = AgFns.buildDatasource(() => q);
    gridApi.setServerSideDatasource(ds);
  }

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    this.selectedAccount = e.data as Account;
  }

  onRowDoubleClick(e: RowDoubleClickedEvent) {
    if (!e.node.isSelected()) {
      return;
    }
    this.selectedAccount = e.data as Account;
    this.ok();
  }

  async ok() {
    if (this.selectedAccount == null) {
      return;
    }
    if (this.selectedAccount.activeStatusId == ActiveStatusCode.Inactive) {
      const ynRes = await this.dialogService.askYesNo('Inactive Account', 'This account is inactive - Do you want to continue anyway?');
      if (ynRes.index == 1) {
        return;
      }
    }
    this.dialogRef.close(this.selectedAccount);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
