// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { CreditMemo } from './credit-memo';
import { JoDetail } from './jo-detail';
import { ReturnCreditType } from './return-credit-type';
import { ReturnReasonType } from './return-reason-type';
import { ReturnRequest } from './return-request';
import { ReturnRequestDetailStatus } from './return-request-detail-status';

/// <code-import> Place custom imports between <code-import> tags
import { ItemDetail } from './item-detail';
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotation';
import * as _ from 'lodash';
/// </code-import>

export class ReturnRequestDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  // requires that associated JoDetail and JoDetailAddon records have been retrieved from the db
    calcDefaultCreditMemoAmt() {
      const jod = this.joDetail;
      const jodaTotal = _.sum(jod.joDetailAddons.map(joda => joda.unitPriceAmt * this.qty));
      const creditMemoAmt = (jod.unitTotalAmt * this.qty) + jodaTotal;
      return creditMemoAmt;
    }
  
    // temporary holding place for ItemDetails
    returnedItemDetails: ItemDetail[];

  
    static getEntityTypeAnnotation(): EntityTypeAnnotation {
      const propAnnotations = [
        new EntityPropertyAnnotation('qty', {
          validators: [ BaseEntity.greaterThanZeroValidator ]
        }),
      ];
  
      return new EntityTypeAnnotation({
        validators: [  ],
        propertyAnnotations: propAnnotations
      });
  
    }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  actualUnitCreditMemoAmt: number;
  defaultUnitCreditMemoAmt: number;
  joDetailId: number;
  processNotes: string;
  proximityOrigReturnRequestDetailId: string;
  proximityReturnRequestDetailId: string;
  qty: number;
  returnCreditTypeId: number;
  returnExplanation: string;
  returnReasonTypeId: number;
  returnRequestDetailStatusId: number;
  returnRequestId: number;
  returnStatusExplanation: string;
  returnStatusdTs: Date;
  creditMemos: CreditMemo[];
  joDetail: JoDetail;
  returnCreditType: ReturnCreditType;
  returnReasonType: ReturnReasonType;
  returnRequest: ReturnRequest;
  returnRequestDetailStatus: ReturnRequestDetailStatus;
}

