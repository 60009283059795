// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { JoHeaderPull } from './jo-header-pull';

/// <code-import> Place custom imports between <code-import> tags
import { UtilFns } from 'app/shared/util-fns';
import { AuthUser } from 'app/shared/auth.service';
import { DateFns } from 'app/shared/date-fns';
/// </code-import>

export class JoPullBatch extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get name() {
    return `${this.id} - ${this.userInit} - [${DateFns.fmtDateShort(this.modTs)}] `;
  }

  canUserEdit(authUser: AuthUser) {
    return authUser.isPullAdmin || (authUser.isPullStaff && this.userInit === authUser.initials);
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  modTs: Date;
  rowVersion: any;
  userInit: string;
  joHeaderPulls: JoHeaderPull[];
}

