import { GridOptions } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AgFns } from 'app/shared/ag-fns';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { ShipFrequency } from 'app/model/entities/ship-frequency';
import { DbQueryService } from 'app/shared/db-query.service';
import { ShipFrequencyCode } from 'app/model/enums/ship-frequency-code';

@Component({
  templateUrl: './ship-frequency-dialog.component.html'
})
export class ShipFrequencyDialogComponent {
  
  
  isPageReady = false;

  sfGridOptions: GridOptions;
  shipFrequencies: ShipFrequency[];

  weekDays = [
    { id: 'MO', name: 'Monday' },
    { id: 'TU', name: 'Tuesday'},
    { id: 'WE', name: 'Wednesday'},
    { id: 'TH', name: 'Thursday'},
    { id: 'FR', name: 'Friday'},
    { id: 'SA', name: 'Saturday'},
    { id: 'SU', name: 'Sunday'}
  ]

  static async show(matDialog: MatDialog, data: any) {

    const r = await matDialog.open(ShipFrequencyDialogComponent, {
      disableClose: true,
      height: '400px',
      width: '600px',
      data: data
    })
      .afterClosed().toPromise();
    return <boolean>r;
  }


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ShipFrequencyDialogComponent>,
    public dbQueryService: DbQueryService, private dialogService: DialogService, private dbSaveService: DbSaveService,
    private matDialog: MatDialog) {

    data.title = data.title || 'Edit Ship Frequency Info';
    
    this.initialize();

  }

  async initialize() {
    this.sfGridOptions = AgFns.initGridOptions( this, {
      onGridReady: this.onSfGridReady,
      stopEditingWhenCellsLoseFocus: true,
    });
    

    this.shipFrequencies = this.dbQueryService.getAllCached(ShipFrequency);

    this.isPageReady = true;
  }

  
  onSfGridReady() {
    const colDefs = [
      { headerName: 'Name', field: 'name', editable: false,   },
      { headerName: 'Day of week to ship', 
        ...AgFns.createDropdownEditor('rRuleByWeekDay', this.weekDays, ), 
        editable: this.isEditableRow.bind(this), cellStyle: this.getEditableStyle.bind(this)
      },
      { headerName: 'Min days for new bin', field: 'minDaysForNewBin', 
        editable: this.isEditableRow.bind(this), cellStyle: this.getEditableStyle.bind(this),
         cellDataType: 'number',
         valueSetter: function(params) {
          var val =  +params.newValue;
          var maxDays = 0;
          if (params.data.id == ShipFrequencyCode.Every2Weeks) {
            maxDays = 13
          } else if (params.data.id == ShipFrequencyCode.Every4Weeks) {
            maxDays = 28
          } else if (params.data.id == ShipFrequencyCode.Every6Weeks) {
            maxDays = 40;
          }
          params.data.minDaysForNewBin = Math.max(0, Math.min(val, maxDays));
          return true;
        }, 
      }

    ];
    AgFns.updateColDefs(colDefs);
    this.sfGridOptions.api.setColumnDefs(colDefs);
    AgFns.sizeColumnsToFit(this.sfGridOptions);
  }

  isEditableRow(p: any) {
    const sf = p.data as ShipFrequency;
    return (sf.id > ShipFrequencyCode.Immediate) && (sf.id < ShipFrequencyCode.WhenBinIsFull);
  }

  getEditableStyle(p: any) {
    
    return this.isEditableRow(p) ? AgFns.setEditableStyle(p) : ''
  }

  
  hasChanges() {
    return this.dbSaveService.uow.hasChanges();
  }

  async ok() {
    await this.dbSaveService.saveChanges();
    this.dialogRef.close(true);
  }

  cancel() {
    this.dbSaveService.rejectChanges();
    this.dialogRef.close(null);
  }



}
