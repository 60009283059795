// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { JoHeader } from './jo-header';
import { JoPullBatch } from './jo-pull-batch';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class JoHeaderPull extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  joHeaderId: number;
  joPullBatchId: number;
  rowVersion: any;
  joHeader: JoHeader;
  joPullBatch: JoPullBatch;
}

