// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Shipment } from './shipment';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ShippingBox extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get externalPackageId(): string {
    return 'u' + this.shipmentId + 'p' + this.id;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  packageApiIdentifier: string;
  rowVersion: any;
  shipmentId: number;
  shippingLabel: string;
  trackingLabelApiIdentifier: string;
  weight: number;
  weightUoMId: string;
  shipment: Shipment;
}

