import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { DbQueryService } from './db-query.service';
import { DbSaveService } from './db-save.service';
import { UniformaxProvider } from './entity-manager-provider';
import { UtilFns } from './util-fns';

@Injectable({providedIn: 'root'})
export class AnonGuardService  {
  firstTime = true;
  constructor(private _router: Router, private injector: Injector, private emProvider: UniformaxProvider, private authService: AuthService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      var parts = state.url.split('/');
      const tableName = parts[1].split('-')[0];
      const dbQueryService = await  this.initializeDbServices();
      const guid = parts[2];
      const r = await dbQueryService.validateCryptoGuid(tableName, guid)
      if (r.length == 0) {
        return this.navToLogin();
      }
    } catch {
      return this.navToLogin();
    }
    return true;
  }

  private async initializeDbServices() {
    await this.emProvider.prepare();
    const dbQueryService = this.injector.get(DbQueryService);
    await dbQueryService.cacheAll(false);
    if (this.firstTime) {
      this.firstTime = false;
      const dbSaveService = this.injector.get(DbSaveService);
    }
    return dbQueryService;
  }

  private navToLogin() {
    this._router.navigate(['/login'], {skipLocationChange: false});
    return false;
  }
}
