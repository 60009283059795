// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Addon } from './addon';
import { Image } from './image';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class AddonImage extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  addonId: number;
  imageId: number;
  isPrimary: boolean;
  isThumbnail: boolean;
  addon: Addon;
  image: Image;
}

