import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { PrinterSetupComponent } from './printer-setup.component';

@NgModule({
  declarations: [
    PrinterSetupComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    PrinterSetupComponent
  ]
})

export class SetupModule {
}
