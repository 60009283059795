<app-uni-dialog title="Update Feature Choice Mapping Conversions" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  
  
  <div uni-flex-row>
    <mat-form-field uni-flex-item >
      <input  matInput placeholder="From Feature" [ngModel]="fromFeature.name"
        readonly="readonly" />
    </mat-form-field>
    <mat-form-field *ngIf="toFeature" uni-flex-item >
      <input  matInput placeholder="To Feature" [ngModel]="toFeature.name"
        readonly="readonly" />
    </mat-form-field>
    <button  mat-raised-button (click)="selectToFeature()">Select To Feature
    </button>
  </div>
  <div class="content-card" style="height: calc(100% - 140px)" >
    <app-ag-grid *ngIf="toFeature != null"  [gridOptions]="fcPairGridOptions" [rowData]="fcPairs"> </app-ag-grid>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button *ngIf="toFeature != null" (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>  
  <!-- <button mat-raised-button *ngIf="toFeature != null" (click)="undo()">Undo
  </button>   -->
</ng-template>
