// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Account } from './account';
import { AddonBinStationStatus } from './addon-bin-station-status';
import { AddonBinStatus } from './addon-bin-status';
import { AddonStation } from './addon-station';
import { ItemDetailAddon } from './item-detail-addon';
import { JoHeader } from './jo-header';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class AddonBin extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  accountId: number;
  addonBinStatusId: number;
  aggregateGroupingKey: string;
  currentAddonBinStationStatusId: number;
  currentAddonStationId: number;
  joHeaderId: number;
  pendingAddonStationId: number;
  priorityOrder: number;
  account: Account;
  addonBinStatus: AddonBinStatus;
  currentAddonBinStationStatus: AddonBinStationStatus;
  currentAddonStation: AddonStation;
  itemDetailAddons: ItemDetailAddon[];
  joHeader: JoHeader;
  pendingAddonStation: AddonStation;
}

