import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { GalleryModule } from "ng-gallery";
import { ImageCropperModule } from "ngx-image-cropper";
import { FileGalleryDialogComponent } from "./file-gallery-dialog.component";
import { ImageEditComponent } from "./image-edit.component";
import { ImageHandlerComponent } from "./image-handler.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GalleryModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    ImageCropperModule,
  ],
  declarations: [
    FileGalleryDialogComponent,
    ImageEditComponent,
    ImageHandlerComponent,
  ],
  exports: [
    FileGalleryDialogComponent,
    ImageEditComponent,
    ImageHandlerComponent,
  ]
})
export class ImageModule {

}