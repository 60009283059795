import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { GridOptions, ColDef, RowSelectedEvent } from '@ag-grid-community/core';
import { AgFns } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { AddonStation, ItemDetail, PoDetail, PoHeader } from 'app/model/entities/entity-model';
import { BarcodePrintStatusCode } from 'app/model/enums/barcode-print-status-code';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { takeUntil } from 'rxjs/operators';
import { PurchaseOrderApplyChangeDialogComponent } from './purchase-order-apply-change-dialog.component';
import { JoStatusCode } from 'app/model/enums/jo-status-code';
import { EditNotesDialogComponent } from 'app/shared/edit-notes-dialog.component';
import { Location } from '@angular/common';
import { BusyService } from 'app/shared/busy.service';
import { NavFns } from 'app/shared/nav-fns';
import * as _ from 'lodash';
import { PoUtilsService } from 'app/shared/po-utils.service';
import { BarcodeService } from 'app/shared/barcode.service';
import { DateFns } from 'app/shared/date-fns';


@Component({
  selector: 'app-purchase-order-receive',
  templateUrl: './purchase-order-receive.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PurchaseOrderReceiveComponent extends DomainBaseComponent {
  @ViewChild('applyCell') applyCell: TemplateRef<any>;
  @ViewChild('unapplyCell') unapplyCell: TemplateRef<any>;
  @ViewChild('modifyCell') modifyCell: TemplateRef<any>;
  @ViewChild('noteCell') noteCell: TemplateRef<any>;
  @ViewChild('auditJoCell') auditJoCell: TemplateRef<any>;

  poHeaderId: number;
  poHeaders: PoHeader[];
  pohGridOptions: GridOptions;

  shouldGroupByTransaction = false;

  needsPrintingKey = '_needsPrinting';
  canUnapplyKey = '_canUnapply';
  trxGroupKey = '_trxGroupKey';

  poDetails: PoDetail[];
  podGridOptions: GridOptions;
  pohRemainingQty: number;
  pohIsHeld: boolean;
  selectedPod: PoDetail;

  podsGridOptions: GridOptions;
  podSummaries: { date: Date, count: number } [] = [];
  shouldShowPodSummaries: boolean;

  tmpTaboo: string;

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, 
    protected poUtilsService: PoUtilsService, protected barcodeService: BarcodeService,
    private matDialog: MatDialog, private location: Location, public busyService: BusyService) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.poHeaderId = +this.route.snapshot.params['poHeaderId'];

    this.pohGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPohGridReady
    });

    this.podGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPodGridReady,
      onRowSelected: this.onPodRowSelected, 
      groupDisplayType: 'singleColumn',
      groupRemoveSingleChildren: true,
      // force expanded
      groupDefaultExpanded: -1,
    });
    this.podGridOptions.rowClassRules = {
      'rs-part-of-trx': function (params) {
        // Note: tried all of these to find the right one.
        // return params.data?.poTrxId != null;
        // return params.node.level > 0;
        // return params.node.rowGroupIndex == 0
        // return params.node.expanded;
        // return params.node.childIndex > 0;
        return params.node.uiLevel > 0
      },
    };

    this.podsGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPodsGridReady,
    });

    // needed later.... 
    await this.dbQueryService.getAll(AddonStation);

    await this.refetchPoDetails();

    this.isPageReady = true;
  }

  private async refetchPoDetails() {
    await AgFns.busyGrid(this.podGridOptions, this.busyService, async () => {
      this.poDetails = await this.dbQueryService.getPoDetailsForReceive(this.poHeaderId);
      this.poDetails.forEach(pod => {
        const needsPrinting = pod.itemDetails != null && pod.itemDetails.length > 0
          && pod.itemDetails.some(itd => itd.barcodePrintStatusId === BarcodePrintStatusCode.None);
        pod[this.needsPrintingKey] = needsPrinting;
        const poTrx = pod.poTrx;
        pod[this.canUnapplyKey] = poTrx == null || (poTrx && (poTrx.podCount == poTrx.poDetails.length));
      });


      if (this.poDetails.length == 0) {
        // This can happen if there are no poDetails for this poHeaderId which can happen if they were unapplied.
        this.poHeaders = [await this.dbQueryService.getPoHeader(this.poHeaderId)];
      } else {
        this.poHeaders = [this.poDetails[0].poHeader];
      }
      this.pohRemainingQty = _.sum(this.poDetails.map(pod => pod.getRemainingQty()));
      this.pohIsHeld = this.poDetails.some(x => x.heldQty > 0);
      this.calcPodSummaries(this.selectedPod);

      await this.considerGroupByTrx();
      this.forceUIRefresh();
    });
  }

  onPohGridReady() {
    const colDefs = [
      { headerName: 'Purchase Order', field: 'id', },
      { headerName: 'Manufacturer', field: 'manufacturer.name', },
      { headerName: 'Status', field: 'poStatus.name', },
      { headerName: 'PO Date', field: 'poDate', },
    ];
    AgFns.initGrid(this.pohGridOptions, colDefs, null, true);
  }

  onPodGridReady() {
    const colDefs = [
      // Already shown above on PurchaseOrder
      // { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      {
        headerName: 'Quantities', children: [
          { headerName: 'Order', field: 'orderQty', maxWidth: 75, type: 'numericColumn' },
          { headerName: 'Held', field: 'heldQty', maxWidth: 75, type: 'numericColumn' },
          {
            headerName: 'Rcvd', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getReceivedQty();
            },
          },
          {
            headerName: 'Cncl', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getCanceledQty();
            },
          },
          {
            headerName: 'Net', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getRemainingQty();
            },
          },
          {
            headerName: 'Shipped', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getShippedQty();
            },
          }],
      },
      {
        headerName: 'Apply to',
        valueGetter: params => {
          const pod = <PoDetail>params.data;
          if (pod == null) return '';
          if (pod.joDetailId != null) {
            return 'Jo Detail: ' + pod.joDetailId;
          } else if (pod.poDetailType != null) {
            return pod.poDetailType.name;
          } else {
            // TODO: why is poDetailType ever null;
            return pod.poDetailTypeId;
          }
        }
      },
      { headerName: 'Ship By', field: 'joDetail.joHeader.shipByDate' },
      { ...AgFns.createButtonProps('Apply', this.applyPod.bind(this), {
        canDisplay: this.canApplyPod.bind(this), fixWidth: 70
        } ), 
      },
      { ...AgFns.createButtonProps('Modify', this.modifyPod.bind(this), {
        canDisplay: this.canModifyPod.bind(this), fixWidth: 80
        } ), 
      },
      { ...AgFns.createButtonProps('Unapply', this.unapplyPod.bind(this), {
        canDisplay: this.canUnapplyPod.bind(this), fixWidth: 70
        } ), 
      },
      {
        headerName: 'Needs Printing', field: this.needsPrintingKey, cellRenderer: AgCheckboxCellComponent,
        suppressMenu: true, isDisabled: (data: PoDetail) => !(data && data.itemDetails && data.itemDetails.length)
      },
      { ...AgFns.createButtonProps('Notes', this.editPoNotes.bind(this), {
        calcClass: this.calcEditPoNotesClass.bind(this), fixWidth: 80
        }), 
       }, 
      { headerName: 'PO Detail Id', field: 'id' },
      { ...AgFns.createButtonProps('Audit', this.goJoAudit.bind(this), {
        canDisplay: this.canJoAudit.bind(this), fixWidth: 70
        }), 
       }, 
      {
        headerName: 'JO Header Id',
        ...NavFns.createIdCellClickedNavProps('joDetail.joHeaderId', this.router, '/job-orders'),
      },
      { headerName: 'Account', field: 'joDetail.joHeader.account.accountName' },
      { headerName: 'JO Status', field: 'joDetail.joHeader.joStatus.name' },
      { headerName: 'Trx Group Key', field: this.trxGroupKey, rowGroup: this.shouldGroupByTransaction, hide: false },
      // For debugging
      { headerName: 'PoTrx Id', field: 'poTrxId' },
      

    ];
    const sortFields = [
      'product.productType.description',
      'product.productType.style',
      'product.choiceValue',
      'poDetailTypeId'
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });

    AgFns.initGrid(this.podGridOptions, colDefs, sortModel, true);
  }

  async onPodRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const pod= e.data as PoDetail;
    if (!pod) {
      return;
    }
    this.selectedPod = pod;
    this.calcPodSummaries(pod);
    
  }

  private calcPodSummaries(pod: PoDetail) {
    if (pod == null) return;
    const grps = _.groupBy(pod.itemDetails, x => DateFns.startOfDay(x.crtnTs));
    const podSummaries = Object.keys(grps).map(k => {
      return {
        date: new Date(k),
        count: grps[k].length
      };
    });
    this.podSummaries = podSummaries;
  }

  onPodsGridReady() {
    const colDefs = [
      { headerName: 'Date', field: 'date', },
      { headerName: 'Qty Received', field: 'count', },
    ];
    AgFns.initGrid(this.podsGridOptions, colDefs, null, true);

  }



  calcEditPoNotesClass(pod: PoDetail) {
    return this.hasPoNotes(pod) ? 'btn-has-data' : '';
  }


  hasModifiableJo(pod: PoDetail) {
    if (pod.joDetailId == null) { return true; }
    const joStatusId = pod.joDetail?.joHeader.joStatusId;
    return joStatusId !== JoStatusCode.Closed;
  }

  canApplyAny() {
    if (this.poHeaders?.length > 0) {
      return this.poHeaders[0].poStatusId != PoStatusCode.Complete;
    } else {
      return false;
    }
  }

  canApplyPod(pod: PoDetail) {
    return pod && this.canApplyAny() && this.hasModifiableJo(pod) && ( pod.getRemainingQty() > 0 || pod.heldQty > 0);
  }

  async applyPod(pod: PoDetail) {
    this.wrapPending(async () => {
      if (pod.getRemainingQty() >= this.pohRemainingQty && !this.pohIsHeld) {
        const ynResult = await this.confirmPoTransition();
        if (ynResult.index === 1) {
          return;
        }
      }
      await this.applyPodCore(pod);
    });
  }

  private async applyPodCore(pod: PoDetail) {
    const qty = pod.getRemainingQty();
    await this.poUtilsService.applyPodToDefault(pod, qty);
    await this.refetchPoDetails();
  }

  async applyAll() {
    const canApplyAll = this.poDetails.some(pod => {
      return this.hasModifiableJo(pod) && pod.getRemainingQty() > 0;
    });
    if (!canApplyAll) {
      await this.dialogService.showOkMessage('Unable to Apply All', 'There is nothing to apply');
      return false;
    }

    if (!this.pohIsHeld) {
      const ynResult = await this.confirmPoTransition();
      if (ynResult.index === 1) {
        return;
      }
    }

    await this.wrapPending(async () => {
      for (const pod of this.poDetails) {
        if (this.canApplyPod(pod)) await this.applyPodCore(pod);
      };
    });
  }

  canUnapplyPod(pod: PoDetail) {
    return pod && this.canApplyAny() && this.hasModifiableJo(pod)
      && (pod.poTrxId != null || pod.getRemainingQty() !== pod.orderQty);
  }

  async unapplyPod(pod: PoDetail) {
    await this.wrapPending(async () => {
      await this.poUtilsService.unapplyPod(pod);
      await this.refetchPoDetails();
    });
  }

  canModifyPod(pod: PoDetail) {
    return pod && this.canApplyAny() && this.hasModifiableJo(pod) && ( pod.getRemainingQty() > 0 || pod.heldQty > 0);
  }

  async modifyPod(pod: PoDetail) {
    await this.wrapPending(async () => {
      const r = await PurchaseOrderApplyChangeDialogComponent.show(this.matDialog, { 
        poDetail: pod, 
        pohRemainingQty: this.pohRemainingQty,
        pohIsHeld: this.pohIsHeld
      });
      if (r) {
        await this.refetchPoDetails();
      }
    });
  }

  canPrintBarcodes() {
    return this.poDetails != null && this.poDetails.some(pod => pod[this.needsPrintingKey]);
  }

  async printBarcodes() {
    const itemDetails: ItemDetail[] = [];
    this.poDetails.forEach(pod => {
      if (pod[this.needsPrintingKey]) {
        const needsPrinting = pod.itemDetails != null && pod.itemDetails.length > 0
          && pod.itemDetails.some(itd => itd.barcodePrintStatusId === BarcodePrintStatusCode.None);
        if (needsPrinting) {
          const newItemDetails = pod.itemDetails.filter(itd => itd.barcodePrintStatusId === BarcodePrintStatusCode.None);
          itemDetails.push(...newItemDetails);
        } else {
          // TODO: I don't think this fork is ever executed. 
          // itemDetails.push(...pod.itemDetails);
        }
      }
    });
    const changes = await this.barcodeService.printItemDetailBarcodesAndNewAddonBinLabels(itemDetails);
    if (changes.length > 0) {
      await this.dbSaveService.saveSelectedChanges(changes);
      await this.refetchPoDetails();
    }
  }

  hasPoNotes(pod: PoDetail) {
    return pod != null && pod.poNotes != null && pod.poNotes.length > 0;
  }

  async editPoNotes(pod: PoDetail) {
    await EditNotesDialogComponent.show(this.matDialog, { detail: pod });
  }

  canJoAudit(pod: PoDetail) {
    return pod?.joDetail != null;
  }

  goJoAudit(pod: PoDetail) {
    const johId = pod?.joDetail && pod.joDetail.joHeaderId;
    if (johId == null) { return; }
    const params = NavFns.buildNavIdParams(johId);
    this.router.navigate(['/jo-audit'], params);
  }

  private async confirmPoTransition() {
    return await this.dialogService.askYesNo('Are you sure?',
      'This operation will mark this PO complete.  You will not be able to undo any part of this PO after this is completed. Do you want to continue?');
  }

  async considerGroupByTrx() {
    if (this.shouldGroupByTransaction) {
      await this.getPoTrxHistsForPo();
    }
    this.updateColDefsForGrouping();
  }

  private updateColDefsForGrouping() {
    const gridApi = this.podGridOptions.api;
    if (gridApi) {
      const columnDefs = gridApi.getColumnDefs();
      const colDef = <ColDef>columnDefs.find(cd => (cd as ColDef).field == this.trxGroupKey);
      if (colDef.rowGroup != this.shouldGroupByTransaction) {
        colDef.rowGroup = this.shouldGroupByTransaction;
        gridApi.setColumnDefs(columnDefs);
      }
    }
  }

  async getPoTrxHistsForPo() {
    const poTrxHists = await this.dbQueryService.getPoTrxHistsForPo(this.poHeaderId);
    const trxMap = new Map<number, number>();
    // Creates a map of PoTrxId -> Group Parent poDetailId
    poTrxHists.forEach(th => {
      trxMap.set(th.poTrxId, th.poDetailId);
      trxMap.set(th.prevPoTrxId, th.poDetailId);
    });
    this.poDetails.forEach(pod => {
      let key: number;
      if (pod.poTrxId == null) {
        key = pod.id;
      } else {
        key = trxMap.get(pod.poTrxId) ?? pod.id;
      }
      pod[this.trxGroupKey] = key;
    });
    this.poDetails = this.poDetails.slice();
    this.podGridOptions.api?.refreshClientSideRowModel();

  }

  private forceUIRefresh() {
    // Hack - but this method is called from after each apply/unapply and doesn't repaint properly without this.
    this.pohGridOptions.api?.refreshCells({ force: true });
    this.podGridOptions.api?.refreshCells({ force: true });
  }

  goAddonBins() {
    this.router.navigate(['/addon-bins'] );
  }

}
