import { Grid, GridOptions } from '@ag-grid-community/core';
import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ProxCombineItem, ProxSoloSubmission, ProxSubmissionItem } from 'app/model/entities/entity-model';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { ProximityPostResult } from 'app/shared/db-save.service';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'proximity-api',
  templateUrl: './proximity-api.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ProximityApiComponent extends DomainBaseComponent implements OnInit {

  currentTabLabel: string;

  subItemGridOptions: GridOptions;

  cmbItemGridOptions: GridOptions;
  combineItems: ProxCombineItem[];

  soloSubGridOptions: GridOptions;
  soloItems: ProxSoloSubmission[];
  
  
  totalRemainingCount: number;

  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,
    protected location: Location
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.subItemGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onSubItemGridReady,
      onModelUpdated: (p) => {}, // need to suppress automatic autosize that occurs here.
      getRowHeight: undefined, // // we want autoHeight for this grid
      rowModelType: 'serverSide',
    })

    this.cmbItemGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onCmbItemGridReady,
      onModelUpdated: (p) => {}, // need to suppress automatic autosize that occurs here.
      getRowHeight: undefined, // // we want autoHeight for this grid
    })

    this.soloSubGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onSoloSubGridReady,
      onModelUpdated: (p) => {}, // need to suppress automatic autosize that occurs here.
      getRowId: (p) => (p.data.tableName ?? '') + '|' + (p.data.entityId?.toString() ?? ''),
      getRowHeight: undefined, // // we want autoHeight for this grid
    })

    this.combineItems = await this.dbQueryService.getProxCombineItems(); 
    
    this.totalRemainingCount = await this.dbQueryService.getProxItemsCount();

    this.isPageReady = true;
  }

  canDeactivate() {
    this.uow.clearEntities(ProxSubmissionItem);
    return true;
  }



  async onSubItemGridReady(evt: any) {
    const width = (w: number) => { return { minWidth: w, maxWidth: w } };
    const colDefs = [
      { headerName: 'Table Name', field: 'tableName', filter: 'agTextColumnFilter', ...width(220) },
      { headerName: 'Entity Id 1', field: 'entityId1', filter: 'agTextColumnFilter', ...width(100) },
      { headerName: 'Entity Id 2', field: 'entityId2', filter: 'agTextColumnFilter', ...width(100) },
      { headerName: 'Is Delete', field: 'isDelete', cellRenderer: AgCheckboxCellComponent , ...width(80) },
      { headerName: 'Created On', field: 'crtnTs', ...width(120) },
      { headerName: 'Error', field: 'error', filter: 'agTextColumnFilter' },
      { colId: 'Allow Resubmit', ...AgFns.createButtonProps('Allow Resubmit', this.allowResubmit.bind(this), 
        { label: 'Allow Resubmit', canDisplay: this.canAllowResubmit.bind(this) }), 
       minWidth: 170
      },
      { headerName: 'Submission Id', field: 'proxSubmissionId', filter: 'agNumberColumnFilter', ...width(100) },
    ];

    const sortModel = [
      { colId: 'crtnTs', sort: 'asc' },
    ] as ISortModel;

    AgFns.initGrid(this.subItemGridOptions, colDefs, sortModel, false);
    this.updateDatasource();  

    this.subItemGridOptions.api.resetRowHeights();
  }

  updateDatasource() {
    const gridApi = this.subItemGridOptions && this.subItemGridOptions.api;
    if (gridApi == null) {
      return;
    }

    let q = this.dbQueryService.createProxSubmissionItemQuery();

    const ds = AgFns.buildDatasource(() => q);
    gridApi.setServerSideDatasource(ds);
  }

  async onCmbItemGridReady(evt: any) {
    const width = (w: number) => { return { minWidth: w, maxWidth: w } };
    const colDefs = [
      { headerName: 'Table Name', field: 'tableName', filter: 'agTextColumnFilter', ...width(220) },
      { headerName: 'From Entity Id', field: 'fromEntityId', filter: 'agTextColumnFilter', ...width(100) },
      { headerName: 'To Entity Id', field: 'toEntityId', filter: 'agTextColumnFilter', ...width(100) },
      { headerName: 'Created On', field: 'crtnTs', ...width(120) },
      { headerName: 'Error', field: 'error', filter: 'agTextColumnFilter' },
      { colId: 'Allow Resubmit', ...AgFns.createButtonProps('Allow Resubmit', this.allowResubmit.bind(this), 
        { label: 'Allow Resubmit', canDisplay: this.canAllowResubmit.bind(this) }), 
       minWidth: 170
      },
      { headerName: 'Submission Id', field: 'proxSubmissionId', filter: 'agNumberColumnFilter', ...width(100) },
    ];

    const sortModel = [
      { colId: 'crtnTs', sort: 'asc' },
    ] as ISortModel;

    AgFns.initGrid(this.cmbItemGridOptions, colDefs, sortModel, false);
  }

  async onSoloSubGridReady(evt: any) {
    const width = (w: number) => { return { minWidth: w, maxWidth: w } };
    const colDefs = [
      { headerName: 'Table Name', field: 'tableName', filter: 'agTextColumnFilter', ...width(220) },
      { headerName: 'Entity Id', field: 'entityId', filter: 'agTextColumnFilter', ...width(100) },
      { headerName: 'Submitted', field: 'submissionTs'},
      { ...AgFns.createButtonProps('Submit', this.submitSoloItem.bind(this), { label: 'Submit'  }), }, 
      { headerName: 'Result', field: 'confirmationKey', filter: 'agTextColumnFilter' },
      { headerName: 'Error', field: 'errorMessage', filter: 'agTextColumnFilter' },
    ];

    const sortModel = [
      { colId: 'submissionTs', sort: 'asc' },
    ] as ISortModel;

    AgFns.initGrid(this.soloSubGridOptions, colDefs, sortModel, false);
  }

  async onTabChanged(evt: any) {
    this.currentTabLabel = evt.tab.textLabel;
    if (this.currentTabLabel.startsWith('Shipping')) {
      this.refreshSoloSubs();
    }
  }

  async submitSoloItem(sub: ProxSoloSubmission) {
    if (sub.submissionTs != null) {
      const ynR = await this.dialogService.askYesNo('Already sent', 'Do you want to overwrite what is on Proximity');
      if (ynR.index == 1) return;
    }

    var r: ProximityPostResult;
    if (sub.tableName == 'Shipment') {
      r = await this.dbSaveService.sendProximityShipment(+sub.entityId);
    } else if (sub.tableName == 'InvoiceHeader') {
      r = await this.dbSaveService.sendProximityInvoice(sub.entityId);
    }
    this.refreshSoloSubs();
  }

  async refreshSoloSubs() {
    const subsPending = await this.dbQueryService.getProxSoloSubsPending();
    const subs = await this.dbQueryService.getProxSoloSubs();
    this.soloItems = subsPending.concat(subs);
    AgFns.refreshGrid(this.soloSubGridOptions, this.soloItems);
  }

  canAllowResubmit(item: ProxSubmissionItem | ProxCombineItem) {
    return (item.error != null || item.proxSubmissionId != null);
  }

  async allowResubmit(item: ProxSubmissionItem | ProxCombineItem) {
    item.error = null;
    item.proxSubmissionId = null;
    await this.dbSaveService.saveSelectedChanges([item]);
    this.totalRemainingCount = await this.dbQueryService.getProxItemsCount();
    this.subItemGridOptions.api.refreshCells();
  }

  async submitSupplierData() {
    this.dialogService.toast("Sending");
    const intervalId = setInterval( () => {
      this.dbQueryService.getProxItemsCount().then(count => {
        this.totalRemainingCount = count;
        this.dialogService.toast(`${count} Items Remaining `);
      });
    }, 4000);
    clearInterval(intervalId);
    // r == null means no issues
    const r = await this.dbSaveService.updateProximitySupplierData();

    if (r != null) {
      const msg  = JSON.stringify(r, null, 2);
      await this.dialogService.showOkMessage('Proximity API Error', msg);
    } else {
      this.dialogService.toast("Send Complete - OK"); 
    }
    this.totalRemainingCount = await this.dbQueryService.getProxItemsCount();
    this.subItemGridOptions.api.refreshServerSide();
    this.combineItems = await this.dbQueryService.getProxCombineItems(); 
    AgFns.refreshGrid(this.cmbItemGridOptions, this.combineItems);
  }
  
}
