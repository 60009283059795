import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogService } from './dialog.service';

export interface OneFieldResult {
  value: any;
  button: string;
  index: number;
}

@Component({
  templateUrl: './one-field-dialog.component.html'
})
export class OneFieldDialogComponent {
  title: string;
  fieldName: string;
  fieldValue: any;
  fieldType: 'integer' | 'float' | 'string';
  buttons: string[];

  static async show(matDialog: MatDialog, data: any) {
    const r = await matDialog.open(OneFieldDialogComponent, {
        height: '480px',
        width: '600px',
        data: data
      })
      .afterClosed().toPromise();
    return <OneFieldResult> r;
  }


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<OneFieldDialogComponent>, 
    private dialogService: DialogService) {
    this.buttons = data.buttons || ['Ok', 'Cancel'];
    this.title = data.title;
    this.fieldName = data.fieldName;
    this.fieldValue = data.fieldValue;
    this.fieldType = data.fieldType ?? 'string';
    dialogRef.disableClose = true;
  }

  async select(index: number) {
    let value = null; 
    if (index == 0 ) {
      value = await this.validateValue();
      if (value == null) return;
    } 
      
    const result = {
      value: value,
      button: this.buttons[index],
      index: index
    } as OneFieldResult;
    
    this.dialogRef.close(result);
  }

  async validateValue() {
    if (this.fieldType === 'string') {
      return this.fieldValue && this.fieldValue.trim();
    } 
    let value;
    if (this.fieldType == 'integer') {
      value = Number.parseInt(this.fieldValue);
    } else if (this.fieldType == 'float') {
      value = Number.parseFloat(this.fieldValue);
    }
    if (Number.isNaN(value)) {
      await this.dialogService.showOkMessage('Not a ' + this.fieldType, `${this.fieldName} must be a ${this.fieldType}`);
      return null;
    }
    return value;
  }
}
