import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface FileSelectDialogData {
  title: string;
  label: string;
}

@Component({
  templateUrl: './file-select-dialog.component.html'
})
export class FileSelectDialogComponent {

  isPageReady = false;
  docFile: File;

  static async show(matDialog: MatDialog, data: FileSelectDialogData) {
    const r = await matDialog.open(FileSelectDialogComponent, {
      disableClose: true,
      height: '230px',
      width: '600px',
      data: data
    })
      .afterClosed().toPromise();
    return <File>r;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FileSelectDialogData, 
    public dialogRef: MatDialogRef<FileSelectDialogData>) {

    this.prepare();
  }

  async prepare() {
    this.isPageReady = true;
  }

  setDocFile(event: Event) {
    this.docFile = (event.target as HTMLInputElement).files[0];
  }

  async ok() {
    this.dialogRef.close(this.docFile);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
