// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { InvoiceHeader } from './invoice-header';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

export class ShipBin extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  // assumes invoiceHeaders and associated joHeaders are fetched. 
  get firstJoh() {
    const invh = _.first(this.invoiceHeaders);
    return invh.joHeader;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  aggregateGroupingKey: string;
  expectedShipDate: Date;
  isBinFull: boolean;
  isOpen: boolean;
  shipFrequencyId: number;
  invoiceHeaders: InvoiceHeader[];
}

