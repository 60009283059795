import { GridOptions, RowDoubleClickedEvent } from '@ag-grid-community/core';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AgFns } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { takeUntil } from 'rxjs/operators';
import { IdentityRole, UserWithRoles } from './identity-data';
import { UserAccountDialogComponent } from './user-account-dialog.component';
import { UserAccountsService } from './user-accounts.service';

@Component({
  selector: 'app-user-accounts',
  templateUrl: './user-accounts.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class UserAccountsComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('editButtonsCell') editButtonsCell: TemplateRef<any>;

  userGridOptions: GridOptions;
  users: UserWithRoles[];
  roles: IdentityRole[];

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private userAccountsService: UserAccountsService, private matDialog: MatDialog) {
    super(domainService);
    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.userGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onUserGridReady,
      onRowDoubleClicked: this.onRowDoubleClick,
    });
    const data = await this.userAccountsService.getIdentityData();
    this.setDisplayRoles(data.Users);
    this.users = data.Users;
    this.roles = data.Roles;
    this.isPageReady = true;
  }

  canDeactivate() {
    return true;
  }

  async onUserGridReady(evt: any) {
    const colDefs = [
      { headerName: 'First Name', field: 'FirstName', filter: 'agTextColumnFilter' },
      { headerName: 'Last Name', field: 'LastName', filter: 'agTextColumnFilter' },
      { headerName: 'UserName', field: 'User.UserName', filter: 'agTextColumnFilter' },
      { headerName: 'Initials', field: 'Initials', filter: 'agTextColumnFilter' },
      { headerName: 'Locked Out', field: 'IsLockedOut' },
      { headerName: 'Roles', field: 'DisplayRoles', filter: 'agTextColumnFilter' },
      { ...AgFns.createCellButtonProps('', this.editButtonsCell) as any }
    ];
    AgFns.initGrid(this.userGridOptions, colDefs);
  }

  setDisplayRoles(users: UserWithRoles[]) {
    users.forEach(u => u.DisplayRoles = u.Roles.join(', '));
  }

  onRowDoubleClick(e: RowDoubleClickedEvent) {
    if (!e.node.isSelected()) {
      return;
    }
    const user = e.data as UserWithRoles;
    this.editUser(user);
  }

  async editUser(user: UserWithRoles, isNew?: boolean) {
    const r = await UserAccountDialogComponent.show(this.matDialog, { user: user, users: this.users, roles: this.roles, isNew: !!isNew });
    if (r) {
      if (isNew) {
        this.users.push(user);
        this.userGridOptions.api?.setRowData(this.users);
      }
      if (this.authUser.initials == r.Initials) {
        // TODO: rights and roles may have changed
        // we really should refresh these here.
      }
      this.setDisplayRoles(this.users);
      this.userGridOptions.api?.refreshCells( { force: true});
    }
    return r;
  }

  async addUser() {
    const user = {
      id: 'new',
      IsLockedOut: false,
      Roles: [],
      User: {}
    } as any;
    return this.editUser(user, true);
  }

}
