<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div *ngIf="isPageReady">

    <div uni-flex-row>
      <mat-form-field uni-flex-item [matTooltip]="addon.account?.accountName">
        <input matInput placeholder="Account" [ngModel]="addon.account?.accountName" disabled="true" />
      </mat-form-field>
      <button *ngIf="isAdding" mat-raised-button (click)="selectAccount()">Select Account
      </button>
    </div>

    <div uni-flex-row>
      <mat-form-field uni-flex-item [matTooltip]="addon.nameAndLocation">
        <input matInput placeholder="Name And Location" [(ngModel)]="addon.nameAndLocation" />
      </mat-form-field>

    </div>

    <div uni-flex-row>
      <mat-checkbox [(ngModel)]="addon.isHiddenOnDocs" name="isHiddenOnDocs">Is Hidden on
        docs</mat-checkbox>
      <mat-checkbox [(ngModel)]="addon.needsAdditionalInfo" name="needsAdditionalInfo">Needs Additional
        Info</mat-checkbox>
    </div>

    <div uni-flex-row><span>&nbsp;</span></div>

    <div uni-flex-row>
      <mat-form-field uni-flex-item [matTooltip]="addon.additionalInfoDescription">
        <input [disabled]="!addon.needsAdditionalInfo" matInput placeholder="Additional Info Description"
          [(ngModel)]="addon.additionalInfoDescription" />
      </mat-form-field>
    </div>

    <div uni-flex-row>
      <div style="width: 400px">
        <app-image-handler [isReadOnly]="!this.user.isProductAdmin" [oldImages]="addon.addonImages"
          (createJoinEntity)="createImageJoinEntity($event)"></app-image-handler>
      </div>
      <mat-form-field uni-flex-item [matTooltip]="addon.mismatchFeature?.name">
        <input disabled matInput placeholder="Mismatch Feature" [ngModel]="addon.mismatchFeature?.name" />
      </mat-form-field>
    </div>

    <mat-card>Station Info

      <div uni-flex-row>
        <mat-form-field>
          <mat-select [(ngModel)]="addon.addonStation" placeholder="Addon Station">
            <mat-option [value]="aos" *ngFor="let aos of addonStations">
              <span>{{aos.name}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field [matTooltip]="addon.groupingKey" style="width: 220px;">
          <input matInput placeholder="Grouping Key" [(ngModel)]="addon.groupingKey" />
        </mat-form-field>

        <button mat-raised-button class="accent-blue" style="margin-left: 1rem; margin-bottom: 1rem;"
          (click)="addAddonDoc()"> Add Document
        </button>

        <button mat-raised-button class="accent-blue" style="margin-left: 1rem; margin-bottom: 1rem;"
          [disabled]="addon.addonDocMaps.length == 0" (click)="viewAddonDocs()">View Documents
        </button>
      </div>

      <div uni-flex-row>
        <mat-form-field uni-flex-item [matTooltip]="addon.instructions">
          <input matInput placeholder="Station Instructions" [(ngModel)]="addon.instructions" />
        </mat-form-field>
      </div>

      

    </mat-card>
      
    <div uni-flex-row><span>&nbsp;</span></div>  


    <div uni-flex-row>
      <mat-form-field style="width: 200px;" [matTooltip]="addon.activeStatus?.name">
        <mat-select [(ngModel)]="addon.activeStatusId" placeholder="Status">
          <mat-option [value]="activeStatus.id" *ngFor="let activeStatus of activeStatuses">
            {{activeStatus.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>