import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InvoiceHeader, Shipment } from 'app/model/entities/entity-model';
import { ShippingBox } from 'app/model/entities/shipping-box';
import { InvoiceStatusCode } from 'app/model/enums/invoice-status-code';
import { WeightUnitOfMeasureCode } from 'app/model/enums/unit-of-measure-code';
import { Address, ModelPackage, Rate, RateShipmentResponseBody, Weight } from 'app/model/shipEngine/models';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { PrintFns, zplPrefix } from 'app/shared/print-fns';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { ShippingService } from 'app/shipping/shipping.service';
import * as _ from 'lodash';
import { AskShipInfoDialogComponent } from './ask-ship-info-dialog.component';


interface RateResult {
  ok: boolean;
  freightAmt?: number;
  shipmentApiIdentifier?: string;
  error?: string;
}

interface RatesResult {
  ok: boolean;
  error?: string;
  shipmentApiIdentifier?: string;
  rates?: Rate[];
}

interface PrintLabelResult {
  ok: boolean;
  labelApiIdentifier?: string;
  shippingLabel?: string;
  error?: string;
}

@Injectable({ providedIn: 'root' })
export class InvoiceShipmentService {
  constructor(
    private uow: UnitOfWork,
    private dbSaveService: DbSaveService,
    private dbQueryService: DbQueryService,
    private shippingService: ShippingService,
    private dialogService: DialogService,
    private busyService: BusyService,

  ) { }


  async askAndCalcFreight(matDialog: MatDialog, invoiceHeader: InvoiceHeader) {
    let shipment: Shipment;
    // in this case the box doesn't exist yet
    if (invoiceHeader.shipmentId == null) {
      shipment = this.uow.createEntity(Shipment, {
        primaryInvoiceHeaderId: invoiceHeader.id,
      });
      invoiceHeader.shipmentId = shipment.id;
    } else {
      if (invoiceHeader.shipment == null) {
        shipment = await this.dbQueryService.getShipment(invoiceHeader.shipmentId);
      } else {
        shipment = invoiceHeader.shipment;
      }
    }

    const r = await this.askAndCalcRate(matDialog, invoiceHeader);
    if (r != null) {
      shipment.freightAmt = r.freightAmt;
      shipment.shipmentApiIdentifier = r.shipmentApiIdentifier;
      invoiceHeader.freightAmt = invoiceHeader.calcShipFreightAmt();
      invoiceHeader.totalAmt = invoiceHeader.calcTotalAmt();
    } else {
      this.dbSaveService.rejectChanges();
      return false;
    }

    if (invoiceHeader.isPrimaryInvoice) {
      // if multiple invoices for a JO, we put the handling on the same invoice that has the freight
      invoiceHeader.orderHandlingAmt = invoiceHeader.joHeader.handlingAmt;
    }

    await this.dbSaveService.saveChangesWrapBusy();
    return true;
  }


  async printShippingLabel(invoiceHeader: InvoiceHeader) {

    if (invoiceHeader.invoiceStatusId === InvoiceStatusCode.Voucher) {
      // retrieve and save the label, and update status to labelled
      const r = await this.busyService.busy(async () => {
        await this.dbQueryService.checkRowVersion(invoiceHeader);
        // return await this.printShippingLabelCore(invoiceHeader.shipment);
        return await this.saveShippingLabels(invoiceHeader.shipment);
      });
      if (!r.ok) {
        return false;
      }
    }
    
    const r = await this.printShippingLabels(invoiceHeader.shipment);
    return r.ok;
  }

  async voidShippingLabel(invoiceHeader: InvoiceHeader) {
    const r = await this.busyService.busy(async () => {
      await this.dbQueryService.checkRowVersion(invoiceHeader);
      return await this.voidShippingLabelCore(invoiceHeader.shipment);
    });

    const shipment = invoiceHeader.shipment;
    if (r.ok) {
      // TODO: write to VoidShipment table here
      shipment.invoiceHeaders.forEach(inv => {
        inv.invoiceStatusId = InvoiceStatusCode.Voucher;
      });

      shipment.shippingBoxes.forEach(sb => {
        sb.trackingLabelApiIdentifier = null;
        sb.shippingLabel = null;
      });
    } else {
      shipment.invoiceHeaders.forEach(inv => {
        inv.invoiceStatusId = InvoiceStatusCode.VoidError;
      });
    }

    await this.dbSaveService.saveChangesWrapBusy();
    return r.ok;
  }

  async removeVoidError(invoiceHeader: InvoiceHeader) {
    await this.dbQueryService.checkRowVersion(invoiceHeader);

    const shipment = invoiceHeader.shipment;
    const invoices = shipment.invoiceHeaders;
    if (!invoices.every(inv => inv.invoiceStatusId === InvoiceStatusCode.VoidError)) {
      await this.dialogService.showOkMessage('Unable to remove void error',
        'Unable to remove the "void-error" on this shipment because all of invoices in it are not marked with a "void error"');
      return false;
    }

    invoices.forEach(inv => {
      inv.invoiceStatusId = InvoiceStatusCode.Voucher;
    });

    shipment.freightAmt = null;
    shipment.shipmentApiIdentifier = null;
    shipment.labelApiIdentifier = null;

    shipment.shippingBoxes.forEach(sb => {
      sb.trackingLabelApiIdentifier = null;
      sb.shippingLabel = null;
    });

    await this.dbSaveService.saveChangesWrapBusy();
    return true;
  }

  // returns true if new rate was calc'd
  private async askAndCalcRate(matDialog: MatDialog, invoiceHeader: InvoiceHeader) {

    const shipment = invoiceHeader.shipment;
    let isShipInfoNeeded = true;
    // loop thru dialog until either canceled or we get a good rate.
    while (isShipInfoNeeded) {
      const shouldContinue = await AskShipInfoDialogComponent.show(matDialog, { shipment: shipment });
      if (!shouldContinue) {
        return null;
      }

      const r = await this.busyService.busy(async () => {
        return await this.getRate(invoiceHeader);
      });
      if (r.ok) {

        isShipInfoNeeded = false;
        return r;
      }
    }
    // return true;
  }

  /** Get all rates for the given invoice shipment from the given carrier. */
  async getRatesForInvoice(invoiceHeader: InvoiceHeader, carrierId: string, serviceId: string, showErrorDialog = true): Promise<RatesResult> {
    const shipTo = this.createAddress(invoiceHeader);
    const shipment = invoiceHeader.shipment;
    const modelPackages = this.createPackages(shipment.shippingBoxes);

    let rateShipmentResponse: RateShipmentResponseBody;
    await this.busyService.busy(async () => {
      rateShipmentResponse = await this.shippingService.getRates(
        shipTo,
        modelPackages,
        carrierId,
        serviceId,
      );
    });

    // Note 'errors' property will be there in some circumstances ( mixed weight types for example)
    // but it is not defined via the api
    const responseErrors = rateShipmentResponse['errors'];
    if (responseErrors && responseErrors.length > 0) {
      const error = responseErrors.map(e => e.message).join('/n');
      return await this.handleError('Shipping Error', error, showErrorDialog);
    }

    const rateResponse = rateShipmentResponse.rate_response;
    const rateErrors = rateResponse.errors;
    if (rateErrors && rateErrors.length > 0) {
      const error = rateErrors.map(e => e.message).join('/n');
      return await this.handleError('Shipping Error', error, showErrorDialog);
    }

    return { ok: true, shipmentApiIdentifier: rateShipmentResponse.shipment_id, rates: rateResponse.rates };
  }

  /** Get a single rate matching the shipment.serviceApiIdentifier (e.g. 'ups_ground') */
  private async getRate(invoiceHeader: InvoiceHeader, showErrorDialog = true): Promise<RateResult> {
    const shipment = invoiceHeader.shipment;
    const ratesResponse = await this.getRatesForInvoice(invoiceHeader, shipment.carrierApiIdentifier, shipment.serviceApiIdentifier, showErrorDialog);

    const shipmentApiIdentifier = ratesResponse.shipmentApiIdentifier;

    const rates = ratesResponse.rates;
    const rate = _.find(rates, r => r.service_code === shipment.serviceApiIdentifier);
    if (rate == null) {
      const error = 'Unable to locate a rate for ' + shipment.serviceApiIdentifier;
      return await this.handleError('Shipping Error', error, showErrorDialog);
    }

    // NOTE: currency is assumed to be $
    const freightAmt =
      rate.shipping_amount.amount +
      rate.insurance_amount.amount +
      rate.confirmation_amount.amount +
      rate.other_amount.amount;

    return { ok: true, freightAmt, shipmentApiIdentifier };
  }

  /** Get shipping label from ShipEngine and update shipment and shippingBoxes */
  private async saveShippingLabels(shipment: Shipment, showErrorDialog = true): Promise<PrintLabelResult> {
    const labelResponse = await this.shippingService.getLabel(shipment.shipmentApiIdentifier);

    const labelErrors = labelResponse['errors'];
    let error: string;
    if (labelErrors) {
      error = labelErrors.map(e => e.message).join('; ');
      return await this.handleError('Shipping Label Error', error, showErrorDialog);
    }

    const labelDownload = labelResponse.label_download.href;
    const labelPackages = labelResponse.packages;
    const labelFormat = labelResponse.label_format;
    const boxes = shipment.shippingBoxes;
    
    // update box corresponding to each label package
    labelPackages.forEach(lp => {
      const box = boxes.find(b => b.externalPackageId === lp.external_package_id);
      if (box) {
        box.trackingLabelApiIdentifier = lp.tracking_number; 
        box.shippingLabel = (lp.label_download && lp.label_download[labelFormat]) || labelDownload;
      }
    });

    // shipment.label = labelResponse.label_download && labelResponse.label_download.href;
    shipment.labelApiIdentifier = labelResponse.label_id;
    shipment.invoiceHeaders.forEach(inv => {
      inv.invoiceStatusId = InvoiceStatusCode.VoucherLabeled;
    });

    await this.dbSaveService.saveChangesWrapBusy();
    return { ok: true, labelApiIdentifier: shipment.labelApiIdentifier };
  }

  /** Print the labels in the shippingBoxes belonging to the shipment. 
   * Each box.shippingLabel is a url for downloading the label.
  */
  private async printShippingLabels(shipment: Shipment) {
    const boxes = shipment.shippingBoxes;
    if (!boxes.length || !boxes.every(box => box.shippingLabel)) {
      return await this.handleError('Label Printing Error', 'No boxes, or boxes do not have shipping labels', true);
    }
    return this.printBoxShippingLabels(boxes);
  }

  async printBoxShippingLabels(boxes: ShippingBox[]) {
    // download the label content from the shippingLabel urls on the boxes
    const prints = boxes.map(box => ({ url: box.shippingLabel, name: box.externalPackageId, data: null }));

    // download data if QZ is not available to do it when printing
    const hasQZ = await PrintFns.startQZ();
    if (!hasQZ) {
      await this.shippingService.downloadFileBlobs(prints);
    }

    await PrintFns.printFiles(prints, 'labelPrinter');

    return { ok: true };
  }

  private async voidShippingLabelCore(shipment: Shipment, showErrorDialog = true) {
    // Do NOT do next line because we want to make sure than we get an opto-conc error if shipment is changed by another user.
    // const shipment = await this.dbQueryService.getShipment(shipmentId);

    // Steve: Review this - probably not what is needed to void a label.
    const labelApiIdentifier = shipment.labelApiIdentifier;
    if (labelApiIdentifier == null) {
      return { ok: false };
    }

    const voidResponse = await this.shippingService.voidLabel(labelApiIdentifier);
    // errors is not defined on the VoidResponse def ... but it exists.
    let error: string = null;
    if (voidResponse['errors']) {
      error = voidResponse['errors'].map(e => e.message).join('/n');
    } else if (!voidResponse.approved) {
      error = voidResponse.message;
    }

    if (error) {
      return await this.handleError('Shipping Label Void Error', error, showErrorDialog);
    } else {
      shipment.labelApiIdentifier = null;
      return { ok: true };
    }
  }

  private async handleError(title: string, error: string, showErrorDialog: boolean) {
    if (showErrorDialog) {
      await this.dialogService.showOkMessage(title, error);
    }
    return { ok: false, error: title + ': ' + error };
  }

  private createAddress(invoiceHeader: InvoiceHeader) {
    const joh = invoiceHeader.joHeader;
    const address = <Address>{
      name: joh.shippingName,
      address_line1: joh.shippingAddress1,
      address_line2: joh.shippingAddress2,
      city_locality: joh.shippingCity,
      state_province: joh.shippingState,
      postal_code: joh.shippingZipCode.trim().replace(/-$/, ''), // trims trailing '-'
      country_code: 'US',
      phone: '714-781-4565', // TODO: should come from invoice
    };
    return address;
  }

  private createPackages(shippingBoxes: ShippingBox[]) {
    // TODO: create a mapping lookup for this
    let unit: string;
    const packages = shippingBoxes.map(sb => {
      const uomId = sb.weightUoMId;
      if (uomId === WeightUnitOfMeasureCode.Ounce) {
        unit = Weight.UnitEnum.Ounce;
      } else if (uomId === WeightUnitOfMeasureCode.Pound) {
        unit = Weight.UnitEnum.Pound;
      } else if (uomId === WeightUnitOfMeasureCode.Gram) {
        unit = Weight.UnitEnum.Gram;
      } else if (uomId === WeightUnitOfMeasureCode.Kilogram) {
        unit = Weight.UnitEnum.Kilogram;
      }
      const modelPackage = <ModelPackage>{
        weight: {
          unit: unit,
          value: sb.weight,
        },
      };
      if (sb.packageApiIdentifier != null) {
        modelPackage.package_code = sb.packageApiIdentifier;
      }
      modelPackage.external_package_id = sb.externalPackageId;
      return modelPackage;
    });
    return packages;
  }

  /** download and print shipping labels.  print 1 label for each url. */
  // Not currently used
  // printShippingLabelsForUrls(urls: string[]) {
  //   const fetched = {};
  //   urls.forEach(url => {
  //     url += '?download=0';
  //     if (!fetched[url]) {
  //       this.http.get(url).toPromise().then(r => {
  //         const zpl = r as string;
  //         fetched[url] = zpl;
  //         PrintFns.printZpl(zpl, 'labelPrinter');
  //       });
  //     } else {
  //       PrintFns.printZpl(fetched[url], 'labelPrinter');
  //     }
  //   });
  // }

}
