<app-uni-dialog title="Items already applied to Job Order Detail" [parent]="this" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <app-ag-grid [gridOptions]="itdGridOptions" [rowData]="itemDetails">
  </app-ag-grid>
  <ng-template #unapplyCell let-row>
    <button (click)="unapplyItemDetail(row)">Unapply</button>
  </ng-template>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="unapplyAll()">Unapply All</button>
  <button mat-raised-button (click)="close()">Close</button>
</ng-template>
