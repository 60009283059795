import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { GalleryItem } from 'ng-gallery';
import { firstValueFrom } from 'rxjs';
import { AppImage, ImageFns } from './image-fns';
import { UnitOfWork } from '../unit-of-work';

export interface FileGalleryDialogData {
  newImages: AppImage[];
  oldImages: AppImage[];
  uow: UnitOfWork;
  /** callback when an image is deleted */
  onDelete?: (image: AppImage) => void;
  /** callback when an image is added or changed */
  onChange?: (image: AppImage) => void;
}

@Component({
  selector: 'app-file-gallery-dialog',
  templateUrl: './file-gallery-dialog.component.html',
})
export class FileGalleryDialogComponent implements OnInit, OnDestroy {

  static async show(matDialog: MatDialog, data: FileGalleryDialogData) {
    return firstValueFrom(matDialog.open(FileGalleryDialogComponent, {
      height: '800px',
      width: '900px',
      data: data,
    }).afterClosed());
  }

  activeIndex = 0;
  showGallery = true;
  showEdit = false;
  images: AppImage[] = [];
  cropInput?: File;
  galleryImages: GalleryItem[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: FileGalleryDialogData,
    private readonly dialogRef: MatDialogRef<FileGalleryDialogData>, private readonly sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.activeIndex = 0;
    const images = ImageFns.convertFileData(this.data.oldImages)
    this.images = images.concat(this.data.newImages);
    this.images.sort((a, b) => {
      if (a.isPrimary && !b.isPrimary) {
        return -1;
      } else if (b.isPrimary && !a.isPrimary) {
        return 1;
      }
      return a.fileName.localeCompare(b.fileName);
    });
    ImageFns.generateUrls(this.images, this.sanitizer);
    this.makeGalleryImages();
  }

  ngOnDestroy(): void {
    ImageFns.clearUrls(this.images);
  }

  delete(image: AppImage): void {
    if (image && this.data.onDelete) {
      this.data.onDelete(image);
      if (image.blobUrl) {
        image.safeUrl = undefined;
        URL.revokeObjectURL(image.blobUrl);
      }
      _.remove(this.images, im => im === image);
      if (this.activeIndex >= this.images.length && this.activeIndex > 0) {
        this.activeIndex--;
      }
      this.redraw();
    }
  }

  /** return false if image is a thumbnail, or has a related thumbnail already */
  canMakeThumbnail(image: AppImage): boolean {
    const thumbname = ImageFns.addSuffix(image.fileName, '-thumb');
    return !image.isThumbnail && !this.images.some(i => i.fileName == thumbname);
  }

  /** Create a new image as a thumbnail of the given image */
  async makeThumbnail(image: AppImage) {
    if (!image.file) {
      return;
    }
    const thumb = await ImageFns.resizeFileToThumbnail(image.file);
    this.addImage(thumb);
  }

  /** Ensure that at most 1 image is set primary */
  primaryChange(item: AppImage) {
    if (this.data.onChange) {
      this.data.onChange(item);
    }
    if (item.isPrimary) {
      // make all other images non-primary, but treat thumbnails separately
      for (const i of this.images) {
        const primary = (i == item);
        if (i.isPrimary != primary && i.isThumbnail == item.isThumbnail) {
          i.isPrimary = primary;
          if (this.data.onChange) {
            this.data.onChange(i);
          }
        }
      }
    }
  }

  /** Open imageCropper for active image */
  edit(item: AppImage) {
    this.cropInput = item.file;
    this.showGallery = false;
    this.showEdit = true;
  }


  /** When Ok clicked on cropper, add the cropped file as a new image */
  async cropOk(blob: Blob) {
    if (blob) {
      const image = this.images[this.activeIndex];
      const file = new File([blob], image.fileName, { lastModified: image.file?.lastModified, type: blob.type });
      const names = this.images.map(i => i.fileName);
      const newName = ImageFns.getNextName(image.fileName, names);
      const image2 = await ImageFns.resizeFileToStandard(file, newName);
      this.addImage(image2);
    }
    this.cropCancel();
  }

  /** When Cancel clicked on cropper */
  cropCancel() {
    this.showEdit = false;
    this.showGallery = true;
  }

  close() {
    ImageFns.clearUrls(this.images);
    this.dialogRef.close();
  }

  indexChange(event: any) {
    this.activeIndex = event.currIndex;
  }

  /** Add to list as a new image */
  private addImage(thumb: AppImage) {
    this.data.newImages?.push(thumb);
    ImageFns.generateUrls([thumb], this.sanitizer);
    this.images.splice(this.activeIndex + 1, 0, thumb);
    this.activeIndex++;
    if (this.data.onChange) {
      this.data.onChange(thumb);
    }
    this.redraw();
  }

  /** Add files chosen by the file control to the newImages collection */
  async addFiles(event: Event) {
    const added = await ImageFns.addFiles(event, this.data.newImages, this.data.uow);
    ImageFns.generateUrls(added, this.sanitizer);
    this.images.push(...added);
    this.activeIndex = 0;
    this.redraw();
  }

  /** Hide and show the gallery to reset the thumbnail display. */
  private redraw() {
    this.showGallery = false;
    this.makeGalleryImages();
    setTimeout(() => {
      this.showGallery = true;
    }, 0);
  }

  /** Convert image to format needed by ng-gallery component */
  private makeGalleryImages() {
    this.galleryImages = this.images.map(x => ({ data: { src: x.safeUrl as string, thumb: x.safeUrl as string,
      alt: x.fileName, appImage: x, canDelete: !!this.data.onDelete }, type: 'image' }));
  }

}
