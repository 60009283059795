import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuardService  {

  constructor(private _router: Router, private _authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._authService.isLoggedIn().then(t => {
      this._authService.redirectUrl = state.url;
      if (!t) {
        this._router.navigate(['/login'], {skipLocationChange: false});
        return false;
      }

      // make sure user has permissions to the route
      const user = this._authService.getUser();

      if (user.isUserAdmin) { return true; }

      if (state.url === '/user-accounts') {
        this._router.navigate(['/home']);
        return false;
      }
      // ...

      return t;
    });
  }
}
