// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { InvoiceHeader } from './invoice-header';
import { ManifestGroup } from './manifest-group';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ManifestGroupInvoice extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  manifestGroupId: number;
  invoiceHeaderId: string;
  modTs: Date;
  invoiceHeader: InvoiceHeader;
  manifestGroup: ManifestGroup;
}

