import { Entity } from 'breeze-client';
import * as _ from 'lodash';

export class EntityFns {

  static getChangedValues(entity: Entity, fieldName: string) {
    const curValue = entity[fieldName];
    const origValue = entity.entityAspect.originalValues[fieldName];
    return { changed: origValue !== curValue, curValue: curValue, origValue: origValue };
  }

  static hasValueChanged(entity: Entity, fieldName: string) {
    const origValue = entity.entityAspect.originalValues[fieldName];
    return origValue !== entity[fieldName];
  }

  static cloneStruct(entity: Entity, propsToExclude: string[], extraProps: string[] = []) {
    var dps = entity.entityType.dataProperties
      .map(dp => dp.name)
      .filter(nm => !propsToExclude.includes(nm));
    dps = dps.concat(...extraProps)
    const clone = _.pick(entity, dps) as Object;
    return clone;
  }

  private static logEntity(e: Entity, alreadyLogged: Entity[], depth: number, maxDepth: number) {
      if (depth >= maxDepth || !e) {
        return;
      }
      let indent = '    '.repeat(depth);
      console.log(indent + e.entityType.shortName + ': ' + e.entityAspect.getKey().values + ' (' + e.entityAspect.entityState.name + ')');

      if (alreadyLogged.includes(e)) {
        return;
      }
      alreadyLogged.push(e);

      indent += '  ';
      // log data properties
      const dataProps = e.entityType.dataProperties;
      dataProps.forEach(p => {
        console.log(indent + p.name + ': ' + e[p.name]);
      });

      // log navigation properties
      const navProps = e.entityType.navigationProperties;
      navProps.forEach(p => {
        const val = e[p.name];
        if (Array.isArray(val)) {
          console.log(indent + p.name + ': [');
          val.forEach(v => {
            this.logEntity(v, alreadyLogged, depth + 1, maxDepth);
          });
          console.log(indent + ']');
        } else {
          console.log(indent + p.name + ': ');
          this.logEntity(val, alreadyLogged, depth + 1, maxDepth);
        }
      });
  }

  static logEntities(entities: Entity[], maxDepth: number) {
    const alreadyLogged = [];
    entities.forEach(e => {
      this.logEntity(e, alreadyLogged, 0, maxDepth);
    });
  }

}
