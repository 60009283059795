import { FuseNavigation } from '@fuse/types';
import { environment } from 'environments/environment';

export const anonNavigation: FuseNavigation[] = [
  {
    id: 'applications',
    title: 'Applications',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
      
    ]
  }];


export const defaultNavigation: FuseNavigation[] = [
  {
    id: 'applications',
    title: 'Applications',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'Home',
        type: 'item',
        icon: 'home',
        url: '/home',
        // badge    : {
        //     title    : '25',
        //     bg       : '#F44336',
        //     fg       : '#FFFFFF'
        // }
      },
      {
        id: 'job-orders',
        title: 'Job Orders',
        type: 'item',
        icon: 'assignment',
        url: '/job-orders',
      },
      {
        id: 'purchase-orders',
        title: 'Purchasing',
        type: 'item',
        icon: 'shopping_cart',
        url: '/purchase-orders',
      },
      {
        id: 'in-process',
        title: 'In Process',
        type: 'item',
        icon: 'shopping_cart',
        url: '/in-process',
      },
      {
        id: 'inventory',
        title: 'Inventory',
        type: 'item',
        icon: 'assessment',
        url: '/inventory',
      },
      {
        id: 'references',
        title: 'References',
        type: 'item',
        icon: 'assessment',
        url: '/references',
      },
      {
        id: 'accounts',
        title: 'Accounts',
        type: 'item',
        icon: 'business',
        url: '/accounts',
      },
      {
        id: 'issues',
        title: 'Issues',
        type: 'item',
        icon: 'report_problem',
        url: '/entity-issue',
      },
      { 
        id: 'user',
        title: 'User',
        type: 'item',
        icon: 'person',
      },
      { 
        id: 'users',
        title: 'Users',
        type: 'item',
        icon: 'person',
        url: '/user-accounts'
      },
      {
        id: 'proxapi',
        title: 'Proximity Api',
        type: 'item',
        icon: 'person',
        url: '/proximity-api'
      },
      {
        id: 'api',
        title: 'API Documentation',
        type: 'item',
        icon: 'extension',
        url: environment.apiRoot + 'swagger/index.html',
        externalUrl: true,
        openInNewTab: true,
      },
      // {
      //   id: 'jo-api',
      //   title: 'Job Order API (Test)',
      //   type: 'item',
      //   icon: 'extension',
      //   url: '/job-orders-api',
      // },
      // {
      //   id: 'return-req-auth-api',
      //   title: 'Return Req. Auth. API (Test)',
      //   type: 'item',
      //   icon: 'extension',
      //   url: '/return-req-auth-api',
      // },
      // {
      //   id: 'account-api',
      //   title: 'Account API (Test)',
      //   type: 'item',
      //   icon: 'extension',
      //   url: '/account-api',
      // },
      // {
      //   id: 'shipping',
      //   title: 'Shipping (Test)',
      //   type: 'item',
      //   icon: 'local_shipping',
      //   url: '/shipping',
      // },
    ],
  },
];
