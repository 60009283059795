<app-uni-page  [parent]="this" title="Manifests" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">

    <as-split-area [size]="50">
      <app-ag-grid [gridOptions]="manifestGridOptions"></app-ag-grid>
      <ng-template #invoiceScheduleCell let-row>
        <button *ngIf="row?.rRule != null" (click)="viewEventSchedule(row)">View</button>
        
      </ng-template>

      <ng-template #sendManifestCell let-row>
        <button [disabled]="!canSendManifest(row)" (click)="sendManifest(row)">Print/Send Manifest</button>
      </ng-template>
    </as-split-area>

    <as-split-area [size]="50">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="Job Orders">
          <app-ag-grid [gridOptions]="johGridOptions" [rowData]="joHeaders" ></app-ag-grid>
        </mat-tab>
        <mat-tab label="Manifest Invoices" >
          <app-ag-grid [gridOptions]="manifestGroupGridOptions" [rowData]="manifestGroups"></app-ag-grid>
          <ng-template #viewManifestCell let-row>
            <button (click)="viewManifest(row)">View Manifest</button>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </as-split-area>
    
  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
  </div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>