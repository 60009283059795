<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary='.cdk-overlay-container' cdkDragHandle>{{ title ?? 'Unhandled Error' }}
</h2>
<mat-dialog-content >
  <div class="content-card" style="flex-grow: 1">
    <div uni-flex-row class="ml-12 mb-24" >
      <mat-form-field style="width: 100%;">
        <!-- have to use ngModel here, not {{}}, or  error is thrown -->
      <textarea rows="8" matInput placeholder="Error Message" [ngModel]="message" editable=false ></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="location?.length > 0" style="width: 100%;">
        <textarea rows="3"  matInput placeholder="Location" [ngModel]="location"></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="stack?.length > 0" style="width: 100%;">
        <!-- have to use ngModel here, not {{}}, or  error is thrown -->
        <textarea rows="6" matInput placeholder="Stack Trace" [ngModel]="stack" ></textarea>
      </mat-form-field>
    </div>
    <div>Do you want to refresh the current page?</div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button class="btn btn-basic" *ngFor="let buttonName of data.buttons, let i=index"
    (click)="select(i)">{{data.buttons[i]}}</button>
</mat-dialog-actions>
