export enum ItemDetailStatusCode {
  InInventory = 0,
  InProcessPendingShipPrep = 10,
  InProcessPendingAddon = 11,
  InProcessAddon = 20,
  InProcessShipPrep = 30,
  InProcessShipHeld = 31,
  InProcessVoucher = 45,
  Shipped = 50,
  Special = 60,
  MigratedUnknown = 91,
  MissingFromInventory = 93,
  DamagedOrLost = 99
}

export const ItemDetailStatusCodes = {
  Addon: [
    ItemDetailStatusCode.InProcessAddon, 
    ItemDetailStatusCode.InProcessPendingAddon
  ],
  ShipPrep: [
    ItemDetailStatusCode.InProcessPendingShipPrep,
    ItemDetailStatusCode.InProcessShipPrep,
    ItemDetailStatusCode.InProcessShipHeld,
  ],
  InProcess: [
    ItemDetailStatusCode.InProcessAddon, 
    ItemDetailStatusCode.InProcessPendingAddon,
    ItemDetailStatusCode.InProcessPendingShipPrep,
    ItemDetailStatusCode.InProcessShipPrep,
    ItemDetailStatusCode.InProcessShipHeld,
    ItemDetailStatusCode.InProcessVoucher,
  ]
}