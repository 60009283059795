// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { PoHeader } from './po-header';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class PoStatus extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: string;
  name: string;
  poHeaders: PoHeader[];
}

