import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { DialogService } from './dialog.service';
import { ErrorLogger } from './error-logger';
import { BusyService } from './busy.service';
import { DbQueryService } from './db-query.service';
import { UnitOfWork } from './unit-of-work';
import { DbSaveService } from './db-save.service';


@Injectable({providedIn: 'root'})
export class DomainService {

  constructor(public uow: UnitOfWork, public authService: AuthService,
    public dbQueryService: DbQueryService, public dbSaveService: DbSaveService,
    public dialogService: DialogService,
    public errorLogger: ErrorLogger, public busyService: BusyService,
    public router: Router, public location: Location ) {
      // Note: can't include ActivatedRoute or ElementRef here

  }

}
