import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GridOptions } from '@ag-grid-community/core';
import { AgFns } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { AddonBin, ItemDetail } from 'app/model/entities/entity-model';
import { EntityFns } from 'app/shared/entity-fns';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';

@Component({
  templateUrl: './addon-bin-split-dialog.component.html',
})
export class AddonBinSplitDialogComponent {
  title: string;
  addonBin: AddonBin;

  gridOptions: GridOptions;
  itemDetails: ItemDetail[];
  isPageReady = false;
  shouldSplit = '_shouldSplit';

  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(AddonBinSplitDialogComponent, {
        disableClose: true,
        height: '480px',
        width: '700px',
        data: data
      })
      .afterClosed()
      .toPromise();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddonBinSplitDialogComponent>,
    private dbQueryService: DbQueryService,
    private dbSaveService: DbSaveService,
    private uow: UnitOfWork,
    private dialogService: DialogService
  ) {
    this.title = 'Select Item Details to split off into their own bin';
    this.addonBin = this.data.addonBin;

    this.prepare();
  }

  async prepare() {
    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
    });

    this.itemDetails = await this.dbQueryService.getItemDetailsForAddonBin(this.addonBin.id)

    // just in case;
    this.itemDetails.forEach(jo => jo[this.shouldSplit] = null);
    this.isPageReady = true;
  }
  
  onGridReady() {
      const colDefs = [
        { headerName: 'Item Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
        { headerName: 'Split', field: this.shouldSplit, cellRenderer: AgCheckboxCellComponent },
        { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
        { headerName: 'Description', field: 'product.productType.description' },
        { headerName: 'Style', field: 'product.productType.style' },
        { headerName: 'Features', field: 'product.featureChoicesExtract' },
        { headerName: 'JO Header Id', field: 'joDetail.joHeaderId' },
        { headerName: 'Jo Detail Id', field: 'joDetail.id' },
      ];
  
      const sortModel =  [
        {   colId: 'itemDetail.id', sort: 'asc' as const },
      ];
    AgFns.initGrid(this.gridOptions, colDefs);
    AgFns.sizeColumnsToFit(this.gridOptions);
  }

  async ok() {
    this.gridOptions.api.stopEditing();

    const idsToSplit = this.itemDetails.filter(x => x[this.shouldSplit])
      .map(x => x.id);

    if (idsToSplit.length === 0) {
      this.dialogService.showOkMessage('Select at least one', 'You must select at least one Item Detail to split off.');
      return false;
    } else if (idsToSplit.length === this.itemDetails.length) {
      this.dialogService.showOkMessage('Cannot select all', `You cannot select all of the Item Details to be split off.
      You must leave at least 1 ItemDetail left on the original Addon Bin`);
      return false;
    }

    const idSet = new Set(idsToSplit);

    const clone = EntityFns.cloneStruct(this.addonBin, ['id']);

    const newAddonBin = this.uow.createEntity(AddonBin, clone);
    
    const itdas = this.addonBin.itemDetailAddons.filter(x => idSet.has(x.itemDetailId));
    itdas.forEach(x => x.addonBinId = newAddonBin.id);

    await this.dbSaveService.saveChanges();
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
