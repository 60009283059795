<app-uni-page  [parent]="this" *ngIf="isPageReady"  title="Inventory/EOQ" 
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">
    <as-split-area [size]="33">
      <app-ag-grid [gridOptions]="ptGridOptions"></app-ag-grid>
      <ng-template #ptButtonsCell let-row>
        <button class="mr-8" (click)="editStyle(row)">Edit Style</button>
      </ng-template>
      

    </as-split-area>
    <as-split-area [size]="64">
      <as-split unit="percent" direction="vertical">
        <as-split-area [size]="33" >
          <app-ag-grid [gridOptions]="ptaGridOptions" [rowData]="ptaItems"></app-ag-grid>
        </as-split-area>

        <as-split-area [size]="64"  >
          <app-ag-grid [gridOptions]="prodGridOptions" [rowData]="prodItems"></app-ag-grid>
        </as-split-area>
      </as-split>
    </as-split-area>
  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <mat-checkbox class="ml-8" [ngModel]="showPercentages" (ngModelChange)="toggleShowPercentages()"
    name="showPercentagesCb">Show Percentages</mat-checkbox>
    <button *ngIf="canShowFormula()" mat-raised-button (click)="showFormula()">
      <span>Show Formula</span>
    </button>
    <button *ngIf="this.uow.hasChanges()" mat-raised-button (click)="saveChanges()">
      <span>Save Changes</span>
    </button>
    <button *ngIf="this.uow.hasChanges()" mat-raised-button (click)="cancelChanges()">
      <span>Cancel Changes</span>
    </button>
  </div>
  <div>
    <button id="product-types" class="mr-8" mat-raised-button (click)="goProductTypes()">
      <span>Product Types</span>
    </button>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>