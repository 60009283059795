import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface YesNoResult {
  button: string;
  index: number;
}

@Component({
  templateUrl: './validation-save-errors-dialog.component.html'
})
export class ValidationSaveErrorsDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ValidationSaveErrorsDialogComponent>) {}

  close() {
    this.dialogRef.close();
  }
}
