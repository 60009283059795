import { ActivatedRoute, Router } from '@angular/router';

import { AuthUser } from './../shared/auth.service';

import { DialogService } from './../shared/dialog.service';
import { DomainService } from './../shared/domain.service';

import { UtilFns } from './util-fns';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DbQueryService } from './db-query.service';
import { UnitOfWork } from './unit-of-work';
import { AgFns } from './ag-fns';
import { DbSaveService } from './db-save.service';
import { BusyService } from './busy.service';
import { provideParent } from './provide-parent';
import { Entity } from 'breeze-client';

@Component({
  template: '<div></div>',
  providers:  [provideParent(this)]
})
export abstract class DomainBaseComponent implements OnInit, OnDestroy {
  authUser: AuthUser;

  self: DomainBaseComponent;
  UtilFns = UtilFns;  // needed in order to access UtilFns from within html
  AgFns = AgFns;
  private _isPageReady: boolean;
  protected dialogService: DialogService;

  protected dbQueryService: DbQueryService;
  protected dbSaveService: DbSaveService;
  public busyService: BusyService;
  protected router: Router;
  protected onDestroy = new Subject<void>();
  public uow: UnitOfWork;
  protected opIsPending: boolean;
  user: AuthUser;

  constructor(protected domainService: DomainService) {
    this.authUser = domainService.authService.getUser();

    this.dialogService = domainService.dialogService;
    this.dbQueryService = domainService.dbQueryService;
    this.dbSaveService = domainService.dbSaveService;
    this.busyService = domainService.busyService;
    this.uow = domainService.uow;
    this.router = domainService.router;
    this.self = this;
    this.isPageReady = false;
  }

  ngOnInit(): void {
    this.user = this.domainService.authService.getUser();
  }

  ngOnDestroy(): void {
    // used to unsubscribe from subscriptions.
    this.onDestroy.next();
    // this._domainService.busyService.setBusy(false);
  }

  get isPageReady() {
    return this._isPageReady;
  }
  set isPageReady(val: boolean) {
    this._isPageReady = val;
    this.domainService.busyService.setBusy(!val);
  }

  getPageIcon() {
    const route = this['route'];
    const pageIcon = route?.snapshot.routeConfig.data?.icon;
    return pageIcon;
  }

  async wrapPending(op: () => Promise<any>) {
    if (this.opIsPending) {
      return null;
    }
    this.opIsPending = true;
    let r = null;
    try {
      const r = await op();
    } finally {
      this.opIsPending = false;
    }
    return r;
  }

  /** get named param from the end of the current route */
  getRouteParam(name: string) {
    let rt = this.router.routerState.snapshot.root;
    while (rt.firstChild) { rt = rt.firstChild; }
    return rt.params[name];
  }

  /** get named param from the first occurance in the current route */
  getFirstParam(name: string) {
    let rt = this.router.routerState.snapshot.root;
    while (!rt.params[name] && rt.firstChild) { rt = rt.firstChild; }
    return rt.params[name];
  }

  dbLookup(lookupTypeName: string, lookupId: any   ) {
    return this.dbQueryService.lookup(lookupTypeName, lookupId);
  }

  getRouteSnapshot() {
    let ss = this.router.routerState.snapshot;
    return ss;
  }

  noop() {
    // do nothing
  }

  goBack() {
    this.domainService.location.back();
  }

}
