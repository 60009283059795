import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusyService } from 'app/shared/busy.service';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(
    private busyService: BusyService,
    private http: HttpClient
  ) { }

  upload<TResponse>(url: string, formData: FormData) {
    return this.busyService.busy(this.http.post<TResponse>(url, formData).toPromise());
  }

  download(url: string, fileName: string): Promise<any> {
    return this.busyService.busy(this.http.get(url, { responseType: 'blob' }).toPromise()
        .then((blob) => this.downloadInternal(blob, fileName)));
  }

  private downloadInternal(blob: Blob, filename: string): void {
    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.target = '_self';

    if (filename) {
      link.download = filename;
    }

    link.click();

    // needs timeout because Firefox removes the object before the actual download
    window.setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 100);
  }  
}
