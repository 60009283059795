import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from 'app/shared/auth-guard.service';
import { EntityManagerProviderGuard } from 'app/shared/entity-manager-guard';
import { InventoryComponent } from './inventory.component';
import { SharedModule } from 'app/shared/shared.module';
import { CanDeactivateGuard } from 'app/shared/can-deactivate-guard.service';
import { InventoryDamageDialogComponent } from './inventory-damage-dialog.component';
import { ProductTypesComponent } from './product-types.component';
import { AltProductTypeDialogComponent } from './alt-product-type-dialog.component';
import { EditProductDialogComponent } from './edit-product-dialog.component';
import { FeatureComponent } from './feature.component';
import { InventoryEoqComponent } from './inventory-eoq.component';
import { AddInventoryComponent } from './add-inventory.component';
import { ProductFinderDialogComponent } from './product-finder-dialog.component';
import { InventoryScanComponent } from './inventory-scan.component';
import { ManufacturerComponent } from './manufacturer.component';
import { EditManufacturerDialogComponent } from './edit-manufacturer-dialog.component';
import { AddonComponent } from './addon.component';
import { EditGenericEntityDialogComponent } from './edit-generic-entity-dialog.component';
import { FeatureChoiceFinderDialogComponent } from './feature-choice-finder-dialog.component';
import { ProductTypeTagComponent } from './product-type-tag.component';
import { ProductTypeFinderDialogComponent } from './product-type-finder-dialog.component';
import { ImageModule } from 'app/shared/image/image.module';
import { FeatureFinderDialogComponent } from './feature-finder-dialog.component';
import { EditFeatureChoiceDialogComponent } from './edit-feature-choice-dialog.component';
import { ProductTypeFeatureChangeDialogComponent } from './product-type-feature-change-dialog.component';
import { FeatureChangeDialogComponent } from './feature-change-dialog.component';

import { EditAddonDialogComponent } from './edit-addon-dialog.component';
import { AddonStationComponent } from './addon-station.component';
import { EditFeatureDialogComponent } from './edit-feature-dialog.component';
import { ProductTypeComponent } from './product-type.component';

const routes = [
  {
    path: 'inventory',
    component: InventoryComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'product-types',
    component: ProductTypesComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'feature',
    component: FeatureComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'inventory-eoq',
    component: InventoryEoqComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'add-inventory',
    component: AddInventoryComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'inventory-scan',
    component: InventoryScanComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'manufacturers',
    component: ManufacturerComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'addons',
    component: AddonComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'addon-stations',
    component: AddonStationComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'product-type/:productTypeId',
    component: ProductTypeComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'product-type-tags',
    component: ProductTypeTagComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
];

routes.forEach( (r: any) => {
  r.data =  Object.assign(r.data ?? {}, { icon: 'assessment'});
});


@NgModule({
  declarations: [
    InventoryComponent,
    InventoryScanComponent,
    AddInventoryComponent,
    InventoryEoqComponent,
    ProductTypeComponent,
    InventoryDamageDialogComponent,
    FeatureComponent,
    AltProductTypeDialogComponent,
    EditProductDialogComponent,
    EditFeatureChoiceDialogComponent,
    ProductFinderDialogComponent,
    ManufacturerComponent,
    ProductTypesComponent,
    EditAddonDialogComponent,
    EditManufacturerDialogComponent,
    AddonComponent,
    AddonStationComponent,
    EditGenericEntityDialogComponent,
    FeatureChoiceFinderDialogComponent,
    FeatureFinderDialogComponent,
    ProductTypeFinderDialogComponent,
    ProductTypeTagComponent,
    ProductTypeFeatureChangeDialogComponent,
    EditFeatureDialogComponent,
    FeatureChangeDialogComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    ImageModule,
  ],
  exports: [
  ],
})

export class InventoryModule {
}
