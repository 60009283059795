// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ProductType } from './product-type';
import { ProductTypeTag } from './product-type-tag';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ProductTypeTagMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  productTypeId: number;
  productTypeTagId: number;
  productType: ProductType;
  productTypeTag: ProductTypeTag;
}

