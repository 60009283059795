import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UtilFns } from './util-fns';

export interface FileViewDialogData {
  title: string;
  files?: File[];
  docs?: IDoc[]; // 
}

export interface IDoc {
  doc: any;
  mimeType: string;
  fileName: string;
}

// /** Convert encoded string into byte array for File or Blob */
// export const stringToByteArray = (s: string): Uint8Array => {
//   const byteCharacters = atob(s);
//   const byteNumbers = new Array(byteCharacters.length);
//   for (let i = 0; i < byteCharacters.length; i++) {
//     byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }
//   const byteArray = new Uint8Array(byteNumbers);
//   return byteArray;
// }



@Component({
  templateUrl: './file-view-dialog.component.html'
})
export class FileViewDialogComponent implements OnInit, OnDestroy {

  isPageReady = false;
  blobUrl: string;
  safeUrl: SafeResourceUrl;
  fileName: string;
  index: number;
  type: 'img'|'pdf'|'txt'|'other';
  files: File[];

  static async show(matDialog: MatDialog, data: FileViewDialogData) {
    const r = await matDialog.open(FileViewDialogComponent, {
      disableClose: true,
      height: '600px',
      width: '800px',
      data: data
    })
      .afterClosed().toPromise();
    return <File>r;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FileViewDialogData, 
    public dialogRef: MatDialogRef<FileViewDialogData>,
    private readonly sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    
    this.index = 0;
    if (this.data.docs != null && this.data.docs.length > 0) {
      this.files = this.data.docs.map( d => {
        const byteArray = UtilFns.stringToByteArray(d.doc);
        const file = new File([byteArray], d.fileName, { type: d.mimeType });
        return file;
      });
    } else if (this.data.files) {
      this.files = this.data.files;
    } else {
      this.dialogRef.close(null);
      return;
    }
    this.isPageReady = true;
    this.showFile();
  }

  ngOnDestroy(): void {
    this.clearUrl();
  }

  hasNext(): boolean {
    return this.index < this.files.length - 1;
  }

  hasPrev(): boolean {
    return this.index > 0 && this.files.length > 0;
  }

  next(): void {
    if (this.hasNext()) {
      this.index++;
      this.showFile();
    }
  }

  prev(): void {
    if (this.hasPrev()) {
      this.index--;
      this.showFile();
    }
  }

  private showFile() {
    this.clearUrl();
    const file = this.files[this.index];
    if (file) {
      this.setFileType(file);
      this.blobUrl = URL.createObjectURL(file);
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobUrl);
      this.fileName = file.name;
    }
  }

  private setFileType(file: File) {
    if (file.type.endsWith('pdf')) {
      this.type = 'pdf';
    }
    else if (file.type.startsWith('image')) {
      this.type = 'img';
    }
    else if (file.type == 'text/plain' || file.type == 'text/html') {
      this.type = 'txt';
    }
    else {
      this.type = 'other';
    }
  }

  private clearUrl() {
    this.type = null;
    if (this.blobUrl) {
      this.safeUrl = null;
      URL.revokeObjectURL(this.blobUrl);
    }
  }

  close() {
    this.clearUrl();
    this.dialogRef.close(null);
  }

}
