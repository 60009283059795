// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ItemDetail } from './item-detail';
import { ItemReplacementStatus } from './item-replacement-status';
import { ItemReplacementType } from './item-replacement-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ItemReplacement extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  itemDetailId: string;
  crtnTs: Date;
  crtnUserInit: string;
  itemReplacementStatusId: string;
  itemReplacementTypeId: string;
  itemDetail: ItemDetail;
  itemReplacementStatus: ItemReplacementStatus;
  itemReplacementType: ItemReplacementType;
}

