// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { InvoiceHeader } from './invoice-header';
import { ProxSoloSubmissionStatus } from './prox-solo-submission-status';
import { ShippingBox } from './shipping-box';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

export class Shipment extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  // assumes that shippingBoxs->invoices have been expanded.
  
  get weightUoMId() {
    if (this.shippingBoxes?.length > 0) {
      return this.shippingBoxes[0].weightUoMId;
    } else {
      return null;
    }
  }

  get trackingNumbers() {
    const r = this.shippingBoxes
      .filter(sb => sb.trackingLabelApiIdentifier != null)
      .map(sb => sb.trackingLabelApiIdentifier);
    return r.length > 0 ? r.join(', ') : null;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  carrierApiIdentifier: string;
  freightAmt: number;
  insuranceAmt: number;
  labelApiIdentifier: string;
  modTs: Date;
  primaryInvoiceHeaderId: string;
  proxSoloSubmissionStatusId: number;
  rowVersion: any;
  serviceApiIdentifier: string;
  shipTs: Date;
  shipmentApiIdentifier: string;
  invoiceHeaders: InvoiceHeader[];
  proxSoloSubmissionStatus: ProxSoloSubmissionStatus;
  shippingBoxes: ShippingBox[];
}

