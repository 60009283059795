import { UniformaxRegistrationHelper } from './../model/entities/registration-helper';
import { UniformaxMetadata } from './../model/entities/metadata';

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import {
  EntityManager, EntityQuery, NamingConvention, DataService,
  DataType, MetadataStore, EntityType, NavigationProperty,
  DataProperty, ValidationOptions, config, AutoGeneratedKeyType
} from 'breeze-client';

import { EntityTypeAnnotation } from '../model/entity-type-annotation';
import { ErrorLogger } from './error-logger';
import * as moment from 'moment-mini';
import { UtilFns } from './util-fns';


abstract class EntityManagerProvider {

  protected _preparePromise: Promise<any>;
  protected _masterManager: EntityManager;
  protected registrationHelper: any;
  protected metadata: any;

  constructor(public errorLogger: ErrorLogger) { }

  abstract getDataService(): DataService;

  prepare(): Promise<boolean> {
    if (!this._preparePromise) {
      NamingConvention.camelCase.setAsDefault();
      config.initializeAdapterInstance('uriBuilder', 'json');
      // See this link: https://stackoverflow.com/questions/17657459/breezejs-date-is-not-set-to-the-right-time
      const zeroTimeRegex = /.{10}T00:00:00/;
      DataType.parseDateFromServer = function (source: string) {
        // zero-time dates should be considered local time, even if they have a timezone specified
        if (zeroTimeRegex.test(source)) {
          source = source.substring(0, 19);
        }
        // remove time zone from all dates so they display locally.
        // source = (source && source.length > 23) ? source.substring(0, 23) : source;
        const date = new Date(Date.parse(source));
        return date;
      };

      const dataService = this.getDataService();

      const master = new EntityManager({
        dataService: dataService
      });
      this._masterManager = master;
      const metadataStore = master.metadataStore;
      metadataStore.importMetadata(<any>this.metadata.value);
      metadataStore.setProperties({
        serializerFn: function (dataProperty, value) {
          return dataProperty.isUnmapped ? undefined : value;
        }
        // serializerFn: function (dataProperty: { isUnmapped: any; }, value: string | Date) {
        //   value = dataProperty.isUnmapped ? undefined : value;
        //   if (value instanceof Date) {
        //     const date = value as Date;
        //     // make ISO string for current time zone (not UTC)
        //     value = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
        //     // for use if dates are DateTimeOffsets, but should not be given the browser timezone nor UTC
        //     value = value.substring(0, 23); // chop off the timezone
        //   }
        //   return value;
        // }
      });
      this.registrationHelper.register(metadataStore);
      this.patchMetadata(metadataStore);
      this.registerAnnotations(metadataStore);

      this._preparePromise = Promise.resolve(true);

    }

    return this._preparePromise;
  }

  get masterManager(): EntityManager {
    return this._masterManager;
  }

  newManager(): EntityManager {
    const manager = this._masterManager.createEmptyCopy();
    return manager;
  }

  // Add validators based on annotations
  private registerAnnotations(metadataStore: MetadataStore) {
    metadataStore.getEntityTypes().forEach((t: EntityType) => {
      const et = <EntityType>t;
      const ctor = <any>et.getCtor();
      // default handling of property display names.
      const props = et.getProperties();
      props.forEach(p => {
        p.displayName = UtilFns.splitCamelCaseToString(p.name);
      });
      if (ctor && ctor.getEntityTypeAnnotation) {
        const etAnnotation = <EntityTypeAnnotation>ctor.getEntityTypeAnnotation();
        et.validators.push(...etAnnotation.validators);
        etAnnotation.propertyAnnotations.forEach((pa) => {
          const prop = et.getProperty(pa.propertyName);
          prop.validators.push(...pa.validators);
          if (pa.displayName != null) {
            prop.displayName = pa.displayName;
          }
        });
      }
    });
  }

  protected patchMetadata(metadataStore: MetadataStore) {
    // make modifications to metadata
  }

}

@Injectable({providedIn: 'root'})
export class UniformaxProvider extends EntityManagerProvider {

  constructor(public errorLogger: ErrorLogger) {
    super(errorLogger);
    this.metadata = UniformaxMetadata;
    this.registrationHelper = UniformaxRegistrationHelper;
  }

  getDataService() {
    return new DataService({
      serviceName: environment.entitiesApiRoot,
      hasServerMetadata: false
    });
  }

  protected patchMetadata(metadataStore: MetadataStore) {
    // add password to User; matches [NotMapped] property on server
    // const userType = <EntityType>metadataStore.getEntityType(User);
    // const userType = User.prototype.entityType;
    // userType.addProperty(new DataProperty({
    //     name: 'password',
    //     dataType: DataType.String
    // }));

  }
}
