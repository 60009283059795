<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div uni-flex-row>
    <mat-form-field style="width: 300px">
      <input matInput [placeholder]="fieldName" [(ngModel)]="fieldValue"  />
    </mat-form-field>
   </div>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button class="btn btn-basic" *ngFor="let buttonName of buttons, let i=index" (click)="select(i)">{{buttons[i]}}</button>
</ng-template>
