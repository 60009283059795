import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProductFinderDialogComponent } from 'app/inventory/product-finder-dialog.component';
import { Account, PoDetail, PoDetailType, PoHeader } from 'app/model/entities/entity-model';
import { PoDetailTypeCode } from 'app/model/enums/po-detail-type-code';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';

export interface EditPodDialogComponentData {
  poHeader: PoHeader;
  matDialog?: MatDialog;
  poDetail?: PoDetail; // if null - then dialog will create a poDetail else edit this one.
}

@Component({
  templateUrl: './edit-pod-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class EditPodDialogComponent {

  poHeader: PoHeader;
  poDetail: PoDetail;
  poDetailTypes: PoDetailType[];
  matDialog: MatDialog;
  allAccounts: Account[];
  matchingAccounts: Account[];

  isPageReady = false;


  static async show(matDialog: MatDialog, data: EditPodDialogComponentData) {
    data.matDialog = matDialog;
    return await matDialog
      .open(EditPodDialogComponent, {
        disableClose: true,
        height: '500px',
        width: '775px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditPodDialogComponent>,
    public uow: UnitOfWork, public dbQueryService: DbQueryService, public dbSaveService: DbSaveService,
    public dialogService: DialogService) {

    this.prepare();
  }

  async prepare() {
    this.matDialog = this.data.matDialog;
    this.poHeader = this.data.poHeader;
    this.poDetail = this.data.poDetail;

    this.poDetailTypes = await this.dbQueryService.cacheAndGetAll(PoDetailType);
    this.allAccounts = await this.dbQueryService.getReissuanceAccounts();

    const nullAccount = <Account> { accountName: '[None]' };
    this.allAccounts.unshift(nullAccount);

    if (this.poDetail == null) {
      const podStruct = <PoDetail>{};
      const now = new Date();
      podStruct.poHeaderId = this.poHeader.id;
      podStruct.poDetailTypeId = PoDetailTypeCode.Stock;
      podStruct.orderQty = 1;

      this.poDetail = this.uow.createEntity(PoDetail, podStruct);
    }

    // remove 'Order' if this is a new poDetail - we should not be able to create an PO based on a Job Order
    if (this.poDetail.joDetailId == null) {
      this.poDetailTypes = this.poDetailTypes.filter(x => x.id !== PoDetailTypeCode.Order);
    }

    this.isPageReady = true;
  }

  hasJoDetail() {
    return this.poDetail.joDetailId != null;
  }

  searchAccounts(query: string){
    this.matchingAccounts = this.allAccounts.filter(a => a.accountName.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  async lookupProduct() {
    const product = await ProductFinderDialogComponent.show(this.matDialog, {
      manufacturer: this.poHeader.manufacturer
    });
    if (product == null) {
      return false;
    }
    this.poDetail.productId = product.id;
    this.poDetail.origProductId = product.id;
  }

  // called from html template
  getErrorMessage(propName: string) {
    const ea = this.poDetail && this.poDetail.entityAspect;
    if (ea == null) {
      return;
    }
    const errors = ea.getValidationErrors(propName);
    // if (errors.length === 0) {
    //   return;
    // }
    return errors.map(e => e.errorMessage).join('; ');

  }

  private getValidationErrorsAsHtml() {
    const ea = this.poDetail && this.poDetail.entityAspect;
    if (ea == null) {
      return '';
    }
    const errors = ea.getValidationErrors().map(e => e.errorMessage);
    if (this.poDetail.productId === 0) {
      errors.unshift('Product must be specified');
    }
    if (errors.length === 0) {
      return '';
    }
    const msg = errors.join('<br>');
    return msg;
  }

  async save() {
    const msg = this.getValidationErrorsAsHtml();
    if (msg.length > 0) {
      this.dialogService.showOkMessage('Validation Errors', msg);
      return false;
    }

    await this.dbSaveService.saveChanges();
    this.dialogRef.close(true);
  }

  cancel() {
    this.uow.rollback();
    this.dialogRef.close(false);
  }

}
