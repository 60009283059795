import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  CreditMemo, ItemBin,
  ItemDetail,
  ItemDetailAddon,
  Product, ReturnRequestDetail,
  ReturnRequestDetailStatus
} from 'app/model/entities/entity-model';
import { BarcodePrintStatusCode } from 'app/model/enums/barcode-print-status-code';
import { ReturnRequestDetailStatusCode } from 'app/model/enums/return-req-auth-detail-status-code';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { EntityState } from 'breeze-client';
import { environment } from 'environments/environment';
import { AuthService, AuthUser } from 'app/shared/auth.service';
import { ReturnReasonTypeCode } from 'app/model/enums/return-reason-type-code';
import { ReturnCreditTypeCode } from 'app/model/enums/return-credit-type-code';


export interface ReturnRequestDetailDialogComponentData {
  returnRequestDetail: ReturnRequestDetail;
}

@Component({
  templateUrl: './return-request-detail-dialog.component.html',
})
export class ReturnRequestDetailDialogComponent {
  matDialog: MatDialog;
  actionMsgs: string[];
  isEditable: boolean;
  rrd: ReturnRequestDetail;

  product: Product;
  returnRequestDetailStatuses: ReturnRequestDetailStatus[];
  
  authUser: AuthUser;
  
  
  isPageReady = false;

  static async show(matDialog: MatDialog, data: ReturnRequestDetailDialogComponentData) {
    // this dialog can pop up a nested dialog
    data['matDialog'] = matDialog;
    const r = await matDialog
      .open(ReturnRequestDetailDialogComponent, {
        disableClose: true,
        height: '800px',
        // width: '860px',
        width: '870px',
        data: data,
      })
      .afterClosed()
      .toPromise();
    return <boolean>r;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReturnRequestDetailDialogComponentData,
    public dialogRef: MatDialogRef<ReturnRequestDetailDialogComponent>,
    private uow: UnitOfWork,
    private dbQueryService: DbQueryService,
    private dbSaveService: DbSaveService,
    private dialogService: DialogService,
    private authService: AuthService 
  ) {
    this.matDialog = this.data['matDialog'];
    
    this.authUser = this.authService.getUser();
    
    
    this.rrd = this.data.returnRequestDetail;
    this.product = this.rrd.joDetail.product;
    this.isEditable = !this.rrd.returnRequestDetailStatus.isFinalState;

    this.returnRequestDetailStatuses = this.dbQueryService.getAllCached(ReturnRequestDetailStatus);
    
    this.isPageReady = true;
  }

  getValidReturnRequestDetailStatuses() {
    let ids = [];
    const shouldCredit = this.rrd.returnCreditTypeId == ReturnCreditTypeCode.Credit
    if (this.rrd.returnReasonTypeId === ReturnReasonTypeCode.Return) {
      ids = [
        ReturnRequestDetailStatusCode.AwaitingReceipt,
        ReturnRequestDetailStatusCode.ReceivedInquiryPending,
        ReturnRequestDetailStatusCode.ReturnedToCustomerRejected,
        shouldCredit ? ReturnRequestDetailStatusCode.ReceivedCreditApplied : ReturnRequestDetailStatusCode.ReceivedAccountOwned,
      ];
    } else if (this.rrd.returnReasonTypeId === ReturnReasonTypeCode.Repair) {
      ids = [
        ReturnRequestDetailStatusCode.AwaitingReceipt,
        ReturnRequestDetailStatusCode.ReceivedInquiryPending,
        ReturnRequestDetailStatusCode.ReceivedRepairPending,
        ReturnRequestDetailStatusCode.ReturnedToCustomerRejected,
        ReturnRequestDetailStatusCode.ReturnedToCustomerRepaired,

      ];
    }
    const statuses = ids.map(id => this.returnRequestDetailStatuses.find(a => a.id === id));
    return statuses;
  }

  getErrorMessage(propName: string) {
    return this.rrd.getErrorFor(propName);
  }

  async ok() {
    let msg = this.rrd.getValidationErrorsAsHtml() || '';
    const account = this.rrd.joDetail.joHeader.account;
    
    if (msg && msg.length !== 0) {
      return await this.dialogService.showOkMessage('Unable to continue - Fix Validation Errors first', msg);
    }

    if (this.rrd.returnRequestDetailStatus.isFinalState) {
      const ynResult = await this.dialogService.askYesNo('Please confirm', "This line item will no longer be editable after you do this. Ok?");
      if (ynResult.index == 1) {
        return;
      }
    }
    
     const {  creditMemo}  = await this.processStatusChange();
    
    if (creditMemo) {
      // we always create the creditMemo record because we need to find it on the server side
      // BUT we won't mark it posted unless it actually gets thru the 'Intuit' post. 
      await this.dbSaveService.saveSelectedChanges([creditMemo]);
      // post to accounting system
      const { ok , err } = await this.postCreditMemo(creditMemo);
      if (!ok) {
        this.dbSaveService.rejectChanges();
        await this.dialogService.showOkMessage('Credit Memo NOT posted', err + '<br><br>Return request is still pending');  
        this.dialogRef.close(false);
        return;
      }
      creditMemo.creditMemoStatusId = 1; // Posted
    }
    
    const saveResult = await this.dbSaveService.saveChanges();

    if (this.actionMsgs.length > 0) {
      const msgs = this.actionMsgs.join('<br>');
      await this.dialogService.showOkMessage('Return operations', msgs);
    }
    
    this.dialogRef.close(true);
  }

  
  canShowCreditMemoInfo() {
    return this.rrd.returnReasonTypeId === ReturnReasonTypeCode.Return && this.rrd.returnCreditTypeId === ReturnCreditTypeCode.Credit; 
  }

  canEditCreditMemoAmt() {
    return this.isEditable && this.canShowCreditMemoInfo() && this.authUser.isAccountAdmin;
  }

  cancel() {
    this.uow.rollback();
    this.dialogRef.close(null);
  }

  async processStatusChange() {
    let isReturnedToInventory = false;
    const jod = this.rrd.joDetail;
    const account = jod.joHeader.account;
    this.actionMsgs = [];
    const reasonTypeId = this.rrd.returnReasonTypeId;
    const statusId = this.rrd.returnRequestDetailStatusId;
    let creditMemo: CreditMemo = null;
    if (reasonTypeId === ReturnReasonTypeCode.Return) {
      if (statusId === ReturnRequestDetailStatusCode.ReceivedCreditApplied) {
        creditMemo = this.createOrFindCreditMemo(this.rrd);
        isReturnedToInventory = true;
      }
      if (statusId == ReturnRequestDetailStatusCode.ReceivedAccountOwned) {
        isReturnedToInventory = true;
      }
    } else if (reasonTypeId === ReturnReasonTypeCode.Repair) {
      if (statusId === ReturnRequestDetailStatusCode.ReceivedRepairPending) {
        this.actionMsgs.push('Label ready to be printed for repair item');
      } else if (statusId === ReturnRequestDetailStatusCode.ReturnedToCustomerRepaired) {
        // do nothing
      }
    }

    if (isReturnedToInventory) {
      const itemDetails = await this.returnToInventory(this.rrd);
      this.rrd.returnedItemDetails = itemDetails;
    }

    this.rrd.returnStatusdTs = new Date();
    
    return { creditMemo: creditMemo };
    
  }

  async returnToInventory(rrad: ReturnRequestDetail) {
    const jod = rrad.joDetail;
    const account = jod.joHeader.account;
    const makeAccountOwned = account.allowReissuanceOrders && rrad.returnCreditTypeId == ReturnCreditTypeCode.AccountOwned;
    const rradQty = rrad.qty;
    // Update the jod return qty.
    jod.returnQty = jod.returnQty + rradQty;
    // create new ItemDetail records
    const nextIds = await this.dbQueryService.getNextItemDetailIds(rradQty);
    const newItemDetails: ItemDetail[] = [];

    for (let ix = 0; ix < rradQty; ix++) {
      const itdStruct = {} as ItemDetail;
      itdStruct.id = nextIds[ix];
      itdStruct.productId = jod.productId;
      itdStruct.crtnTs = new Date();
      // TODO: we probably want to include this in the dialog -
      // i.e. where to put returned item in inventory i.e. what bin
      itdStruct.itemBinId = ItemBin.UnitecDefault;
      itdStruct.accountId = makeAccountOwned ? account.id : null;
      // TODO: when do we print new BarCode for this.
      itdStruct.barcodePrintStatusId = BarcodePrintStatusCode.None;
      // create new ItemDetails
      const newItd = this.uow.createEntity(ItemDetail, itdStruct, EntityState.Added);
      newItemDetails.push(newItd);
      // then create new ItemDetailAddons
      jod.joDetailAddons.forEach(jodAddon => {
        const itdAddon = {} as ItemDetailAddon;
        itdAddon.itemDetailId = newItd.id;
        itdAddon.addonId = jodAddon.addonId;
        itdAddon.additionalInfo = jodAddon.additionalInfo;
        
        const itdm = this.uow.createEntity(ItemDetailAddon, itdAddon, EntityState.Added);
      });
    }
    this.actionMsgs.push('Returned items added back to inventory as new items');
    this.actionMsgs.push('Barcodes may now be printed for the items returned to inventory');
    
    return newItemDetails;
  }


  private createOrFindCreditMemo(rrad: ReturnRequestDetail) {
    // CreditMemo may have already been created in an Unposted state
    // if a prior operation created it but was unable to post it.
    if (rrad.creditMemos.length > 0) {
      // creditMemo was created earlier but not posted. 
      return rrad.creditMemos[0];
    }
    const cmStruct = {} as CreditMemo;
    cmStruct.returnRequestDetailId = rrad.id;
    cmStruct.creditMemoAmt = rrad.actualUnitCreditMemoAmt * rrad.qty;
    cmStruct.wasAmtOverridden = rrad.actualUnitCreditMemoAmt != rrad.defaultUnitCreditMemoAmt;
    
    // TODO: need to get a list of valid CreditMemoStatus's.
    cmStruct.creditMemoStatusId = 0; // Unposted. ( 1 = Posted)
    const cm = this.uow.createEntity(CreditMemo, cmStruct, EntityState.Added);
    return cm;
  }


  /** Post the credit memo to accounting.  It should already have been saved to the server. */
  private async postCreditMemo(creditMemo: CreditMemo) {
    const url = environment.apiRoot + `api/quickbooks/PostCreditMemoToGL?creditMemoId=${creditMemo.id}`;
    const query = this.uow.createQuery(CreditMemo, url);
    try {
      await query.execute();
      this.actionMsgs.push('Credit memo was posted to the accounting system');
      return { ok: true, err: null}
    } catch(err) {
      console.log('Error posting credit memo to ' + url, err);
      // this.actionMsgs.push('Error posting credit memo');
      return { ok: false, err: err };
    }
  }
}
