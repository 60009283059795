<app-uni-dialog title="Print/Email Purchase Order" [parent]="this" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div uni-flex-row>
    <mat-form-field uni-flex-item >
      <input matInput placeholder="Manufacturer Name" [ngModel]="mfg.name" readonly="readonly" />
    </mat-form-field>
    <mat-form-field uni-flex-item >
      <input matInput placeholder="Purchase Order ID" [ngModel]="poHeader.id" readonly="readonly" />
    </mat-form-field>
    <mat-form-field uni-flex-item>
      <input matInput placeholder="Status" [ngModel]="poHeader.poStatus?.name" readonly="readonly" />
    </mat-form-field>
  </div>

  <div uni-flex-row>
    <mat-form-field uni-flex-item>
      <input matInput placeholder="WH Contact" [ngModel]="mfg.whContact" readonly="readonly" />
    </mat-form-field>
    <mat-form-field uni-flex-item>
      <input matInput placeholder="Contact Email" [(ngModel)]="emailAddress" name="emailAddress" />
    </mat-form-field>
  </div>

  <div>
    <span style="margin-left: 8px">{{emailStatus}}</span>
  </div>

  <div *ngIf="isPrinting">
    If the PO is <b>successfully printed</b>, it can be moved to <b>Open/Incomplete</b> status.
  </div>
  <div *ngIf="isOpenIncomplete">
    This PO is in Open/Incomplete status.
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div *ngIf="!isPrinting">
    <button *ngIf="canPreview" mat-raised-button (click)="showPreview()">Preview</button>
    <button [disabled]="!canSendEmail()" mat-raised-button (click)="sendEmail()">Email</button>
    <button mat-raised-button (click)="printDocument()">Print</button>
    <button mat-raised-button (click)="close()">Close</button>
  </div>
  <div *ngIf="isPrinting">
    <button mat-raised-button (click)="setStatusOpen()">Set to Open/Incomplete Status</button>
    <button mat-raised-button (click)="isPrinting = false">Cancel</button>
  </div>
</ng-template>
