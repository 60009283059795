import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface YesNoResult {
  button: string;
  index: number;
}

@Component({
  templateUrl: './yes-no-dialog.component.html'
})
export class YesNoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<YesNoDialogComponent>) {
    data.buttons = data.buttons || ['Yes', 'No'];
    dialogRef.disableClose = true;
  }

  select(index: number) {
    const result = {
      button: this.data.buttons[index],
      index: index
    };
    this.dialogRef.close(result);
  }
}
