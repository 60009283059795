<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <!-- replace with app options: 
    use date picker?
    grid spacing narrow/wide?
    ...?
    -->
    <printer-setup></printer-setup>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->
<!-- frame for holding zpl for printing, if no QZ -->
<iframe name="zplframe" id="zplframe" style="display: none"></iframe>
