import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ItemDetail, ItemDetailAddon, JoDetail, JoHeader, PoDetail } from 'app/model/entities/entity-model';
import { ItemDetailStatusCode, ItemDetailStatusCodes } from 'app/model/enums/item-detail-status-code';
import { JoStatusCode } from 'app/model/enums/jo-status-code';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgFns } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { FileViewDialogComponent, FileViewDialogData } from 'app/shared/file-view-dialog.component';
import { NavFns } from 'app/shared/nav-fns';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { ShippingService } from 'app/shipping/shipping.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { InvoiceShipmentService } from './invoice-shipment.service';

@Component({
  selector: 'app-jo-in-process',
  templateUrl: './jo-in-process.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class JoInProcessComponent extends DomainBaseComponent {


  selectedJoHeader: JoHeader;
  johGridOptions: GridOptions;

  joDetails: JoDetail[];
  jodGridOptions: GridOptions;

  itemDetails: ItemDetail[];
  itdGridOptions: GridOptions;
  itemDetailId: string;

  invoicedItemDetails: ItemDetail[];
  invoicedItdGridOptions: GridOptions;

  poDetails: PoDetail[];
  podGridOptions: GridOptions;

  // invoiceHeader: InvoiceHeader;

  errorMessage = '';
  areAllFound = false;

  constructor(
    public uow: UnitOfWork,
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected shippingService: ShippingService,
    protected invoiceShipmentService: InvoiceShipmentService,
    private matDialog: MatDialog
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.johGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJohGridReady,
      onFirstDataRendered: AgFns.autoSizeAllColumns,
      onRowSelected: this.onJohRowSelected,
      onModelUpdated: () => {
        this.clearCurrentSelection();
      },
      rowModelType: 'serverSide',
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.clearCurrentSelection();
        }
      }
    });
    AgFns.captureGridRouteParams(this.johGridOptions, this.route, 'id');

    this.jodGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJodGridReady,
    });

    this.itdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdGridReady,
    }, { detailProperty: 'itemDetailAddons' });

    this.invoicedItdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onInvoicedItdGridReady,
    });

    this.podGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPodGridReady,
    });

    this.isPageReady = true;
  }

  async updateDatasource() {
    const gridApi = this.johGridOptions && this.johGridOptions.api;
    if (gridApi == null) {
      return;
    }
    const ds = AgFns.buildDatasource(() => this.dbQueryService.createJoHeadersInProcessQuery());

    gridApi.setServerSideDatasource(ds);
  }

  async onJohRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const joh = e.data as JoHeader;
    if (!joh) {
      return;
    }
    this.selectedJoHeader = joh;

    this.updateLocation();

    return this.queryDetails(joh);

  }

  clearCurrentSelection() {
    // HACK: 
    // this insures that we don't reenter here after the deselectAll call below which causes a modelUpdated event
    // which calls this.  The problem is when returning to the page this method is called before the 'key' row is selected
    // but then is called again after the row is selected ( because deselectAll causes modelUpdated after a timeout) and this 
    // 2nd call clears the row that has just been selected. UGH.
    if (this.selectedJoHeader === null) {
      return;
    }
    const gridState = this.johGridOptions.context?.gridState;
    if (gridState.isNavigating) return;
    this.joDetails = [];
    this.poDetails = [];
    this.itemDetails = [];
    this.invoicedItemDetails = [];
    
    this.selectedJoHeader = null;
    this.johGridOptions.api?.deselectAll();
    
    this.updateLocation();
  }

  async queryDetails(joh: JoHeader) {
    // run next two queries in parallel.
    const p1 = this.dbQueryService.getJoDetailsForJoHeader(joh.id).then(r => {
      this.joDetails = r;
      this.poDetails = _.flatMap(r.map(jod => jod.poDetails));
    });
    const p2 = this.dbQueryService.getItemDetailsByJoHeaderId(joh.id).then(r => {
      // NOTE: invoiceHeader logic below shouldn't be necessary but 'bad' old data requires it.
      this.itemDetails = r.filter(itd =>
        itd.itemDetailStatusId < ItemDetailStatusCode.Shipped && itd.itemDetailStatusId != 0);
        // (itd.itemDetailStatusId === ItemDetailStatusCode.InProcessPendingShipPrep
        //   || itd.itemDetailStatusId === ItemDetailStatusCode.InProcessPendingAddon
        // )
        // && itd.invoiceHeaderId == null);

      this.invoicedItemDetails = r.filter(itd =>
        (itd.itemDetailStatusId === ItemDetailStatusCode.Shipped
          || itd.itemDetailStatusId == ItemDetailStatusCode.InProcessVoucher)
        || itd.invoiceHeaderId != null);

      
    });

    // need to wait for both queries to resolve so that breeze can link up invoiceDetails
    await Promise.all([p1, p2]);
    AgFns.autoSizeAllColumns(this.itdGridOptions);
    // AgFns.autoSizeAllColumns(this.podGridOptions)
    
  }

  updateLocation() {
    const urlTree = this.router.createUrlTree(['/jo-in-process']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.johGridOptions, this.selectedJoHeader?.id.toString());
    this.domainService.location.replaceState(url);
  }

  onJohGridReady(evt: any) {
    const colDefs = [
      {
        headerName: 'Job Order Id',
        ...NavFns.createIdCellClickedNavProps('id', this.router, '/job-orders'), filter: 'agNumberColumnFilter'
      },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { headerName: 'JO Date', field: 'crtnTs', filter: 'agDateColumnFilter' },
      { headerName: 'Ship By Date', field: 'shipByDate', filter: 'agDateColumnFilter' },
      { headerName: 'Ship Frequency', field: 'shipFrequency.name', filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'joStatus.name', filter: 'agTextColumnFilter' },
      { headerName: 'Allow Partial Shipments', field: 'allowPartialShipments', editable: false, cellRenderer: AgCheckboxCellComponent },
    ];
    AgFns.initGrid(this.johGridOptions, colDefs, null, true);
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.johGridOptions);
    AgFns.autoSizeAllColumns(this.johGridOptions);
  }

  onJodGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', },
      { headerName: 'Description', field: 'product.productType.description', },
      { headerName: 'Style', field: 'product.productType.style', },
      { headerName: 'Features', field: 'product.featureChoicesExtract', },
      { headerName: 'Order Qty', field: 'orderQty', type: 'numericColumn', },
      { headerName: 'Cancel Qty', field: 'cancelQty', type: 'numericColumn', },
      // could break this into pulled vs rcvd
      {
        headerName: 'Pulled/Rcvd Qty', colId: 'idtQty', type: 'numericColumn',
        headerTooltip: '(calc) Quantities already pulled or received',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getItemDetailsRcvdQty();
        }
      },
      {
        headerName: 'Open PO Qty', colId: 'poQty', type: 'numericColumn',
        headerTooltip: '(calc) PO Detail quantities not yet received or canceled',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getPoDetailsRemainingQty();
        }
      },
      {
        headerName: 'Net Qty', colId: 'netQty', type: 'numericColumn',
        headerTooltip: '(calc) The Net quantities remaining after all itemDetails and unreceived purchase order details have been accounted for',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getRemainingQty();
        }
      },
      // { headerName: 'Return Qty', field: 'returnQty', type: 'numericColumn',  },
      {
        headerName: 'Voucher Qty', colId: 'voucherQty', type: 'numericColumn',
        headerTooltip: '(calc) Item Details on voucher but not yet shipped for this JODetail',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getItemDetailsVoucheredQty();
        }
      },
      {
        headerName: 'Shipped Qty', colId: 'shippedQty', type: 'numericColumn',
        headerTooltip: '(calc) Item Details shipped for this JODetail',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getItemDetailsShippedQty();
        }
      },
      {
        headerName: 'Uncomplete Qty', colId: 'uncompleteQty', type: 'numericColumn',
        headerTooltip: '(calc) Job Order quantities not yet shipped',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getUnshippedQty();
        }
      },
      { headerName: 'JO Detail Id', field: 'id', },

    ];
    const sortFields = [
      'id',
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });

    this.jodGridOptions.getRowClass = function (params) {
      const jod = params.node.data as JoDetail;
      if (jod.getUnshippedQty() == 0) {
        return 'rs-completed';
      }
    };

    AgFns.initGrid(this.jodGridOptions, colDefs, sortModel, true);
  }

  onItdGridReady(evt: any) {
    const colDefs = [

      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { headerName: 'Status', field: 'itemDetailStatus.name' },
      { headerName: 'Account Owned', field: 'isAccountOwned', editable: false, cellRenderer: AgCheckboxCellComponent },
      { ...AgFns.createButtonProps('In Process', this.gotoProcess.bind(this), 
          { calcLabel: this.getProcessLabel.bind(this) }), 
        colId: 'inProcess', width: 150
      },
      { headerName: 'Item Detail Id', field: 'id' },
    ];
    
    this.updateItdMasterDetail(this.itdGridOptions);
    AgFns.initGrid(this.itdGridOptions, colDefs, null, true);
  }

  updateItdMasterDetail(parentGridOptions: GridOptions) {
    // rowtype is ItemDetailAddon
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Addon', field: 'addon.nameAndLocation' },
      { headerName: 'Instructions', field: 'addon.instructions' },
      { headerName: 'Addl. Info', field: 'additionalInfo' },
      {
        headerName: 'AddonBin', field: 'addonBinId', ...NavFns.createCellClickedCalcNavProps(this.router,
          (event) => {
            const itda = <ItemDetailAddon>event.data;
            return { navPath: `/addon-bin/${itda.addonBinId}` };
          })
      },
      // { headerName: 'Price/Unit', field: '???' },  // UnitPriceAmt is on JoDetailAddon - not ItemDetailAddon.
      // { ...AgFns.createCellButtonProps('Documents', this.viewDocCell)  },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const itd = params.data as ItemDetail;
        params.successCallback(itd.itemDetailAddons);
      },
    };
  }

  onInvoicedItdGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Item Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { headerName: 'Status', field: 'itemDetailStatus.name' },
      { headerName: 'Account Owned', field: 'isAccountOwned', editable: false, cellRenderer: AgCheckboxCellComponent },
      {
        headerName: 'Voucher', field: 'invoiceHeaderId', ...NavFns.createCellClickedCalcNavProps(this.router,
          (event) => {
            const itd = <ItemDetail>event.data;
            const invhId = itd.invoiceHeaderId;
            return { navPath: `/jo-invoice/${this.selectedJoHeader.id}/${invhId}` };
          })
      },
    ];
    AgFns.initGrid(this.invoicedItdGridOptions, colDefs, null, true);
  }

  onPodGridReady(evt: any) {
    const colDefs = [
      // Already shown above on PurchaseOrder
      // { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      {
        headerName: 'Quantities', children: [
          { headerName: 'Order', field: 'orderQty', maxWidth: 75, type: 'numericColumn' },
          {
            headerName: 'Rcvd', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getReceivedQty();
            },
          },
          {
            headerName: 'Cncl', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getCanceledQty();
            },
          },
          {
            headerName: 'Net', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getRemainingQty();
            },
          },
          {
            headerName: 'Shipped', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getShippedQty();
            },
          }],
      },
      { headerName: 'PO', field: 'poHeaderId' },
      { headerName: 'PO Detail', field: 'id' },
      { headerName: 'JO Detail', field: 'joDetailId' },
    ]
    const sortFields = [
      'product.productType.description',
      'product.productType.style',
      'product.choiceValue',
      'poDetailTypeId'
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });

    this.podGridOptions.getRowClass = function (params) {
      const pod = params.node.data as PoDetail;
      if (pod.getRemainingQty() == 0) {
        return 'rs-completed';
      }
    };

    AgFns.initGrid(this.podGridOptions, colDefs, sortModel, true);
  }

  async onTabChanged(evt: any) {
    const currentTabLabel = evt.tab.textLabel;
    // HACK: needed because AgGrid loses buttons when tabbing from one tab to another and then back.
    this.joDetails = this.joDetails.slice();
    this.poDetails = this.poDetails.slice();
    this.itemDetails = this.itemDetails.slice();
    this.invoicedItemDetails = this.invoicedItemDetails.slice();
  }

  gotoProcess(itd: ItemDetail) {
    if ( ItemDetailStatusCodes.Addon.includes(itd.itemDetailStatusId)) {
      this.router.navigate(['/addon-bin', itd.itemDetailAddons[0].addonBinId]);
    } else if (ItemDetailStatusCodes.ShipPrep.includes(itd.itemDetailStatusId)) {  
      const params = NavFns.buildNavIdParams(itd.joDetail.joHeaderId, 'id');
      this.router.navigate(['/jo-ship-prep'], params);
    } else if (itd.invoiceHeaderId != null) {
      this.router.navigate(['/jo-invoice', itd.joDetail.joHeaderId, itd.invoiceHeaderId]);
    } else {

    }
  }

  getProcessLabel(itd: ItemDetail) {
    if ( ItemDetailStatusCodes.Addon.includes(itd.itemDetailStatusId)) {
      return 'AddonBin'
    } else if (ItemDetailStatusCodes.ShipPrep.includes(itd.itemDetailStatusId)) {
      return 'Ship Prep'
    } else if (ItemDetailStatusCode.InProcessVoucher == itd.itemDetailStatusId) {
      return 'Invoice';
    } else {
      return 'xxx';
    }
  }

  // TODO: check if this is even needed any longer.
  printTrackingBarcode() {

  }

  hasAddonDocs(itdAddon: ItemDetailAddon) {
    return itdAddon?.addon.addonDocMaps.length > 0;
  }

  async viewAddonDocs(itdAddon: ItemDetailAddon) {
    const data: FileViewDialogData = {
      title: 'View Addon Document',
      docs: itdAddon.addon.addonDocMaps.map(x => x.addonDoc)
    };
    await FileViewDialogComponent.show(this.matDialog, data);

  }

  isJoOpenComplete() {
    return this.selectedJoHeader?.joStatusId == JoStatusCode.OpenProcessComplete;
  }

  hasItemDetails() {
    return this.itemDetails && this.itemDetails.length > 0;
  }

  goBack() {
    this.domainService.location.back();
  }
}
