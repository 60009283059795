import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";

@Component({
  selector: 'app-image-edit',
  templateUrl: './image-edit.component.html',
})
export class ImageEditComponent {
  @Input() cropInput?: File;
  @Output() ok = new EventEmitter<Blob>();
  @Output() cancel = new EventEmitter<void>();

  cropOutput?: Blob;
  canvasRotation = 0;
  transform: ImageTransform = {};

  /** When imageCropper changes an image */
  imageCropped(event: ImageCroppedEvent) {
    this.cropOutput = event.blob || undefined;
  }

  cropOk() {
    this.ok.emit(this.cropOutput);
  }

  cropCancel() {
    this.cancel.emit();
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

}
