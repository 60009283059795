import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ItemDetailAddon, AddonBin, ItemDetail, Addon, ItemDetailStatus, JoHeader, JoDetail, InvoiceHeader, InvoiceDetail, ShipBin, ShipFrequency } from 'app/model/entities/entity-model';
import { AgFns } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { FileViewDialogComponent, FileViewDialogData } from 'app/shared/file-view-dialog.component';
import { NavFns } from 'app/shared/nav-fns';
import { UnitOfWork } from 'app/shared/unit-of-work';
import * as _ from 'lodash';
import { scan, takeUntil } from 'rxjs/operators';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { JoShipScanDialog } from './jo-ship-scan-dialog.component';
import { DateFns } from 'app/shared/date-fns';
import { InvoiceStatusCode } from 'app/model/enums/invoice-status-code';
import { JoShipPrepScanDialog } from './jo-ship-prep-scan-dialog.component';
import { InventoryDamageDialogComponent } from 'app/inventory/inventory-damage-dialog.component';
import { ShipFrequencyCode } from 'app/model/enums/ship-frequency-code';
import { ByWeekday, RRule } from 'rrule';
import { BarcodeService } from 'app/shared/barcode.service';

@Component({
  selector: 'app-jo-ship-prep',
  templateUrl: './jo-ship-prep.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class JoShipPrepComponent extends DomainBaseComponent {

  shouldShowAllDetails: boolean;
  itemDetailStatuses: ItemDetailStatus[];
  
  johGridOptions: GridOptions;
  joHeaders: JoHeader[];
  selectedJoHeaderId: number;
  selectedJoHeader: JoHeader;

  itdGridOptions: GridOptions;
  allItemDetails: ItemDetail[];
  itemDetails: ItemDetail[];

  
  scannedItemDetailId: string;
  scanErrorMessage = '';

  interval: any;
  refreshOptions: any[];
  refreshSeconds = 0;
  shippingStatusCodes = [
    ItemDetailStatusCode.InProcessPendingShipPrep,
    ItemDetailStatusCode.InProcessShipPrep, 
    ItemDetailStatusCode.InProcessShipHeld,
    ItemDetailStatusCode.InProcessVoucher
  ];

  constructor(
    public uow: UnitOfWork,
    protected domainService: DomainService,
    protected barcodeService: BarcodeService,
    protected route: ActivatedRoute,
    private matDialog: MatDialog
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  setupRefresh() {
    this.updateLocation();
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.refreshSeconds == 0) return;
    this.interval = setInterval(() => {
      if (this.isPageReady) {
        this.refreshGrids(); 
      }
   }, this.refreshSeconds * 1000);
  }

  canDeactivate() {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.uow.clearEntities(JoHeader);
    this.uow.clearEntities(JoDetail);
    this.uow.clearEntities(ItemDetail);
    this.uow.clearEntities(ItemDetailAddon);
    

    return true;
  }

  async updateFromContext() {
    const qparams =  this.route.snapshot.queryParams;
    this.refreshSeconds = +(qparams['refresh'] ?? 0);
    // this.setupRefresh();
    this.selectedJoHeaderId = +(qparams['joHeaderId'] ?? 0);
    this.shouldShowAllDetails =  !!(+(qparams['showAllDetails'] ?? 0));

    this.refreshOptions = [
      { name: 'Only on entering page', refreshSeconds: 0 },
      { name: 'Every 10 Seconds', refreshSeconds: 10 },
      { name: 'Every 30 Seconds', refreshSeconds: 30 },
      { name: 'Every 60 Seconds', refreshSeconds: 60 },
      { name: 'Every 90 Seconds', refreshSeconds: 90 },
    ]

    this.johGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJohGridReady,
      onRowSelected: this.onJohRowSelected,
      onFilterChanged: this.onFilterChanged,
      rowModelType: 'serverSide'
    });
    AgFns.captureGridRouteParams(this.johGridOptions, this.route, 'id');

    this.itdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdGridReady,
    }, { detailProperty: 'itemDetailAddons' });

    await this.dbQueryService.getAllCached(ItemDetailStatus);

    this.isPageReady = true;

    this.refreshGrids();
  }

  onJohGridReady(evt: any) {
    const colDefs = [
      { ...AgFns.createButtonProps('Create Voucher', this.prepareToInvoice.bind(this), 
          { label: 'Create Voucher' }), colId: 'prepareToInvoice', width: 140
      },
      { headerName: 'Job Order Id', maxWidth: 110, minWidth: 110,
        ...NavFns.createIdCellClickedNavProps('id', this.router, '/jo-in-process'), filter: 'agNumberColumnFilter'
      },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { headerName: 'JO Date', field: 'joDate', filter: 'agDateColumnFilter' },
      { headerName: 'Ship By Date', field: 'shipByDate', filter: 'agDateColumnFilter' },
      { headerName: 'Ship Frequency', field: 'shipFrequency.name', filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'joStatus.name', filter: 'agTextColumnFilter' },
      { headerName: 'Allow Partial Shipments', field: 'allowPartialShipments', editable: false, cellRenderer: AgCheckboxCellComponent },
      

    ];
    AgFns.initGrid(this.johGridOptions, colDefs, null, true);
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.johGridOptions);
    AgFns.autoSizeAllColumns(this.johGridOptions);
  }

  async updateDatasource() {
    const gridApi = this.johGridOptions && this.johGridOptions.api;
    if (gridApi == null) {
      return;
    }
    const ds = AgFns.buildDatasource(() => this.dbQueryService.createJoHeadersInShippingProcessQuery());

    gridApi.setServerSideDatasource(ds);
  }

  async onJohRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const joh = e.data as JoHeader;
    if (!joh) {
      return;
    }
    this.selectedJoHeader = joh;

    this.updateLocation();

    return this.queryDetails(joh);

  }

  clearCurrentSelection() {
    // HACK: 
    // this insures that we don't reenter here after the deselectAll call below which causes a modelUpdated event
    // which calls this.  The problem is when returning to the page this method is called before the 'key' row is selected
    // but then is called again after the row is selected ( because deselectAll causes modelUpdated after a timeout) and this 
    // 2nd call clears the row that has just been selected. UGH.
    if (this.selectedJoHeader === null) {
      return;
    }
    const gridState = this.johGridOptions.context?.gridState;
    if (gridState.isNavigating) return;
    this.joHeaders = [];
    this.itemDetails = [];
    
    
    this.selectedJoHeader = null;
    this.johGridOptions.api?.deselectAll();
    
    this.updateLocation();
  }

  async queryDetails(joh: JoHeader) {
    // run next two queries in parallel.
    
    this.allItemDetails = await this.dbQueryService.getItemDetailsByJoHeaderId(joh.id)
    this.refreshGrids();
  }
  
  onItdGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Item Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { headerName: 'Status', field: 'itemDetailStatus.name' },
      { ...AgFns.createButtonProps('Mark damaged', this.markDamaged.bind(this), 
        { label: 'Mark Damaged', canDisplay: this.canMarkDamaged.bind(this) }),
      colId: 'markDamaged', minWidth: 105
      },
      { headerName: 'Voucher Id', field: 'invoiceHeaderId', maxWidth: 110, minWidth: 110, filter: 'agNumberColumnFilter',
        ...NavFns.createCellClickedCalcNavProps(this.router,
          (event) => { 
            const itd = <ItemDetail> event.data;
            return { navPath: `/jo-invoice/${ itd.invoiceHeader.joHeaderId }/${ itd.invoiceHeaderId }` }; 
          }), 
      },
      
      { headerName: 'Ship Bin Id', field: 'invoiceHeader.shipBinId', maxWidth: 110, minWidth: 110, filter: 'agNumberColumnFilter',
        ...NavFns.createCellClickedCalcNavProps(this.router,
          (event) => { 
            const itd = <ItemDetail> event.data;
            return { navPath: `/jo-ship/${ itd.invoiceHeader.shipBinId }` }; 
          }), 
      },
      { ...AgFns.createButtonProps('Hold', this.markHold.bind(this), 
          { calcLabel: this.calcMarkHoldLabel.bind(this), canDisplay: this.canMarkHold.bind(this) }),
        colId: 'markHold', minWidth: 100
      },
      
      { headerName: 'Jo Detail Id', field: 'joDetail.id' },
      { ...AgFns.createButtonProps('Mark as scanned', this.markAsScanned.bind(this), 
          { label: 'Mark As scanned', canDisplay: this.canMarkAsScanned.bind(this) }),
        colId: 'markAsScanned', width: 150
      }
    ];

    const sortModel = [
      { colId: 'itemDetail.id', sort: 'asc' as const },
    ];

    this.updateItdMasterDetail(this.itdGridOptions);
    AgFns.initGrid(this.itdGridOptions, colDefs, sortModel);

    const col = this.itdGridOptions.columnApi.getColumn('markAsScanned');
    this.itdGridOptions.columnApi.setColumnVisible(col, this.authUser.isShippingAdmin);

  }

  updateItdMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Addon', field: 'addon.nameAndLocation' },
      { headerName: 'Instructions', field: 'addon.instructions' },
      { headerName: 'Addl. Info', field: 'additionalInfo' },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const itd = params.data as ItemDetail;
        var itdAddons = itd.itemDetailAddons;
        params.successCallback(itdAddons);
      },
    };
  }

  async prepareToInvoice(joh: JoHeader) {
    if (this.selectedJoHeader != joh) {
      await this.queryDetails(joh);
    }
    if (this.allItemDetails.some(x => x.itemDetailStatusId == ItemDetailStatusCode.InProcessPendingShipPrep)) {
      this.dialogService.showOkMessage('Unable to prepare invoice', `Some 'pending' item details have not yet been scanned into Ship Prep`);
      return false;
    }
    const shipPrepItds = this.itemDetails.filter(x => x.itemDetailStatusId == ItemDetailStatusCode.InProcessShipPrep);
    if (shipPrepItds.length == 0) {
      this.dialogService.showOkMessage('Unable to prepare invoice', `There are no item details currently available in the 'Ship Prep' state.`);
      return false;
    }
    if (!joh.allowPartialShipments) {
      // insure that all job order details have corresponding item details.
      const joDetails = await this.dbQueryService.getJoDetailsSimple(joh.id);
      var allFound = joDetails.every(jod => {
        const joQty = jod.orderQty - jod.cancelQty;
        const shipPrepQty = shipPrepItds.filter(x => x.joDetailId == jod.id).length;
        // otherItds is itemDetails for this job order that are already shipped or on voucher.
        // this can happen if 'allowPartialShipments' used to be true and is now false.
        const otherItdsQty = this.allItemDetails.filter(x => x.joDetailId == jod.id &&  [45, 50].includes(x.itemDetailStatusId) ).length;
        const ok = (joQty == shipPrepQty + otherItdsQty)
        if (!ok) {
          this.dialogService.showOkMessage('Unable to prepare invoice', 
            `This job order does not allow partial invoices and not all item details associated with one of the job order details are 
            available in the 'Ship Prep state.  </br>
            Job Order Detail Id: ${jod.id} </br>
            Job Order Qty:       ${joQty} </br>
            Ship Prep Qty:       ${shipPrepQty} </br>
            Prev vouchered Qty:  ${otherItdsQty} 
          `);
          return false;
        }
      });
      if (!allFound) {
        return false;
      }
    }

    const otherItds = this.allItemDetails.filter(x => x.itemDetailStatusId < ItemDetailStatusCode.InProcessShipPrep 
      || x.itemDetailStatusId == ItemDetailStatusCode.InProcessShipHeld)
    if (otherItds.length > 0) {
      const yrResult = await this.dialogService.askYesNo('Some item details still in process', 
      `Some of the item details on this job order are still in process or have been 'held'.
      </br> Do you still want to continue?`);
      if (yrResult.index == 1) {
        return false;
      }
    }
    
    var scannedItds = await JoShipScanDialog.show(this.matDialog, { 
      joHeader: joh,
      itemDetails: shipPrepItds,
      mustScanAll: !joh.allowPartialShipments
    });
    if (scannedItds == null)  return;
    const initResult = await this.initializeInvoice(joh, scannedItds);
    
    const ynResult = await this.dialogService.askYesNo("Print Ship Bin related labels", "Do you want to print Invoice package labels and Ship Bin labels now?")
    if (ynResult.index == 0) {
      if (initResult.isNewShipBin) {
        await this.barcodeService.printShipBinBarcode(initResult.invh.shipBin);
      }
      await this.barcodeService.printInvoicePackageBarcode(initResult.invh )
    }
    
    this.refreshGrids();

  }

  async initializeInvoice(joh: JoHeader, invItds: ItemDetail[]) {
    // find the 'next' invoice id for this JoHeader;

    const invoiceHeaderId = await this.dbQueryService.getNextInvoiceHeaderId(joh.id);
    const invh = {} as InvoiceHeader;
    const now = new Date();
    invh.id = invoiceHeaderId;
    
    invh.invoiceDate = DateFns.startOfDay(now);
    const appConfig = this.dbQueryService.appConfig;
    const dueDays = joh.account.dueDateNumDays || appConfig.account.dueDateNumDays;
    invh.dueDate = DateFns.dateAdd(invh.invoiceDate, dueDays, 'days');

    invh.crtnTs = now;

    invh.invoiceStatusId = InvoiceStatusCode.Voucher;
    invh.joHeaderId = joh.id;

    const shipBin = await this.findOrCreateShipBin(joh);
    invh.shipBin = shipBin;

    invItds.forEach(itd => {
      itd.itemDetailStatusId = ItemDetailStatusCode.InProcessVoucher;
      itd.invoiceHeaderId = invoiceHeaderId;
    });

    const invoiceHeader = this.uow.createEntity(InvoiceHeader, invh);
    const joDetails = _.uniq(invItds.map(itd => itd.joDetail));
    joDetails.forEach(jod => {
      const invd = {} as InvoiceDetail;
      invd.invoiceHeaderId = invoiceHeader.id;
      invd.joDetailId = jod.id;

      // we need to allow for only some of the joDetail qty to be consumed on an invoiceDetail
      // OLD code - assumed entire jod was on an invoice line item. 
      const relatedItds = invItds.filter(x =>jod.id == x.joDetailId);
      // itemDetail.accountId is null if Unitec inventory
      invd.redistributeQty = relatedItds.filter(itd => itd.accountId !== null).length;
      invd.shipQty = relatedItds.length - invd.redistributeQty; // TODO: check this
      // 
      // invd.redistributeQty = jod.itemDetails.filter(itd => itd.accountId !== null).length;
      // invd.shipQty = jod.itemDetails.length - invd.redistributeQty; // TODO: check this

      const invoiceDetail = this.uow.createEntity(InvoiceDetail, invd);
    });
    invoiceHeader.subtotalAmt = (invoiceHeader.calcExtendedAmt() + invoiceHeader.calcExtendedReissueAmt());
    invoiceHeader.taxAmt = invoiceHeader.subtotalAmt * (invoiceHeader.joHeader.taxRate || 0);
    invoiceHeader.totalAmt = invoiceHeader.subtotalAmt + invoiceHeader.taxAmt + invoiceHeader.orderHandlingAmt; // no freight yet.

    var returnValue = {
      invh: invoiceHeader,  isNewShipBin: shipBin.entityAspect.entityState.isAdded()
    }
    await this.dbSaveService.saveChanges();
    this.dialogService.toast('Voucher Created');
    return returnValue;
    // this.router.navigate(['/jo-invoice', joh.id, invoiceHeader.id]);
  }

  async findOrCreateShipBin(joh: JoHeader) {
    const shipBinGroupingKey = this.UtilFns.stringHash64(joh.shippingKey);
    var shipBin = await this.dbQueryService.getShipBinByKey(shipBinGroupingKey);
    const expectedShipDate = this.calcExpectedShipDate(joh.shipFrequency);
    if (shipBin == null) {
      shipBin = this.uow.createEntity(ShipBin, {
        aggregateGroupingKey: shipBinGroupingKey,
        shipFrequencyId: joh.shipFrequencyId,
        isOpen: true,
        expectedShipDate: expectedShipDate
      })
    }
    return shipBin;
  }

  calcExpectedShipDate(shipFrequency: ShipFrequency) {
    const today = DateFns.startOfDay(new Date());
    
    var interval = 0;
    switch (shipFrequency.id) {
      case ShipFrequencyCode.Immediate:
        return today;
      case ShipFrequencyCode.WhenBinIsFull:
        return today;
      case ShipFrequencyCode.Every2Weeks:
        interval = 2;
        break;
      case ShipFrequencyCode.Every4Weeks:
        interval = 4;
        break;
      case ShipFrequencyCode.Every6Weeks:
        interval = 6;
        break;        
      default: 
        break;
    }
    const minBinDate = DateFns.dateAdd(today, shipFrequency.minDaysForNewBin ?? 1, 'days')
    const rrule = new RRule({ freq: RRule.WEEKLY, interval: interval, byweekday: <ByWeekday> shipFrequency.rRuleByWeekDay ?? 'MO'  });
    return rrule.after(minBinDate);
  }

  canMarkHold(itd: ItemDetail) {
    return itd.itemDetailStatusId == ItemDetailStatusCode.InProcessShipPrep || itd.itemDetailStatusId == ItemDetailStatusCode.InProcessShipHeld ;
  }

  calcMarkHoldLabel(itd: ItemDetail) {
    if (itd.itemDetailStatusId == ItemDetailStatusCode.InProcessShipPrep) {
      return 'Hoid';
    } else {
      return 'Unhold';
    }
  }

  async markHold(itd: ItemDetail) {
    if (itd.itemDetailStatusId == ItemDetailStatusCode.InProcessShipPrep) {
      itd.itemDetailStatusId = ItemDetailStatusCode.InProcessShipHeld;
    } else {
      itd.itemDetailStatusId = ItemDetailStatusCode.InProcessShipPrep;
    }
    await this.dbSaveService.saveSelectedChanges([itd]);
    AgFns.refreshGrid(this.itdGridOptions, this.itemDetails);
  }

  canMarkDamaged(itd: ItemDetail) {
    return itd.itemDetailStatusId < ItemDetailStatusCode.InProcessVoucher;
  }

  async markDamaged(itd: ItemDetail) {
    const r = await InventoryDamageDialogComponent.show(this.matDialog, {
      itemDetail: itd
    });
    if (r) {
      this.refreshGrids();
      
    }
  }

  canMarkAsScanned(itd: ItemDetail) {
    return itd.itemDetailStatusId == ItemDetailStatusCode.InProcessPendingShipPrep && this.authUser.isShippingAdmin;
  }
  
  async markAsScanned(itd: ItemDetail) {
    if (itd?.itemDetailStatusId == ItemDetailStatusCode.InProcessPendingShipPrep) {
      itd.itemDetailStatusId = ItemDetailStatusCode.InProcessShipPrep;
      this.dialogService.toast('Found and marked.');
      this.refreshGrids(); 
      await this.dbSaveService.saveChanges();
    } else if (itd) {
      this.scanErrorMessage = `Invalid - Item detail with id: ${itd.id} is currently in the '${itd.itemDetailStatus.name}' state.`;
    } else {
      this.scanErrorMessage = `Invalid - Item detail with id: ${itd.id} cannot be found. `;
    }
    this.scannedItemDetailId = '';
  }
  
  public async refreshGrids() {
    if (!this.allItemDetails) return;
    this.updateLocation();
    if (!this.shouldShowAllDetails) {
      this.itemDetails = this.allItemDetails.filter(x => this.shippingStatusCodes.includes(x.itemDetailStatusId) );
    } else {
      this.itemDetails = this.allItemDetails;
    }
    AgFns.refreshGrid(this.itdGridOptions, this.itemDetails);
    AgFns.autoSizeAllColumns(this.itdGridOptions);
    
  }

  
  onFilterChanged() {
    this.clearCurrentSelection();
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/jo-ship-prep'], {
      queryParams: {
        refresh: this.refreshSeconds,
        showAllDetails: this.shouldShowAllDetails ? 1 : 0
      }
    });
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.johGridOptions, this.selectedJoHeader?.id.toString());
    this.domainService.location.replaceState(url);
  }

  hasAddonDocs(addon: Addon) {
    return addon.addonDocMaps.length > 0;
  }

  async onViewAddonDocs(addon: Addon) {
    const data: FileViewDialogData = {
      title: 'View Addon Documents',
      docs: addon.addonDocMaps.map(x => x.addonDoc)
    };
    await FileViewDialogComponent.show(this.matDialog, data);

  }

  hasAddonImages(addon: Addon) {
    return addon.addonDocMaps.length > 0;
  }

  async onViewAddonImages(addon: Addon) {
    const data: FileViewDialogData = {
      title: 'View Addon Images',
      docs: addon.addonImages.map(x => x.image)
    };
    await FileViewDialogComponent.show(this.matDialog, data);

  }

  async showShipPrepScanDialog() {
    const r = await JoShipPrepScanDialog.show(this.matDialog);
    if (this.selectedJoHeader) {
      this.allItemDetails = await this.dbQueryService.getItemDetailsByJoHeaderId(this.selectedJoHeader.id)
      this.refreshGrids();
    }
  }

}
