// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { PoDetail } from './po-detail';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class PoDetailType extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: string;
  name: string;
  poDetails: PoDetail[];
}

