import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

/**
 * FnValidator - Uses input function to validate an Angular FormControl
 * @example
 * <mat-form-field>
 *   <input matInput required placeholder="Confirm Password" name="confirmPassword" [(ngModel)]="confirmPassword" #confirm="ngModel" [validatorFn]="validateConfirm" />
 *   <mat-error>{{confirm?.errors?.confirm.message}}</mat-error>
 * </mat-form-field>
 * where `validateConfirm` is a function that accepts an AbstractControl and returns `null` if no errors, and { confirm: "error message" } otherwise.
 */
@Directive({
  selector: '[validatorFn][ngModel],[validatorFn][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: FnValidator, multi: true }
  ]
})
export class FnValidator implements Validator {
  // tslint:disable: no-input-rename
  @Input('validatorFn') validatorFn: ValidatorFn;

  constructor() { }

  validate(c: AbstractControl) {
    return this.validatorFn(c);
  }
}
