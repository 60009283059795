import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AgFns } from 'app/shared/ag-fns';
import { takeUntil } from 'rxjs/operators';
import { Addon, AddonDoc, AddonStation, } from '../model/entities/entity-model';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { EditAddonDialogComponent } from './edit-addon-dialog.component';


@Component({
  selector: 'app-addon',
  templateUrl: './addon.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AddonComponent extends DomainBaseComponent implements OnInit {

  addonGridOptions: GridOptions;
  selectedAddon: Addon;


  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {

    this.addonGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAddonGridReady,
      onRowSelected: this.onAddonRowSelected,
      onFilterChanged: this.onFilterChanged,
      rowModelType: 'serverSide',
      onFirstDataRendered: AgFns.autoSizeAllColumns,
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.updateLocation();
        }
      }
    });
    AgFns.captureGridRouteParams(this.addonGridOptions, this.route, 'id');

    this.isPageReady = true;
  }

  async canDeactivate() {
    
    this.uow.clearEntities(Addon);
    this.uow.clearEntities(AddonDoc);
    this.uow.clearEntities(AddonStation);
    return true;
  }
  
  onAddonGridReady(evt: any) {
    const colDefs = [
      this.productAdminAllow({ ...AgFns.createButtonProps('', this.addonEdit.bind(this), { label: 'Edit' }), maxWidth: 60, minWidth: 60 }),
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { headerName: 'Name and Location', field: 'nameAndLocation', filter: 'agTextColumnFilter' },
      { headerName: 'Grouping Key', field: 'groupingKey' , filter: 'agTextColumnFilter' },
      { headerName: 'Station', field: 'addonStation.name', filter: 'agTextColumnFilter', },
      { headerName: 'Station Instructions', field: 'instructions' , filter: 'agTextColumnFilter' },
      { headerName: 'Is a mismatch addon', field: 'isFeatureMismatchAddon', editable: false, cellRenderer: AgCheckboxCellComponent },
      { headerName: 'Should display on docs', field: 'shouldDisplayOnDocs', editable: false, cellRenderer: AgCheckboxCellComponent },
      { headerName: 'Needs Additional Info', field: 'needsAdditionalInfo', editable: false, cellRenderer: AgCheckboxCellComponent },
      { headerName: 'Additional Info Description', field: 'additionalInfoDescription', filter: 'agTextColumnFilter' },
      { headerName: '# Images', sortable: false,
        valueGetter: params => {
          const addon = <Addon> params.data;
          return addon && addon.addonImages.length;
        }
      },
      { headerName: 'Status', field: 'activeStatus.name',  },
      // No longer supported.
      // this.invAllow({ ...AgFns.createButtonProps('', this.addonDelete.bind(this), { label: 'Delete' }) }),
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter' },
    ];
    // sorted on server.
    AgFns.initGrid(this.addonGridOptions, colDefs, null);

    this.updateDatasource();
    
   
  }

  updateDatasource() {
    const gridApi = this.addonGridOptions.api;
    if (gridApi == null) {
      return;
    }
    const sortModel = [ { colId: 'account.accountName', sort: 'asc' as const } ];

    const ds = AgFns.buildDatasource( () => this.dbQueryService.createAddonQuery(), sortModel );
        
    gridApi.setServerSideDatasource(ds);
    AgFns.applyGridRouteParams(this.addonGridOptions);
    
  }

  productAdminAllow(x: any) {
    return this.user.isProductAdmin ? x : {};
  }

  
  async onAddonRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const addon = e.data as Addon;
    if (!addon) {
      return;
    }
    this.selectedAddon = addon;
    this.updateLocation(addon.id);
  }

  onFilterChanged() {
    this.updateLocation();
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/addons']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.addonGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  // -----------------------------------------------------


  
  async addonAdd() {
    const r = await EditAddonDialogComponent.show(this.matDialog, {  });
    if (r) {
      this.addonGridOptions.api.refreshServerSide();
    }
  }

  async addonEdit(addon: Addon) {
    const r = await EditAddonDialogComponent.show(this.matDialog, { addon: addon });
    if (r) {
      this.addonGridOptions.api.refreshCells();
    }
  }

  goAddonStations() {
    this.router.navigate(['/addon-stations']);
  }


}
