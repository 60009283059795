import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
 canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({providedIn: 'root'})
export class CanDeactivateGuard  {
  canDeactivate(component: CanComponentDeactivate) {
    const r = component.canDeactivate ? component.canDeactivate() : true;
    return r;
  }
}
