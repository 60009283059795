<div *ngIf="isPageReady" id="printable-invoice"
  class="standard-page page-layout carded fullwidth inner-scroll accent-blue">

  <div class="center no-print p-8" style="max-width: 8.5in; min-width: 8.5in">

    <div *ngIf="!manifest" #printableArea class="content-card theme-default screen-p-20">
      <h2 *ngIf="!invoiceHeaders">Manifest {{manifestId}} not found</h2>
      <h2 *ngIf="invoiceHeaders">No invoices found for manifest {{manifestId}}</h2>
    </div>
    <div *ngIf="manifest" #printableArea class="content-card theme-default screen-p-20" style="overflow: auto">
      <div>
        <div fxLayout="row" class="h1 mt-6">
          <span style="flex: auto">Unitec Distribution Systems</span>
          <span>Invoice Manifest</span>
        </div>
        <div fxLayout="row" class="mt-6">
          <span style="flex: auto; font-style: italic">Innovative Apparel Distribution Systems<br />Tax Id:
            52-0551098</span>
          <span *ngIf="isPosted" class="h1" style="font-style: italic; color: gray">REPRINT</span>
        </div>
        <div *ngIf="startDate!=null" fxLayout="row" class="mt-6">
          <span>Manifest '{{manifest.manifestName}}' for Invoices posted between and including dates: 
          {{startDate | date:'MM/dd/yyyy'}} and {{endDate | date:'MM/dd/yyyy'}}</span>
        </div>
        <div *ngIf="startDate==null" fxLayout="row" class="mt-6">
          <span>Manifest '{{manifest.manifestName}}' for Invoices posted before and including: 
          {{endDate | date:'MM/dd/yyyy'}}</span>
        </div>

        <div fxLayout="row wrap" class="mt-12 ml-12" fxLayoutGap="20px" fxLayout.cs="Column">
          <mat-form-field style="width: 40%" class="no-underline">
            <textarea matInput matAutosize placeholder="Bill To" [ngModel]="getBillingAddress()"
              readonly="readonly" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"></textarea>
          </mat-form-field>
          <mat-form-field style="width: 40%" class="no-underline">
            <textarea matInput placeholder="Remit To" [ngModel]="getUnitecAddress()"
              readonly="readonly" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"></textarea>
          </mat-form-field>
        </div>

        <table class="grid-print">
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Invoice No.</th>
              <th>Invoice Date</th>
              <th class="col-right">Subtotal</th>
              <th class="col-right">Freight</th>
              <th class="col-right">Handling</th>
              <th class="col-right">Tax</th>
              <th class="col-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let i of flatInvoices">
              <tr>
                <td>{{i.empName}}</td>
                <td>{{i.invoiceId}}</td>
                <td>{{i.invoiceDate}}</td>
                <td class="col-right">{{i.subTotalAmt}}</td>
                <td class="col-right">{{i.freightAmt}}</td>
                <td class="col-right">{{i.orderHandlingAmt}}</td>
                <td class="col-right">{{i.taxAmt}}</td>
                <td class="col-right">{{i.totalAmt}}</td>
              </tr>
            </ng-container>
            <tr class="bottom-row">
              <td colspan="3"></td>
              <td class="col-right">{{ subTotalAmt | currency }}</td>
              <td class="col-right">{{ freightAmt | currency }}</td>
              <td class="col-right">{{ orderHandlingAmt | currency }}</td>
              <td class="col-right">{{ taxAmt | currency }}</td>
              <td class="col-right">{{ totalAmt | currency}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <ng-container *ngIf="manifest.shouldIncludeInvoices">
        <ng-container *ngFor="let ih of invoiceHeaders">
          <div class="break"></div>
          <app-printable-invoice-content [invoiceHeader]="ih"></app-printable-invoice-content>
        </ng-container>
      </ng-container>

    </div>

    <div class="accent no-print" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-between center" class="mb-12">

      <div class="left-buttons">
        <button mat-raised-button [disabled]="!manifest" (click)="showPrintDialog()">
          <span>Print / Email</span>
        </button>
        <span style="margin-left: 8px">{{email?.status}} {{email?.statusTs | date:'long'}}</span>
      </div>

      <div>
        <button mat-raised-button (click)="goBack()">
          <span>Go Back</span>
        </button>
      </div>
    </div>
  </div>
  <!-- / CENTER -->
</div>