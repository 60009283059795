import { Injectable } from '@angular/core';
import { Product, PoDetailCancel, PoHeader, PoNote, AddonBin, Addon, EntityIssue } from 'app/model/entities/entity-model';
import { ItemBin } from 'app/model/entities/item-bin';
import { ItemDetail } from 'app/model/entities/item-detail';
import { JoDetail } from 'app/model/entities/jo-detail';
import { PoDetail } from 'app/model/entities/po-detail';
import { PoTrx } from 'app/model/entities/po-trx';
import { PoTrxHist } from 'app/model/entities/po-trx-hist';
import { BarcodePrintStatusCode } from 'app/model/enums/barcode-print-status-code';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { JoStatusCode } from 'app/model/enums/jo-status-code';
import { PoDetailTypeCode } from 'app/model/enums/po-detail-type-code';
import { PoNoteTypeCode } from 'app/model/enums/po-note-type-code';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { ProcessStatusCode } from 'app/model/enums/process-status-code';
import * as _ from 'lodash';
import { AuthService } from './auth.service';
import { BusyService } from './busy.service';
import { DbQueryService } from './db-query.service';
import { DbSaveService } from './db-save.service';
import { DialogService } from './dialog.service';
import { EntityFns } from './entity-fns';
import { UnitOfWork } from './unit-of-work';
import { DateFns } from './date-fns';
import { AddonUtilsService } from './addon-utils.service';
import { AddonBinStationStatusCode } from 'app/model/enums/addon-bin-station-status-code';
import { UtilFns } from './util-fns';
import { WarningTypeCode } from 'app/model/enums/warning-type-code';


@Injectable({providedIn: 'root'})
export class PoUtilsService {
  constructor(
    public uow: UnitOfWork,
    public dbQueryService: DbQueryService,
    public dbSaveService: DbSaveService,
    public addonUtilsService: AddonUtilsService,
    public dialogService: DialogService,
    public authService: AuthService,
    public busyService: BusyService
  ) {}

  
  async deleteStrandedPoHeaders(pohIdSet: Set<number>) {
    if (pohIdSet.size == 0) {
      return;
    }
    const pohIds = Array.from(pohIdSet);
    
    const pohs = await this.dbQueryService.getPoHeaders(pohIds);
    pohs.forEach(poh => {
      if (poh.poDetails.length == 0) {
        poh.entityAspect.setDeleted();
      }
    });
    if (this.uow.hasChanges()) {
      await this.dbSaveService.saveChanges();
    }
  }

  async applyPodToHold(pod: PoDetail, qty: number) {
    const poTrx = this.createPoTrx('Apply to Held', 1);
      this.addToPoTrxHist(pod, poTrx.id);
      pod.poTrx = poTrx;
      pod.heldQty = pod.heldQty + qty;
      const poNote = this.createPoNote(pod, poTrx, `Held application - qty: ${qty}.`);
      if (qty > 0) {
        this.createIssue(pod, poNote.note);
      }
      // insures that any mods of the pod by another user hit an Optimistic concurrency violation.
      pod.modTs = new Date();
      await this.dbSaveService.saveChanges();
      return true;
  }

  createIssue(pod: PoDetail, note: string) {
    const issueStruct = <EntityIssue> {};
    issueStruct.crtnTs = new Date(Date.now());
    issueStruct.accountId = pod.accountId;
    issueStruct.crtnUserInit = this.authService.getUser().initials;
    issueStruct.description = note;
    issueStruct.warningTypeId = WarningTypeCode.Warning;
    issueStruct.entityTypeName = 'PoDetail';
    issueStruct.entityId =  pod.id.toString();
    const issue = this.uow.createEntity(EntityIssue, issueStruct);
  }

  async applyPodToDefault(pod: PoDetail, qty: number) {
    // No need for PoTrx logic here
    let itemDetails: ItemDetail[];
    if (qty < pod.orderQty) {
      const poTrx = this.createPoTrx('Apply to Default', 1);
      this.addToPoTrxHist(pod, poTrx.id);
      pod.poTrx = poTrx;
      this.createPoNote(pod, poTrx, `Default application - qty: ${qty}.`);
      itemDetails = await this.createItemDetailsFromPoDetail(pod, qty, poTrx.id);
    } else {
      itemDetails = await this.createItemDetailsFromPoDetail(pod, qty, null);
    }
    if (itemDetails == null) {
      return false;
    }
    
    // insures that any mods of the pod by another user hit an Optimistic concurrency violation.
    pod.modTs = new Date();
    await this.dbSaveService.saveChanges();
    this.dialogService.toast(`Saved ${qty} new ItemDetail records`);
    return true;
  }

  async applyPodToStock(pod: PoDetail, qty: number) {
    if (qty > pod.getRemainingQty()) {
      // toast here
      return false;
    }

    // we want to handle this differently.
    if (pod.poDetailTypeId === PoDetailTypeCode.Stock) {
      return await this.applyPodToDefault(pod, qty);
    }

    const poTrx = this.createPoTrx('Apply to Stock', 3); // 3 records either added or changed ( 1 mod + 2 adds)
    // copy pod to hist before modifying it.
    this.addToPoTrxHist(pod, poTrx.id);

    pod.modTs = new Date();
    // orig pod qty is reduced
    pod.orderQty = pod.orderQty - qty;
    pod.poTrx = poTrx;
    this.createPoNote(pod, poTrx, `Original PO Detail - reduced qty by ${qty}.`);

    const now = new Date();

    // clone 1 is qty moving items to stock ( a new Pod on existing Po) + create itemDetails
    const clone1 = <PoDetail>EntityFns.cloneStruct(pod, ['id']);
    clone1.joDetailId = null;
    clone1.poDetailTypeId = PoDetailTypeCode.Stock;
    clone1.orderQty = qty;
    clone1.poTrx = poTrx;
    clone1.crtnTs = new Date();
    const pod1 = this.uow.createEntity(PoDetail, clone1);
    this.createPoNote(pod1, poTrx, `New PO Detail for stock - qty: ${qty}.`);
    const itemDetails = await this.createItemDetailsFromPoDetail(pod1, qty, poTrx.id);
    if (itemDetails == null) {
      return false;
    }

    // clone 2 is recreating a copy of the original pod with qty ( a new Pod with new Po )
    const clone2Poh = <PoHeader>EntityFns.cloneStruct(pod.poHeader, ['id']);
    clone2Poh.poStatusId = PoStatusCode.NewUnposted;
    clone2Poh.isLocked = true;
    clone2Poh.crtnTs = now;
    clone2Poh.poDate = DateFns.startOfDay(now);

    const poh2 = this.uow.createEntity(PoHeader, clone2Poh);
    
    const clone2 = <PoDetail>EntityFns.cloneStruct(pod, ['id']);
    clone2.poHeaderId = poh2.id;
    clone2.orderQty = qty;
    clone2.poTrx = poTrx;
    clone2.crtnTs = now;
    const pod2 = this.uow.createEntity(PoDetail, clone2);
    // This is a poNote without a PoHeaderId, and will need to be fixed up during 'import'
    this.createPoNote(pod2, poTrx, `New PoDetail for stock with new PoHeader - qty ${qty}.`);
    // Note: we now have excess stock (qty)
    await this.dbSaveService.saveChanges();
    return true;
  }

  // This function is used for Jod->Jod (alt) and for Stock->Jod
  // assumes jod.poDetails is populated.
  async applyPodToJod(pod: PoDetail, jod: JoDetail, qty: number) {

    const now = new Date();
    // we want to handle this differently.
    if (pod.joDetailId === jod.id) {
      return await this.applyPodToDefault(pod, qty);
    }

    let altPod = <PoDetail> null;
    const isJoOpenComplete = jod.joHeader.joStatusId === JoStatusCode.OpenProcessComplete;
    if (isJoOpenComplete) {
      const poDetails = jod.poDetails.filter(pod2 => pod2.getRemainingQty() >= qty);
      if (poDetails.length === 0) {
        this.dialogService.showOkMessage(`Insufficient qty`, 'Unable to locate a single alternative Po Detail record with sufficient qty. Try lowering the qty.');
        return false;
      }
      altPod = _.first(poDetails);
    }

    const count = 3 + (isJoOpenComplete ? 1 : 0);
    const poTrx = this.createPoTrx('Apply to JO Detail', count); // 3 or 4 records either added or changed ( 2 mod + 2 adds)

    // copy pod to hist before modifying it.
    this.addToPoTrxHist(pod, poTrx.id);
    pod.modTs = now;
    // orig pod qty is reduced
    pod.orderQty = pod.orderQty - qty;
    pod.poTrx = poTrx;
    this.createPoNote(pod, poTrx, `Original PO Detail - reduced qty by ${qty}.`);
    // clone 1 is recreating a copy of the original pod with new jod and qty ( a new Pod with orig po ) + create itemDetails
    const clone1 = <PoDetail>EntityFns.cloneStruct(pod, ['id']);
    clone1.joDetailId = jod.id;
    clone1.poDetailTypeId = PoDetailTypeCode.Order;
    clone1.orderQty = qty;
    clone1.poTrx = poTrx;
    clone1.crtnTs = now;
    const pod1 = this.uow.createEntity(PoDetail, clone1);
    this.createPoNote(pod1, poTrx, `New PO Detail on orig PO with selected/alternative JO Detail - qty: ${qty}`);
    const itemDetails = await this.createItemDetailsFromPoDetail(pod1, qty, poTrx.id);
    // TODO: is next para needed?
    if (itemDetails == null) {
      this.dialogService.showOkMessage('Error', 'Unable to create ItemDetails');
      this.dbSaveService.rejectChanges();
      return false;
    }

    if (isJoOpenComplete) {
      // uses altPod

      // copy altPod to hist before modifying it.
      this.addToPoTrxHist(altPod, poTrx.id);
      // this is needed because we just stole this number of items from this pod for the new pod created above
      altPod.orderQty = altPod.orderQty - qty;
      altPod.poTrx = poTrx;
      this.createPoNote(altPod, poTrx, `Original/alternative PO Detail - reduced qty by ${qty}.`);
      // clone 2 is a copy of the altPod with original pod's jod ( or stock)
      const clone2 = <PoDetail>EntityFns.cloneStruct(altPod, ['id']);
      if (pod.joDetailId == null) {
        clone2.poDetailTypeId = PoDetailTypeCode.Stock;
        clone2.joDetailId = null;
      } else {
        clone2.poDetailTypeId = PoDetailTypeCode.Order; // was already this value.. but for clarity.
        clone2.joDetailId = pod.joDetailId;
      }
      clone2.orderQty = qty;
      clone2.poTrx = poTrx;
      clone2.crtnTs = now;
      const pod2 = this.uow.createEntity(PoDetail, clone2);
      this.createPoNote(pod2, poTrx, `New PO Detail which is a copy of the alternative PO Detail - qty: ${qty}.`);
    } else {
      // else create a single clone with copy of the orig pod but new poHeaderId
      const clone2Poh = <PoHeader>EntityFns.cloneStruct(pod.poHeader, ['id']);
      clone2Poh.poStatusId = PoStatusCode.NewUnposted;
      clone2Poh.isLocked = true;
      clone2Poh.crtnTs = now;
      clone2Poh.poDate = DateFns.startOfDay(now);
      const poh2 = this.uow.createEntity(PoHeader, clone2Poh);

      const clone2 = <PoDetail>EntityFns.cloneStruct(pod, ['id']);
      clone2.poHeaderId = poh2.id;
      clone2.orderQty = qty;
      clone2.poTrx = poTrx;
      clone2.crtnTs = now;
      const pod2 = this.uow.createEntity(PoDetail, clone2);
      this.createPoNote(pod2, poTrx, `New PO Detail which is a copy of the original PO Detail but with new PO Header - qty ${qty}.`);
    }
    await this.dbSaveService.saveChanges();
    return true;
  }

  async applyPodToCancel(pod: PoDetail, iss: Product, qty: number ) {
    const now = new Date();
    if (qty > pod.getRemainingQty()) {
      // toast here
      return false;
    }
    const isPodForJo = pod.joDetailId != null;

    const poTrx = this.createPoTrx('Apply to Cancel', isPodForJo ? 2 : 1);
    this.addToPoTrxHist(pod, poTrx.id);
    pod.modTs = new Date();
    // only the poTrx is updated
    pod.poTrx = poTrx;
    this.createPoNote(pod, poTrx, `Canceled qty: ${qty}.`);
    this.createPoDetailCancels(pod, poTrx.id, qty);

    // if orig po was applied to a Job Order then we need to do additional work
    if (isPodForJo) {
      const clone1Poh = <PoHeader>EntityFns.cloneStruct(pod.poHeader, ['id']);
      clone1Poh.poStatusId = PoStatusCode.NewUnposted;
      clone1Poh.isLocked = true;
      clone1Poh.crtnTs = now;
      clone1Poh.poDate = DateFns.startOfDay(now);
      const poh1 = this.uow.createEntity(PoHeader, clone1Poh);

      // else create a single clone with copy of the orig pod but with new poHeaderId and the new productId. ( but same JoDetailId)
      const clone1 = <PoDetail>EntityFns.cloneStruct(pod, ['id']);
      clone1.poHeaderId = poh1.id;
      clone1.productId = iss.id;
      clone1.orderQty = qty;
      clone1.poTrx = poTrx;
      clone1.crtnTs = new Date();
      const pod2 = this.uow.createEntity(PoDetail, clone1);
      // This is a poNote without a PoHeaderId, and will need to be fixed up during 'import'
      this.createPoNote(pod2, poTrx, `New PO Detail created to replace canceled PO Detail with different item style/size - qty: ${qty}.`);
    }
    await this.dbSaveService.saveChanges();
    return true;
  }

  async unapplyPod(pod: PoDetail) {
    if (pod.poTrxId != null) {
      await this.unapplyPodTrx(pod);
    } else {
      await this.unapplyPodSimple(pod);
    }
  }

  private async unapplyPodTrx(pod: PoDetail) {
    const poTrx = await this.dbQueryService.getPoTrx(pod.poTrxId);
    if (poTrx == null) {
      return false;
    }
    const poDetails = poTrx.poDetails;
    const poTrxHists = poTrx.poTrxHists;
    const itemDetails = poTrx.itemDetails;

    if (poDetails.length !== poTrx.podCount) {
      await this.dialogService.showOkMessage(
        'Unable to unapply',
        `This PO Detail can not be unapplied until other unapplications are performed first.  
        Unapply operations must occur in reverse order to the original application.`
      );
      return false;
    }

    const hasStockPods = poDetails.some(pod => pod.poDetailTypeId != PoDetailTypeCode.Order);
    if (hasStockPods) {
      const altPoHeaders = _.uniq(poDetails.filter(pod2 => pod2.poHeaderId != pod.poHeaderId).map(pod2 => pod2.poHeader));
      const areAnyAltPoHeadersIncomplete = altPoHeaders.some(poh => {
          const r = poh && poh.poStatusId == PoStatusCode.OpenIncomplete && poh.id != pod.poHeaderId
          return !!r;
        });
      if (areAnyAltPoHeadersIncomplete) {
        await this.dialogService.showOkMessage(
          'Unable to unapply',
          `This PO Detail can no longer be unapplied because this would result in removing details from an already placed Purchase Order (i.e. a PO in 'Open/Incomplete' status.)`
        );
        return false;
      }
    }

    const areAnyPosComplete = _.uniq(poDetails.map(pod2 => pod2.poHeader)).some(
      poh => {
        const r = poh && (( poh.poStatusId === PoStatusCode.Complete)); // || (poh.poStatusId == PoStatusCode.OpenIncomplete && poh.id != pod.poHeaderId));
        return !!r;
      });
    if (areAnyPosComplete) {
      await this.dialogService.showOkMessage(
        'Unable to unapply',
        `This PO Detail can no longer be unapplied because it or or one of its related PO Details is now in a 'Completed'` // or 'Open/Incomplete' status.`
      );
      return false;
    }
    // not all poDetails have joHeaders
    const joHeaders = poDetails.filter(pod2 => pod.joDetail != null).map(pod2 => pod.joDetail.joHeader);
    const areAnyJosComplete = joHeaders.some(joh => joh.joStatusId === JoStatusCode.Closed);
    if (areAnyJosComplete) {
      await this.dialogService.showOkMessage(
        'Unable to unapply',
        `This PO Detail can no longer be unapplied because it or or one of its related Job Orders is now in a 'Closed' status.`
      );
      return false;
    }

    const itdas =_.flatMap(itemDetails, x  => x.itemDetailAddons);
    // to remove 
    const isAddonInProgress = itdas.some(x => 
      (x.addonBinStationStatusId != null && x.addonBinStationStatusId != AddonBinStationStatusCode.Pending) 
      || x.addonBin?.currentAddonStationId != null);
    if (isAddonInProgress) {
      await this.dialogService.showOkMessage(
        'Unable to unapply',
        `This Item Details associated with this PO Detail are already part of an Addon workflow and can no longer be unapplied.`
      );
      return false;
    }

    if (poTrx.poDetailCancels.length === 0) {
      if (!await this.checkIfCanRemoveItemDetails(itemDetails)) {
        return false;
      }
      await this.removeItemDetails(itemDetails);
    }

    // remove all poDetailCancels.
    poTrx.poDetailCancels.slice().forEach(podc => podc.entityAspect.setDeleted());

    // remove all poNotes related to apply
    const notesToDelete = poTrx.poNotes.slice();
    notesToDelete.forEach(pon => pon.entityAspect.setDeleted());

    // replace poDetails with poTrxHists or delete them.
    // there may be no poTrxHists if the original op was a default op.
    let pohIdSet = new Set<number>();
    if (poTrxHists.length > 0) {
      poDetails.slice().forEach(curPod => {
        const poTrxHist = poTrxHists.find(pot => pot.poDetailId === curPod.id);

        if (poTrxHist != null) {
          const { poTrxId, poDetailId, prevPoTrxId, ...oldPod } = poTrxHist['_backingStore'];
          // copy oldPod over pod
          oldPod.poTrxId = prevPoTrxId;
          Object.assign(curPod, oldPod);
        } else {
          pohIdSet.add(curPod.poHeaderId);
          curPod.entityAspect.setDeleted();
        }
      });
    }

    // TODO: remove PoTrx and PoTrxHist related to this.
    await this.dbSaveService.saveChanges();

    await this.deleteStrandedPoHeaders(pohIdSet);

    return true;
  }


  async unapplyPodSimple(pod: PoDetail) {
    const isPosComplete = pod.poHeader && pod.poHeader.poStatusId === PoStatusCode.Complete;
    if (isPosComplete) {
      await this.dialogService.showOkMessage(
        'Unable to unapply',
        `This PO Detail can no longer be unapplied because it is now in a 'Completed' status.`
      );
      return false;
    }
    const isJosComplete = pod.joDetail && pod.joDetail.joHeader.joStatusId === JoStatusCode.Closed;
    if (isJosComplete) {
      await this.dialogService.showOkMessage(
        'Unable to unapply',
        `This PO Detail can no longer be unapplied because its related Job Order is now in a 'Closed' status.`
      );
      return false;
    }

    if (pod.itemDetails.length == 0) {
      await this.dialogService.showOkMessage(
        'Unable to unapply',
        `This PO Detail can no longer be unapplied because there are no item details associated with it - probably as a result of a cancellation.`
      );
      return false;
    }

    const itdas =_.flatMap(pod.itemDetails, x  => x.itemDetailAddons);
    // to remove 
    const isAddonInProgress = itdas.some(x => 
      (x.addonBinStationStatusId != null && x.addonBinStationStatusId != AddonBinStationStatusCode.Pending) 
      || x.addonBin?.currentAddonStationId != null);
    if (isAddonInProgress) {
      await this.dialogService.showOkMessage(
        'Unable to unapply',
        `This Item Details associated with this PO Detail are already part of an Addon workflow and can no longer be unapplied.`
      );
      return false;
    }

    if (! await this.checkIfCanRemoveItemDetails(pod.itemDetails)) {
      return false;
    }

    await this.removeItemDetails(pod.itemDetails);
    await this.dbSaveService.saveChanges();
    return true;
  }

  private async checkIfCanRemoveItemDetails(itemDetails: ItemDetail[]) {
    const areAnyItdsUnavailable = itemDetails.some( itd => {
      // TODO: check this logic...
      let isUnavailable =  itd.itemDetailStatusId >= ItemDetailStatusCode.InProcessAddon;
        // && itd.itemDetailStatusId < ItemDetailStatusCode.Shipped;
      
      return isUnavailable;
    });

    if (areAnyItdsUnavailable) {
      await this.dialogService.showOkMessage(
        'Unable to unapply',
        `This PO Detail can no longer be unapplied because one of its related ItemDetails has already been at least partially processed.`
      );
      return false;
    }
    return true;

  }

  async removeItemDetails(itemDetails: ItemDetail[]) {
    
    const addonBinIds = new Set<number>();
    itemDetails.slice().forEach(itd => {
      // also delete the associated itemDetailAddons...
      itd.itemDetailAddons.slice().forEach(itda => {
        addonBinIds.add(itda.addonBinId);
        itda.addonBinId = null;
        itda.addonBinStationStatusId = null;
        itda.entityAspect.setDeleted();
      });
      itd.entityAspect.setDeleted();
    });
    // remove empty bins
    await this.addonUtilsService.checkAndRemoveEmptyBins(Array.from(addonBinIds));
    
  }

  createPoTrx(note: string, podCount: number) {
    const struct = <PoTrx>{};
    struct.crtnTs = new Date();
    struct.podCount = podCount;
    struct.note = note;
    const poTrx = this.uow.createEntity(PoTrx, struct);
    return poTrx;
  }

  addToPoTrxHist(pod: PoDetail, poTrxId: number) {
    const histClone1 = <PoTrxHist>EntityFns.cloneStruct(pod, ['id', 'poTrxId']);
    histClone1.poDetailId = pod.id;
    histClone1.poTrxId = poTrxId;
    histClone1.prevPoTrxId = pod.poTrxId;
    return this.uow.createEntity(PoTrxHist, histClone1);
  }

  createPoNote(pod: PoDetail, poTrx: PoTrx, note: string) {
    const struct = <PoNote> {
      poHeaderId: pod.poHeaderId, // note: this might be null
      poDetailId: pod.id,
      poNoteTypeId: PoNoteTypeCode.ApplyChange,
      crtnTs: new Date(),
      crtnUserInit: this.authService.getUser().initials,
      note: poTrx.note + ' / ' + note,
      poTrxId: poTrx.id,
    };
    const pon = this.uow.createEntity(PoNote, struct);

    // Removed per discussion with Jeff on 4/18/2022
    // // if a poDetailNote and it has a corresponding JoDetail
    // // create a copy a JoNote copy
    // if (pod.joDetailId != null) {
    //   // Sanity check - shouldn't happen - we should be fetching JoDetail if there is a JoDetailId
    //   if (pod.joDetail == null) {
    //     this.dialogService.showOkMessage(
    //       'Unable to create corresponding JoDetail note',
    //       `Please report this error to the developer.  This should not happen.`
    //     );
    //     return pon;
    //   }
    //   const joStruct = <JoNote> {
    //     joHeaderId: pod.joDetail.joHeaderId,
    //     joDetailId: pod.joDetailId,
    //     joNoteTypeId: JoNoteTypeCode.PodNote,
    //     crtnTs: new Date(),
    //     crtnUserInit: this.authService.getUser().initials,
    //     note: poTrx.note + ' / ' + note,
    //   };
    //   const jon = this.uow.createEntity(JoNote, joStruct);
    // }
    return pon;
  }

  private async createItemDetailsFromPoDetail(pod: PoDetail, qty: number, poTrxId: number = null) {
    const struct = <ItemDetail>{};
    struct.accountId = pod.accountId;
    struct.productId = pod.productId;
    struct.itemBinId = ItemBin.UnitecDefault;
    struct.barcodePrintStatusId = BarcodePrintStatusCode.None;
    if (poTrxId != null) {
      struct.poTrxId = poTrxId;
    }
    if (pod.poDetailTypeId === PoDetailTypeCode.Stock) {
      struct.joDetailId = null;
      struct.itemDetailStatusId = ItemDetailStatusCode.InInventory;
    } else if (pod.poDetailTypeId === PoDetailTypeCode.Special) {
      struct.joDetailId = null;
      struct.itemDetailStatusId = ItemDetailStatusCode.Special;
    } else if (pod.poDetailTypeId === PoDetailTypeCode.Order) {
      struct.joDetailId = pod.joDetailId;
      const joStatusId = pod.joDetail.joHeader.joStatusId;
      if (joStatusId === JoStatusCode.OpenProcessIncomplete) {
        if (pod.joDetail.joDetailAddons.length > 0) {
          struct.itemDetailStatusId = ItemDetailStatusCode.InProcessPendingAddon;
        } else {
          struct.itemDetailStatusId = ItemDetailStatusCode.InProcessPendingShipPrep;
        }
      } else if (joStatusId === JoStatusCode.OpenProcessComplete) {
        if (pod.joDetail.joDetailAddons.length > 0) {
          struct.itemDetailStatusId = ItemDetailStatusCode.InProcessPendingAddon;
        } else {
          struct.itemDetailStatusId = ItemDetailStatusCode.InProcessPendingShipPrep;
        }
      } else {
        this.dialogService.showOkMessage(
          'Unable to apply',
          `PoDetail's JoDetail has a status of ${pod.joDetail.joHeader.joStatus.name}`
        );
        return null;
      }
    } else {
      this.dialogService.showOkMessage(
        'Unable to apply',
        `Not sure how to apply a Purchase Order Detail type of ${pod.poDetailType.name}`
      );
      return null;
    }
    struct.poDetailId = pod.id;
    struct.invoiceHeaderId = null;
    struct.crtnTs = new Date();

    var accountAddons: Addon[] = [];
    if (pod.joDetail != null) {
      accountAddons = await this.dbQueryService.getAddons(pod.joDetail.joHeader.accountId);
    } 

    const nextIds = await this.dbQueryService.getNextItemDetailIds(qty);
    const itemDetails: ItemDetail[] = [];
    for (let step = 0; step < qty; step++) {
      struct.id = nextIds[step];
      const itemDetail = this.uow.createEntity(ItemDetail, struct);
      if (itemDetail.joDetailId != null) {
        const jod = itemDetail.joDetail;
        const itdas = this.addonUtilsService.createItemDetailAddonsFromJoDetail(jod, itemDetail, accountAddons);
      }
      itemDetails.push(itemDetail);
    }

    // creates or finds addon bins across all of the itemDetails - because they may share bins. 
    const addonBins = await this.addonUtilsService.findOrCreateAddonBins(itemDetails);

    return itemDetails;
  }

  

  createPoDetailCancels(poDetail: PoDetail, poTrxId: number, qtyCanceled: number) {
    const struct = <PoDetailCancel>{};
    struct.poDetailId = poDetail.id;
    // for Undo always undo the latest PoDetailCancel record for
    struct.crtnTs = new Date();
    struct.qty = qtyCanceled;
    struct.poTrxId = poTrxId;
    const podc = this.uow.createEntity(PoDetailCancel, struct);
    return podc;
  }

  
  
  
}
