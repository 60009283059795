import { UtilFns } from './util-fns';
import { Injectable } from '@angular/core';

export type ErrorLevel = 'info'|'warning'|'error'|'success';

export class ErrorEntry {
  constructor(public error: any, public errorLevel: ErrorLevel) {

  }

  formatError() {
    return UtilFns.getErrorMessage(this.error);
  }
}

@Injectable({providedIn: 'root'})
export class ErrorLogger {
  _errors: ErrorEntry[] = [];
  constructor() {
  }

  log(e: string | any, errorLevel?: ErrorLevel) {
    // tslint:disable-next-line:no-unused-expression
    console && console.log && console.log(e.toString());
    errorLevel = errorLevel || 'error';
    const ee = new ErrorEntry(e, errorLevel);
    this._errors.push(ee);
    // notify(ee.formatError(), errorLevel, errorLevel === 'error' ? 5000 : 2000);
  }

  getErrors() {
    return this._errors;
  }
}
