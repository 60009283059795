// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ProxCombineItem } from './prox-combine-item';
import { ProxSubmissionItem } from './prox-submission-item';
import { ProxSubmissionStatus } from './prox-submission-status';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ProxSubmission extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  confirmationKey: string;
  proxSubmissionStatusId: number;
  submissionTs: Date;
  proxCombineItems: ProxCombineItem[];
  proxSubmissionItems: ProxSubmissionItem[];
  proxSubmissionStatus: ProxSubmissionStatus;
}

