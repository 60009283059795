import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Email, InvoiceHeader, JoHeader, Manifest, ManifestGroup, ManifestGroupInvoice } from 'app/model/entities/entity-model';
import { BusyService } from 'app/shared/busy.service';
import { DateFns } from 'app/shared/date-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { EmailService } from 'app/shared/email.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { UtilFns } from 'app/shared/util-fns';
import { environment } from 'environments/environment';

export interface ManifestPrintDialogComponentData {
  manifest: Manifest;
  invoices: InvoiceHeader[];
  manifestGroupId: number;
  /** if we are not already on the preview page */
  canPreview: boolean;
}

@Component({
  templateUrl: './manifest-print-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class ManifestPrintDialogComponent {

  manifest: Manifest;
  invoices: InvoiceHeader[];
  manifestGroupId: number;
  joHeader: JoHeader;
  emailAddress: string;
  emailStatus: string;
  email: Email;
  pdfurl: string;
  canPreview: boolean;
  isPrinting: boolean;
  isAccounted: boolean;

  static async show(matDialog: MatDialog, data: ManifestPrintDialogComponentData) {
    return await matDialog
      .open(ManifestPrintDialogComponent, {
        disableClose: true,
        height: '340px',
        width: '800px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: ManifestPrintDialogComponentData, public dialogRef: MatDialogRef<ManifestPrintDialogComponent>,
    private emailService: EmailService, private router: Router, public busyService: BusyService, private uow: UnitOfWork,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService) {

    this.manifest = data.manifest;
    this.invoices = data.invoices;
    this.manifestGroupId = data.manifestGroupId;
    this.canPreview = data.canPreview;
    this.prepare();
  }

  prepare() {
    this.joHeader = this.manifest.joHeaders[0];
    this.emailAddress = this.joHeader && this.joHeader.empEmail && this.joHeader.empEmail.trim();
    if (this.manifestGroupId) {
      this.getEmailStatus();
    }
  }

  async getOrCreateManifestGroup(): Promise<ManifestGroup> {
    let q = this.uow.createQuery(ManifestGroup, 'ManifestGroups');
    if (this.manifestGroupId) {
      q = q.where({ id: this.manifestGroupId });
    
      const groups = await q.execute();
      if (groups && groups.length > 0) {
        const mg = groups[0];
        this.manifestGroupId = mg.id;
        return mg;
      } else {
        return null;
      }
    } else {
      const today = DateFns.startOfDay(new Date());
      const mg = this.uow.createEntity(ManifestGroup, { 
        manifestId: this.manifest.id,
        sentDate: today });
      this.invoices.forEach(ih => {
        this.uow.createEntity(ManifestGroupInvoice, { manifestGroup: mg, invoiceHeader: ih });
      });
      await this.dbSaveService.saveChanges();
      this.manifestGroupId = mg.id;
      this.dbSaveService.updateManifestAndRelated(this.manifest);
      return mg;
    }
  }

  canSendEmail(): boolean {
    // TODO better email validation; check manifest status
    return (this.emailAddress && this.emailAddress.length >= 6);
  }

  async sendEmail() {
    if (!this.canSendEmail()) {
      return;
    }
    const manifestGroup = await this.getOrCreateManifestGroup();
    if (manifestGroup == null) {
      return;
    }

    const status = await this.emailService.sendManifestEmail(manifestGroup.id, this.emailAddress);
    this.emailStatus = 'Email status: ' + status;

    if (status === 'accepted' || status === 'stored') {
      this.emailStatus = 'Email status: ' + status + '; checking for delivery';
    }
  }

  async getEmailStatus() {
    const email = await this.emailService.getManifestEmailStatus(this.manifestGroupId);
    if (email) {
      this.email = email;
      this.emailStatus = 'Email status: ' + email.status;
    }
  }

  async printDocument() {
    const manifestGroup = await this.getOrCreateManifestGroup();
    if (manifestGroup == null) {
      return;
    }

    this.pdfurl = environment.apiRoot + 'api/pdf/pdffromurl/Manifest' + this.manifest.id + '?url=' + UtilFns.getBaseUrl() +
      'printable-manifest-group/' + manifestGroup.id;

    const pwin = window.open(this.pdfurl, '_blank');
    
  }

  showPreview() {
    let url = '/printable-manifest/' + this.manifest.id;
    this.router.navigateByUrl(url);
    this.close();
  }

  close() {
    this.dialogRef.close(null);
  }

}
