import { Injectable } from '@angular/core';
import { InvoiceDelayDataForChart, JobOrdersByStatusForChart, ManufacturerCountInInventory } from 'app/model/entities/-chart-classes';
import { ItemDetailSummary } from 'app/model/entities/-item-detail-summary';
import { ProductNeededForEOQ } from 'app/model/entities/-product-type-summary';
import {
  Account, BarcodePrintStatus, Config, DocSubmitType,  AddonDoc,
  EntityIssue, EOQType, InvoiceDetail, InvoiceHeader, InvoiceStatus, ItemBin, ItemDetail, ItemDetailAddon, ItemDetailStatus,
  Image,   ItemReplacementStatus, ItemReplacementType, Feature, FeatureChoice, ProductType,
  ProductTypeAlt, Product, ProductEOQ, JoDetail, JoDetailAddon, JoHeader, JoNote, JoPullBatch, JoStatus, 
  Manifest, ManifestGroup, ManifestGroupInvoice,  Manufacturer, PoDetail,
  PoDetailType, PoHeader, PoNote, PoNoteType, PoStatus, PoTrx, PoTrxHist, ReturnRequest, ReturnRequestDetail,
  ReturnRequestDetailStatus, ReturnRequestStatus, Shipment, WarningType, ActiveStatus, ProxSubmissionItem, ProductTypeTag,
  ProductTypeTagMap, ProxCombineItem, ProductFeatureChoice,
  _MigrationFeatureChoiceChange, _MigrationProductTypeChange,
  Addon,  AddonStation,  AddonBin,   AddonBinStatus,  AddonBinStationStatus,
  ShipBin,
  ShipFrequency,
  ProxSoloSubmission,
  ProxSoloSubmissionStatus,
  ProxSubmissionStatus,
  ReturnReasonType,
} from 'app/model/entities/entity-model';
import { InvoiceStatusCode } from 'app/model/enums/invoice-status-code';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { JobStatusCodeExt, JoStatusCode } from 'app/model/enums/jo-status-code';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { ReissuanceTypeCode } from 'app/model/enums/reissuance-type-code';
import { Entity } from 'breeze-client';
import * as _ from 'lodash';
import { RRule } from 'rrule';
import { UnitOfWork } from './unit-of-work';
import { UtilFns } from './util-fns';
import { DateFns } from './date-fns';
import { AddonBinStatusCode } from 'app/model/enums/addon-bin-status-code';


interface HasRowVersion {
  rowVersion: any;
}

interface AppConfig {
  productTypeDefault?: {
    orderingCostAmt: number;
    unitCarryingCostPerYearAmt: number;
    safetyStockPerYearPct: number;
  },
  unitecAddress?: {
    city: string;
    line1: string;
    name: string;
    phone: string;
    zipCode: string;
    state: string;
  },
  account?: {
    contactSchedule: string,
    dueDateNumDays: number;
  },
  addon?: {
    maxBatchSize: number;
  }
}

@Injectable({ providedIn: 'root' })
export class DbQueryService {
  cachedLookups = {};
  private cacheAllPromise: Promise<any>;
  public appConfig: AppConfig;

  constructor(public uow: UnitOfWork) { }

  getUnitecAddressAsString() {
    const addr = this.appConfig.unitecAddress;
    return `${addr.name}\n${addr.line1}\n${addr.city}, ${addr.state} ${addr.zipCode}\n`;
  }

  async cacheAll(shouldIncludeConfigs = true) {
    if (this.cacheAllPromise) {
      return this.cacheAllPromise;
    }

    // cache a bunch of lookups

    this.cacheAllPromise = this.uow.manager.executeQuery('lookups');
    await this.cacheAllPromise;
    this.cache(ActiveStatus, 'id', 'name');
    this.cache(AddonBinStationStatus, 'id', 'name');
    this.cache(AddonBinStatus, 'id', 'name');
    this.cache(JoStatus, 'id', 'name');
    this.cache(InvoiceStatus, 'id', 'name');
    this.cache(ItemDetailStatus, 'id', 'name');
    this.cache(PoStatus, 'id', 'name');
    this.cache(PoDetailType, 'id', 'name');
    this.cache(PoNoteType, 'id', 'name');
    this.cache(ProxSubmissionStatus, 'id', 'name');
    this.cache(ProxSoloSubmissionStatus, 'id', 'name');
    this.cache(DocSubmitType, 'id', 'name');
    this.cache(BarcodePrintStatus, 'id', 'name');
    this.cache(ItemReplacementStatus, 'id', 'name');
    this.cache(ItemReplacementType, 'id', 'name');
    this.cache(ReturnRequestStatus, 'id', 'name');
    this.cache(ReturnReasonType, 'id', 'name');
    this.cache(ReturnRequestDetailStatus, 'id', 'name');
    this.cache(WarningType, 'id', 'name');
    this.cache(EOQType, 'id', 'name');
    this.cache(ShipFrequency, 'id', 'name');

    if (shouldIncludeConfigs) {
      const configs = await this.getConfigs();
      this.updateAppConfig(configs);
    }

  }

  updateAppConfig(configs: Config[]) {
    this.appConfig = this.parseConfigs(configs);
  }

  private parseConfigs(configs: Config[]) {
    let appConfig: AppConfig = {};
    configs.forEach(c => {
      if (appConfig[c.groupName] == null) {
        appConfig[c.groupName] = {};
      }
      if (c.fieldType == 'string') {
        appConfig[c.groupName][c.fieldName] = c.value;
      } else if (c.fieldType == 'number') {
        appConfig[c.groupName][c.fieldName] = Number.parseFloat(c.value);
      }
    })
    return appConfig;
  }

  createQuery<T extends Entity>(type: new () => T, resourceName: string = null) {
    return this.uow.createQuery(type, resourceName);
  }

  async getAll<T extends Entity>(type: new () => T) {
    const r = this.uow
      .createQuery(type)
      .execute();
    return r;
  }

  // used to get all of a type that has already been fetched into the entityManager cache
  // intended for different use case than getAllCached which is
  getAlreadyFetched<T extends Entity>(type: new () => T) {
    const r = this.uow.getEntities(type);
    return r;
  }

  getAllCached<T extends Entity>(type: new () => T) {
    const r = this.uow.getEntities(type);
    if (r.length === 0) {
      throw Error('Not a cached resource: ' + type.name);
    }
    return r;
  }

  async cacheAndGetAll<T extends Entity>(type: new () => T) {
    await this.cacheAll();
    return this.getAllCached(type);
  }

  private cache<T extends Entity>(type: new () => T, keyProp: string, valueProp: string) {
    const typeName = type.prototype.entityType.shortName;
    let map = this.cachedLookups[typeName];
    if (map) {
      return map;
    }
    const r = this.uow.getEntities(type);
    map = _.chain(r)
      .keyBy(keyProp)
      .mapValues(valueProp)
      .value();
    this.cachedLookups[typeName] = map;
    return map;
  }

  lookup(typeName: string, id: any) {
    const map = this.cachedLookups[typeName];
    if (map == null) {
      return null;
    }
    return map[id];
  }

  async getById<T extends Entity>(type: new () => T, id: any) {
    return this.uow.fetchEntityByKey(type, id);
  }

  async checkRowVersion(entity: Entity & HasRowVersion) {
    const rowVersion = entity.rowVersion;
    const r = await entity.entityAspect.entityManager.fetchEntityByKey(entity.entityAspect.getKey());
    // r.entity will be null if the entity no longer exists.
    if (r.entity == null || entity.rowVersion != rowVersion) {
      throw 'optimistic concurrency error';
    }
  }

  // fieldKey is the real key field of entity, fieldsNames are list of fields that are the alternate keys
  async checkIfIsUnique<T extends Entity>(entity: T, fieldKey: string, ...fieldNames: string[]): Promise<boolean> {
    const type = entity.constructor as { new(): T; };
    const where = {};
    fieldNames.forEach(f => where[f] = entity[f]);
    const r = await this.uow.createQuery(type)
      .where(where)
      .execute();

    return r.length == 0 ? true : r.length == 1 && r[0][fieldKey] === entity[fieldKey]
  }

  async checkIfInUse<T1 extends Entity, T2 extends Entity, K extends keyof T2>(entity: T1, dependentType: new () => T2, depFieldName: K): Promise<boolean> {
    const where = {};
    where[depFieldName as string] = entity['id'];
    const r = await this.uow.createQuery(dependentType)
      .where(where)
      .take(1)
      .execute();

    return r.length > 0;
  }

  // async checkIfInUse<T1 extends Entity, T2 extends Entity>(entity: T1, dependentType: new () => T2, depFieldName: string): Promise<boolean> {
  //   const where = {};
  //   where[depFieldName] = entity['id'];
  //   const r = await this.uow.createQuery(dependentType)
  //     .where(where)
  //     .execute();

  //   return r.length > 0;
  // }

  // Specific queries

  createJoHeaderQuery() {
    const q = this.uow
      .createQuery(JoHeader, 'JoHeaders')
      // .where({ not: { joStatusId: JoStatusCode.Closed }})
      .where({
        joStatusId: {
          in: [JoStatusCode.Closed, JoStatusCode.OpenProcessComplete],
        },

      })
      .expand(['account', 'joDetails']);
    return q;
  }

  createJoHeaderQueryForStatusAndMonthOf(joStatusId: string, date: Date) {
    const dtStart = DateFns.startOfMonth(date);
    const dtEnd = DateFns.endOfMonth(date);
    const q = this.uow
      .createQuery(JoHeader, 'JoHeaders')

      .where({
        'joStatusId': joStatusId,
        'joDate': { '>=': dtStart, '<': dtEnd }
      })
      .expand(['joDetails']);
    // const r = await q.execute();
    // return r;
    return q;
  }

  createJoHeadersWithPostedInvoicesQuery() {
    const q = this.uow.createQuery(JoHeader, 'GetJoHeadersWithPostedInvoices')
      .expand(['account', 'joDetails']);
    return q;
  }

  createJoHeaderModeQuery(mode: JobStatusCodeExt) {
    const q = this.uow.createQuery(JoHeader, 'GetJoHeadersForJobOrderMode').withParameters({ jobOrderMode: mode });
    return q;
  }

  createJoHeadersInProcessQuery() {
    const q = this.uow.createQuery(JoHeader, 'GetJoHeadersInProcess')
      .expand(([
        'account'
      ]))
    return q;  
  }

  createJoHeadersInShippingProcessQuery() {
    const q = this.uow.createQuery(JoHeader, 'GetJoHeadersInShippingProcess')
      .expand(([
        'account'
      ]))
    return q;  
  }

  createInvoiceQuery() {
    const q = this.uow.createQuery(InvoiceHeader, 'InvoiceHeaders')
      .expand([
        'invoiceDetails.joDetail.product.productType.manufacturer',
        'invoiceDetails.joDetail.product.productFeatureChoices.featureChoice',
        'joHeader',
        'shipment'
      ]);
    return q;
  }

  createReturnRequestQuery() {
    const q = this.uow
      .createQuery(ReturnRequest, 'ReturnRequests')
      .expand(['returnRequestDetails', 'joHeader.account']);
    return q;
  }

  createManifestQuery() {
    const q = this.uow
      .createQuery(Manifest, 'Manifests')
      .expand(['manifestGroups.manifestGroupInvoices', 'joHeaders', 'joHeaders.account']);
    return q;
  }

  createPoHeaderQuery(poStatusId: string) {
    let q = this.uow.createQuery(PoHeader, 'PoHeaders');
    if (poStatusId != null) {
      q = q.where({ poStatusId: poStatusId });
    }
    q = q.expand(['manufacturer', 'poNotes']);
    return q;
  }

  createItemDetailQuery() {
    let q = this.uow.createQuery(ItemDetail, 'ItemDetails');
    // if (poStatusId != null) {
    //   q = q.where({ poStatusId: status });
    // }
    q = q.expand([
      'product.productType',
      'product.productType.manufacturer',
      'product.productFeatureChoices.featureChoice',
      'itemBin'
    ]);
    return q;
  }

  createItemDetailAddonQuery() {
    const whereClause = { 'itemDetail.itemDetailStatusId': { in: 
      [ ItemDetailStatusCode.InProcessPendingAddon, ItemDetailStatusCode.InProcessAddon ] 
    }};
    const q = this.uow
      .createQuery(ItemDetailAddon, 'ItemDetailAddons')
      .where(whereClause)
      .expand([
        'itemDetail',
        'itemDetail.product.productType.manufacturer',
        'itemDetail.product.productFeatureChoices.featureChoice',
        'addon.addonDocMaps.addonDoc',
        'itemDetail.joDetail',
        'itemDetail.poDetail'
      ]);

    return q;
  }

  createItemDetailSummaryQuery(isAccountOwned: boolean) {
    let q = this.uow.createQuery(null, 'GetItemDetailSummaries'); // method on server
    if (isAccountOwned !== null) {
      q = q.withParameters({ isAccountOwned: isAccountOwned });
    }
    return q;
  }



  async getPoDetailBackorders() {
    // const q = this.uow.createQuery(null, 'GetPoDetailBackorders'); // method on server
    const q = this.uow.createQuery(null, 'GetPoDetailBackorders'); // method on server
    const r = await q.execute();
    return r;
  }

  async getPoDetailAccountBackorders(accountGuid) {
    // method on server
    const q = this.uow.createQuery(null, 'GetPoDetailAccountBackorders')
      .withParameters({ guid: accountGuid });
    const r = await q.execute();
    return r;
  }

  async getPoDetailManufacturerBackorders(manufacturerGuid) {
    // method on server
    const q = this.uow.createQuery(null, 'GetPoDetailManufacturerBackorders')
      .withParameters({ guid: manufacturerGuid });
    const r = await q.execute();
    return r;
  }

  createProductTypeAccountSummaryQuery(productTypeId: number) {
    // method on server
    const q = this.uow.createQuery(null, 'GetProductTypeAccountSummaries')
      .withParameters({ productTypeId: productTypeId });
    return q;
  }

  createProductSummaryQuery(productTypeId: number) {
    // method on server
    const q = this.uow.createQuery(null, 'GetProductSummaries')
      .withParameters({ productTypeId: productTypeId });
    return q;
  }

  createAccountQuery(shouldShowReissueAccountsOnly: boolean = false) {
    let q = this.uow.createQuery(Account, 'Accounts');
    if (shouldShowReissueAccountsOnly) {
      q = q.where({ reissuanceTypeId: 1 });
    }

    return q;
  }

  createManufacturerQuery() {
    let q = this.uow.createQuery(Manufacturer, 'Manufacturers')
      .expand([
        'manufacturerCryptonym',
        'manufacturerImages'
      ]);

    return q;
  }


  createProductQuery() {
    let q = this.uow.createQuery(Product, 'Products');
    q = q.expand([
      'productType',
      'productType.manufacturer',
      'productFeatureChoices.featureChoice',
    ]);
    return q;
  }

  createProductTypeFinderQuery(excludeId: number = null) {
    let q = this.uow.createQuery(ProductType);
    q = q.expand([
      'manufacturer',

    ]);
    return q;
  }

  createProductTypesQuery(excludeId: number = null) {
    let q = this.uow.createQuery(ProductType, 'ProductTypes');
    q = q.expand([
      'manufacturer',
      'productTypeAltProductTypes',
      'productTypeFeatureMaps.feature',
      'products',
      'products.productFeatureChoices.featureChoice'
    ]);
    if (excludeId) {
      q = q.where({ not: { id: excludeId } });
    }
    return q;
  }

  createProductTypesForFeatureQuery(featureId: number) {
    let q = this.uow
      .createQuery(ProductType)
      .where({
        productTypeFeatureMaps: { any: { featureId: featureId } }
      })
      .expand([
        'manufacturer',
        'productTypeFeatureMaps.feature'
      ]);
    return q;
  }

  createProductTypesForEOQQuery(excludeId: number = null) {
    let q = this.uow.createQuery(ProductType, 'ProductTypes');
    q = q.expand(['manufacturer', 'products']);
    if (excludeId) {
      q = q.where({ not: { id: excludeId } });
    }
    // q = q.orderBy('manufacturer.name');
    // q = q.orderBy('style');
    return q;
  }

  async getConfigs() {
    let q = this.uow.createQuery(Config, 'Configs');
    const r = await q.execute();
    return r;
  }

  async getItemBins() {
    let q = this.uow.createQuery(ItemBin, 'ItemBins');
    const r = await q.execute();
    return r;
  }

  async getProductsNeededForEOQ() {
    const q = this.uow.createQuery(null, 'GetProductsNeededForEOQ')
    const r = await q.execute() as unknown as ProductNeededForEOQ[];
    return r;
  }

  async getAccountsWithInvoices() {
    const q = this.uow.createQuery(Account, 'GetAccountsWithInvoices');
    const r = await q.execute();
    return r;
  }

  async getInvoiceDelayDataForChart(accountId?: number) {
    let q = this.uow.createQuery(null, 'GetInvoiceDelayDataForChart')
    if (accountId != null) {
      q = q.withParameters({ accountId: accountId });
    }
    const r = await q.execute() as unknown as InvoiceDelayDataForChart[];
    return r;
  }

  async getTopManufacturersInInventory() {
    let q = this.uow.createQuery(null, 'GetTopManufacturersInInventory')

    const r = await q.execute() as unknown as ManufacturerCountInInventory[];
    return r;
  }

  async getJobOrdersByStatusForChart() {
    let q = this.uow.createQuery(null, 'GetJobOrdersByStatusForChart')

    const r = await q.execute() as unknown as JobOrdersByStatusForChart[];
    return r;
  }

  async getItemCountInInventory() {
    let q = this.uow.createQuery(null, 'GetItemCountInInventory')
    const r = await q.execute();
    return _.first(r) as any as number;
  }

  async getManifestLastSentDate(manifestId: number) {
    const q = this.uow
      .createQuery(ManifestGroupInvoice, 'ManifestGroupInvoices')
      .where({ 'manifestGroup.manifest.id': manifestId })
      .orderBy('manifestGroup.sentDate', true)
      .take(1);
    const r = await q.execute();
    return r.length > 0 ? r[0].manifestGroup.sentDate : null;
  }

  async getManifestNextProcessDate(manifest: Manifest) {
    const ruleString = manifest.rRule;
    if (ruleString == null) {
      return null;
    }

    const ruleObj = RRule.fromString(ruleString);
    let lastDt = await this.getManifestLastSentDate(manifest.id);
    let nextDt: Date;
    if (lastDt == null) {
      let dt = new Date();
      dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
      nextDt = ruleObj.after(dt, true);
    } else {
      lastDt = new Date(lastDt.getFullYear(), lastDt.getMonth(), lastDt.getDate());
      nextDt = ruleObj.after(lastDt, false);
    }
    // var nextProcessDt = new Date( nextDt.getTime() + Math.abs(nextDt.getTimezoneOffset()*60000) );
    var nextProcessDt = nextDt;
    return nextProcessDt;
  }

  async getManufacturer(manufId: number) {
    const q = this.uow
      .createQuery(Manufacturer, 'Manufacturers')
      .where({ id: manufId })

    const r = await q.execute();
    return _.first(r);
  }

  async getManufacturersContaining(nameSearch: string) {
    const q = this.uow
      .createQuery(Manufacturer, 'Manufacturers')
      .where({ name: { contains: nameSearch } })
      .orderBy('name');

    const r = await q.execute();
    return r;
  }

  async getAccount(accountId: number) {
    const q = this.uow
      .createQuery(Account, 'Accounts')
      .where({ id: accountId })

    const r = await q.execute();
    return _.first(r);
  }

  async getReissuanceAccounts() {
    const q = this.uow
      .createQuery(Account, 'Accounts')
      .where({ reissuanceTypeId: ReissuanceTypeCode.Allow })
      .orderBy('accountName', false);

    const r = await q.execute();
    return r;
  }

  async getJoHeadersForRra(joh: JoHeader) {
    const whereClause = {
      // This means that this JoHeader was created by Uniformax not Proximity as a result of a return.
      proximityJoHeaderId: null,
      joStatusId: JoStatusCode.OpenProcessIncomplete,
      accountId: joh.accountId,
    };

    // TODO: review if the oldCusKey is needed any longer
    if (joh.oLD_CUS_KEY != null) {
      whereClause['legacyCustomerId'] = joh.oLD_CUS_KEY;
    }
    const q = this.uow.createQuery(JoHeader, 'JoHeaders').where(whereClause);
    const r = await q.execute();
    return r;
  }

  async getProductType(productTypeId: number) {
    const q = this.uow
      .createQuery(ProductType, 'ProductTypes')
      .where({ id: productTypeId })
      .expand([
        'manufacturer',
        'products.productFeatureChoices.featureChoice',
        'productTypeFeatureMaps.feature',
        'productTypeAltProductTypes',
        'productTypeImages'
      ]);
    const r = await q.execute();
    return _.first(r);
  }

  async getProductTypeMapsForTag(productTypeTagId: number) {
    const q = this.uow
      .createQuery(ProductTypeTagMap)
      .where({ productTypeTagId: productTypeTagId })
      .expand([
        'productType.manufacturer',
      ]);
    const r = await q.execute();
    return r;
  }


  async getProductTypeTags() {
    const q = this.uow
      .createQuery(ProductTypeTag)

    const r = await q.execute();
    return r;
  }

  async getFeatures(shouldExpandFeatureChoices = false) {
    let q = this.uow
      .createQuery(Feature, 'Features');
    if (shouldExpandFeatureChoices) {
      q = q.expand(
        ['featureChoices.reorderFeatureChoice'])
    }
    const r = await q.orderBy('name').execute();
    return r;
  }

  async getFeatureChoices(featureId: number) {
    const q = this.uow
      .createQuery(FeatureChoice, 'FeatureChoices')
      .where({ featureId: featureId });
    const r = await q.execute();
    return r;
  }

  async getProductsCountWithFeatureChoice(featureChoiceId: number) {
    const q = this.uow.createQuery(null, 'GetProductsCountWithFeatureChoice')
      .withParameters({ featureChoiceId });
    const r = await q.execute();
    return _.first(r) as any as number;
  }


  createProductsForFeatureChoiceQuery(featureChoiceId: number) {
    const q = this.uow
      .createQuery(Product, 'Products')
      .where({
        productFeatureChoices: { any: { featureChoiceId: featureChoiceId } }
      })
      .expand([
        'productType.manufacturer',
        'productFeatureChoices.featureChoice.feature'
      ])
    return q;
  }

  async getUnpostedPoHeadersForManufacturer(manufacturerId: number) {
    const q = this.uow
      .createQuery(PoHeader, 'PoHeaders')
      .where({ manufacturerId: manufacturerId, poStatusId: PoStatusCode.NewUnposted })
      .expand(['manufacturer', 'poDetails'])
      .orderBy('poDate', true)
    const r = await q.execute();
    return r;
  }

  async getPoHeader(poHeaderId: number) {
    const q = this.uow
      .createQuery(PoHeader, 'PoHeaders')
      .where({ id: poHeaderId })
      .expand(['manufacturer', 'shippingWarehouse', 'poDetails']);
    const r = await q.execute();
    return _.first(r);
  }


  async getPoHeaders(poHeaderIds: number[]) {
    const q = this.uow
      .createQuery(PoHeader, 'PoHeaders')
      .where({ id: { in: poHeaderIds } })
      .expand(['poDetails']);
    const r = await q.execute();
    return r;
  }

  async getPoDetail(poDetailId: number) {
    const q = this.uow
      .createQuery(PoDetail, 'PoDetails')
      .where({ id: poDetailId });
    const r = await q.execute();
    return r.length ? r[0] : null;
  }

  async getPoDetails(poHeaderId: number) {
    const q = this.uow
      .createQuery(PoDetail, 'PoDetails')
      .where({ poHeaderId: poHeaderId })
      .expand([
        'product.productType',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'joDetail',
        'joDetail.joHeader',
        'joDetail.joHeader.account',
        // 'poHeader.poNotes'
      ]);
    const r = await q.execute();
    return r;
  }

  async getPoDetailsExpanded(poHeaderId: number) {
    const q = this.uow
      .createQuery(PoDetail, 'PoDetails')
      .where({ poHeaderId: poHeaderId })
      .expand([
        'poNotes',
        'poDetailCancels',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'origProduct.productFeatureChoices.featureChoice',
        'joDetail.joHeader.account',
        'itemDetails'
      ]);
    const r = await q.execute();
    return r;
  }

  async getPoDetailsForReceive(poHeaderId: number) {
    const q = this.uow
      .createQuery(PoDetail, 'PoDetails')
      .where({ poHeaderId: poHeaderId })
      .expand([
        'poHeader',
        'poNotes',
        'poDetailCancels',
        'product.productType.manufacturer',
        'product.productType.productTypeFeatureMaps',
        'product.productFeatureChoices.featureChoice.feature',
        'joDetail.joHeader.account',
        'joDetail.joDetailAddons.addon.addonStation',
        'itemDetails.itemDetailAddons.addon',
        'itemDetails.itemDetailAddons.addonBin.pendingAddonStation',
        'poTrx.poDetails'
      ]);
    const r = await q.execute();
    return r;
  }

  async getPoTrxHistsForPo(poHeaderId: number) {
    const q = this.uow
      .createQuery(PoTrxHist, 'GetPoTrxHistsForPo') // method on server
      .withParameters({ poHeaderId: poHeaderId });
    const r = await q.execute();
    return r;
  }

  async getOrphanPoDetails() {
    const q = this.uow
      .createQuery(PoDetail, 'PoDetails')
      .where({ poHeaderId: null })
      .expand([
        'product.productType',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'joDetail',
        'joDetail.joHeader',
        'joDetail.joHeader.account',
        'poNotes',
      ]);
    const r = await q.execute();
    return r;
  }

  async getJoDetailById(joDetailId: number) {
    const q = this.uow
      .createQuery(JoDetail, 'JoDetails')
      .where({ id: joDetailId })
      .expand([
        'joHeader.account',
        'itemDetails.product.productType.manufacturer',
        'joDetailAddons.addon.addonStation',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice.feature',
      ]);
    const r = await q.execute();
    return _.first(r);
  }

  async getJoDetailsForIds(jodIds: number[]) {
    const delimJodIds = jodIds.map(id => id.toString()).join(',');
    const q = this.uow.createQuery(JoDetail, 'GetJoDetailsForIds')
      .withParameters({ delimJodIds: delimJodIds });
    const r = await q.execute();
    return r;
  }

  async getPoDetailsForIds(podIds: number[]) {
    const delimPodIds = podIds.map(id => id.toString()).join(',');
    const q = this.uow.createQuery(PoDetail, 'GetPoDetailsForIds')
      .withParameters({ delimPodIds: delimPodIds });
    const r = await q.execute();
    return r;
  }

  async getPoTrx(poTrxId: number) {
    const q = this.uow
      .createQuery(PoTrx, 'PoTrxs')
      .where({ id: poTrxId })
      .expand([
        'poTrxHists',
        'poDetails',
        'poDetails.poNotes',
        'poDetails.poHeader',
        'poDetails.joDetail.joHeader',
        'poDetails.itemDetails',
        'poDetails.itemDetails.itemDetailAddons.addon'
      ]);
    const r = await q.execute();
    return _.first(r);
  }

  async getJoDetailsForPoModification(productTypeId: number) {
    const q = this.uow
      .createQuery(JoDetail, 'GetJoDetailsForPoModification') // method on server
      .withParameters({ productTypeId: productTypeId });
    const r = await q.execute();
    return r;
  }

  async getJoDetail(joDetailId: number) {
    const q = this.uow
      .createQuery(JoDetail, 'JoDetails')
      .where({ id: joDetailId });
    const r = await q.execute();
    return r.length ? r[0] : null;
  }

  

  async getJoDetails(joHeaderId: number) {
    const q = this.uow
      .createQuery(JoDetail, 'JoDetails')
      .where({ joHeaderId: joHeaderId })
      .expand([
        'product.productType',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'itemDetails',
        'joDetailAddons.addon',
        'poDetails',
        'poDetails.poDetailCancels',
        'poDetails.itemDetails',
        'joNotes'
      ]);
    const r = await q.execute();
    return r;
  }

  async getJoDetailsSimple(joHeaderId: number) {
    const q = this.uow
      .createQuery(JoDetail, 'JoDetails')
      .where({ joHeaderId: joHeaderId })
      const r = await q.execute();
      return r;
  }

  async getInvoiceDetailsForJoReturn(joHeaderId: number) {
    const q = this.uow
      .createQuery(InvoiceDetail, 'InvoiceDetails')
      .where({
        'joDetail.joHeaderId': joHeaderId,
        'invoiceHeader.invoiceStatusId': InvoiceStatusCode.InvoicePosted
      })
      .expand([
        'invoiceHeader',
        'joDetail.returnRequestDetails',
        'joDetail.returnRequestDetails.creditMemos',
        'joDetail.product.productType',
        'joDetail.product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'joDetail.joDetailAddons.addon',
        'joDetail.itemDetails',
        'joDetail.joHeader.account',
      ]);
    const r = await q.execute();
    return r;
  }

  async getReturnRequestDetails(returnRequestId: number) {
    const q = this.uow
      .createQuery(ReturnRequestDetail)
      .where({
        'returnRequestId': returnRequestId,
      })
      .expand([
        'creditMemos',
        'joDetail.invoiceDetails.invoiceHeader',
        'joDetail.product.productType.manufacturer',
        'joDetail.product.productFeatureChoices.featureChoice',
        'joDetail.joDetailAddons.addon',
        'joDetail.itemDetails',
        
      ]);
    const r = await q.execute();
    return r;
  }

  async getJoDetailsByJoPullBatchId(joPullBatchId: number) {
    const q1 = this.uow
      .createQuery(JoDetail, 'JoDetails')
      .where({ 'joHeader.joHeaderPull.joPullBatchId': joPullBatchId })
      .expand([
        'joDetailAddons.addon',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'joHeader.joHeaderPull.joPullBatch',
        'joHeader.account',
        'itemDetails.product.productType.manufacturer',
        'itemDetails.itemDetailAddons.addon',
        'itemDetails.itemDetailAddons.addonBin',
        'poDetails.poDetailCancels',
        'poDetails.itemDetails',
        'poDetails.poHeader',
      ]);
    const r = await q1.execute();
    return r;
  }

  async getJoDetailsForAudit(joHeaderId: number) {
    const q1 = this.uow
      .createQuery(JoDetail, 'JoDetails')
      .where({ joHeaderId: joHeaderId })
      .expand([
        'product.productType',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'joHeader',
        'joHeader.account',
        'poDetails',
        'poDetails.poHeader',
        'poDetails.itemDetails',
        'poDetails.poDetailCancels',
        'itemDetails',
        'itemDetails.product.productType',
        'itemDetails.product.productType.manufacturer',
        'itemDetails.product.productFeatureChoices.featureChoice',
        'poDetails.itemDetails.product.productType',
        'poDetails.itemDetails.product.productType.manufacturer',
        'poDetails.itemDetails.product.productFeatureChoices.featureChoice',
      ]);
    const r = await q1.execute();
    return r;
  }

  createJoHeadersForAuditQuery() {
    const q = this.uow
      .createQuery(JoHeader, 'JoHeaders')
      .expand([
        'account',
        'joDetails.poDetails.itemDetails',
        'joDetails.itemDetails',
        'joDetails.poDetails.poDetailCancels',
        'joDetails.joNotes',
        'joDetails.poDetails.poNotes',
        'joNotes'
      ]);

    return q;
  }

  async getJoHeadersForAuditCalc(takeCount: number, skipCount: number) {
    const q = this.createJoHeadersForAuditQuery()
      .where({ joStatusId: JoStatusCode.OpenProcessComplete })
      .take(takeCount)
      .skip(skipCount)
      .orderBy('id');
    const r = await q.execute();
    return r;
  }

  async getJoHeaderForAuditCalc(joHeaderId: number) {
    const q = this.createJoHeadersForAuditQuery().where({ id: joHeaderId });

    const r = await q.execute();
    return _.first(r);
  }

  // used to insure that a pull batch is not avail if any JoDetails have an invoice
  async getInvoiceDetailsByJoPullBatchId(joPullBatchId: number) {
    const q = this.uow
      .createQuery(InvoiceDetail, 'InvoiceDetails')
      .where({ 'joDetail.joHeader.joHeaderPull.joPullBatchId': joPullBatchId });
    const r = await q.execute();
    return r;
  }

  async getJoPullBatches() {
    const q = this.uow
      .createQuery(JoPullBatch, 'JoPullBatches')
      .expand('joHeaderPulls.joHeader.account');

    const r = await q.execute();
    return r;

  }

  async getJoPullBatch(joPullBatchId: number) {
    const q = this.uow
      .createQuery(JoPullBatch, 'JoPullBatches')
      .where({ id: joPullBatchId })
      .expand('joHeaderPulls');
    const r = await q.execute();
    return _.first(r);
  }

  async getJoHeaderWithInvInfoById(joHeaderId: number) {
    const q = this.uow
      .createQuery(JoDetail, 'JoDetails')
      .where({ 'joHeader.id': joHeaderId })
      .expand([
        'product.productType',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'invoiceDetails',
        'joHeader.account',
        'joHeader.invoiceHeaders',
        'joDetailAddons.addon'
      ]);
    const joDetails = await q.execute();
    if (joDetails.length === 0) {
      return null;
    }
    return joDetails[0].joHeader;
  }

  async getJoDetailsForJoHeader(joHeaderId: number) {
    const q = this.uow
      .createQuery(JoDetail, 'JoDetails')
      .where({ 'joHeader.id': joHeaderId })
      .expand([
        'itemDetails',
        'itemDetails.itemDetailAddons.addon',
        'product.productType',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'invoiceDetails',
        'joHeader.account',
        'joHeader.invoiceHeaders',
        'joDetailAddons.addon',
        'poDetails',
        'poDetails.poDetailCancels',
        'poDetails.itemDetails',
        'poDetails.poHeader',
      ]);

    const r = await q.execute();
    return r;

  }

  async getItemDetailByIdForScan(itemDetailId: string) {
    const q = this.uow
      .createQuery(ItemDetail, 'ItemDetails')
      .where({ id: itemDetailId })
      .expand([
        'joDetail.joHeader'
      ]);
    const r = await q.execute();
    return _.first(r); 
  }

  async getItemDetailById(itemDetailId: string) {
    const q = this.uow
      .createQuery(ItemDetail, 'ItemDetails')
      .where({ id: itemDetailId })
      .expand([
        'itemDetailAddons.addon',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'product.productType']);
    const r = await q.execute();
    return _.first(r);
  }

  async getItemDetailsByItemDetailSummary(ids: ItemDetailSummary) {
    const q = this.uow
      .createQuery(ItemDetail, 'ItemDetails')
      .where({
        productId: ids.productId,
        itemDetailStatusId: ids.itemDetailStatusId,
      })
      .expand([
        'itemDetailAddons.addon',
        'invoiceHeader',
        'invoiceHeader.invoiceDetails',
        'joDetail',
        'itemBin',
        'account',
        'itemReplacement',
        'joDetail.joHeader',
        'joDetail.joHeader.account',
        'joDetail.joDetailAddons.addon'
      ]);

    const r = await q.execute();
    return r;
  }

  async getItemDetailsByJoHeaderId(joHeaderId: number) {
    const q = this.uow
      .createQuery(ItemDetail, 'ItemDetails')
      .where({ 'joDetail.joHeaderId': joHeaderId })
      // OLD clause - the or clause is needed because of some old bad data that might not yet have been cleaned up.
      // .where({ or: [{ 'joDetail.joHeaderId': joHeaderId }, { 'invoiceHeader.joHeaderId': joHeaderId }] })
      // .where({ 'invoiceHeader.joHeaderId': joHeaderId }) -- will not work if invoice has not yet been created.
      .expand([
        'product.productType',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'invoiceHeader',
        'invoiceHeader.invoiceDetails',
        'itemDetailAddons.addon',
        'joDetail',

      ]);
    const r = await q.execute();
    return r;
  }

  async getItemDetailsByJoDetailId(joDetailId: number) {
    const q = this.uow
      .createQuery(ItemDetail, 'ItemDetails')
      .where({ 'joDetailId': joDetailId })
      .expand([
        'product.productType',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'itemDetailAddons.addon'
      ]);
    const r = await q.execute();
    return r;
  }

  async getItemDetailsByProductTypeId(productTypeId: number, itemDetailStatusId?: number) {
    const whereClause = { 'product.productTypeId': productTypeId };
    if (itemDetailStatusId != null) {
      whereClause['itemDetailStatusId'] = itemDetailStatusId;
    }
    const q = this.uow
      .createQuery(ItemDetail, 'ItemDetails')
      .where(whereClause)
      .expand([
        'product.productType',
        'product.productType.manufacturer',
        'product.productFeatureChoices.featureChoice',
        'itemDetailAddons.addon'
      ]);

    const r = await q.execute();
    return r;
  }

  async getAltItemDetailsForStyle(productTypeId: number) {
    const q = this.uow
      .createQuery(ItemDetail, 'GetAltItemDetailsForStyle') // method on server
      .withParameters({ productTypeId: productTypeId });
    const r = await q.execute();
    return r;
  }

  async getEmptyProductTypes() {
    const q = this.uow.createQuery(ProductType, 'GetEmptyProductTypes'); // method on server
    const r = await q.execute();
    return r;
  }

  async getProductTypesForManuf(manufacturerId: number) {
    const q = this.uow.createQuery(ProductType, 'ProductTypes').where({
      manufacturerId: manufacturerId,
    });
    const r = await q.execute();
    return r;
  }

  async getMatchingAddon(addon: Addon) {
    const q = this.uow.createQuery(Addon).where({
      accountId: addon.accountId,
      nameAndLocation: addon.nameAndLocation,
      not: { id: addon.id }
    });
    const r = await q.execute();
    return _.first(r);
  }

  async getMatchingEntity<T extends Entity>(type: { new(): T; }, entity: T & { id: any }, altKeyFieldNames: string[]) {
    var opts = {
      not: { id: entity.id }
    };
    altKeyFieldNames.forEach(fieldName => opts[fieldName] = entity[fieldName]);

    const q = this.uow.createQuery(type).where(opts);
    const r = await q.execute();
    return _.first(r);
  }

  async getMatchingManufacturer(manufacturer: Manufacturer) {
    const q = this.uow.createQuery(Manufacturer).where({
      name: manufacturer.name,
      not: { id: manufacturer.id }
    });
    const r = await q.execute();
    return _.first(r);
  }

  async getMatchingProductType(manufacturerId: number, style: string) {
    const q = this.uow.createQuery(ProductType, 'ProductTypes').where({
      manufacturerId: manufacturerId,
      style: style,
    });
    const r = await q.execute();
    return _.first(r);
  }

  async getMatchingProduct(productTypeId: number, productFeatureChoices: ProductFeatureChoice[]) {

    const grp = productFeatureChoices.map(pfc => {
      return {
        productFeatureChoices: { any: { 'featureChoice.choiceValue': pfc.featureChoice.choiceValue } }
      }
    })
    const whereClause = {
      and: [{ productTypeId: productTypeId }, ...grp]
    }
    const q = this.uow.createQuery(Product, 'Products').where(whereClause);

    const r = await q.execute();
    return _.first(r);
  }

  async isEmptyProduct(productId: number) {
    const q = this.uow
      .createQuery(null, 'IsEmptyProduct') // method on server
      .withParameters({ productId: productId });
    const r = await q.execute();
    return r.length === 0;
  }

  async getAltProductTypeIdsFor(productTypeId: number) {
    const q = this.uow
      .createQuery(null, 'GetAltProductTypeIdsFor') // method on server
      .withParameters({ productTypeId: productTypeId });
    const r = await q.execute();
    return (r as any) as number[];
  }

  async getAltProductTypesForProductType(productTypeId: number) {
    const q = this.uow
      .createQuery(ProductTypeAlt, 'ProductTypeAlts')
      .where({ productTypeId: productTypeId })
      .expand(['altProductType', 'altProductType.manufacturer']);
    const r = await q.execute();
    const altProductTypes = _.flatMap(r, ita => ita.altProductType);
    return altProductTypes;
  }

  async getProductsForProductType(productTypeId: number) {
    const q = this.uow.createQuery(Product, 'Products')
      .where({ productTypeId: productTypeId })
      .expand(['productFeatureChoices.featureChoice']);
    const r = await q.execute();
    return r;
  }

  async getProductEOQs(productTypeId: number) {
    const q = this.uow.createQuery(ProductEOQ, 'ProductEOQs')
      .where({ 'product.productTypeId': productTypeId })
      .expand(['product']);
    const r = await q.execute();
    return r;
  }

  async canDeleteProductType(productTypeId: number) { }

  async getNextInvoiceHeaderId(joHeaderId: number) {
    // find the 'next' invoice id for this JoHeader;
    const q = this.uow.createQuery(InvoiceHeader, 'InvoiceHeaders').where({ joHeaderId: joHeaderId });
    const r = await q.execute();
    if (r.length === 0) {
      return joHeaderId + '-01';
    }
    const invoices = _.sortBy(r, inv => inv.id);
    const lastId = _.last(invoices).id;
    const lastTwoDigits = lastId.substring(lastId.length - 2);
    const nextSuffix = parseInt(lastTwoDigits, 10) + 1;
    const newSuffix = ('0' + nextSuffix).slice(-2);
    return joHeaderId + '-' + newSuffix;
  }

  async getShipment(shipmentId: number) {
    const q = this.uow
      .createQuery(Shipment, 'Shipments')
      .where({ id: shipmentId })
      .expand(['invoiceHeaders', 'shippingBoxes']);
    const r = await q.execute();
    return _.first(r);
  }

  async getManifest(manifestId: number) {
    const q = this.uow
      .createQuery(Manifest, 'Manifests')
      .where({ id: manifestId })
      .expand(['manifestGroups']);
    const r = await q.execute();
    return _.first(r);
  }

  async getManifestGroup(manifestGroupId: number) {
    const q = this.uow
      .createQuery(ManifestGroup, 'ManifestGroups')
      .where({ id: manifestGroupId })
      .expand(['manifestGroupInvoices.invoiceHeader']);
    const r = await q.execute();
    return _.first(r);
  }

  // async getManifestsThatAreCurrentlyDue() {
  //   const today = UtilFns.startOfDay(new Date());
  //   const q = this.uow
  //     .createQuery(Manifest, 'Manifests')
  //     .where({ nextProcessDate: { le: today }});

  //   const r = await q.execute();
  //   return r;
  // }

  async getManifestInvoicesForManifestGroup(manifestGroupId: number) {
    const q = this.uow
      .createQuery(InvoiceHeader, 'GetManifestInvoicesForManifestGroup')
      .withParameters({ manifestGroupId: manifestGroupId })
      .expand(this.getInvoiceHeaderExpandForManifest());
    const r = await q.execute();
    return r;
  }

  async getManifestInvoicesPostedButUnsent(manifestId: number) {
    const q = this.uow
      .createQuery(InvoiceHeader, 'GetManifestInvoicesPostedButUnsent')
      .withParameters({ manifestId: manifestId })
      .expand(this.getInvoiceHeaderExpandForManifest());
    const r = await q.execute();
    return r;
  }

  private getInvoiceHeaderExpandForManifest() {
    return [
      'invoiceDetails',
      'invoiceDetails.joDetail',
      'invoiceDetails.joDetail.product.productType',
      'invoiceDetails.joDetail.product.productType.manufacturer',
      'invoiceDetails.joDetail.product.productFeatureChoices.featureChoice',
      'invoiceDetails.joDetail.joDetailAddons.addon',
      'shipment',
      'shipment.shippingBoxes',
      'joHeader.account',
      'manifestGroupInvoices.manifestGroup.manifest.manifestGroups'
    ]
  }

  async getInvoice(invoiceId: string) {
    const q = this.uow
      .createQuery(InvoiceHeader, 'InvoiceHeaders')
      .where({ id: invoiceId })
      .expand([
        'invoiceDetails',
        'invoiceDetails.joDetail',
        'invoiceDetails.joDetail.product.productType',
        'invoiceDetails.joDetail.product.productType.manufacturer',
        'invoiceDetails.joDetail.product.productFeatureChoices.featureChoice',
        'invoiceDetails.joDetail.joDetailAddons.addon',
        'shipment',
        'shipment.shippingBoxes',
        'joHeader.account',
      ]);
    const r = await q.execute();
    return _.first(r);
  }

  async getInvoicesOnSameShipment(invoiceHeaderId: string) {
    const q = this.uow
      .createQuery(InvoiceHeader, 'InvoiceHeaders')
      .where({ id: invoiceHeaderId })
      .expand([
        'shipment',
        'shipment.invoiceHeaders',
        'shipment.invoiceHeaders.joHeader'
      ]);

    const r = await q.execute();
    const invoiceHeader = _.first(r);
    return invoiceHeader?.shipment?.invoiceHeaders ?? [invoiceHeader]  // in case the invoiceHeader does not have a shipment ( converted data)
  }

  async getVoucherInvoices() {
    const q = this.uow
      .createQuery(InvoiceHeader, 'InvoiceHeaders')
      .where({
        invoiceStatusId: {
          in: [InvoiceStatusCode.Voucher, InvoiceStatusCode.VoucherLabeled, InvoiceStatusCode.VoidError],
        },
      })
      .expand([
        'joHeader.account', 
        'shipBin',
        'shipment', 
        'shipment.shippingBoxes'
      ]);
    const r = await q.execute();
    return r;
  }

  async getVoucherInvoicesOnAccount(accountId: number) {
    const q = this.uow
      .createQuery(InvoiceHeader, 'InvoiceHeaders')
      .where({
        invoiceStatusId: {
          in: [InvoiceStatusCode.Voucher, InvoiceStatusCode.VoucherLabeled, InvoiceStatusCode.VoidError],
        },
        'joHeader.accountId': accountId
      })
      .expand(['joHeader', 'shipment', 'shipment.shippingBoxes']);
    const r = await q.execute();
    return r;
  }

  async getReturnRequestsByJoHeaderId(joHeaderId: number) {
    const q = this.uow
      .createQuery(ReturnRequest, 'ReturnRequests')
      .where({ 'joHeaderId': joHeaderId })
      .expand(['returnRequestDetails', 'returnRequestDetails.creditMemos'])
    const r = await q.execute();
    return r

  }

  async getReturnRequestDetailsByJoHeaderId(joHeaderId: number) {
    const q = this.uow
      .createQuery(ReturnRequestDetail, 'ReturnRequestDetails')
      .where({ 'joDetail.joHeaderId': joHeaderId })
      .expand(['creditMemos', 'returnRequest']);
    const r = await q.execute();
    return r;
  }

  async getInvoiceHeadersByJoHeaderId(joHeaderId: number) {
    const q = this.uow
      .createQuery(InvoiceHeader, 'InvoiceHeaders')
      .where({ joHeaderId: joHeaderId })
      .expand(['invoiceDetails']);
    const r = await q.execute();
    return r;
  }

  async getInvoiceDetailsByJoHeaderId(joHeaderId: number) {
    const q = this.uow
      .createQuery(InvoiceDetail, 'InvoiceDetails')
      .where({ 'joDetail.joHeaderId': joHeaderId })
      .expand(['invoiceHeader']);
    const r = await q.execute();
    return r;
  }

  async getJoNotesForHeader(joHeaderId: number) {
    const q = this.uow
      .createQuery(JoNote, 'JoNotes')
      .where({ joHeaderId: joHeaderId });
    const r = await q.execute();
    return r;
  }

  async getJoNotesForDetail(joDetailId: number) {
    const q = this.uow
      .createQuery(JoNote, 'JoNotes')
      .where({ joDetailId: joDetailId });
    const r = await q.execute();
    return r;
  }

  async getPoNotesForHeader(poHeaderId: number) {
    const q = this.uow
      .createQuery(PoNote, 'PoNotes')
      .where({ poHeaderId: poHeaderId });
    const r = await q.execute();
    return r;
  }

  async getPoNotesForDetail(poDetailId: number) {
    const q = this.uow
      .createQuery(PoNote, 'PoNotes')
      .where({ poDetailId: poDetailId });

    const r = await q.execute();
    return r;
  }

  async getNextItemDetailIds(count: number) {
    const nextId = await this.getNextGenId('ItemDetail', count);

    const nextIds: string[] = [];
    for (let i = 0; i <= count; i++) {
      nextIds.push((nextId + i).toString());
    }
    return nextIds;
  }

  async getEntityIssues(entityTypeName: string = null, entityId: string = null) {
    let q = this.uow
      .createQuery(EntityIssue, 'EntityIssues')
      .expand(['account']);
    if (entityTypeName != null) {
      q = q.where({ entityTypeName: entityTypeName, entityId: entityId });
    }
    const r = await q.execute();
    return r;
  }

  createAddonQuery() {
    const q = this.uow
      .createQuery(Addon)
      .expand([
        'account',
        'addonStation',
        'addonDocMaps',
        'addonImages'
      ])
    return q;
  }

  async getAddons(accountId: number) {
    const q = this.uow
      .createQuery(Addon)
      .where({ accountId: accountId })
      .expand([
        'addonStation',
        'addonDocMaps.addonDoc',
        'addonImages'
      ])
    const r = await q.execute();
    return r;
  }

  async getAddonStations() {
    const q = this.uow
      .createQuery(AddonStation)
    const r = await q.execute();
    return r;
  }

  

  async getJodAddonsWithDocs(joDetailAddonIds: number[]) {
    let q = this.uow
      .createQuery(JoDetailAddon, 'JoDetailAddons');
    q = q.where({ id: { 'in': joDetailAddonIds } })
      .expand([
        'addon.addonDocMaps.addonDoc'
      ])
    const r = await q.execute();
    return r;
  }

  async findJoNoteForIssue(issue: EntityIssue) {
    let whereClause: object;
    if (issue.entityTypeName === 'JoHeader') {
      whereClause = { 'joHeaderId': +issue.entityId };
    } else if (issue.entityTypeName === 'JoDetail') {
      whereClause = { 'joDetailId': +issue.entityId };
    } else {
      return;
    }
    // whereClause['isIssue'] = true;
    whereClause['note'] = issue.description;
    whereClause['crtnUserInit'] = issue.crtnUserInit;
    const q = this.uow
      .createQuery(JoNote, 'JoNotes')
      .where(whereClause);
    const r = await q.execute();
    return r.length > 0 ? r[0] : null;
  }

  async validateCryptoGuid(tableName: string, guid: string) {
    const q = this.uow
      .createQuery(null, 'ValidateCryptoGuid') // method on server
      .withParameters({ tableName: tableName, guid: guid });
    const r = await q.execute();
    return r;
  }

  async getNextGenId(keyName: string, count: number) {
    const q = this.uow
      .createQuery(null, 'GetNextGenId') // method on server
      .withParameters({ keyName: keyName, count: count });
    const r = await q.execute();
    return _.first(r) as any as number;
  }

  async getProxItemsCount() {
    const q = this.uow
      .createQuery(null, 'GetProxItemsCount') // method on server
    const r = await q.execute();
    return _.first(r) as unknown as number;
  }

  createProxSubmissionItemQuery() {
    const q = this.uow
      .createQuery(ProxSubmissionItem);
    return q;
  }

  async getProxSoloSubmissionFor(tableName: string, entityId: string) {
    const q = this.uow
      .createQuery(ProxSoloSubmission)
      .where({
        tableName,
        entityId
      });
    const r = await q.execute();
    return _.first(r);
  }

  async getProxCombineItems() {
    const q = this.uow
      .createQuery(ProxCombineItem);
    return q.execute();
  }

  async getProxSoloSubs() {
    const q = this.uow
      .createQuery(ProxSoloSubmission);
    return q.execute();
  }

  async getProxSoloSubsPending() {
    const q1 = this.uow
      .createQuery(Shipment)
      .where({
        'proxSoloSubmissionStatusId': { ne: 2 }
      }).execute();
      
    const q2 = this.uow
      .createQuery(InvoiceHeader)
      .where({
        'invoiceStatusId': 'POSTED',
        'proxSoloSubmissionStatusId': { ne: 2 }
      }).execute()

    var [shipments, invoices] = await Promise.all([q1, q2]);
    const subs1 = shipments.map(x => {
      return <any> {
        tableName: 'Shipment',
        entityId: x.id.toString()
      }
    });
    const subs2 = invoices.map(x => {
      return <any> {
        tableName: 'InvoiceHeaders',
        entityId: x.id.toString()
      }
    });
    return [...subs1, ...subs2]
  }

  async getImages(...imageIds: number[]) {
    const q = this.uow
      .createQuery(Image)
      .where({ id: { in: imageIds } });
    return q.execute();
  }

  //  AddonBins
  async getAddonBin(addonBinId: number) {
    const q = this.uow
    .createQuery(AddonBin)
    .where({ 
      id: addonBinId  
     })
    .expand([
      'account',
      'itemDetailAddons',
      'pendingAddonStation',
      'currentAddonStation',
    ])
    const r = await q.execute();
    return _.first(r);
  }

  async getAddonBinsByIds(addonBinIds: number[]) {
    var q = this.uow
    .createQuery(AddonBin)
    .expand([
      'account',
      'itemDetailAddons',
      'pendingAddonStation',
      'currentAddonStation',
    ])
    .where({ id: { in: addonBinIds } });
    const r = await q.execute();
    return r;
  }

  async getAddonBins(shouldShowClosedBins: boolean) {
    var q = this.uow
    .createQuery(AddonBin)
    .expand([
      'account',
      'itemDetailAddons',
      'pendingAddonStation',
      'currentAddonStation',
    ])
    if (!shouldShowClosedBins) {
      q = q.where({ 
        not: {
          addonBinStatusId: AddonBinStatusCode.Closed  
        }
      });
    }
    return q.execute();

  }

  async getItemDetailAddonsForAddonBin(addonBinId: number) {
    const q = this.uow
      .createQuery(ItemDetailAddon)
      .where({ 
        addonBinId: addonBinId,
       })
      .expand([
        'addonBin',
        'itemDetail.joDetail',
        'itemDetail.product.productType.manufacturer',
        'itemDetail.product.productFeatureChoices.featureChoice.feature',
        'addon.addonStation',
        'addon.addonDocMaps.addonDoc',
        'addon.addonImages.image',
      ])
      const r = await q.execute();
      return r;
    }

  async getItemDetailsForAddonBin(addonBinId: number) {
    
      const itdas = await this.getItemDetailAddonsForAddonBin(addonBinId);
      const itemDetailSet = new Set(itdas.map(x => x.itemDetail));
      const itemDetails = Array.from(itemDetailSet)
      return itemDetails;
  }

  
  
  async getAvailableAddonBinsFor(accountId: number, aggGroupingKey: string) {
    const q = this.uow
      .createQuery(AddonBin)
      .where({ 
        accountId: accountId,
        aggregateGroupingKey: aggGroupingKey,
        addonBinStatusId: { in: [ AddonBinStatusCode.Open, AddonBinStatusCode.New] },
        currentAddonStationId: null 

       })
      .expand([
        'itemDetailAddons',
        ])
    var addonBins = await q.execute();
    
    return addonBins;
  }
  
  

  //  ShipBins
  async getShipBinByKey(aggregateGroupingKey: string) {
    const today = DateFns.startOfDay(new Date());
    const q = this.uow
    .createQuery(ShipBin)
    .where({ 
      aggregateGroupingKey: aggregateGroupingKey,
      isOpen: true,
      or: [
        { expectedShipDate: { ge: today } },
        { shipFrequencyId: 0 }
      ]
     });
    
    const r = await q.execute();
    return _.first(r);
  }


  // Migration tables

  async getMigrationFeatureChoiceChanges(fromOrigFeatureName: string) {
    const q = this.uow
      .createQuery(_MigrationFeatureChoiceChange)
      .where({ fromOrigFeatureName: fromOrigFeatureName })
    const r = await q.execute();
    return r;
  }

  async getMigrationProductTypeChange(productTypeId: number) {
    const q = this.uow
      .createQuery(_MigrationProductTypeChange)
      .where({ productTypeId: productTypeId });
    const r = await q.execute();
    return _.first(r);

  }

  async getFeatureChoiceSetForProductType(productTypeId: number) {
    let q = this.uow
      .createQuery(Product)
      .where({
        productTypeId: productTypeId
      })
      .expand('productFeatureChoices');
    const prods = await q.execute();
    const fcIds = _.flatMap(prods, p => p.productFeatureChoices.map(x => x.featureChoiceId));
    const fcIdSet = new Set(fcIds);
    return fcIdSet;
  }

  async getMigrationFeatureChoiceSetForFeatureChange(fromFeature: Feature, toFeature: Feature) {
    const q = this.uow
      .createQuery(_MigrationFeatureChoiceChange)
      .where({
        fromOrigFeatureName: fromFeature._origName,
        toOrigFeatureName: toFeature._origName
      });
    const mfccs = await q.execute();

    const fromFcs = fromFeature.featureChoices;
    const fcIds = mfccs.map(mfcc => {
      const fc = fromFcs.find((fc => fc._origChoiceValue == mfcc.fromOrigFeatureChoiceName));
      return fc?.id;
    });
    return new Set(fcIds);
  }

  async getProductTypeIdsWithMigrationsForFeatureChoice(featureChoiceId: number) {
    const q = this.uow.createQuery(null, 'GetProductTypeIdsWithMigrationsForFeatureChoice')
      .withParameters({ featureChoiceId }); // method on server
    const r = await q.execute();
    var z = r.map(x => x as any as number);
    return z;
  }

}
