<app-uni-dialog title="Scan into Ship Prep." [parent]="this" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div *ngIf="isPageReady" style="display: flex; flex-direction: column;height: 100%;">
    <div class="h3 ml-12 mt-8 mb-4">Details - </div>
    <div style="flex-grow: 1;"> 
      <app-ag-grid style="height: 100%;"  [gridOptions]="itdGridOptions"    [rowData]="scannedItemDetails">
      </app-ag-grid>
    </div>
    <div class="mt-20" style="height: 100px">
      <div class="h3 ml-12">Scan Item Details for Pending Shipment.
      </div>
      <div class="ml-12" style="display: flex; flex-direction: row;">
        <mat-form-field class="mt-8" style="width: 150px;">
          <input matInput placeholder="Item Detail Id" [(ngModel)]="scannedItemDetailId" name="scannedItemDetailId"
            autocomplete="off" maxlength="12" size="12" (input)="onInputFilled($event, scanItemDetailId)">
        </mat-form-field>
        <div *ngIf="scanErrorMessage?.length" class="content-card" style="flex-grow: 1">
          <h3 class="ml-12">{{ scanErrorMessage }}</h3>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Close</button>
  
</ng-template>