<app-uni-dialog title="Print/Email Invoice" [parent]="this" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div fxLayout="row wrap" class="ml-12" fxLayoutAlign="left center" fxLayoutGap="20px" fxLayout.cs="Column">
    <mat-form-field style="width: 300px">
      <input matInput placeholder="Shipping Name" [ngModel]="joHeader && joHeader.shippingName" readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 110px">
      <input matInput placeholder="Manifest" [ngModel]="manifest.manifestName" readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 160px">
      <input matInput placeholder="Include Invoices" [ngModel]="manifest.shouldIncludeInvoices" readonly="readonly" />
    </mat-form-field>
  </div>

  <div fxLayout="row wrap" class="mt-12 ml-12" fxLayoutAlign="left center" fxLayoutGap="20px" fxLayout.cs="Column">
    <mat-form-field style="width: 300px">
      <input matInput placeholder="Billing Name" [ngModel]="joHeader && joHeader.billingName" readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 300px">
      <input matInput placeholder="Invoice Email" [(ngModel)]="emailAddress" name="emailAddress" />
    </mat-form-field>
  </div>

  <div>
    <span style="margin-left: 8px">{{emailStatus}}</span>
  </div>

  <div *ngIf="isPrinting">
    If the Invoice is <b>successfully printed</b>, it can be posted to the accounting system.
  </div>
  <div *ngIf="isAccounted">
    This invoice has been sent to the accounting system.
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div *ngIf="!isPrinting">
    <button *ngIf="canPreview" mat-raised-button (click)="showPreview()">Preview</button>
    <button [disabled]="!canSendEmail()" mat-raised-button (click)="sendEmail()">Email</button>
    <button mat-raised-button (click)="printDocument()">Print</button>
    <button mat-raised-button (click)="close()">Close</button>
  </div>
  <div *ngIf="isPrinting">
    <button mat-raised-button (click)="isPrinting = false">Cancel</button>
  </div>

</ng-template>
