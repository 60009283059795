import { agCharts } from '@ag-grid-enterprise/charts';
import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AgCartesianSeriesTooltipRendererParams } from 'ag-charts-community';
import { Account } from 'app/model/entities/entity-model';
import { AgFns } from 'app/shared/ag-fns';

import { DbQueryService } from 'app/shared/db-query.service';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

interface ChartInfo { 
  id: string, 
  name: string, 
  usesAccount: boolean
}

@Component({
  selector: 'charts-container',
  templateUrl: './charts-container.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ChartsContainerComponent extends DomainBaseComponent implements OnInit {
  
  title: string;
  options: any;
  accountId: number;
  account: Account;
  allAccounts: Account[];
  matchingAccounts: Account[];
  charts: ChartInfo[];
  chart: ChartInfo;
  chartId: string;
  

  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,
    protected location: Location,
    protected dbQueryService: DbQueryService,
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  
  async updateFromContext() {
    this.chartId = this.route.snapshot.queryParams['chartId'] || 'InvoiceStats';

    // const allAccounts = await this.uow.queryAll(Account, 'Accounts');
    // Only get accounts that actually have invoices.
    const allAccounts = await this.dbQueryService.getAccountsWithInvoices();
    this.allAccounts = _.sortBy(allAccounts, a => a.accountName);
    this.charts = [
      { id: 'InvoiceDelay', name: 'Invoice Delay', usesAccount: true},
      { id: 'InvoiceStats', name: 'Invoice Stats', usesAccount: true},
      { id: 'ManufInventoryCount', name: 'Inventory Counts by Manufacturer', usesAccount: false },
      { id: 'JoStats', name: 'Current Job Orders by Age and Status', usesAccount: false }
    ]
    this.chart = this.charts.find(c => c.id == this.chartId) || this.charts[0];
    this.account = null;
    this.isPageReady = true;
  }

  updateLocation() {
    const urlTree = this.router.createUrlTree(['/charts-container'], {
      queryParams: {
        chartId: this.chartId
      },
    });
    const url = urlTree.toString();
    this.domainService.location.replaceState(url);
  }


  searchAccounts(query: string){
    this.matchingAccounts = this.allAccounts.filter(a => a.accountName.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  getTitle(accountId?: number) {
    const accountName = (accountId == null) ?  'All accounts' : this.allAccounts.find(a => a.id == accountId)?.accountName;
    return `Invoice Delay Chart - ${accountName} `;
  }

  onChartChange(chart: ChartInfo) {
    this.chartId = chart.id;
    this.updateLocation();
  }

  onAccountChange(accountIdString: string) {
    this.accountId = Number.parseInt(accountIdString);
    if (Number.isNaN(this.accountId)) {
      this.accountId == null;
    }
    this.account = this.getAccount(this.accountId);
  }

  getAccount(accountId: number) {
     return (accountId == null) ?  null : this.allAccounts.find(a => a.id == accountId);
  }

  clearAccount() {
    this.accountId = null;
    this.account = null;
  }

  canDeactivate() {
    return true;
  }

  
  
}
