import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AgEditorComponent, ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

@Component({
    selector: 'app-ag-mat-datepicker',
    template: `
        <input matInput [matDatepicker]="picker" [(ngModel)]="value" #inputpicker (dateChange)="onDateChange($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker panelClass="ag-custom-component-popup" #picker></mat-datepicker>`
})

// <input matInput [matDatepicker]="picker" [(ngModel)]="value" (dateChange)="onDateChange($event)">
// <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
// <mat-datepicker panelClass="ag-custom-component-popup" #picker></mat-datepicker>
        
export class AgMatDatepickerComponent implements OnInit, AgEditorComponent, AfterViewInit, ICellEditorAngularComp {
    columnWidth: string;
    params: ICellEditorParams;
    value: string;
    @ViewChild('picker', { read: MatDatepicker }) picker: MatDatepicker<Date>;
    @ViewChild('inputpicker')  inputPicker: any;

    constructor() {
    }

  
    ngOnInit() {
    }

    ngAfterViewInit() {
      // set's the focus cursor into the input box. 
      setTimeout( () =>  this.inputPicker.nativeElement.focus(), 0);
      // if uncommented - this would open the datepicker popup on cell click.
        // this.picker.open();
    }

    isPopup(): boolean {
        return true;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isCancelAfterEnd(): boolean {
        return false;
    }

    agInit(params: any): void {
        this.params = params;
        this.value = params.value;
    }

    getValue(): string {
        return this.value;
    }

    onDateChange(e: MatDatepickerInputEvent<any>): void {
        // So user doesn't have to click another cell after selecting from the calendar
        this.params.stopEditing();
    }

}
