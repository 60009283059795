// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ActiveStatus } from './active-status';
import { Feature } from './feature';
import { ProductFeatureChoice } from './product-feature-choice';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class FeatureChoice extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  activeStatusId: number;
  choiceValue: string;
  displayOrder: number;
  featureId: number;
  reorderFeatureChoiceId: number;
  _origChoiceValue: string;
  activeStatus: ActiveStatus;
  feature: Feature;
  inverseReorderFeatureChoice: FeatureChoice[];
  productFeatureChoices: ProductFeatureChoice[];
  reorderFeatureChoice: FeatureChoice;
}

