// TODO: Consider replacing moment with Luxon - discuss with Steve
import * as moment from 'moment-mini';
// import { endWith } from 'rxjs';

export class DateFns {
  static NullDate = new Date(0);

    // Date fields in a projection come across as UTC strings - this takes the strings and converts them back to dates
  // r is the array of projected objects, fieldsNames is an array of fieldNames. 
  static fixupDatesInProjection(r: object[], fieldNames: string[]) {
    r.forEach( ent => {
      fieldNames.forEach(fn => {
        const val = ent[fn];
        if (val != null) {
          ent[fn] = moment(val).toDate();
        }
      });
    });
  }

  static getFiscalStartDate(date, fiscalMonthDayStartDate) {
    const fiscalStartMonth = fiscalMonthDayStartDate.getMonth();
    // getDate() return day of the month
    const fiscalStartDay = fiscalMonthDayStartDate.getDate();
    let yearAdj = 0;
    if(date.getMonth() < fiscalStartMonth) {
      yearAdj = -1;
    } else if (date.getMonth() === fiscalStartMonth && date.getDate() < fiscalStartDay ) {
      yearAdj = -1;
    } 

    const year = date.getFullYear() + yearAdj;
    const r = new Date(year, fiscalStartMonth, fiscalStartDay);
    return r;
  }

  // static toLocalDate(date: Date) {
  // // fix utc date from calendar back to local date
  //   date.setUTCHours(date.getHours());
  //   date.setUTCMinutes(date.getMinutes());
  // }

  // Array of dates between two dates including endpoints.
  static getDatesBetween(startDate, stopDate) {
    const dates: Date[] = [];
    let beginDate = moment(startDate);
    const endDate = moment(stopDate);
    while (beginDate <= endDate) {
      dates.push(beginDate.toDate());
      beginDate = beginDate.add(1, 'days');
    }
    return dates;
  }

  static startOfDay(date: Date): Date {
    return moment(date)
      .startOf('day')
      .toDate();
  }

  static endOfDay(date: Date): Date {
    return moment(date)
      .endOf('day')
      .toDate();
  }

  static startOfMonth(date: Date): Date {
    return moment(date)
      .startOf('month')
      .toDate();
  }

  static endOfMonth(date: Date): Date {
    // return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return moment(date)
      .endOf('month')
      .toDate();
  }

  static endOfYear(date: Date): Date {
    // return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return moment(date)
      .endOf('year')
      .toDate();
  }

  // duration is from moment - looks like '5 days' or '1 month'
  static dateAdd(date: Date, num: number, type:  string): Date {
    return moment(date)
      .add(num, type as moment.unitOfTime.DurationConstructor)
      .toDate();
  }

  

  /** format 05/08/17 */
  static fmtDate(date: Date, zeroDisplay = ''): string {
    if (!moment.isDate(date)) {
      return '';
    }
    if (date.getTime() === 0) {
      return zeroDisplay;
    }
    return moment(date).format('MM/DD/YY');
  }

  /** format 5/8/17 */
  static fmtDateShort(date: Date, zeroDisplay = '') {
    if (!moment.isDate(date)) {
      return '';
    }
    if (date.getTime() === 0) {
      return zeroDisplay;
    }
    return moment(date).format('M/D/YY');
  }

  static fmtDateTimeShort(date: Date, zeroDisplay = '') {
    if (!moment.isDate(date)) {
      return '';
    }
    if (date.getTime() === 0) {
      return zeroDisplay;
    }
    return moment(date).format('MM/DD/YY hh:mm');
  }

  static fmtDateTimeShortZ(date: Date, zeroDisplay = '') {
    if (!moment.isDate(date)) {
      return '';
    }
    if (date.getTime() === 0) {
      return zeroDisplay;
    }
    return moment(date).format('MM/DD/YY hh:mm a ZZ');
  }

  /** format 05/08/2017 */
  static fmtDate4(date: Date, zeroDisplay = '') {
    if (!moment.isDate(date)) {
      return '';
    }
    if (date.getTime() === 0) {
      return zeroDisplay;
    }
    return moment(date).format('MM/DD/YYYY');
  }

  /** format 5/8/2017 */
  static fmtDate4Short(date: Date, zeroDisplay = '') {
    if (!moment.isDate(date)) {
      return '';
    }
    if (date.getTime() === 0) {
      return zeroDisplay;
    }
    return moment(date).format('M/D/YYYY');
  }

  /** format 5/8/2017 9:07:08 PM Pacific Daylight Time */
  static fmtDateTimeTimeZone(date: Date) {
    if (!moment.isDate(date)) {
      return '';
    }
    return date.toLocaleString('en-US', { timeZoneName: "short" });
  }

  /** format October 2017 */
  static fmtDateMonthYear(date: Date) {
    if (!moment.isDate(date)) {
      return '';
    }
    return moment(date).format('MMMM YYYY');
  }

  /** format 9:06 PM */
  static fmtTime(date: Date, zeroDisplay = '') {
    if (!moment.isDate(date)) {
      return '';
    }
    if (date.getTime() === 0) {
      return zeroDisplay;
    }
    return moment(date).format('h:mm A');
  }

  static dateDiff(d1: Date, d2: Date, difType: moment.unitOfTime.Diff = 'days') {
    if (d1 == null || d2 == null) {
      return null;
    }
    return moment(d2).diff(moment(d1), difType);
  }

  static maxDate(d1: Date | null, d2: Date | null) {
    if (d1 == null || d1 === DateFns.NullDate) {
      return d2;
    }
    if (d2 == null || d2 === DateFns.NullDate) {
      return d1;
    }
    return d1 > d2 ? d1 : d2;
  }

  static minDate(d1: Date | null, d2: Date | null) {
    if (d1 == null || d1 === DateFns.NullDate) {
      return d2;
    }
    if (d2 == null || d2 === DateFns.NullDate) {
      return d1;
    }
    return d1 < d2 ? d1 : d2;
  }

  // static toServerDate(date: Date) {
  //   if (!date) { return date; }
  //   // _fromServer is e.g. '2013-06-02T12:00:00.000-07:00'; see entity-manager-provider.ts#46
  //   let s = date['_fromServer'] as string;
  //   if (!s || s.length < 29) { return date; } // no time zone
  //   s = s.substring(0, 23);
  //   const date2 = new Date(s);
  //   return date2;
  // }

  static toZeroDate(date: Date) {
    if (date == null) {
      return date;
    }
    const newDate = new Date(date);
    // newDate.setUTCHours(date.getHours());
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  static areDatesEqual(d1: Date, d2: Date) {
    if (!d1 || !d2) {
      return false;
    }
    return d1.getTime() === d2.getTime();
  }

  static pokeDate(value: Date) {
    const ts = value;
    ts.setSeconds(ts.getSeconds() + 10);
    return ts;
  }

}