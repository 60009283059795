<app-uni-dialog title="Select Feature Choice conversions" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  
  <div uni-flex-row>
    <mat-form-field uni-flex-item  [matTooltip]="productType.manufacturer.name" >
      <input matInput placeholder="Manufacturer" [ngModel]="productType.manufacturer.name"
        readonly="readonly" />
    </mat-form-field>
    <mat-form-field  uni-flex-item [matTooltip]="productType.style">
      <input  matInput placeholder="Style" [ngModel]="productType.style" 
        readonly="readonly" />
    </mat-form-field>
  </div>
  <div uni-flex-row>
    <mat-form-field uni-flex-item >
      <input  matInput placeholder="From Feature" [ngModel]="fromFeature.name"
        readonly="readonly" />
    </mat-form-field>
    <mat-form-field *ngIf="toFeature" uni-flex-item >
      <input  matInput placeholder="To Feature" [ngModel]="toFeature.name"
        readonly="readonly" />
    </mat-form-field>
    <button  mat-raised-button (click)="selectToFeature()">Select To Feature
    </button>
  </div>
  <div class="content-card" style="height: calc(100% - 140px)" >
    <app-ag-grid *ngIf="toFeature != null"  [gridOptions]="fcPairGridOptions" [rowData]="fcPairs"> </app-ag-grid>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button *ngIf="toFeature != null" (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>  
  <button mat-raised-button *ngIf="toFeature != null" (click)="undo()">Undo
  </button>  
</ng-template>
