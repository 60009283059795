import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { DataServiceWebApiAdapter } from 'breeze-client/adapter-data-service-webapi';
import { ModelLibraryBackingStoreAdapter } from 'breeze-client/adapter-model-library-backing-store';
import { UriBuilderJsonAdapter } from 'breeze-client/adapter-uri-builder-json';
import { AjaxHttpClientAdapter } from 'breeze-client/adapter-ajax-httpclient';

import { AuthHttpInterceptor } from './auth-http-interceptor';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';


import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginModule } from './login/login.module';

import { SharedModule } from './shared/shared.module';
import { JobOrdersModule } from './job-orders/job-orders.module';
import { HomeModule } from './home/home.module';
import { InventoryModule } from './inventory/inventory.module';
import { PurchasingModule } from './purchasing/purchasing.module';

import { ShippingModule } from './shipping/shipping.module';

import { GlobalErrorHandler } from './shared/global-error-handler';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SetupModule } from './setup/setup.module';
import { AdminModule } from './admin/admin.module';
import { SentryHttpInterceptor } from './sentry-http-interceptor';

const appRoutes: Routes = [
    {
        path      : '**',
        redirectTo: 'home'
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    providers: [
      {provide: ErrorHandler, useClass: GlobalErrorHandler},
      { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: SentryHttpInterceptor, multi: true },
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgIdleKeepaliveModule.forRoot(),
        RouterModule.forRoot(appRoutes, { enableTracing: false}),
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        // App modules
        LayoutModule,
        SharedModule,

        // Uniformax modules
        LoginModule,
        HomeModule,
        InventoryModule,
        PurchasingModule,
        JobOrdersModule,
        ShippingModule,
        SetupModule,
        AdminModule
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule {
  constructor(http: HttpClient) {
    ModelLibraryBackingStoreAdapter.register();
    UriBuilderJsonAdapter.register();
    AjaxHttpClientAdapter.register(http);
    DataServiceWebApiAdapter.register();
  }

}
