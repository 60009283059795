import { ColDef, GridOptions, RowSelectedEvent, ValueGetterParams, ITooltipParams } from '@ag-grid-community/core';
import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Shipment } from 'app/model/entities/shipment';
import { ShippingBox } from 'app/model/entities/shipping-box';
import { Rate } from 'app/model/shipEngine/models';
import { AgFns } from 'app/shared/ag-fns';
import { BusyService } from 'app/shared/busy.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UtilFns } from 'app/shared/util-fns';
import { ShippingCarrier, ShippingService } from 'app/shipping/shipping.service';
import * as _ from 'lodash';
import * as moment from 'moment-mini';
import { InvoiceShipmentService } from './invoice-shipment.service';

@Component({
  templateUrl: './ask-ship-info-dialog.component.html'
})
export class AskShipInfoDialogComponent {
  @ViewChild('deleteBoxCell') deleteBoxCell: TemplateRef<any>;

  boxWeightGridOptions: GridOptions;
  uomMap = {
    oz: 'Ounces',
    lb: 'Pounds',
    gr: 'Grams',
    kg: 'Kilograms'
  };
  uomList = Object.entries(this.uomMap);
  uomId: string;
  carriers: ShippingCarrier[];
  selectedCarrier: ShippingCarrier;
  selectedService: { code: string, name: string };
  shipmentApiIdentifier: string;
  shipment: Shipment;
  boxes: ShippingBox[];
  packageMap: any;
  isPageReady = false;

  rateGridOptions: GridOptions;
  rates: Rate[] = [];

  static async show(matDialog: MatDialog, data: any) {

    const r = await matDialog.open(AskShipInfoDialogComponent, {
      disableClose: true,
      height: '710px',
      width: '600px',
      data: data
    })
      .afterClosed().toPromise();
    return <boolean>r;
  }


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AskShipInfoDialogComponent>,
    public shippingService: ShippingService, private dialogService: DialogService, private dbSaveService: DbSaveService,
    private invoiceShipmentService: InvoiceShipmentService, private busyService: BusyService, private matDialog: MatDialog) {

    data.title = data.title || 'Enter shipping info';
    this.shipment = <Shipment>data.shipment;
    this.initialize();

  }

  async initialize() {
    this.boxWeightGridOptions = AgFns.initGridOptions( this, {
      onGridReady: this.onBoxWeightGridReady,
      stopEditingWhenCellsLoseFocus: true,
    });
    this.rateGridOptions = AgFns.initGridOptions( this, {
      onGridReady: this.onRateGridReady,
      onRowDoubleClicked: this.onRateDoubleClicked,
      onModelUpdated: (p) => p.api?.sizeColumnsToFit(),
      // pagination: false,
      tooltipShowDelay: 300,
    });

    await this.getCarrierInfo();

    this.boxes = this.shipment.shippingBoxes;
    if (this.boxes.length == 0) {
      this.uomId = 'lb';
      this.addBox();
    } else {
      this.uomId = this.boxes[0].weightUoMId;
    }

    this.isPageReady = true;

    await this.initRateGridOnOpen();
  }

  async getCarrierInfo() {
    this.carriers = await this.shippingService.getShippingCarriers();

    if (this.shipment.carrierApiIdentifier) {
      this.selectedCarrier = _.find(this.carriers, c => c.code === this.shipment.carrierApiIdentifier);
    }
    if (!this.selectedCarrier) {
      this.selectedCarrier = _.find(this.carriers, c => c.name === "UPS");
    }

    if (this.shipment.serviceApiIdentifier) {
      this.selectedService = this.selectedCarrier.services.find(s => s.code === this.shipment.serviceApiIdentifier);
      this.shipmentApiIdentifier = this.shipment.shipmentApiIdentifier;
    }

    this.packageMap = _.mapValues(_.keyBy(this.selectedCarrier.packages, 'code'), 'name');
  }

  /** If re-opening dialog after rate is already selected, re-load the rate grid and select the current rate row. */
  async initRateGridOnOpen() {
    if (this.shipment.serviceApiIdentifier && this.shipment.shippingBoxes.length && this.shipment.shippingBoxes.every(b => b.weight > 0)) {
      await this.lookupRates();
      await UtilFns.wait(100);
      const gridApi = this.rateGridOptions.api;
      const node = gridApi.getRowNode(this.shipment.serviceApiIdentifier);
      if (node) {
        // Old code - no longer supported
        // gridApi.selectNode(node, false, true);
        node.setSelected(true);
        gridApi.ensureNodeVisible(node);
      }
    } 
  }

  onBoxWeightGridReady() {
    AgFns.attachGridState(this.boxWeightGridOptions);
    this.boxWeightGridOptions.singleClickEdit = true;
    this.boxWeightGridOptions.stopEditingWhenCellsLoseFocus = true;

    const colDefs = [
      { ...AgFns.createCellButtonProps('', this.deleteBoxCell, 'del'), maxWidth: 40 },
      {
        headerName: 'Box Id', editable: false, width: 70,
        valueGetter: params => {
          const sb = <ShippingBox>params.data;
          return sb.id > 0 ? sb.id : 'New box' + sb.id;
        }
      },
      { headerName: 'Weight', field: 'weight', editable: true, width: 70 },
      // { headerName: 'Unit Of Measure', field: 'weightUoMId', width: 150,
      //   cellEditor: 'agRichSelectCellEditor',
      //   cellEditorParams: { values: Object.keys(this.uomMap) },
      //   refData: this.uomMap,
      //   editable: true,
      // },
      {
        headerName: 'Package', field: 'packageApiIdentifier', editable: true,
        ...AgFns.createDropdownEditorPropsFromMapObj(this.packageMap),
      }

    ];
    AgFns.updateColDefs(colDefs);
    this.boxWeightGridOptions.api.setColumnDefs(colDefs);
    AgFns.sizeColumnsToFit(this.boxWeightGridOptions);
  }

  onRateGridReady() {
    this.rateGridOptions.getRowId = params => params.data.service_code,
    this.rateGridOptions.rowSelection = 'single';
    this.rateGridOptions.onRowSelected = this.onRateRowSelected.bind(this);
    AgFns.attachGridState(this.rateGridOptions);

    const colDefs: ColDef[] = [
      { field: 'carrier_friendly_name', headerName: 'Carrier', width: 70 },
      {
        field: 'service_type', headerName: 'Service', width: 310, valueGetter: (params: ValueGetterParams) => {
          const rate = params.data as Rate;
          return rate.service_type + ' (' + (rate.package_type || rate.service_code) + ')'
        }, tooltipValueGetter: (params: ITooltipParams) => {
          const rate = params.data as Rate;
          return rate.service_code + (rate.package_type ? ': ' + rate.package_type : '');
        }
      },
      {
        field: 'delivery_days', headerName: 'Delivery Days', width: 80, type: 'numericColumn',
        tooltipValueGetter: (params: ITooltipParams) => {
          const cdd = params.data.carrier_delivery_days;
          return cdd && cdd.length > 3 ? cdd : moment(params.data.estimated_delivery_date).format('dddd, M/D, h:mm a');
        }
      },
      {
        field: 'amount', headerName: 'Amount', width: 70, type: 'numericColumn', sort: 'asc', 
        valueFormatter: (params) => UtilFns.fmtCurrency(params.value),
        valueGetter: (params: ValueGetterParams) => {
          const rate = params.data as Rate;
          const amount = rate.shipping_amount.amount +
            rate.insurance_amount.amount +
            rate.confirmation_amount.amount +
            rate.other_amount.amount;
          return +amount.toFixed(2);
        }
      },
    ];
    AgFns.updateColDefs(colDefs);
    this.rateGridOptions.api.setColumnDefs(colDefs);
  }

  onRateRowSelected(event) {
    if (!event.node.selected) {
      return;
    }
    const rate = event.data as Rate;
    this.selectedCarrier = this.carriers.find(c => c.code === rate.carrier_id);
    this.selectedService = { code: rate.service_code, name: rate.service_type };
  }

  onRateDoubleClicked(event) {
    this.ok();
  }

  onCarrierChange(newCarrier: ShippingCarrier) {
    this.selectedCarrier = newCarrier;
    this.packageMap = _.mapValues(_.keyBy(this.selectedCarrier.packages, 'code'), 'name');
    this.boxes.forEach(b => b.packageApiIdentifier = null);

    // Reset column def for Package column to show package options for the new carrier
    const pcol = this.boxWeightGridOptions.columnApi.getColumn('packageApiIdentifier');
    pcol.setColDef({
      headerName: 'Package', field: 'packageApiIdentifier', editable: true,
      ...AgFns.createDropdownEditorPropsFromMapObj(this.packageMap),
    }, null);

    this.boxWeightGridOptions.api.redrawRows();
    this.clearRates();
  }

  onUomChange(newUomId: string) {
    this.uomId = newUomId;
    this.shipment.shippingBoxes.forEach(sb => sb.weightUoMId = newUomId);
    this.clearRates();
  }

  addBox() {
    const newBox = this.dbSaveService.uow.createEntity(ShippingBox, {
      shipmentId: this.shipment.id,
      weightUoMId: this.uomId
    });
    this.boxes = this.shipment.shippingBoxes.slice();
    
    this.clearRates();
  }

  deleteBox(box: ShippingBox) {
    box.entityAspect.setDeleted();
    this.boxes = this.shipment.shippingBoxes.slice();
    this.clearRates();
  }

  private clearRates() {
    this.rates = [];
    this.selectedService = null;
  }

  canGetRates() {
    return this.shipment.shippingBoxes.length;
  }

  canClickOk() {
    return this.selectedService && this.shipmentApiIdentifier && this.rates.length && this.shipment.shippingBoxes.length;
  }

  async ok() {
    this.boxWeightGridOptions.api.stopEditing();
    const boxes = this.shipment.shippingBoxes;
    if (boxes.length == 0) {
      this.dialogService.showOkMessage('No boxes found', 'You must have at least one box.');
      return;
    }
    if (!boxes.every(sb => sb.weight > 0)) {
      this.dialogService.showOkMessage('Invalid Weight', 'All boxes must have weights greater than 0.');
      return;
    }

    this.shipment.carrierApiIdentifier = this.selectedCarrier.code;
    this.shipment.serviceApiIdentifier = this.selectedService.code;
    this.shipment.shipmentApiIdentifier = this.shipmentApiIdentifier;

    const rate = _.find(this.rates, r => r.service_code === this.selectedService.code);

    // NOTE: currency is assumed to be $
    this.shipment.freightAmt =
      rate.shipping_amount.amount +
      rate.insurance_amount.amount +
      rate.confirmation_amount.amount +
      rate.other_amount.amount;

    await this.dbSaveService.saveChanges();

    this.dialogRef.close(true);
  }

  cancel() {
    this.dbSaveService.rejectChanges();
    this.dialogRef.close(null);
  }

  async lookupRates() {
    
    await AgFns.waitForGrid(this.rateGridOptions);
    await AgFns.busyGrid(this.rateGridOptions, this.busyService, async () => {
      const ratesResponse = await this.invoiceShipmentService.getRatesForInvoice(this.shipment.invoiceHeaders[0], this.selectedCarrier.code, undefined);
      this.rates = ratesResponse.rates;
      this.shipmentApiIdentifier = ratesResponse.shipmentApiIdentifier;
    });
  }

}
