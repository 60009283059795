<app-uni-dialog [title]="data.title" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <form #form>
    <div>
      <label class="form-label" for="doc_file">{{data.label}}</label>
      <input type="file" id="doc_file" #doc_file name="doc_file" (change)="setDocFile($event)" class="form-control"/>
    </div>
  </form>
</ng-template>

<ng-template #footerTemplate>
  <button [disabled]="docFile == null" mat-raised-button (click)="ok()"> Ok </button>
  <button mat-raised-button (click)="cancel()"> Cancel </button>
</ng-template>

