<app-uni-dialog title="User Account Edit" [parent]="this" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <form *ngIf="!!user" #userForm="ngForm">
    <div uni-flex-row>
      <mat-form-field style="width: 250px;">
        <input matInput required minlength="2" placeholder="First Name" name="firstname" [(ngModel)]="user.FirstName" />
      </mat-form-field>
      <mat-form-field style="width: 250px;">
        <input matInput required minlength="2" placeholder="Last Name" name="lastname" [(ngModel)]="user.LastName" />
      </mat-form-field>
    </div>
    <div uni-flex-row>
      <mat-form-field style="width: 250px;">
        <input matInput required minlength="2" maxlength="4" [disabled]="!isUserAdmin" placeholder="Initials"
          name="initials" [(ngModel)]="user.Initials" #initials="ngModel" [validatorFn]="validateInitials"
          (ngModelChange)="user.Initials = $event.toUpperCase()" [disabled]="!canEditInitials()" />
        <mat-error>{{initials?.errors?.initials?.message}}</mat-error>
      </mat-form-field>
      <mat-form-field style="width: 250px;">
        <input matInput required type="email" email placeholder="Username (Email)" name="username"
          [(ngModel)]="user.User.UserName" />
      </mat-form-field>
    </div>
    <div uni-flex-row>
      <mat-form-field style="width: 250px">
        <input matInput [required]="user.id=='new'" type="password" placeholder="Password" name="password"
          [(ngModel)]="user.Password" #password="ngModel" [pattern]="pwRegex" />
        <mat-error>Password must be at least 8 characters long, and contain at least one uppercase, one lowercase,
          one numeric, and one special character</mat-error>
      </mat-form-field>
      <mat-form-field style="width: 250px;">
        <input matInput [required]="user.id=='new'" type="password" placeholder="Confirm Password"
          name="confirmPassword" [(ngModel)]="confirmPassword" #confirm="ngModel" [validatorFn]="validateConfirm" />
        <mat-error>{{confirm?.errors?.confirm?.message}}</mat-error>
      </mat-form-field>
    </div>

    
    <div uni-flex-row>
      <mat-checkbox [disabled]="!isUserAdmin" [(ngModel)]="user.IsLockedOut" name="lockedOut">Locked Out</mat-checkbox>
    </div>
    <div uni-flex-row>
      <h3>Roles</h3>
    </div>
    <div uni-flex-row>
      <div *ngFor="let role of userRoles">
        <mat-checkbox style="width: 200px" [disabled]="(!isUserAdmin) || user.IsLockedOut" [(ngModel)]="role.isSelected"
          [name]="role.name">
          {{UtilFns.splitCamelCaseToString(role.name)}}
        </mat-checkbox>
        </div>
    </div>

    
  </form>
</ng-template>

<ng-template #footerTemplate>

  <button mat-raised-button [disabled]="!canSave()" (click)="save()">
    <span>Save</span>
  </button>
  <button mat-raised-button [disabled]="!user" type="button" (click)="close()">
    <span>Cancel</span>
  </button>
</ng-template>