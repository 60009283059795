import { Injectable } from '@angular/core';
import { JoDetail } from 'app/model/entities/jo-detail';
import { JoStatusCode } from 'app/model/enums/jo-status-code';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import * as _ from 'lodash';
import { AuthService } from './auth.service';
import { BusyService } from './busy.service';
import { DbQueryService } from './db-query.service';
import { DbSaveService } from './db-save.service';
import { DialogService } from './dialog.service';
import { UnitOfWork } from './unit-of-work';


@Injectable({providedIn: 'root'})
export class JoUtilsService {
  constructor(
    public uow: UnitOfWork,
    public dbQueryService: DbQueryService,
    public dbSaveService: DbSaveService,
    public dialogService: DialogService,
    public authService: AuthService,
    public busyService: BusyService
  ) {}

  
  async uncompleteBatch(joPullBatchId: number) {

    const joDetails = await this.dbQueryService.getJoDetailsByJoPullBatchId(joPullBatchId);
    if (joDetails.length === 0) {
      this.dialogService.showOkMessage('Unable to uncomplete this batch', 'No JO Details found.');
      return { ok: false }
    }

    const somePodsHaveBeenSent = joDetails.some(jod => jod.poDetails.some(pod => pod.poHeader != null && pod.poHeader.poStatusId !== PoStatusCode.NewUnposted));
    if (somePodsHaveBeenSent) {
      this.dialogService.showOkMessage('Unable to uncomplete this batch',
        `Some of the PO Details associated with this batch have already been sent to the manufactur on a Purchase Order`);
        return { ok: false }
    }

    const r = await this.dbQueryService.getInvoiceDetailsByJoPullBatchId(joPullBatchId);
    if (r.length > 0) {
      this.dialogService.showOkMessage('Unable to uncomplete this batch', 'Some of the Job OrderDetails in this batch are already on an invoice.');
      return { ok: false }
    }

    // don't allow uncomplete of any 'Completed' PO Details

    const res = await this.dialogService.askYesNo('Uncomplete this batch', 'Are you sure?');
    if (res.index !== 0) {
      return { ok: false }
    }
    this.dialogService.toast({ message: 'Uncompleting this batch and deleting associated order detail records...' });
    // // 2 steps
    // // Mark all JoHeader records that are part of this batch with the 'prev' status.
    // // Create a collection of PurchaseOrderDetail records
    const joHeaders = _.uniq(joDetails.map(jod => jod.joHeader));
    joHeaders.forEach(joh => {
      joh.joStatusId = JoStatusCode.OpenProcessIncomplete;
    });

    let podCount = 0;
    let pohIdSet = new Set<number>();
    joDetails.forEach(jod => {
      // Note: there can be more than one PurchaseOrderDetail for a single JoDetail
      jod.poDetails.forEach(pod => {
        const poh = pod.poHeader;
        // Only undo New/Unposted Pod's
        if (poh == null || poh.poStatusId === PoStatusCode.NewUnposted) {
          if (poh != null) {
            pohIdSet.add(pod.poHeaderId);
          }
          pod.entityAspect.setDeleted();
          podCount++;
        }
      });
    });

    const sr = await this.dbSaveService.saveChanges();
    
    this.dialogService.toast({ message: `Deleted ${podCount} Purchase Order Detail record(s)`});
    return { ok: true, pohIdSet };
    
  }

  
  async deleteJoHeaderFromBatch(joHeaderId: number) {
    const q = this.uow
      .createQuery(JoDetail, 'JoDetails')
      .expand(['joHeader', 'joHeader.joHeaderPull', 'joHeader.joHeaderPull.joPullBatch', 'itemDetails'])
      .where({ joHeaderId: joHeaderId });

    const joDetails = await q.execute();
    if (joDetails.length === 0) {
      return;
    }
    // collect these here because they may get lost during delete process
    const joHeader = joDetails[0].joHeader;
    const joPullBatch = joHeader.joHeaderPull.joPullBatch;

    // first unlink all of the itemDetail records
    joDetails.forEach(jod => {
      jod.itemDetails.forEach(itd => {
        itd.joDetailId = null;
      });
    });

    // Then delete the joHeaderPull record
    joHeader.joHeaderPull.entityAspect.setDeleted();

    // Then check if any joHeaders remain in batch and if not delete the entire batch
    if (joPullBatch.joHeaderPulls.length === 0) {
      joPullBatch.entityAspect.setDeleted();
    } else {
      joPullBatch.modTs = new Date(); // For OC purposes
    }

    return await this.dbSaveService.saveChanges();
  }

  

}