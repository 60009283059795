<app-uni-dialog title="Select Alternative Styles" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <app-ag-grid  [gridOptions]="itsGridOptions"> </app-ag-grid>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>  
</ng-template>
