/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ColDef, ColumnApi, GetRowIdParams, GridApi, GridOptions, RowDoubleClickedEvent, RowDragEvent, GridReadyEvent, IDatasource, CellClickedEvent,
  GetContextMenuItemsParams, CellRangeParams, IServerSideDatasource, IServerSideGetRowsParams, FirstDataRenderedEvent,
  ValueGetterParams, ValueSetterParams, IRowNode, SortModelItem, SortChangedEvent, RowSelectedEvent, ValueFormatterParams
} from '@ag-grid-community/core';
import { ActivatedRoute, UrlTree } from '@angular/router';

import { Entity } from 'breeze-client';
import * as _ from 'lodash';


export class UniAgFns {

  static getProductSortOrderColDef(
    productFn: (p: ValueGetterParams) => any,
    colId = 'productSortOrder',
    shouldHide = true
  ) {
    return {
      colId: colId,
      headerName: 'Product Sort Order', // if not hidden
      hide: shouldHide,
      valueGetter: (params) => {
        const product = productFn(params);
        return product?.getSortOrder();
      },
    };
  }

}
