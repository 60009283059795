<app-uni-dialog [title]="data.title" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div *ngIf="type == 'pdf' || type == 'txt'">
    <iframe [src]="safeUrl" height="450px" width="752px" ></iframe>
  </div>
  <div *ngIf="type == 'img'">
    <img [src]="safeUrl" height="450px" width="752px" />
  </div>
  <div *ngIf="type == 'other'" style="height: 100%; display: flex; justify-content: center; align-items: center;">
    <span>No viewer available for this file type</span>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="prev()" [disabled]="!hasPrev()"> &lt; Prev </button>
  <button mat-raised-button (click)="next()" [disabled]="!hasNext()"> Next &gt; </button>
  <div style="flex-grow: 1;"><span class="text-bold ml-12 mr-12">{{fileName}}</span></div>
  <a mat-raised-button [href]="safeUrl" target="_self" [download]="fileName">Download</a>
  <button mat-raised-button (click)="close()"> Close </button>
</ng-template>

