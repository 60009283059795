import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Manufacturer, PoHeader, Account } from 'app/model/entities/entity-model';
import { UnitOfWork } from 'app/shared/unit-of-work';
import * as _ from 'lodash';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';


@Component({
  templateUrl: './create-po-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class CreatePoDialogComponent {

  poHeader: PoHeader;
  allManufs: Manufacturer[];
  matchingManufs: Manufacturer[];
  allAccounts: Account[];
  matchingAccounts: Account[];
  isPageReady = false;


  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(CreatePoDialogComponent, {
        disableClose: true,
        height: '250px',
        width: '800px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CreatePoDialogComponent>,
    public uow: UnitOfWork, public dbSaveService: DbSaveService, public dialogService: DialogService)  {

    this.prepare();
  }

  async prepare() {
    const allManufs = await this.uow.queryAll(Manufacturer, 'Manufacturers');
    this.allManufs = _.sortBy(allManufs, m => m.name);

    const poStruct = <PoHeader> {};
    const now = new Date();
    poStruct.poDate = now;
    poStruct.poStatusId = PoStatusCode.NewUnposted;
    poStruct.crtnTs = now;
    poStruct.shippingWarehouseId = 1; // TODO: magic number
    this.poHeader = this.uow.createEntity(PoHeader, poStruct);
    this.isPageReady = true;
  }

  searchManufs(query: string){
    this.matchingManufs = this.allManufs.filter(m => m.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }



  async save() {
    if (this.poHeader.manufacturer == null) {
      this.dialogService.showOkMessage('Cannot save', 'You must specify a Manufacturer.');
      return false;
    }
    // TODO: check that Manuf is filled in
    await this.dbSaveService.saveChanges();
    this.dialogRef.close(this.poHeader);
  }

  cancel() {
    this.uow.rollback();
    this.dialogRef.close(null);
  }

}
