<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  
    <span *ngIf="jobOrders.length == 1">
      <div uni-flex-row class="mb-24" >
        <div class="h3">This purchase order only has a single Job Order associated with it.</div>
      </div>
    </span>
    <span *ngIf="jobOrders.length > 1">
      <div class="h3 mb-4">Job Orders on Purchase Order: {{ poHeader.id }} </div>
      <app-ag-grid style="height: 300px;" [gridOptions]="joGridOptions" [rowData]="jobOrders"></app-ag-grid>
    </span>
  
</ng-template>

<ng-template #footerTemplate>
  <button *ngIf="isPageReady && jobOrders.length > 1" mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>
