import { GridOptions } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { JoStatus } from 'app/model/entities/jo-status';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DialogService } from 'app/shared/dialog.service';
import { NavFns } from 'app/shared/nav-fns';
import { UtilFns } from 'app/shared/util-fns';

export interface JoStatsDialogComponentData {
  joStatusId: string
  dateInMonth: Date;
}

@Component({
  templateUrl: './jo-stats-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class JoStatsDialogComponent {

  title: string;
  johGridOptions: GridOptions;
  isPageReady = false;

  static async show(matDialog: MatDialog, data: JoStatsDialogComponentData) {
    return await matDialog
      .open(JoStatsDialogComponent, {
        disableClose: true,
        height: '570px',
        width: '1000px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: JoStatsDialogComponentData, public dialogRef: MatDialogRef<JoStatsDialogComponent>,
    private dbQueryService: DbQueryService, private dialogService: DialogService, private router: Router) {

      const joStatuses = this.dbQueryService.getAllCached(JoStatus);
      const joStatusName = joStatuses.find(s => s.id == this.data.joStatusId).name;
      const monthName = this.formatDateAsMonth(this.data.dateInMonth);
      this.title = `Job Orders for ${monthName} with status: '${joStatusName}'`;

    this.prepare();

  }

  async prepare() {
    this.johGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJohGridReady,
      rowModelType: 'serverSide',
    });
    this.isPageReady = true;
  }

  async onJohGridReady(evt: any) {

    const colDefs = [
      { headerName: 'Job Order', field: 'id', filter: 'agNumberColumnFilter', ...NavFns.createIdCellClickedNavProps('id', this.router, '/job-orders', this.dialogRef),   },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'joStatus.name'  },
      { headerName: 'JO Date', field: 'joDate', filter: 'agDateColumnFilter' },
      
      { headerName: 'Days to Ship', field: 'numDaysToShip', type: 'numericColumn' },
      { headerName: 'CrtnTs', field: 'crtnTs', filter: 'agDateColumnFilter' },
    ];
    const sortModel = [
      { colId: 'id', sort: 'asc' },
      { colId: 'crtnTs', sort: 'asc' },
    ] as ISortModel;

    // next line is needed by the buildDatasource method
    AgFns.initGrid(this.johGridOptions, colDefs, sortModel);
    
    await UtilFns.wait(0);
    this.updateDatasource();
  }

  

  updateDatasource() {
    const gridApi = this.johGridOptions.api;
    if (gridApi == null) {
      return;
    }

    const ds = AgFns.buildDatasource(() => this.dbQueryService.createJoHeaderQueryForStatusAndMonthOf(this.data.joStatusId, this.data.dateInMonth));
    gridApi.setServerSideDatasource(ds);
  }

  formatDateAsMonth(date: Date) {
    const options = { year: 'numeric', month: 'long',  } as any;
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }


  async ok() {
    this.dialogRef.close(true);
  }

}
