import { Injectable } from '@angular/core';
import { ItemDetail } from 'app/model/entities/item-detail';
import { ItemDetailAddon } from 'app/model/entities/item-detail-addon';
import { JoDetail } from 'app/model/entities/jo-detail';
import { ProcessStatusCode } from 'app/model/enums/process-status-code';
import { EntityState } from 'breeze-client';
import * as _ from 'lodash';
import { AuthService } from './auth.service';
import { BusyService } from './busy.service';
import { DbQueryService } from './db-query.service';
import { DbSaveService } from './db-save.service';
import { DialogService } from './dialog.service';
import { UnitOfWork } from './unit-of-work';
import { AddonBin } from 'app/model/entities/addon-bin';
import { AddonBinStatusCode } from 'app/model/enums/addon-bin-status-code';
import { Addon } from 'app/model/entities/addon';
import { UtilFns } from './util-fns';
import { AddonBinStationStatusCode } from 'app/model/enums/addon-bin-station-status-code';
import { AddonStation } from 'app/model/entities/addon-station';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';


@Injectable({providedIn: 'root'})
export class AddonUtilsService {
  constructor(
    public uow: UnitOfWork,
    public dbQueryService: DbQueryService,
    public dbSaveService: DbSaveService,
    public dialogService: DialogService,
    public authService: AuthService,
    public busyService: BusyService
  ) {}

  

  // assumes that both JoDetailAddons and ItemDetailAddons have been queried and Product for both Jod and Itd.
  createItemDetailAddonsFromJoDetail(jod: JoDetail, itd: ItemDetail, accountAddons: Addon[]) {
    const now = new Date(Date.now());
    const user = this.authService.getUser();
    const itdAddons = itd.itemDetailAddons.slice();
    // fn to create a copy of the just the specified fields from another object.
    
    const newItdAddons: ItemDetailAddon[] = [];
    jod.joDetailAddons.forEach(joda => {
      // if the same addon already exists on the itemDetail do NOT add another
      if (!itdAddons.some(itdm => itdm.addonId == joda.addonId)) {
        const itdmStruct = <ItemDetailAddon> { addonId: joda.addonId, additionalInfo: joda.additionalInfo };
        itdmStruct.itemDetailId = itd.id;
        itdmStruct.modTs = now;
        itdmStruct.modUserInit = user.initials;
        const itdm = this.uow.createEntity(ItemDetailAddon, itdmStruct, EntityState.Added);
        newItdAddons.push(itdm);
      }
    });
    // if product ids don't match - create a new addon
    if (jod.product.id != itd.product.id) {
      const itda = this.createMismatchAddon(jod, itd, accountAddons);
      newItdAddons.push(itda)
    }

    return newItdAddons;
  }

  private createMismatchAddon(jod: JoDetail, itd: ItemDetail, accountAddons: Addon[]) {
    const now = new Date(Date.now());
    const user = this.authService.getUser();
    const mismatchFeature = _.first(jod.product.productFeatureChoices).featureChoice.feature;
    if (mismatchFeature.mismatchAddonNameAndLocation == null) {
      // will require a saveChanges call
      mismatchFeature.mismatchAddonNameAndLocation = "Feature Mismatch";
    }
    const mismatchName = mismatchFeature.mismatchAddonNameAndLocation;
    var mismatchAddon = accountAddons.find(x => x.nameAndLocation == mismatchName);
    if (mismatchAddon == null) {
      // TODO: consider whether to make use of mismatchAddonIsCrossAccount or not.
      mismatchAddon = this.uow.createEntity(Addon, {
        accountId: jod.joHeader.accountId,
        nameAndLocation: mismatchName,
        groupingKey: Math.random().toString(36).substring(0, 4),
        addonStationId: AddonStation.TailoringStationId,
        isHiddenOnDocs: true,
        // isCrossAccountAddon: mismatchFeature.mismatchAddonIsCrossAccount,
        needsAdditionalInfo: true,
      });
      accountAddons.push(mismatchAddon);
    }
    const itdaStruct = <ItemDetailAddon>{
      itemDetailId: itd.id,
      addonId: mismatchAddon.id,
      additionalInfo: `from: '${itd.product.getFeatureChoicesExtract()}' to: '${jod.product.getFeatureChoicesExtract()}'`,
      modTs: now,
      modUserInit: user.initials
    };
    const itda = this.uow.createEntity(ItemDetailAddon, itdaStruct, EntityState.Added);
    return itda;
  }

  buildAggregateGroupingKey(addons: Addon[]) {
    return _.uniq(addons.map(x => + x.addonStationId.toString() + '^' + x.groupingKey )).sort().join(':');
  }

  async findOrCreateAddonBins(itemDetails: ItemDetail[]) {
    // insure that these run sequentially - we don't want them to run 
    // in parallel because each iter may find an AddonBin created 
    // by an earlier iter.
    var addonBins = new Set<AddonBin>();
    for (const itd of itemDetails){
      const addonBin = await this.findOrCreateAddonBin(itd);
      if (addonBin != null) {
        addonBins.add(addonBin);
      }
    }
    // returns a uniq list
    return Array.from(addonBins);
  }
  

  // assumes that itemDetail.joDetail.joHeader already fetched
  // assumes that itemDetail.itemDetailAddons.addon.addonStation already fetched
  private async findOrCreateAddonBin(itemDetail: ItemDetail) {
    // stock item
    if (itemDetail.joDetail == null) {
      return null;
    }
    const joHeader = itemDetail.joDetail.joHeader;
    
    const itdas = itemDetail.itemDetailAddons;
    if (itdas.length == 0) {
      return null;
    }
    const addonsFromItd = itdas.map(x => x.addon);
    const aggGroupingKey = this.buildAggregateGroupingKey(addonsFromItd);
    // we need to make sure that we get already created bins that have not yet been saved as well.
    const newAddonBins = this.dbQueryService.getAlreadyFetched(AddonBin).filter(
      aob => aob.accountId == joHeader.accountId && aob.aggregateGroupingKey == aggGroupingKey);
    var addonBins = await this.dbQueryService.getAvailableAddonBinsFor(joHeader.accountId, aggGroupingKey);
    addonBins = newAddonBins.concat(...addonBins);
    // only bins that have less then the max batch size in terms of number of items ( not itemDetailAddons)
    addonBins = addonBins.filter(x => _.uniq(x.itemDetailAddons.map(x => x.itemDetailId)).length < this.dbQueryService.appConfig.addon.maxBatchSize);
    var addonBin = _.first(addonBins.filter(b => (b.joHeaderId == null) || joHeader.id == b.joHeaderId ));
    
    if (addonBin == null) {
      addonBin = this.dbSaveService.createEntity(AddonBin, {
        accountId: itemDetail.joDetail.joHeader.accountId,
        aggregateGroupingKey: aggGroupingKey,
        addonBinStatusId: AddonBinStatusCode.New,
        priorityOrder: 9999,
      });
    }

    // when adding items to an addonBin - the binStation must go back to pending
    if (addonBin.currentAddonBinStationStatusId != null) {
      addonBin.pendingAddonStationId = addonBin.currentAddonStationId;
      addonBin.currentAddonStationId = null;
      addonBin.currentAddonBinStationStatus = null;
    }

    itdas.forEach(ida => {
      ida.addonBinId = addonBin.id;
      ida.addonBinStationStatusId = AddonBinStationStatusCode.Pending;
    });

    this.updatePendingAddonStation(addonBin);


    return addonBin;
  }


  // called after AddonBin and ItemDetailAddons have been updated for completion.
  public updatePendingAddonStation(addonBin: AddonBin) {
    const areAllClosed = addonBin.itemDetailAddons.every(x => x.addonBinStationStatusId == AddonBinStationStatusCode.Closed);
    if (areAllClosed) {
      addonBin.addonBinStatusId = AddonBinStatusCode.Closed;
    }

    // calculate the pending station - it may have changed as a result of adding addons.
    var openStations = addonBin.itemDetailAddons
      .filter(x => x.addonBinStationStatusId != AddonBinStationStatusCode.Closed)
      .map(x => x.addon.addonStation);
    // remove dups
    openStations = Array.from(new Set(openStations));
    
    if (openStations.length > 0) {
      if (addonBin.pendingAddonStationId == null || openStations.every(x => x.id != addonBin.pendingAddonStationId)) {
        // set pendingAddonStation either because its null or because the current pending is no longer available
        const pendingAddonStation = _.first(_.sortBy(openStations, x => x.defaultPriorityOrder));
        addonBin.pendingAddonStationId = pendingAddonStation.id;
      }
    } else {
      addonBin.pendingAddonStationId = null
      addonBin.currentAddonBinStationStatusId = AddonBinStationStatusCode.Closed;
      addonBin.itemDetailAddons.forEach(x => x.addonBinStationStatusId = AddonBinStationStatusCode.Closed);
      var itds = Array.from(new Set(addonBin.itemDetailAddons.map(x => x.itemDetail)));
      itds.forEach(x => x.itemDetailStatusId = ItemDetailStatusCode.InProcessPendingShipPrep);

    } 
      
  }

  public async checkAndRemoveEmptyBins(addonBinIds: number[]) {
    const addonBins = await this.dbQueryService.getAddonBinsByIds(addonBinIds);

    addonBins.forEach(b => {
      if (b.itemDetailAddons.length == 0) {
        b.entityAspect.setDeleted();
      }
    });
  }
}