<app-uni-dialog title="Edit {{inputData.addressTypeName}} Address" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <app-address-component [entity]="inputData.entity" [prefix]="inputData.addressPrefix" [isReadonly]="!inputData.editable"></app-address-component>
</ng-template>

<ng-template #footerTemplate>
  <button [disabled]="!canSave()" mat-raised-button (click)="saveChanges()">Save
  </button>
  <button mat-raised-button (click)="cancelChanges()">Cancel
  </button>
</ng-template>
