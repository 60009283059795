// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ActiveStatus } from './active-status';
import { DocSubmitType } from './doc-submit-type';
import { ManufacturerCryptonym } from './manufacturer-cryptonym';
import { ManufacturerImage } from './manufacturer-image';
import { PoHeader } from './po-header';
import { ProductType } from './product-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Manufacturer extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  accountReferenceName: string;
  activeStatusId: number;
  billingAddress1: string;
  billingAddress2: string;
  billingCity: string;
  billingContact: string;
  billingEmail: string;
  billingExt: string;
  billingFax: string;
  billingName: string;
  billingState: string;
  billingTelephone: string;
  billingZipCode: string;
  description: string;
  lastBackorderRequestDate: Date;
  leadTimeDays: number;
  name: string;
  oLD_MFT_KEY: string;
  poDocSubmitTypeId: number;
  rowVersion: any;
  whAddress1: string;
  whAddress2: string;
  whCity: string;
  whContact: string;
  whEmail: string;
  whExt: string;
  whFax: string;
  whName: string;
  whState: string;
  whTelephone: string;
  whZipCode: string;
  activeStatus: ActiveStatus;
  manufacturerCryptonym: ManufacturerCryptonym;
  manufacturerImages: ManufacturerImage[];
  poDocSubmitType: DocSubmitType;
  poHeaders: PoHeader[];
  productTypes: ProductType[];
}

