// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ItemDetail } from './item-detail';
import { PoDetail } from './po-detail';
import { PoDetailCancel } from './po-detail-cancel';
import { PoNote } from './po-note';
import { PoTrxHist } from './po-trx-hist';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class PoTrx extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  crtnTs: Date;
  note: string;
  podCount: number;
  itemDetails: ItemDetail[];
  poDetailCancels: PoDetailCancel[];
  poDetails: PoDetail[];
  poNotes: PoNote[];
  poTrxHists: PoTrxHist[];
}

