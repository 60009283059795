import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from 'app/shared/auth-guard.service';
import { EntityManagerProviderGuard } from 'app/shared/entity-manager-guard';
import { PurchaseOrdersComponent } from './purchase-orders.component';
import { SharedModule } from 'app/shared/shared.module';
import { CombinePurchaseOrdersDialogComponent } from './combine-purchase-orders-dialog.component';
import { SplitPurchaseOrdersDialogComponent } from './split-purchase-orders-dialog.component';
import { CreatePurchaseOrdersComponent } from './create-purchase-orders.component';
import { PurchaseOrderReceiveComponent } from './purchase-order-receive.component';
import { PurchaseOrderApplyChangeDialogComponent } from './purchase-order-apply-change-dialog.component';
import { PrintablePoComponent } from './printable-po.component';
import { PoPrintDialogComponent } from './po-print-dialog.component';
import { CreatePoDialogComponent } from './create-po-dialog.component';
import { CanDeactivateGuard } from 'app/shared/can-deactivate-guard.service';
import { EditPodDialogComponent } from './edit-pod-dialog.component';
import { EditPoInstructionsDialogComponent } from './edit-po-instructions-dialog.component';
import { PurchaseEOQComponent } from './purchase-eoq.component';
import { BackordersComponent } from './backorders.component';
import { AccountBackordersComponent } from './account-backorders.component';
import { AnonGuardService } from 'app/shared/anon-guard.service';
import { ManufBackordersComponent } from './manuf-backorders.component';


const routes = [
  {
    path: 'purchase-orders',
    component: PurchaseOrdersComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'create-purchase-orders',
    component: CreatePurchaseOrdersComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
  },
  {
    path: 'purchase-order-receive/:poHeaderId',
    component: PurchaseOrderReceiveComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
  },
  {
    path: 'printable-po/:poHeaderId',
    component: PrintablePoComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
  },
  {
    path: 'purchase-eoq',
    component: PurchaseEOQComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'backorders',
    component: BackordersComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    // prefix 'account' here is important - used by anon guard
    path: 'account-backorders/:guid',
    component: AccountBackordersComponent,
    canActivate: [AnonGuardService],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    
    path: 'manufacturer-backorders/:guid',
    component: ManufBackordersComponent,
    canActivate: [AnonGuardService],
    canDeactivate: [CanDeactivateGuard]
  },
];
routes.forEach( (r: any) => {
  r.data = Object.assign(r.data ?? {}, { icon: 'shopping_cart'});
});

@NgModule({
  declarations: [
    PurchaseOrdersComponent,
    PurchaseEOQComponent,
    BackordersComponent,
    AccountBackordersComponent,
    ManufBackordersComponent,
    EditPodDialogComponent,
    EditPoInstructionsDialogComponent,
    CombinePurchaseOrdersDialogComponent,
    SplitPurchaseOrdersDialogComponent,
    CreatePurchaseOrdersComponent,
    CreatePoDialogComponent,
    PurchaseOrderReceiveComponent,
    PurchaseOrderApplyChangeDialogComponent,
    PrintablePoComponent, 
    PoPrintDialogComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [
  ],
})
export class PurchasingModule {}
