// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Addon } from './addon';
import { AddonBin } from './addon-bin';
import { AddonBinStationStatus } from './addon-bin-station-status';
import { ItemDetail } from './item-detail';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ItemDetailAddon extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  additionalInfo: string;
  addonBinId: number;
  addonBinStationStatusId: number;
  addonId: number;
  itemDetailId: string;
  modTs: Date;
  modUserInit: string;
  addon: Addon;
  addonBin: AddonBin;
  addonBinStationStatus: AddonBinStationStatus;
  itemDetail: ItemDetail;
}

