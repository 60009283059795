import { ProductType } from "./product-type";
import { Account } from "./account";
import { Manufacturer } from "./manufacturer";
import { ProductEOQ } from "./product-eoq";
import { EOQTypeCode } from "../enums/eoq-type-code";
import { Product } from "./entity-model";

export class ProductNeededForEOQ {
  productId: number;
  eOQSize: string;
  reorderSize: string;
  eOQQty: number;
  reorderPointQty: number;
  poQty: number;
  invCount: number;
  product: Product;
}


export interface ProductTypeXXXSummary {
  productTypeId: number;
  productType: ProductType;
  invoiceYear: number;
  qty: number;
}

export interface ProductTypeXXXCrosstabSummary {
  
  productTypeId: number;
  year0Qty: number;
  year1Qty: number;
  year2Qty: number;
  year3Qty: number;
  inventoryQty: number;
  
  productType: ProductType;
}

// handrolled summary class
export class ProductTypeAccountSummary implements ProductTypeXXXSummary {
  
  productTypeId: number;
  accountId: number;
  invoiceYear: number;
  qty: number;
  
  productType: ProductType;
  account: Account;
}

export class ProductTypeAccountCrosstabSummary {
  
  productTypeId: number;
  accountId: number;
  year0Qty: number;
  year1Qty: number;
  year2Qty: number;
  year3Qty: number;
  inventoryQty: number;
  
  productType: ProductType;
  account: Account;
}

export class ProductSummary  {
  productId: number; 
  invoiceYear: number;
  qty: number;
  product: Product;
  get productTypeId() {
    return this.product.productTypeId;
  }
  get productType() {
    return this.productType;
  }
}

export class ProductCrosstabSummary {
  productId: number;
  product: Product;
  
  productEOQ: ProductEOQ;
  featureChoicesExtract: string;
  year0Qty: number;
  year1Qty: number;
  year2Qty: number;
  year3Qty: number;
  
  inventoryQty: number;
  calcSafetyStockQty:  number;
  calcReorderPeriod: number;

  get productTypeId() {
    return this.product.productTypeId;
  }
  get productType() {
    return this.productType;
  }

  get projQty() {
    return this.productEOQ.projectedUsageQty;
  }
  set projQty(qty: number) {
    this.productEOQ.projectedUsageQty = qty;
    this.recalcAll();
  }

  get calcEOQQty() {
    return this.productEOQ.eOQQty;
  }
  set calcEOQQty(qty: number) {
    this.productEOQ.eOQQty = qty;
  }

  get calcReorderPointQty() {
    return this.productEOQ.reorderPointQty;
  }
  set calcReorderPointQty(qty: number) {
    this.productEOQ.reorderPointQty = qty;
  }

  recalcAll() {
    this.recalcEOQ();
    this.recalcSafetyStock();
    this.recalcReorderPoint();
    this.recalcReorderPeriod();
  }

  private recalcEOQ() {
    const its = this.productType;
    const qty = this.projQty;
    if (qty ==  0) {
      this.calcEOQQty = 0;
    } else if (its.eOQTypeId == EOQTypeCode.FixedQuantity) {
      const base = (2 * qty * its.orderingCostAmt)/its.unitCarryingCostPerYearAmt;
      this.calcEOQQty = Math.round(Math.sqrt(base));
    } else if (its.eOQTypeId == EOQTypeCode.FixedTime) {
      const safetyStock =  qty * (its.safetyStockPerYearPct);
      const base = (2 * ( qty + safetyStock) * its.orderingCostAmt)/its.unitCarryingCostPerYearAmt;
      this.calcEOQQty = Math.round(Math.sqrt(base));
    } else {
    }
  }

  private recalcSafetyStock() {
    const its = this.productType;
    const qty = this.projQty;
    if (qty == 0) {
      this.calcSafetyStockQty = 0;
    } else {
      this.calcSafetyStockQty = Math.round(qty * its.safetyStockPerYearPct);
    }
  }

  private recalcReorderPoint() {
    const its = this.productType;
    const eoqQty = this.calcEOQQty;
    if (eoqQty == 0) {
      this.calcReorderPointQty = 0;
    } else {
      this.calcReorderPointQty = Math.round(((eoqQty/250)*(its.leadTimeDays ?? 0)) + (its.safetyStockPerYearPct * eoqQty));
    }
  }

  private recalcReorderPeriod() {
    const its = this.productType;
    const eoqQty = this.calcEOQQty;
    if (eoqQty == 0) {
      this.calcReorderPeriod = 0;
    } else {
      this.calcReorderPeriod =  Math.round( 365 * eoqQty / this.projQty);
    }
  }


}