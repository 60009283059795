import { Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AccountFinderDialogComponent } from 'app/admin/account-finder-dialog.component';
import { Account, ActiveStatus, Addon, AddonDoc, AddonDocMap, AddonImage, AddonStation  } from 'app/model/entities/entity-model';
import { AuthService, AuthUser } from 'app/shared/auth.service';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { FileSelectDialogComponent } from 'app/shared/file-select-dialog.component';
import { FileViewDialogComponent, FileViewDialogData } from 'app/shared/file-view-dialog.component';
import { FileGalleryDialogComponent } from 'app/shared/image/file-gallery-dialog.component';
import { ImageFns } from 'app/shared/image/image-fns';
import { ImageHandlerComponent } from 'app/shared/image/image-handler.component';
import { UnitOfWork } from 'app/shared/unit-of-work';

@Component({
  templateUrl: './edit-addon-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class EditAddonDialogComponent {
  @ViewChild(ImageHandlerComponent) imageHandler?: ImageHandlerComponent;
  user: AuthUser;
  addon: Addon;

  activeStatuses: ActiveStatus[];
  addonStations: AddonStation[];


  isAdding: boolean;
  title: string;
  isPageReady = false;


  static async show(matDialog: MatDialog, data: any) {

    return await matDialog
      .open(EditAddonDialogComponent, {
        disableClose: true,
        height: '730px',
        width: '850px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditAddonDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, private uow: UnitOfWork,
    private authService: AuthService, private dialogService: DialogService, public busyService: BusyService, public matDialog: MatDialog) {

    this.user = this.authService.getUser();

    this.addon = this.data.addon;
    if (this.addon == null) {
      this.isAdding = true;
      this.addon = this.createNewAddon();
    }

    this.title = this.isAdding ? 'Creating New Addon' : 'Editing Addon';

    this.prepare();
  }

  private createNewAddon() {
    return this.uow.createEntity(Addon, {
      accountId: null,
      addonStationId: null,
      activeStatusId: 1
    });
  }

  private async prepare() {

    this.activeStatuses = this.dbQueryService.getAllCached(ActiveStatus);

    this.addonStations = await this.dbQueryService.getAll(AddonStation);
    
    this.isPageReady = true;
  }


  async selectAccount() {
    const account = await AccountFinderDialogComponent.show(this.matDialog, { } )
    if (account != null) {
      this.addon.account = account;
    }
  }

  async viewAddonDocs() {
    const data: FileViewDialogData = { 
      title: 'View Addon Document',
      docs: this.addon.addonDocMaps.map(x => x.addonDoc)
    };
    await FileViewDialogComponent.show(this.matDialog, data);

  }

  async addAddonDoc() {
    var data = { title: 'Add Addon Doc', label: 'Doc'}
    const r = await FileSelectDialogComponent.show(this.matDialog, data);
    
    if (r) {
      const data = await ImageFns.blobToBase64(r);
      const aod = this.dbSaveService.createEntity(AddonDoc, {
        doc: data,
        fileName: r.name,
        mimeType: r.type,
        name: r.name,
        description: 'n/a'
      });
      this.dbSaveService.createEntity(AddonDocMap, {
        addonId: this.addon.id,
        addonDocId: aod.id,
      });

    }

  }

  /** Create the join entity between Image and parent entity */
  createImageJoinEntity(event: { imageId: number, isPrimary: boolean }): void {
    this.dbSaveService.uow.createEntity(AddonImage, {
      addonId: this.addon.id,
      imageId: event.imageId,
      isPrimary: event.isPrimary
    });
  }

  async ok() {
    const ok = await this.save();
    if (!ok) {
      return;
    }
    this.dialogRef.close(true);
  }

  async save() {
    // remove excess spaces
    const isValid = await this.validate();
    if (!isValid) { return false; }

    await this.imageHandler?.attachFiles();

    try {
      const sr = await this.dbSaveService.saveChanges();
      return true;
    } catch (e) {
      return false;
    }
  }

  async validate() {

    let matchingEntity = await this.dbQueryService.getMatchingAddon(this.addon);
    if (matchingEntity != null) {
      await this.dialogService.showOkMessage('Duplicate Name', `Cannot save because this addon is a duplicate of an existing addon`);
      return false;
    }

    const ea = this.addon.entityAspect;
    if (! ea.validateEntity()) {
      const vErrors = ea.getValidationErrors();
      const errMsg = vErrors.map(ve => ve.errorMessage).join('<br>');
      await this.dialogService.showOkMessage('Validation Error(s)', errMsg);
      return false;
    }
    return true;
  }

  async cancel() {
    // This insures that the rejectChanges below does not cause an html error on the page.
    this.isPageReady = false;
    // entityAspect may not exist if it is a new object
    this.addon.entityAspect?.rejectChanges();

    this.dialogRef.close(false);
  }
}
