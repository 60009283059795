import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PoHeader } from 'app/model/entities/entity-model';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';


@Component({
  templateUrl: './edit-po-instructions-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class EditPoInstructionsDialogComponent {
  poHeader: PoHeader;
  

  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(EditPoInstructionsDialogComponent, {
        disableClose: true,
        height: '430px',
        width: '800px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditPoInstructionsDialogComponent>,
    public uow: UnitOfWork, public dbSaveService: DbSaveService, public dialogService: DialogService)  {
      this.poHeader =  data.poHeader;
  }

  async save() {
    if (this.poHeader.entityAspect.entityState.isUnchanged()) {
      return false;
    }
    await this.dbSaveService.saveChanges();
    this.dialogRef.close(true);
  }

  cancel() {
    this.uow.rollback();
    this.dialogRef.close(false);
  }

}
