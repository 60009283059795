import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActiveStatus, DocSubmitType, Manufacturer, ManufacturerImage } from 'app/model/entities/entity-model';
import { AuthService, AuthUser } from 'app/shared/auth.service';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { ImageHandlerComponent } from 'app/shared/image/image-handler.component';
import { UnitOfWork } from 'app/shared/unit-of-work';

@Component({
  templateUrl: './edit-manufacturer-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class EditManufacturerDialogComponent {
  @ViewChild(ImageHandlerComponent) imageHandler?: ImageHandlerComponent;
  user: AuthUser;
  manufacturer: Manufacturer;

  activeStatuses: ActiveStatus[];
  docSubmitTypes: DocSubmitType[];


  isAdding: boolean;
  title: string;
  isPageReady = false;


  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(EditManufacturerDialogComponent, {
        disableClose: true,
        height: '575px',
        width: '850px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditManufacturerDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, private uow: UnitOfWork,
    private authService: AuthService, private dialogService: DialogService, public busyService: BusyService) {

    this.user = this.authService.getUser();

    this.manufacturer = this.data.manufacturer;
    if (this.manufacturer == null) {
      this.isAdding = true;
      this.manufacturer = this.createManufacturer();
    }

    this.title = this.isAdding ? 'Creating New Manufacturer' : 'Editing Manufacturer';


    this.prepare();
  }

  private createManufacturer() {
    return this.uow.createEntity(Manufacturer, {
    });
  }

  private async prepare() {
    this.activeStatuses = this.dbQueryService.getAllCached(ActiveStatus)
    this.docSubmitTypes = this.dbQueryService.getAllCached(DocSubmitType)

    this.isPageReady = true;
  }

  onTabChanged(e: any) {

  }


  async ok() {
    const ok = await this.save();
    if (!ok) {
      return;
    }
    this.dialogRef.close(true);
  }

  async save() {
    // remove excess spaces

    const isValid = await this.validate();
    if (!isValid) { return false; }

    await this.imageHandler?.attachFiles();

    try {
      const sr = await this.dbSaveService.saveChanges();
      return true;
    } catch (e) {
      return false;
    }
  }

  async validate() {

    let matchingEntity = await this.dbQueryService.getMatchingManufacturer(this.manufacturer);
    if (matchingEntity != null) {
      await this.dialogService.showOkMessage('Duplicate Name', `Cannot save because this manufacturer's name is a duplicate of an existing name`);
      return false;
    }

    const ea = this.manufacturer.entityAspect;
    if (! ea.validateEntity()) {
      const vErrors = ea.getValidationErrors();
      const errMsg = vErrors.map(ve => ve.errorMessage).join('<br>');
      await this.dialogService.showOkMessage('Validation Error(s)', errMsg);
      return false;
    }

    return true;
  }

  async cancel() {
    this.isPageReady = false;
    // entityAspect may not exist if it is a new object
    this.manufacturer.entityAspect?.rejectChanges();

    this.dialogRef.close(false);
  }

  /** Create the join entity between Image and parent entity */
  createImageJoinEntity(event: { imageId: number, isPrimary: boolean }): void {
    this.dbSaveService.uow.createEntity(ManufacturerImage, {
      manufacturerId: this.manufacturer.id,
      imageId: event.imageId,
      isPrimary: event.isPrimary
    });
  }

}
