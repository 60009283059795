import { environment } from "environments/environment";
import * as qz from "qz-tray";

export const pdfPrefix = 'data:application/pdf;base64,';
export const zplPrefix = 'data:application/zpl;base64,';

export interface PrintFile {
  url: string;
  name: string;
  data: Blob;

}

export class PrintFns {

  private static printFn: () => void;
  private static subscribed: boolean;

  /** Provide function to call before print */
  static setPrintFn(callback: () => void) {
    PrintFns.printFn = callback;
    PrintFns.preparePrinting();
  }

  /** Function called before printing */
  private static beforePrint() {
    if (PrintFns.printFn) {
      PrintFns.printFn();
    }
  }

  /** Set up listeners to handle print event (ctrl-P printing) */
  private static preparePrinting() {
    if (PrintFns.subscribed) {
      return;
    }

    if (window.matchMedia) {
        const mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener(function(mql) {
            if (mql.matches) {
                PrintFns.beforePrint();
            }
        });
    }

    window.onbeforeprint = PrintFns.beforePrint;
    PrintFns.subscribed = true;
  }

  static print() {
    let appdisplay: string;
    try {
      appdisplay = this.setAppDisplay('none');

      window.print();
    } finally {

      this.setAppDisplay(appdisplay);

      const printEle = document.getElementById('printSection');
      if (printEle) {
        printEle.parentElement.removeChild(printEle);
      }

    }
  }

  private static setAppDisplay(val: string): string {
    const apps = document.body.getElementsByTagName('app');
    if (apps.length) {
      const el = apps[0] as HTMLElement;
      const old = el.style.display;
      el.style.display = val;
      return old;
    }
  }

  static printElement(ele: HTMLElement) {

    const printSelectEle = this.getPrintSection();
    const eleClone = ele.cloneNode(true);
    printSelectEle.appendChild(eleClone);

    // set flag to signal PdfRenderer that page is ready to print
    (window as any).printReady = true;
  }


  /** Find or create or find an element with an id of 'printSection'
   * printSection is styled in the css to print properly.
   */
  private static getPrintSection() {
    let printSelectEle = document.getElementById('printSection');
    if (printSelectEle) {
      printSelectEle.innerHTML = '';
    } else {
      printSelectEle = document.createElement('div');
      printSelectEle.id = 'printSection';
      document.body.appendChild(printSelectEle);
    }
    return printSelectEle;
  }

  // See https://qz.io/ to install the QZ Tray client program first
  /** Attempt to print zpl by sending raw data via QZ.  Falls back to printPlainText() if QZ is not running. */
  static async printZpl(zpl: string, printerType: PrinterType): Promise<any> {
    const printer = await this.getPrinter(printerType);
    if (printer) {
      const config = qz.configs.create(printer);
      return this.qzPrint(config, [zpl]);
    } else {
      return this.printPlainText(zpl);
    }
  }

  /** Call qz.print and catch certain errors */
  private static async qzPrint(config: qz.Configs | qz.Configs[], data: string[] | qz.PrintData[]) {
    return qz.print(config, data).catch(r => {
      console.log(r);
      if (r.message != "Printing cancelled") {
        throw r;
      }
    });
  }

  /** Print a data url containing ZPL */
  static async printZplUrl(url: string, printer: PrinterType): Promise<any> {
    if (url.startsWith(zplPrefix)) {
      // blob url
      const b64 = url.substring(zplPrefix.length);
      const zpl = window.atob(b64);
      return await PrintFns.printZpl(zpl, printer);
    } else {
      // http url - need to fetch data
      // TODO
      throw new Error("ZPL url not yet implemented");
    }
  }

  /** Print many data urls containing zpl */
  static async printZplBlobs(printFiles: PrintFile[], printer: PrinterType): Promise<any> {
    printFiles.forEach(async pf => {
      const zpl = await pf.data.text();
      this.printZpl(zpl, printer);
    });
  }

  static isZplUrl(url: string) {
    return url.startsWith(zplPrefix) || url.endsWith('.zpl');
  }

  /** Print many pdf or zpl files using, using Blob data or QZ to download from the urls.  Fall back to openPdfDataUrl if QZ not running. */
  static async printFiles(printFiles: PrintFile[], printerType: PrinterType): Promise<any> {
    const printer = await this.getPrinter(printerType);
    if (printer) {
      const configs: qz.Config[] = [];
      const data: qz.PrintData[] = [];
      for (const pf of printFiles) {
        const isZpl = this.isZplUrl(pf.url);
        const hasBlob = !!pf.data;
        configs.push(qz.configs.create(printer));
        data.push({
          type: isZpl ? 'raw' : 'pixel',
          format: isZpl ? 'command' : 'pdf',
          flavor: hasBlob ? 'base64' : 'file',
          data: hasBlob ? pf.data : pf.url,
        });
      }
      return this.qzPrint(configs, data);
    } else {
      const urls = printFiles.map(pf => pf.url);
      this.openPdfDataUrls(urls);
    }
  }

  static async getPrinter(printerType: PrinterType): Promise<string> {
    const started = await this.startQZ();
    if (started) {
      const sp = this.getSelectedPrinters();
      if (sp && sp[printerType]) {
        return qz.printers.find(sp[printerType]) as Promise<string>;
      } else {
        return qz.printers.getDefault();
      }
    } else {
      return null;
    }
  }

  static async getInstalledPrinters(): Promise<qz.PrinterDetails[]> {
    const started = await this.startQZ();
    if (started) {
      return qz.printers.details();
    } else {
      return null;
    }

  }

  private static _startPromise: Promise<boolean>;

  /** Connect to QZ.  Return true if successful, false otherwise. */
  static async startQZ(): Promise<boolean> {

    if (this._startPromise) {
      return this._startPromise;
    }

    // configure certificate retrieval
    const baseUrl = environment.apiRoot + "api/qz/";
    qz.security.setCertificatePromise(function(resolve, reject) {
      fetch(baseUrl + "GetCertificate", {cache: 'no-store', headers: {'Content-Type': 'text/plain'}, credentials: 'include'})
         .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });
    });

    // configure message signing
    qz.security.setSignatureAlgorithm("SHA512");
    qz.security.setSignaturePromise(function(toSign) {
       return function(resolve, reject) {
          fetch(baseUrl + "SignMessage", {cache: 'no-store', headers: {'Content-Type': 'text/plain'}, method: 'POST', body: toSign, credentials: 'include'})
             .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });
       } as any;
    });

    this._startPromise = qz.websocket.connect().then(() => true)
    .catch(() => {
      // if a connect was not successful, launch the mimetype (to start QZ tray), try 2 more times
      window.location.assign("qz:launch");
      return qz.websocket.connect({ retries: 1, delay: 0.5 }).then(() => true)
      .catch(err => {
        console.error(err);
        console.log("Error connecting to QZ printer: " + err.toString());
        return false;
      });
    });
    return this._startPromise;
  }

  /** save to local storage */
  static saveSelectedPrinters(printers: SelectedPrinters): void
  {
    if (!printers) { return; }
    window.localStorage.setItem('uniformax.printers', JSON.stringify(printers));
  }

  /** get from local storage */
  static getSelectedPrinters(): SelectedPrinters {
    const json = window.localStorage.getItem('uniformax.printers');
    return json && JSON.parse(json);
  }

  /** Send raw text/commands to the printer.  Doesn't work in Chrome, which always renders it first. */
  static printPlainText(zpl: string): Promise<any> {
    return new Promise(function(ok, err) {
      try {
        const frame = window.frames['zplframe'];
        const printWindow = (frame && frame.contentWindow as Window) || frame as Window;
        if (!printWindow ) {
          err('Print window not found');
          return;
        }
        printWindow.document.open('text/plain');
        printWindow.document.write(zpl);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        ok(true);
      } catch (ex) {
        err(ex);
      }
    });
  }

  /** Open a PDF from a data url like 'data:application/pdf;base64,AbCdEf...' */
  static openPdfDataUrls(dataUrls: string[]) {
    const printWindow = window.open();
    if (!printWindow) {
      throw new Error("Could not open tab due to pop-up blocker");
    }
    const iframes = dataUrls.map(url => '<iframe style="width: 100%; height: 100%; border: 0" src="' +
    url + '"></iframe>');
    const html = '<html><body style="margin: 0">' + iframes + '</body></html>';
    printWindow.document.open('text/html');
    printWindow.document.write(html);
    printWindow.document.close();
    printWindow.focus();
  }


}

export type PrinterType = 'barcodePrinter' | 'invoicePrinter' | 'labelPrinter';
export type LabelFormat = 'pdf'|'zpl';
export interface SelectedPrinters {
  barcodePrinter: string;
  invoicePrinter: string;
  labelPrinter: string;
  labelFormat: LabelFormat;
}
