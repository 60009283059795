import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Email, Manufacturer, PoHeader } from 'app/model/entities/entity-model';
import { BusyService } from 'app/shared/busy.service';
import { environment } from 'environments/environment';
import { EmailService } from 'app/shared/email.service';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { UtilFns } from 'app/shared/util-fns';

export interface PoPrintDialogComponentData {
  poHeader: PoHeader;
  /** if we are not already on the preview page */
  canPreview: boolean;
}

@Component({
  templateUrl: './po-print-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class PoPrintDialogComponent {

  poHeader: PoHeader;
  mfg: Manufacturer;
  emailAddress: string;
  emailStatus: string;
  email: Email;
  pdfurl: string;
  canPreview: boolean;
  isPrinting: boolean;
  isOpenIncomplete: boolean;
  isPageReady = false;

  static async show(matDialog: MatDialog, data: PoPrintDialogComponentData) {
    return await matDialog
      .open(PoPrintDialogComponent, {
        disableClose: true,
        height: '340px',
        width: '800px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: PoPrintDialogComponentData, public dialogRef: MatDialogRef<PoPrintDialogComponent>,
    private emailService: EmailService, private router: Router, public busyService: BusyService) {

    this.poHeader = data.poHeader;
    this.canPreview = data.canPreview;
    this.prepare();
  }

  async prepare() {
    this.mfg = this.poHeader.manufacturer;
    this.emailAddress = this.mfg.whEmail || this.mfg.billingEmail;
    if (this.emailAddress) { this.emailAddress = this.emailAddress.trim(); }

    this.pdfurl = environment.apiRoot + 'api/pdf/pdffromurl/PO' + this.poHeader.id + '?url=' + UtilFns.getBaseUrl() +
      'printable-po/' + this.poHeader.id + '&headerLeft=Purchase Order ' + this.poHeader.id;
    await this.getEmailStatus();
    this.isPageReady = true;
  }

  canSendEmail(): boolean {
    if (this.poHeader.poStatusId === PoStatusCode.Complete) {
      return false;
    }
    return UtilFns.validateEmail(this.emailAddress);
  }

  async sendEmail() {
    if (!this.canSendEmail()) {
      return;
    }
    const status = await this.emailService.sendPoEmail(this.poHeader.id, this.emailAddress);
    this.emailStatus = 'Email status: ' + status;

    if (status === 'accepted' || status === 'stored') {
      await this.getEmailStatus();
    }
  }

  async getEmailStatus() {
    const email = await this.emailService.getPoEmailStatus(this.poHeader.id);
    if (email) {
      this.email = email;
      this.emailStatus = 'Email status: ' + email.status;
    }
  }

  printDocument() {
    const pwin = window.open(this.pdfurl, '_blank');
    if (this.poHeader.poStatusId === PoStatusCode.NewUnposted) {
      setTimeout(() => this.isPrinting = true, 1000);
    }
  }

  async setStatusOpen() {
    this.poHeader.poStatusId = PoStatusCode.OpenIncomplete;
    await this.busyService.busy(
      this.poHeader.entityAspect.entityManager.saveChanges([this.poHeader]).then(sr => {
        this.isOpenIncomplete = true;
      }).finally(() => {
        this.isPrinting = false;
      })
    );
  }

  showPreview() {
    this.router.navigateByUrl('/printable-po/' + this.poHeader.id);
    this.close();
  }

  close() {
    this.dialogRef.close(null);
  }

}
