// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Manufacturer } from './manufacturer';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ManufacturerCryptonym extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  guid: string;
  crtnTs: Date;
  expirationDate: Date;
  manufacturerId: number;
  manufacturer: Manufacturer;
}

