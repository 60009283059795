import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { ItemDetail } from 'app/model/entities/item-detail';
import { ItemReplacementStatus, ItemReplacementType, ItemReplacement, PoHeader, PoDetail } from 'app/model/entities/entity-model';
import { ItemReplacementStatusCode } from 'app/model/enums/item-replacement-status-code';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { AuthService } from 'app/shared/auth.service';
import { ItemDetailStatusCode, ItemDetailStatusCodes } from 'app/model/enums/item-detail-status-code';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { DateFns } from 'app/shared/date-fns';
import * as _ from 'lodash';
import { ItemReplacementTypeCode } from 'app/model/enums/item-replacement-type-code';


export interface InventoryDamageDialogData {
  itemDetail: ItemDetail;
}

@Component({
  templateUrl: './inventory-damage-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class InventoryDamageDialogComponent {
  title: string;
  itemDetail: ItemDetail;
  itemReplacementTypes: ItemReplacementType[];
  itemReplacementStatuses: ItemReplacementStatus[];
  itemReplacementType: ItemReplacementType;
  itemReplacementStatus: ItemReplacementStatus;

  addonBinId: number;

  isPageReady = false;

  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(InventoryDamageDialogComponent, {
        disableClose: true,
        height: '500px',
        width: '800px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: InventoryDamageDialogData, public dialogRef: MatDialogRef<InventoryDamageDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService,
    private uow: UnitOfWork, private authService: AuthService, private dialogService: DialogService) {

    this.itemDetail = this.data.itemDetail;
    const itr = this.itemDetail.itemReplacement;
    if (itr != null) {
      this.itemReplacementType = itr.itemReplacementType;
      this.itemReplacementStatus = itr.itemReplacementStatus;
    }
    this.prepare();
  }

  async prepare() {
    this.itemReplacementTypes = await this.dbQueryService.cacheAndGetAll(ItemReplacementType);
    this.itemReplacementStatuses = await this.dbQueryService.cacheAndGetAll(ItemReplacementStatus);
    if ( ItemDetailStatusCodes.InProcess.includes(this.itemDetail.itemDetailStatusId)) {
      this.title = 'Mark item damaged/lost/other';
    } else {
      // Wrong item replacement types are only available for in-process itemDetails.
      this.itemReplacementTypes = this.itemReplacementTypes.filter(x => !x.id.startsWith('WRONG'));
      this.title="Inventory Damaged/Lost Items"
    }

    this.isPageReady = true;
  }

  onItemReplacementTypeChange(irt: ItemReplacementType) {
    this.itemReplacementType = irt;
    this.itemReplacementStatus = this.itemReplacementStatuses.find(irs => irs.id === ItemReplacementStatusCode.None);
  }

  async apply() {
    let itr = this.itemDetail.itemReplacement;

    if (ItemDetailStatusCodes.Addon.includes(this.itemDetail.itemDetailStatusId)) {
      this.itemDetail.itemDetailAddons.slice().forEach(itda => {
        this.addonBinId = itda.addonBinId;
        itda.entityAspect.setDeleted();
      }); 
      
    }

    if ( ([ItemReplacementTypeCode.WrongByManuf, ItemReplacementTypeCode.WrongItem] as string[]).includes(this.itemReplacementType.id) ) {
      this.itemDetail.itemDetailStatusId = ItemDetailStatusCode.InInventory;
      this.itemDetail.joDetailId = null;
    } else {
      this.itemDetail.itemDetailStatusId = ItemDetailStatusCode.DamagedOrLost;
    }
    
    
    if (itr == null) {
      const struct = <ItemReplacement> {};
      struct.itemDetail = this.itemDetail;
      struct.itemReplacementStatus = this.itemReplacementStatus;
      struct.itemReplacementType = this.itemReplacementType;
      struct.crtnTs = new Date();
      struct.crtnUserInit = this.authService.getUser().initials,
      itr = this.uow.createEntity(ItemReplacement, struct );
    } else {
      itr.itemReplacementStatus = this.itemReplacementStatus;
      itr.itemReplacementType = this.itemReplacementType;
    }
    
    if (this.itemDetail.joDetail != null) {
      await this.createOrFindAndUpdatePuchaseOrder()
    }
    
    await this.dbSaveService.saveChanges();
    this.dialogService.toast('Item Replacement record updated');

    // check if the addonBin associated with this item is empty - if so delete it. 
    if (this.addonBinId != null) {
      const addonBin = await this.dbQueryService.getAddonBin(this.addonBinId);
      if (addonBin.itemDetailAddons.length == 0) {
        addonBin.entityAspect.setDeleted();
        await this.dbSaveService.saveChanges();
      }
    }
    this.dialogRef.close(true);
  }

  async createOrFindAndUpdatePuchaseOrder() {
    const poHeaders = await this.dbQueryService.getUnpostedPoHeadersForManufacturer(this.itemDetail.product.productType.manufacturer.id);
    const now = new Date();
    // find or create the corresponding poHeader for each jod
    const manuf = this.itemDetail.product.productType.manufacturer;
    let poHeader = _.first(poHeaders);
    // next line for testing
    // poHeader = null;
    if (poHeader == null) {
      const poStruct = {
        shippingWarehouseId: 1, // TODO: magic number for unitec
        manufacturerId: manuf.id,
        poStatusId: PoStatusCode.NewUnposted,
        poDate: DateFns.startOfDay(now),
        crtnTs: now
      };
      poHeader = this.uow.createEntity(PoHeader, poStruct)
    }
    const leadDays = manuf.leadTimeDays;
    const podStruct = {} as PoDetail;
    const jod = this.itemDetail.joDetail;
    podStruct.poHeaderId = poHeader.id;
    podStruct.joDetailId = jod.id;
    podStruct.productId = jod.productId;
    podStruct.origProductId = jod.productId;
    podStruct.poDetailTypeId = 'ORDER';
    podStruct.orderQty = 1;
    podStruct.crtnTs = now;
    podStruct.expectedShipDate = DateFns.dateAdd(now, leadDays, 'days');
    const ord = this.uow.createEntity(PoDetail, podStruct);
    this.dialogService.toast('New unposted Purchase Order detail item created');
  }


  cancel() {
    this.dialogRef.close(null);
  }

}
