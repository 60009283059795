import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EditColComponent } from './edit-col.component';
import { InfoComponent } from './info.component';

import { AgGridModule } from '@ag-grid-community/angular';

import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';

import { TemplateRendererComponent } from './template-renderer.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { AgCheckboxCellComponent } from './ag-checkbox-cell.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { AddressComponent } from './address.component';
import { AgMatDatepickerComponent } from './ag-mat-datepicker.component';
import { AgLoadingOverlayComponent } from './ag-loading-overlay.component';
import { BackButtonComponent } from './back-button.component';

import { EditPropComponent } from './edit-prop.component';
import { YesNoDialogComponent } from './yes-no-dialog.component';
import { ValidationSaveErrorsDialogComponent } from './validation-save-errors-dialog.component';
import { GlobalErrorDialogComponent } from './global-error-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FuseSharedModule } from '@fuse/shared.module';

import { OneFieldDialogComponent } from './one-field-dialog.component';
import { EditNotesDialogComponent } from './edit-notes-dialog.component';
import { BreezeValidator } from './breeze-validator';
import { FnValidator } from './fn-validator';
import { AngularSplitModule } from 'angular-split';


import { AddressDialogComponent } from './address-dialog.component';
import { AgGridWrapperComponent } from './ag-grid-wrapper.component';
import { UniPageComponent } from './uni-page.component';
import { UniDialogComponent } from './uni-dialog.component';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { DynamicChildLoaderDirective } from './dynamic-child-loader.directive';
import { FileSelectDialogComponent } from './file-select-dialog.component';
import { FileViewDialogComponent } from './file-view-dialog.component';
import { ButtonRendererComponent } from './button-renderer.component';
import { ImageModule } from './image/image.module';
import { AddonFinderDialogComponent } from './addon-finder-dialog.component';
import { AuthUserSelectDialogComponent } from './auth-user-select-dialog.component';
// import { PdfComponent } from './pdf-dialog.component';



const stdModules = [
  CommonModule,
  FormsModule,

  NgxMatSelectSearchModule,
  NgxCurrencyDirective,
  FuseSharedModule,

  MatDialogModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatMenuModule,
  MatSelectModule,
  MatSnackBarModule,
  MatRadioModule,
  MatTabsModule,
  MatDividerModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,

  DragDropModule,
  AgChartsAngularModule,
  ImageModule,
];

@NgModule({
  imports: [
    ...stdModules,
    AngularSplitModule,
    AgGridModule,
    // No longer needed after Ag30
    // AgGridModule.withComponents([
    //   TemplateRendererComponent,
    //   AgCheckboxCellComponent,
    //   AgMatDatepickerComponent,
    //   AgLoadingOverlayComponent,
    // ]),
  ],
  exports: [
    ...stdModules,

    AgGridModule,
    AngularSplitModule,
    EditColComponent,
    InfoComponent,
    AddressComponent,
    AddressDialogComponent,
    AgGridWrapperComponent,
    UniPageComponent,
    UniDialogComponent,
    AuthUserSelectDialogComponent,
    // PdfComponent,

    EditNotesDialogComponent,
    EditPropComponent,
    BackButtonComponent,
    ValidationSaveErrorsDialogComponent,
    BreezeValidator,
    FnValidator,
    DynamicChildLoaderDirective,
  ],
  declarations: [
    EditPropComponent,
    BackButtonComponent,
    ButtonRendererComponent,
    YesNoDialogComponent,
    OneFieldDialogComponent,
    ValidationSaveErrorsDialogComponent,
    GlobalErrorDialogComponent,
    EditColComponent,
    EditNotesDialogComponent,
    FileSelectDialogComponent,
    FileViewDialogComponent,
    AddonFinderDialogComponent,
    AuthUserSelectDialogComponent,
    InfoComponent,
    AddressComponent,
    AgGridWrapperComponent,
    UniPageComponent,
    UniDialogComponent,
    // PdfComponent,
    AddressDialogComponent,
    AgCheckboxCellComponent,
    AgMatDatepickerComponent,
    AgLoadingOverlayComponent,
    TemplateRendererComponent,
    BreezeValidator,
    FnValidator,
    DynamicChildLoaderDirective,
  ],
})
export class SharedModule {
  constructor() {
    ModuleRegistry.registerModules([
      ClientSideRowModelModule, InfiniteRowModelModule, ServerSideRowModelModule, MenuModule, RangeSelectionModule, MasterDetailModule,
      SetFilterModule, RichSelectModule, RowGroupingModule
    ]);

  }
}
