import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UniformaxProvider } from './entity-manager-provider';
import { DbQueryService } from './db-query.service';
import { AuthService } from './auth.service';
import { DbSaveService } from './db-save.service';


@Injectable({ providedIn: 'root' })
export class EntityManagerProviderGuard  {
  firstTime = true;
  constructor(private emProvider: UniformaxProvider, private authService: AuthService, private injector: Injector) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.doWork();
  }

  async doWork() {
    await this.emProvider.prepare();
    const isLoggedIn = await this.authService.isLoggedIn();
    if (isLoggedIn) {
      // Need injector because providing DbQueryService in the ctor causes initialization bug.
      const dbQueryService = this.injector.get(DbQueryService);
      await dbQueryService.cacheAll();
      if (this.firstTime) {
        this.firstTime = false;
        const dbSaveService = this.injector.get(DbSaveService);
        await dbSaveService.updateManifestIssues();
      }
    }
    return true;
  }
}
