<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate >
  <app-ag-grid  [gridOptions]="notesGridOptions" [rowData]="notes">
  </app-ag-grid>
  <ng-template #buttonsCell let-row>
    <button class="mr-8" (click)="deleteNote(row)">Del</button>
  </ng-template>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="addNote()">Add Note
  </button>
  <button *ngIf="!this.isEditingAllowed" mat-raised-button (click)="allowEditing()">Allow Editing
  </button>
  <button *ngIf="this.isEditingAllowed" mat-raised-button (click)="disallowEditing()">Disallow Editing
  </button>
  <button mat-raised-button [disabled]="!uow.hasChanges()" (click)="save()">Save & Exit
  </button>
  <button mat-raised-button [disabled]="!uow.hasChanges()" (click)="undo(false)">Undo Changes
  </button>
  <button mat-raised-button (click)="undo(true)">Cancel
  </button>
</ng-template>

