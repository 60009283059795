<app-uni-page  [parent]="this" title="Back Orders {{ getTitle() }}"
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <div *ngIf="!isPageReady" class="content-card" style="flex-grow: 1">
    <mat-spinner class="mt-12 ml-12" diameter="50"></mat-spinner>
  </div>
  <div *ngIf="isPageReady" class="content-card" style="flex-grow: 1">
    <mat-spinner class="mt-12 ml-12" diameter="50" *ngIf="busyService.isBusy | async"></mat-spinner>
    <app-ag-grid [gridOptions]="pobGridOptions" [rowData]="pobItems" ></app-ag-grid>
  </div>

</ng-template>

<ng-template #footerTemplate >
  <div class="left-buttons">
    <button mat-raised-button [disabled]="!this.currentPob" (click)="sendAccountEmail()">
      <span>Send Account Email</span>
    </button>
    <button mat-raised-button [disabled]="!this.currentPob" (click)="sendManufEmail()">
      <span>Send Manufacturer Email</span>
    </button>
    <button mat-raised-button (click)="showHideIdColumns()">
      <span>Show/Hide Id Columns</span>
    </button>
    <button mat-raised-button [disabled]="!this.currentPob" [matMenuTriggerFor]="menu">
      Reset CryptoKey for
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="resetAccountGuid()">Account</button>
      <button mat-menu-item (click)="resetManufGuid()">Manufacturer</button>
    </mat-menu>
    <button *ngIf="this.uow.hasChanges()" mat-raised-button (click)="saveChanges()">
      <span>Save Changes</span>
    </button>
    <button *ngIf="this.uow.hasChanges()" mat-raised-button (click)="cancelChanges()">
      <span>Cancel Changes</span>
    </button>
  </div>
  <div>
      <button mat-raised-button [disabled]="!this.currentPob" (click)="gotoAccountPage()">
      <span>Go to Account Page</span>
    </button>
    
    <button mat-raised-button [disabled]="!this.currentPob" (click)="gotoManufPage()">
      <span>Go to Manufacturer Page</span>
    </button>
    
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>
