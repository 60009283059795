import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { EntityAspect } from "breeze-client";
import { Image } from 'app/model/entities/entity-model';
import { UtilFns } from "../util-fns";
import { UnitOfWork } from "../unit-of-work";

export interface AppImage {
  data?: any;
  mimeType: string;
  fileName: string;
  isPrimary?: boolean;
  isThumbnail: boolean;
  entityAspect?: EntityAspect;
  /** converted from data */
  file?: File
  blobUrl?: string;
  safeUrl?: SafeResourceUrl;
  width?: number;
  height?: number;
}

interface Size {
  height: number;
  width: number;
}

const standardSize: Size = { width: 1600, height: 1600 };
const thumbnailSize: Size = { width: 100, height: 100 };

/** group 1 = root, 2 = suffix (-01 -02 etc.), 3 = extension */
const filenameRegex = /^(.*?)(\-[0-9]{2})?\.([a-z[A-Z]+)$/;

export class ImageFns {

  private static canvas: HTMLCanvasElement;

  /** Convert blob to base64 string, so it can be sent in JSON */
  static blobToBase64(blob) {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        let s = reader.result as string;
        s = s.substring(s.indexOf(',')+1)
        resolve(s);
      };
    });
  }

  /** Convert the byte array to a File on each image */
  static convertFileData(images: AppImage[] | undefined): AppImage[] {
    if (images && images.length) {
      for (const img of images) {
        const type = img.mimeType || this.mimeTypeFromFilename(img.fileName);
        if (!img.file) {
          const byteArray = UtilFns.stringToByteArray(img.data);
          const file = new File([byteArray], img.fileName, { type });
          img.file = file;
        }
      }
      return images;
    }
    return [];
  }

  /** Convert the files to AppImages */
  static convertToImages(files: File[] | undefined): AppImage[] {
    if (files && files.length) {
      const images = files.map(f => ({
        file: f,
        fileName: f.name,
        mimeType: f.type || this.mimeTypeFromFilename(f.name)
      } as AppImage));
      return images;
    }
    return [];
  }

  /** Guess mime type from file extension */
  static mimeTypeFromFilename(filename: string) {
    if (filename.endsWith('.jpg') || filename.endsWith('.jpeg')) {
      return 'image/jpeg';
    } else if (filename.endsWith('.png')) {
      return 'image/png';
    } else if (filename.endsWith('.webp')) {
      return 'image/webp';
    } else if (filename.endsWith('.gif')) {
      return 'image/gif';
    } else if (filename.endsWith('.pdf')) {
      return 'application/pdf';
    }
    return null;
  }

  /** Create the safeUrls for use in the img tag */
  static generateUrls(images: AppImage[], sanitizer: DomSanitizer): void {
    for (const img of images) {
      if (img && img.file && !img.safeUrl) {
        img.blobUrl = URL.createObjectURL(img.file);
        img.safeUrl = sanitizer.bypassSecurityTrustUrl(img.blobUrl);
      }
    }
  }

  /** Dispose the object URLs to prevent memory leaks */
  static clearUrls(images: AppImage[]): void {
    for (const img of images) {
      if (img && img.blobUrl) {
        img.safeUrl = undefined;
        URL.revokeObjectURL(img.blobUrl);
        img.blobUrl = undefined;
      }
    }
  }


  /** Add suffix to the fileName, before the extension, e.g. fileName.ext -> fileNamesuffix.ext */
  static addSuffix(fileName: string, suffix: string): string {
    const dot = fileName.lastIndexOf('.');
    if (dot < 0)  {
      return fileName + suffix;
    }
    return fileName.substring(0, dot) + suffix + fileName.substring(dot);
  }

  /** Get the next file name that's not already in the list of names, by adding a version suffix.  Suffixes are like '-01', '-02', etc */
  static getNextName(name: string, names: string[]): string {
    const match = name.match(filenameRegex);
    const root = match ? match[1] : name;
    const ext = match ? match[3] : '';
    for (let i=1; i<=99; i++) {
      const suffix = '-' + (i < 10 ? '0' : '') + i;
      const test = root + suffix + '.' + ext;
      if (names.indexOf(test) < 0) {
        return test;
      }
    }
    return name;
  }

  /** Resize the image to standard size; return the AppImage with the file and new size and optional new name */
  static resizeFileToStandard(file: File, newName?: string): Promise<AppImage> {
    return this.resizeImageFile(file, standardSize, newName);
  }

  /** Resize the image to thumbnail size; return the AppImage with the file and new size and '-thumb' suffix */
  static resizeFileToThumbnail(file: File): Promise<AppImage> {
    const newName = this.addSuffix(file.name, '-thumb');
    return this.resizeImageFile(file, thumbnailSize, newName);
  }

  /** Add files chosen by a input file control to the newImages collection */
  static async addFiles(event: Event, newImages: AppImage[], uow: UnitOfWork) {
    if (event.target && (event.target as HTMLInputElement).files) {
      const files = (event.target as HTMLInputElement).files as FileList;
      const p: Promise<AppImage>[] = [];
      for (let i = 0; i < files?.length; i++) {
        p[i] = ImageFns.resizeFileToStandard(files[i]).then((appImage) =>
          this.makeImage(appImage, uow).then((supimage) => {
            newImages.push(supimage);
            return supimage;
          }));
      }
      return Promise.all(p);
    }
  }

  /** Create an Image entity from a AppImage, if img is not alread a Image */
  static async makeImage(img: AppImage, uow: UnitOfWork) {
    if (!img || img.entityAspect) {
      // null or already a Image
      return img as Image;
    }
    const buf = await ImageFns.blobToBase64(img.file);
    const si = uow.createEntity(Image, {
      data: buf,
      // description: img.fileName,
      fileName: img.fileName,
      mimeType: img.mimeType,
      // name: img.fileName,
      // width: img.width,
      // height: img.height,
      isThumbnail: img.isThumbnail,
    });
    si['file'] = img.file;
    return si;
  }


  /** Resize the image blob, then wrap it in a AppImage with the new size information and optional new name. */
  private static async resizeImageFile(file: File, maxSize: Size, newName?: string): Promise<AppImage> {
    const bs = await this.resizeImageBlob(file, maxSize);
    const name = newName || file.name;
    const file2 = new File([bs.blob], name, { type: bs.blob.type });
    return { file: file2, fileName: name, mimeType: file.type,
      width: bs.size.width, height: bs.size.height, isThumbnail: this.isThumbnailSize(bs.size), isPrimary: false };
  }

  /** Resize the image blob to the given size */
  private static async resizeImageBlob(blob: Blob, maxSize: Size): Promise<{ blob: Blob, size: Size }> {
    if (!this.canvas) {
      this.canvas = document.createElement('canvas');
    }
    const canvas = this.canvas;

    const img = await createImageBitmap(blob);

    let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.drawImage(img, 0, 0);

    const size = this.getNewSize(img, maxSize);

    canvas.width = size.width;
    canvas.height = size.height;
    ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.drawImage(img, 0, 0, size.width, size.height);

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve({ blob: blob as Blob, size });
      }, 'image/jpeg', 0.8);
    });
  }

  /** Get new size of image, smaller than max.width and max.height */
  private static getNewSize(img: ImageBitmap, max: Size): Size {
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > max.width) {
        height *= max.width / width;
        width = max.width;
      }
    } else {
      if (height > max.height) {
        width *= max.height / height;
        height = max.height;
      }
    }

    return { width: Math.round(width), height: Math.round(height) };
  }

  /** Return true if size is small enough to be a thumbnail */
  private static isThumbnailSize(size: Size): boolean {
    return size.width <= thumbnailSize.width && size.height <= thumbnailSize.height;
  }

}