<app-uni-dialog [parent]="this" [ignoreBusy]=true [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <table class="ui-datatable-data ui-widget-content" style="width: 100%">
    <tr *ngFor="let entityError of data.entityErrors" class="ui-widget-content">
      <td width="33%" *ngIf="entityError.entity"><b class="mr-3">{{entityError.entity.getEntityDisplayName()}}</b></td>
      <td><span>{{entityError.errorMessage}}</span></td>
    </tr>
  </table>
</ng-template>

<ng-template #footerTemplate>
  <button class="btn btn-primary" (click)="close()">Close</button>
</ng-template>
