export enum JoStatusCode {
  // NewUnposted = '1000.00',
  // ReturnClearancePending = '1000.10',
  // ReturnCleared = '1000.15',
  // AwaitingApproval = '1100.00',
  // AwaitingPurchaseOrder = '1200.00',
  // PostedUnprinted = '1500.00',
  OpenProcessIncomplete = '2000.00',
  // ReadyToProcess = '2000.10',
  OpenProcessComplete = '2100.00',
  Closed = '9000.00',
  // ApprovalDenied = '9100.00',
}

enum JoStatusAll  {
  All = '0000',
}



export type JobStatusCodeExt = JoStatusCode | JoStatusAll;
export const JobOrderMode = { ...JoStatusCode, ...JoStatusAll };

