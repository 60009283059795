<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>

  <div *ngIf="isPageReady">
    <div uni-flex-row>
      <mat-form-field style="width: 400px;">
        <input matInput placeholder="Manufacturer" [ngModel]="productType.manufacturer.name" readonly="readonly" />
      </mat-form-field>
      <mat-form-field style="width: 400px;" [matTooltip]="productType.style">
        <input matInput placeholder="Style" [(ngModel)]="productType.style" readonly="readonly" />
      </mat-form-field>

      <mat-form-field style="width: 200px;" [matTooltip]="product.activeStatus.name">
        <mat-select [disabled]="!this.user.isProductAdmin" [(ngModel)]="product.activeStatusId" placeholder="Status">
          <mat-option [value]="activeStatus.id" *ngFor="let activeStatus of activeStatuses">
            {{activeStatus.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngFor="let pfc of this.product.productFeatureChoices; let i = index;">
      <div uni-flex-row>
        
        <mat-form-field  style="width: 400px;"
          [matTooltip]="pfc.featureChoice.feature.name">
          <input matInput placeholder="Feature" [ngModel]="pfc.featureChoice.feature.name" readonly="readonly" />
        </mat-form-field>

        <mat-form-field *ngIf="!isAdding" style="width: 400px;"
          [matTooltip]="pfc.featureChoice.choiceValue">
          <input matInput placeholder="Value" [(ngModel)]="pfc.featureChoice.choiceValue" readonly="readonly" />
        </mat-form-field>
        
        <mat-form-field *ngIf="isAdding" style="width: 400px;">
          <mat-select [(ngModel)]="pfc.featureChoice" placeholder="Feature Choice">
            <mat-option [value]="featureChoice" *ngFor="let featureChoice of pfc.featureChoice.feature.featureChoices">
              <span>{{featureChoice.choiceValue}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        
      </div>
    </ng-container>
  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>