/**
 * ShipEngine API
 * ShipEngine is the world's best multi-carrier shipping API, with more than 20 supported shipping carriers.  You can easily get and compare shipping rates, print labels, track your shipments, and validate addresses anywhere in the world.  ### Worldwide Major Carrier Support Access the top global carriers in minutes—instead of weeks. Simply [connect your existing carrier accounts](https://docs.shipengine.com/docs/setup-a-carrier) in the API dashboard, and then [begin creating labels](https://docs.shipengine.com/docs/use-a-specific-service).  ### Real-Time Tracking [Track packages](https://docs.shipengine.com/docs/track-a-package) across any of our 20+ supported carrier accounts and create tracking events to keep your customers up-to-date. Easily integrate real-time tracking information for shipments into your app, email, or SMS.  ### Get Rates Across Carriers Make sure you ship as cost-effectively as possible by [quickly comparing rates](https://docs.shipengine.com/docs/get-shipping-rates) using the ShipEngine Rates API. As long as you have the carrier connected to your account, you'll be able to see and compare different rates and services.  ### Global Address Validation No matter your shipping volume, failed deliveries and address change surcharges cut into your bottom line and damage perception with customers. Our address validation services ensure your packages make it to the right place the first time. [Learn how to leverage our address validation services here.](https://docs.shipengine.com/docs/address-validation)  ShipEngine supports address validation for over 180 countries, including the United States, Canada, Great Britain, Australia, Germany, France, Norway, Spain, Sweden, Israel, Italy, and more. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: sales@shipengine.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Any residential or business mailing address, anywhere in the world.  > **Note:** Either `name` or `company_name` must be set. Both may be specified, if relevant. 
 */
export interface Address { 
    /**
     * The name of a contact person at this address.  This field may be set instead of - or in addition to - the `company_name` field. 
     */
    name?: string;
    /**
     * The phone number of a contact person at this address.  The format of this phone number varies depending on the country. 
     */
    phone?: string;
    /**
     * If this is a business address, then the company name should be specified here. 
     */
    company_name?: string;
    /**
     * The first line of the street address.  For some addresses, this may be the only line.  Other addresses may require 2 or 3 lines. 
     */
    address_line1: string;
    /**
     * The second line of the street address.  For some addresses, this line may not be needed. 
     */
    address_line2?: string;
    /**
     * The third line of the street address.  For some addresses, this line may not be needed. 
     */
    address_line3?: string;
    /**
     * The name of the city or locality
     */
    city_locality: string;
    /**
     * The state or province.  For some countries (including the U.S.) only abbreviations are allowed.  Other countries allow the full name or abbreviation. 
     */
    state_province: string;
    /**
     * postal code
     */
    postal_code: string;
    /**
     * The two-letter [ISO 3166-1 country code](https://en.wikipedia.org/wiki/ISO_3166-1) 
     */
    country_code: string;
    /**
     * Indicates whether this is a residential address.
     */
    address_residential_indicator: Address.AddressResidentialIndicatorEnum;
}
export namespace Address {
    export type AddressResidentialIndicatorEnum = 'unknown' | 'yes' | 'no';
    export const AddressResidentialIndicatorEnum = {
        Unknown: 'unknown' as AddressResidentialIndicatorEnum,
        Yes: 'yes' as AddressResidentialIndicatorEnum,
        No: 'no' as AddressResidentialIndicatorEnum
    };
}
