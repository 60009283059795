<app-uni-dialog title="Return Information" [parent]="this" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div uni-flex-row>
    <mat-form-field style="width: 400px;">
      <input matInput placeholder="Manufacturer" [ngModel]="product.productType.manufacturer.name" readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 400px;" [matTooltip]="product.productType.description">
      <input matInput placeholder="Style" [ngModel]="product.productType.description" readonly="readonly" />
    </mat-form-field>
  </div>

  <div uni-flex-row>
    <mat-form-field style="width: 400px;" [matTooltip]="product.productType.style">
      <input matInput placeholder="Style" [ngModel]="product.productType.style" readonly="readonly" />
    </mat-form-field>
    <ng-container *ngFor="let pfc of this.product.productFeatureChoices; let i = index;">
      <mat-form-field style="width: 400px;">
        <input matInput placeholder="Size" [ngModel]="pfc.featureChoice.choiceValue" readonly="readonly" />
      </mat-form-field>
    </ng-container>
  </div>


  <div uni-flex-row>
    
    <mat-form-field style="width: 190px;" >
      <input matInput placeholder="Quantity" [ngModel]="rrd.qty" readonly="readonly" />
    </mat-form-field>
    
    <mat-form-field style="width: 300px;" [matTooltip]="rrd.returnReasonType.name" >
      <input matInput placeholder="Return Type" [ngModel]="rrd.returnReasonType.name" readonly="readonly" />
    </mat-form-field>

    <mat-form-field *ngIf="rrd.returnCreditType" style="width: 300px;" [matTooltip]="rrd.returnCreditType?.name" >
      <input matInput placeholder="Credit Type" [ngModel]="rrd.returnCreditType?.name" readonly="readonly" />
    </mat-form-field>
  </div>

  <div uni-flex-row>
    <mat-form-field style="width: 810px">
      <textarea matInput placeholder="Return Explanation" readonly="readonly" [(ngModel)]="rrd.returnExplanation"
        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"></textarea>
    </mat-form-field>
  </div>

  <div uni-flex-row>
    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select style="width: 300px;" [disabled]="!isEditable"
        
        [(ngModel)]="rrd.returnRequestDetailStatusId">
        <mat-option [value]="returnRequestDetailStatus.id"
          *ngFor="let returnRequestDetailStatus of getValidReturnRequestDetailStatuses()">{{returnRequestDetailStatus.name}}
        </mat-option>
      </mat-select>
      <mat-hint class="error-hint" *ngIf="getErrorMessage('returnRequestDetailStatusId')">
        {{ getErrorMessage('returnRequestDetailStatusId') }}
      </mat-hint>
    </mat-form-field>
  </div>


  <div uni-flex-row *ngIf="canShowCreditMemoInfo()" >
    <mat-form-field style="width: 400px;" >
      <input matInput currencyMask placeholder="Default Credit Amt/Unit" readonly="readonly" [ngModel]="rrd.defaultUnitCreditMemoAmt" />
    </mat-form-field>

    
    <mat-form-field style="width: 400px;" >
      <input matInput currencyMask placeholder="Actual Credit Amt/Unit" [disabled]="!canEditCreditMemoAmt()" [(ngModel)]="rrd.actualUnitCreditMemoAmt" />
      <mat-hint class="error-hint" *ngIf="getErrorMessage('creditMemoAmt')">
        {{ getErrorMessage('creditMemoAmt') }}
      </mat-hint>
    </mat-form-field>
    
  </div>

  <div uni-flex-row>
    <mat-form-field style="width: 810px">
      <textarea matInput placeholder="Return Status Explanation" [disabled]="!isEditable" [(ngModel)]="rrd.returnStatusExplanation"
        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"></textarea>
    </mat-form-field>
  </div>

  <div uni-flex-row>
    <mat-form-field style="width: 810px">
      <textarea matInput placeholder="Processing Notes" [disabled]="!isEditable" [(ngModel)]="rrd.processNotes"
        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"></textarea>
    </mat-form-field>
  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button [disabled]="!isEditable" (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
  
</ng-template>
