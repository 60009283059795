// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { InvoiceHeader } from './invoice-header';
import { JoDetail } from './jo-detail';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class InvoiceDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  invoiceHeaderId: string;
  joDetailId: number;
  redistributeQty: number;
  rowVersion: any;
  shipQty: number;
  invoiceHeader: InvoiceHeader;
  joDetail: JoDetail;
}

