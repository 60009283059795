import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/shared/auth.service';
import { UtilFns } from 'app/shared/util-fns';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseUtilService } from 'app/shared/fuse.service';

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: UntypedFormGroup;
    error = '';
    success = '';
    working = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _formBuilder: UntypedFormBuilder,
        private _authService: AuthService,
        private _fuseUtilService: FuseUtilService
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required]], // , Validators.email]],
            password: ['', Validators.required],
            persist  : [false]
        });
    }

  onSubmit() {
    this.error = '';
    const { email, password, persist } = this.loginForm.value;
    if (!this.working) {
      this.working = true;
      this._authService.login(email, password, persist).then(_ => {
        this.error = null;
        // const user = this._authService.getUser();
        this.success = 'Logged in';
        this.working = false;

        this._fuseUtilService.setupNavigation();
        // add admin-specific menu item
        // const user = this._authService.getUser();
        // if (user.isAdmin) {
        //   const item = this._fuseNavigationService.getNavigationItem('userAccounts');
        //   if (item) {
        //     return;
        //   }

        //   const userAccountsNavItem = {
        //       id: 'userAccounts',
        //       title: 'User Accounts',
        //       type: 'item',
        //       icon: 'supervisor_account',
        //       url: '/user-accounts',
        //   };
        //   // customFunctionNavItem.children.push(userAccountsNavItem as any);
        //   this._fuseNavigationService.addNavigationItem(userAccountsNavItem, 'end');
        // } else {
        //   this._fuseNavigationService.removeNavigationItem('userAccounts');
        // }


      }, error => {
        if (error.status === 0) {
          this.error = 'Unable to contact server';
        } else if (error.status === 401) {
          this.error = error.error || 'Invalid login name or password';
        } else {
        //   this._logger.log(error, 'error');
          this.error = UtilFns.getErrorMessage(error);
        }

        this.working = false;

      });
    }
  }

}
