<app-uni-page [parent]="this" title="Inventory Maintenance" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>


  <div *ngIf="isPageReady" class="content-card" style="flex-grow: 4">

    <div class="h3 ml-12 mt-12" style="height: 70px;">
      <span class="mr-12">
        Inventory for ProductType:
      </span>
      <mat-form-field class="ml-12 mt-6" style="width: 350px;">
        <mat-select [ngModel]="selectedManuf" (ngModelChange)="onManufSelected($event)" placeholder="Manufacturer">
          <mat-option>
            <ngx-mat-select-search ngModel (ngModelChange)="searchManuf($event)" placeholder="Manufacturer"
              [placeholderLabel]="'Find manufacturer...'" [noEntriesFoundLabel]="'no matching manufacturer found'">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let manuf of searchManufs" [value]="manuf">
            {{manuf.name}} {{ manuf.activeStatus.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field  class="ml-12" style="width: 300px;">
        <mat-select [disabled]="manufProductTypes == null || manufProductTypes.length == 0" [ngModel]="selectedProductType" (ngModelChange)="onProductTypeSelected($event)" placeholder="ProductType">
          <mat-option>
            <ngx-mat-select-search ngModel (ngModelChange)="searchProductType($event)" placeholder="ProductType"
              [placeholderLabel]="'Find ProductType...'" [noEntriesFoundLabel]="'no matching itemstyle found'">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let productType of searchProductTypes" [value]="productType">
            {{productType.style}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field  class="ml-12" style="width: 200px;">
        <mat-select  [ngModel]="allowedStatusName" (ngModelChange)="onAllowedStatusNameChange($event)" placeholder="Filter">
          <mat-option *ngFor="let name of allowedStatusNames" [value]="name">
            {{ name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <app-ag-grid style="height: calc(100%-90px);" [gridOptions]="itdGridOptions" [rowData]="itemDetails"></app-ag-grid>
    <ng-template #selectItemDetailCell let-row>
      <button (click)="selectItemDetail(row)">Mark as scanned</button>
    </ng-template>
  </div>

  <div *ngIf="isPageReady" class="content-card theme-default" style="flex-grow: 1">
    <div class="h3 ml-12 mb-6 mt-12">Find/Scan Inventory</div>
    
    <div uni-flex-row class="mt-4">
      <mat-form-field matTooltip="Scan/Edit Item Detail Id" class="ml-12">
        <input matInput placeholder="Item Detail Id" [(ngModel)]="itemDetailId" name="itemDetailId" autocomplete="off"
          maxlength="12" size="12" (input)="onInputFilled($event, processItemDetailId)">
      </mat-form-field>
    </div>
    <span *ngIf="errorMessage?.length" class="content-card">
      <h3 class="ml-12">{{ errorMessage }}</h3>
    </span>
    
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <!-- <button *ngIf="canMoveToMissing()" mat-raised-button 
      (click)="moveInventoryToMissing()">Move Unscanned Inventory to Missing
    </button>  -->
  </div>
  <div>
    <button mat-raised-button (click)="goAddInventory()">
      <span>Add Inventory</span>
    </button>

    <button  mat-raised-button (click)="goInventoryEoq()">
      <span>EOQ Calculator</span>
    </button>
    <!-- <button  mat-raised-button (click)="sendSupplierDataToProximity()">
      <span>Update Proximity</span>
    </button> -->
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>
