// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ActiveStatus } from './active-status';
import { EOQType } from './eoq-type';
import { Manufacturer } from './manufacturer';
import { Product } from './product';
import { ProductTypeAlt } from './product-type-alt';
import { ProductTypeFeatureMap } from './product-type-feature-map';
import { ProductTypeImage } from './product-type-image';
import { ProductTypeTagMap } from './product-type-tag-map';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotation';
import { Validator } from 'breeze-client';
/// </code-import>

export class ProductType extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    
    const propAnnotations = [
      
    ];

    return new EntityTypeAnnotation({
      validators: [ ],
      propertyAnnotations: propAnnotations
    });
  }

  getFeatureNamesExtract() {
    return this.productTypeFeatureMaps.map(ptf => ptf.feature.name).join(';');
  }

  get featureNamesExtract() {
    return this.getFeatureNamesExtract();
  }

  get name() {
    if (this.manufacturer) {
      return this.manufacturer.name + ' / ' + this.description + ' / ' + this.style;
    } else {
      return 'not yet available';
    }
  }

  get productTypeAlts() {
    return this.productTypeAltProductTypes;
  }

  get safetyStockPerYearPctFormatted() {
    return this.safetyStockPerYearPct * 100;
  }
  set safetyStockPerYearPctFormatted(value) {
    this.safetyStockPerYearPct = value / 100;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  activeStatusId: number;
  allowReorderFeatureChoices: boolean;
  description: string;
  descriptionExt: string;
  eOQModTs: Date;
  eOQTypeId: string;
  leadTimeDays: number;
  manufacturerId: number;
  modTs: Date;
  modUserInit: string;
  orderingCostAmt: number;
  rowVersion: any;
  safetyStockPerYearPct: number;
  style: string;
  unitCarryingCostPerYearAmt: number;
  activeStatus: ActiveStatus;
  eOQType: EOQType;
  manufacturer: Manufacturer;
  productTypeAltAltProductTypes: ProductTypeAlt[];
  productTypeAltProductTypes: ProductTypeAlt[];
  productTypeFeatureMaps: ProductTypeFeatureMap[];
  productTypeImages: ProductTypeImage[];
  productTypeTagMaps: ProductTypeTagMap[];
  products: Product[];
}

