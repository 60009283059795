import { ColDef, GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { InvoiceDetail, InvoiceHeader, InvoiceStatus, JoDetail, JoHeader } from 'app/model/entities/entity-model';
import { AgFns, GridState } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { NavFns } from 'app/shared/nav-fns';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { environment } from 'environments/environment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class InvoicesComponent extends DomainBaseComponent {

  invhGridOptions: GridOptions;
  invoiceHeaders: InvoiceHeader[];
  selectedInvoiceHeader: InvoiceHeader;

  invdGridOptions: GridOptions;
  invoiceDetails: InvoiceDetail[];
  
  
  constructor(
    public uow: UnitOfWork,
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    private http: HttpClient,
    private matDialog: MatDialog,
    
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  canDeactivate() {
    this.uow.clearEntities(InvoiceHeader);
    this.uow.clearEntities(InvoiceDetail);
    this.uow.clearEntities(JoHeader);
    this.uow.clearEntities(JoDetail);
    return true;
  }

  async updateFromContext() {

    this.invhGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onInvhGridReady,
      onRowSelected: this.onInvhRowSelected,
      onFilterChanged: this.onFilterChanged,
      rowModelType: 'serverSide',
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.clearCurrentSelection();
        }
      }
    });
    AgFns.captureGridRouteParams(this.invhGridOptions, this.route, 'id');
   
    this.invdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onInvdGridReady,
    });

    this.isPageReady = true;
  }

  onInvhGridReady(evt: any) {
    const colDefs = [
      // { headerName: 'Invoice', field: 'id', filter: 'agTextColumnFilter' },
      { headerName: 'Invoice', field: 'id', filter: 'agTextColumnFilter', ...NavFns.createCellClickedCalcNavProps(this.router,
        (event) => { 
          const invh = <InvoiceHeader> event.data;
          return { navPath: `/jo-invoice/${ invh.joHeaderId }/${ invh.id }` }; 
        })
      },
      { headerName: 'Date', field: 'invoiceDate', filter: 'agDateColumnFilter' },
      { ...AgFns.createSetFilterObjectProps('Status', 'invoiceStatus.name', this.dbQueryService.getAllCached(InvoiceStatus)) },
      { ...NavFns.createIdCellClickedNavProps('joHeaderId', this.router, '/job-orders') },
      { ...AgFns.createButtonProps('', this.sendToProximity.bind(this), { label: 'Send to Proximity'} ) },
      { headerName: 'Prox Status', field: 'shipment.proxSoloSubmissionStatus.name',  },
    ];

    const sortModel = [{ colId: 'invoiceDate', sort: 'desc' as const}];
    
    AgFns.initGrid(this.invhGridOptions, colDefs, sortModel);
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.invhGridOptions);
  }

  onInvdGridReady(evt: any) {
    const colDefs: ColDef[] = [
      { headerName: 'Invoice Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'JoDetail Id', field: 'joDetailId', type: 'numericColumn' },
      { headerName: 'Manufacturer', field: 'joDetail.product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'joDetail.product.productType.description' },
      { headerName: 'Style', field: 'joDetail.product.productType.style' },
      { headerName: 'Features', field: 'joDetail.product.featureChoicesExtract' },
      { headerName: 'New Qty', field: 'shipQty' },
      { headerName: 'Price/Unit', field: 'joDetail.unitPriceAmt' },
      { headerName: 'Oversize Amt/Unit', field: 'joDetail.unitOversizeAmt' },
      { headerName: 'Custom Amt/Unit', field: 'joDetail.unitCustomAmt' },
      { headerName: 'Mods Amt/Unit', field: 'joDetail.unitModsAmt' },
      { headerName: 'Total Amt/Unit', field: 'joDetail.unitTotalAmt' },
      {
        headerName: 'Extended Amt',
        valueGetter: params => {
          return params.data.joDetail.unitTotalAmt * params.data.shipQty;
        },
        valueFormatter: params => {
          return this.UtilFns.fmtCurrency(params.value);
        },
      },
      { headerName: 'Redist Qty', field: 'redistributeQty' },
      { headerName: 'Reissue Amt/Unit', field: 'joDetail.unitReissueAmt' },
      {
        headerName: 'Extended Reissue Amt',
        valueGetter: params => {
          return (params.data.redistributeQty || 0) * params.data.joDetail.unitReissueAmt || 0;
        },
        valueFormatter: params => {
          return this.UtilFns.fmtCurrency(params.value);
        },
      },
    ];
    // Add back later if we want to see mods for invd line items.
    // this.updateInvdMasterDetail(this.invdGridOptions);
    AgFns.initGrid(this.invdGridOptions, colDefs, null, true);
  }

  

  async updateDatasource() {
    const gridApi = this.invhGridOptions?.api;
    if (gridApi == null) {
      return;
    }
    const ds = AgFns.buildDatasource(() => this.dbQueryService.createInvoiceQuery());

    gridApi.setServerSideDatasource(ds);

    
  }

  async onInvhRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const invh = e.data as InvoiceHeader;
    if (!invh) {
      return;
    }
    this.selectedInvoiceHeader = invh;
    this.updateLocation(invh.id);
    this.invoiceDetails = invh.invoiceDetails;
  }
 
  
  onFilterChanged() {
    this.clearCurrentSelection();
  }

  clearCurrentSelection() {
    this.updateLocation();
    if (this.selectedInvoiceHeader === null) {
      return;
    }
    this.selectedInvoiceHeader = null;
    this.invoiceDetails = [];

    this.invhGridOptions.api?.deselectAll();
  }

  
  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/invoices']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.invhGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }
  
  async sendToProximity(invh: InvoiceHeader) {
    const shipmentId = invh.shipmentId;
    if (invh.shipmentId == null) {
      await this.sendInvoiceToProximity(invh.id);
    } else {
      await this.sendShipmentToProximity(invh.shipmentId);
    }
  }

  async sendInvoiceToProximity(invoiceHeaderId: string) {
    const submission = await this.dbQueryService.getProxSoloSubmissionFor('InvoiceHeader', invoiceHeaderId);
    if (submission != null && submission.confirmationKey != null) {
      const ynR = await this.dialogService.askYesNo('Already sent', 'Do you want to overwrite what is on Proximity');
      if (ynR.index == 1) return;
    }

    try {
      const r = await this.dbSaveService.sendProximityInvoice(invoiceHeaderId);
      if (r.HttpStatusCode == HttpStatusCode.Ok) {
        this.dialogService.toast("Invoice submitted ok");
      } else {
        await this.dialogService.showOkMessage("Invoice submission failed", r.ErrorMessage);
      }

      // TODO: this doesn't do anything...
      // this.invdGridOptions.api.refreshServerSide();
      // this.invdGridOptions.api.refreshCells({force: true })
      // Had to do this instead...
      this.updateDatasource();
      return r;
    } catch (e) {
      var x = e;
    }
  }

  async sendShipmentToProximity(shipmentId: number) {
    const submission = await this.dbQueryService.getProxSoloSubmissionFor('Shipment', shipmentId.toString());
    if (submission != null && submission.confirmationKey != null) {
      const ynR = await this.dialogService.askYesNo('Already sent', 'Do you want to overwrite what is on Proximity');
      if (ynR.index == 1) return;
    }

    try {
      const r = await this.dbSaveService.sendProximityShipment(shipmentId);
      if (r.HttpStatusCode == HttpStatusCode.Ok) {
        this.dialogService.toast("Shipment submitted ok");
      } else {
        await this.dialogService.showOkMessage("Shipment submission failed", r.ErrorMessage);
      }

      // TODO: this doesn't do anything...
      // this.invdGridOptions.api.refreshServerSide();
      // this.invdGridOptions.api.refreshCells({force: true })
      // Had to do this instead...
      this.updateDatasource();
      return r;
    } catch (e) {
      var x = e;
    }
  }

  goShipping() {
   
    this.router.navigate(['/jo-ship']);
  }

  

}
