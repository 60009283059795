<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary='.cdk-overlay-container' cdkDragBoundary='.cdk-overlay-container' cdkDragHandle >
  {{ title ?? parent?.title ?? parent?.data?.title }}
</h2>

<!-- Title is 52px + Actions is 44px = 96px -->
<mat-dialog-content class="standard-page" style="height: calc(100% - 96px);" *ngIf="parent.isPageReady ?? true"  >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</mat-dialog-content>
  
<mat-dialog-actions *ngIf="parent.isPageReady ?? true">
  <ng-container *ngIf="ignoreBusy; else handleBusy" >
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-container>
  <ng-template #handleBusy >
    <mat-spinner class="ml-12" diameter="35" *ngIf="busyService.isBusy | async; else notBusy"></mat-spinner>
    <ng-template #notBusy>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </ng-template>    
  </ng-template>
</mat-dialog-actions>

<!-- <ng-template #defaultContent>
  <div>Default Content</div>
</ng-template>
<ng-template #defaultFooter>
  <div>Default Footer</div>
</ng-template> -->