import { GetRowIdParams, GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { JoDetail } from 'app/model/entities/jo-detail';
import { BarcodePrintStatusCode } from 'app/model/enums/barcode-print-status-code';
import { ReturnRequestDetailStatusCode } from 'app/model/enums/return-req-auth-detail-status-code';
import { ReturnRequestStatusCode } from 'app/model/enums/return-req-auth-status-code';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { NavFns } from 'app/shared/nav-fns';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { InvoiceDetail, JoHeader, ReturnCreditType, ReturnReasonType, ReturnRequest, ReturnRequestDetail } from '../model/entities/entity-model';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { ReturnRequestDetailDialogComponent } from './return-request-detail-dialog.component';
import { BarcodeService } from 'app/shared/barcode.service';
import { OneFieldDialogComponent } from 'app/shared/one-field-dialog.component';
import { ToastTypes } from 'app/shared/toast.service';
import { EntityFns } from 'app/shared/entity-fns';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-jo-return-request',
  templateUrl: './jo-return-request.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class JoReturnRequestComponent extends DomainBaseComponent implements OnInit {
  initReturnRequestId: number;

  rrGridOptions: GridOptions;
  selectedReturnRequest: ReturnRequest; 

  rrdGridOptions: GridOptions;
  returnRequestDetails: ReturnRequestDetail[] = [];

  constructor(protected domainService: DomainService, protected barcodeService: BarcodeService,
    protected route: ActivatedRoute, protected matDialog: MatDialog, private location: Location) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {

    this.rrGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onRrGridReady,
      onRowSelected: this.onRrRowSelected,
      onFilterChanged: this.onRraFilterChanged,
      rowModelType: 'serverSide',
      
    })
    AgFns.captureGridRouteParams(this.rrGridOptions, this.route, 'id');

    // TODO: check this.
    const gridState = AgFns.getGridState(this.rrGridOptions);
    if (gridState && gridState.fm == null) {
      const returnRequestId = this.route.snapshot.queryParams['returnRequestId'];
      if (returnRequestId != null) {
        gridState.fm = AgFns.addFilterClause({}, 'id', 'number', returnRequestId);
      }
    }
    
    this.rrdGridOptions = AgFns.initGridOptions( this, {
      onGridReady: this.onRrdGridReady,
      getRowId: (params: GetRowIdParams) =>  params.data?.id.toString(),
    });

    await this.dbQueryService.getAll(ReturnCreditType);
    await this.dbQueryService.getAll(ReturnReasonType);
    // await this.dbQueryService.getAll(ReturnRequestDetailStatus);

    this.isPageReady = true;
  }

  canDeactivate() {
    this.uow.clearEntities(ReturnRequestDetail);
    this.uow.clearEntities(ReturnRequest);
    this.uow.clearEntities(JoDetail);
    this.uow.clearEntities(JoHeader);
    this.uow.clearEntities(InvoiceDetail);
    return true;
  }

  onRrGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Job Order', 
        ...NavFns.createIdCellClickedNavProps('joHeaderId', this.router, '/job-orders') },
      { headerName: 'Account', field: 'joHeader.account.accountName', filter: 'agTextColumnFilter' },
      // { ...AgFns.createSetFilterObjectProps('JO Status', 'joHeader.joStatus.name', this.dbQueryService.getAllCached(JoStatus)) },
      { headerName: 'JO Date', field: 'joHeader.joDate', filter: 'agDateColumnFilter' },
      { headerName: 'Received', field: 'receivedTs', filter: 'agDateColumnFilter' },
      { headerName: 'Return Req. Status', field: 'returnRequestStatus.name',  filter: 'agTextColumnFilter' },
      { headerName: 'Return Address', field: 'returnAddress1',  filter: 'agTextColumnFilter'}  ,
      { headerName: 'Return City', field: 'returnCity',  filter: 'agTextColumnFilter'}  ,
      { headerName: 'Return State', field: 'returnState'}  ,
      { headerName: 'Return Zip', field: 'returnZipCode'}  ,
      { headerName: 'Return Request Id', field: 'id', filter: 'agNumberColumnFilter' }
        
    ];
    const sortModel = [
        { colId: 'recievedTs', sort: 'asc' },
        { colId: 'joHeader.account.accountName', sort: 'asc' },
      ] as ISortModel;
    
    AgFns.initGrid(this.rrGridOptions, colDefs, sortModel);

    this.updateDatasource();
    AgFns.applyGridRouteParams(this.rrGridOptions);
  }

  updateDatasource() {
    const gridApi = this.rrGridOptions.api;
    if (gridApi == null) {
      return;
    }
    const ds = AgFns.buildDatasource(() => this.dbQueryService.createReturnRequestQuery());
    gridApi.setServerSideDatasource(ds);
  }

  onRrdGridReady(evt: any) {
    const colDefs = [
      { ...AgFns.createButtonProps('Edit', this.editReturn.bind(this), { 
          calcLabel: (x: ReturnRequestDetail) => x.returnRequestDetailStatus.isFinalState ? 'View' : 'Edit',
          fixWidth: 100 })}, 
      { ...AgFns.createButtonProps('Split', this.split.bind(this), {  
          canDisplay: (x: ReturnRequestDetail) => x.qty > 1 && !x.returnRequestDetailStatus.isFinalState,
          fixWidth: 100,
        })
      }, 
      { headerName: 'Manufacturer', field: 'joDetail.product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'joDetail.product.productType.description' },
      { headerName: 'Style', field: 'joDetail.product.productType.style' },
      { headerName: 'Features', field: 'joDetail.product.featureChoicesExtract' },
      { headerName: 'Qty', field: 'qty' },
      { headerName: 'Status', field: 'returnRequestDetailStatus.name' },
      { headerName: 'Return Type', field: 'returnReasonType.name' },
      { headerName: 'Return Credit Type', field: 'returnCreditType.name' },
      { headerName: 'Return Explanation', field: 'returnExplanation' },
      { headerName: 'Credit Amt/Unit (default)', field: 'defaultUnitCreditMemoAmt' },
      { headerName: 'Actual Amt/Unit (default)', field: 'actualUnitCreditMemoAmt' },
                                                         
      
      { headerName: 'Invoice Ids',  
        valueGetter: params => {
          const rrd = <ReturnRequestDetail>params.data;
          const idList = _.uniq(rrd.joDetail.invoiceDetails.map(invd => invd.invoiceHeaderId)).sort().join(', ');
          return idList;
        },
      },
      
      // { headerName: 'Return Request Detail Id', field: 'id',  },
    ];
    const sortFields = [
      'joDetail.product.productType.manufacturer.name',
      'joDetail.product.productType.description',
      'joDetail.product.productType.style',
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });
    
    
    AgFns.initGrid(this.rrdGridOptions, colDefs, sortModel, true);
  }

  
  
  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/jo-return-req-auth']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.rrGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  async onRrRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const rr = e.data as ReturnRequest;
    if (!rr) {
      return;
    }
    this.selectedReturnRequest = rr;
    // locate rows by joHeaderId
    this.updateLocation(rr.id);

    await this.AgFns.busyGrid(this.rrdGridOptions, this.busyService, async () => {
      this.returnRequestDetails = await this.dbQueryService.getReturnRequestDetails(rr.id);
      AgFns.refreshGrid(this.rrdGridOptions, this.returnRequestDetails);
      
    });
  }

  async onRraFilterChanged() {
    this.updateLocation();
    // this.returnRequestDetails = [];
  }

  isRraInProcess() {
    return this.selectedReturnRequest.returnRequestStatusId === ReturnRequestStatusCode.InProcess;
  }

  async editReturn(rrad: ReturnRequestDetail) {
    await ReturnRequestDetailDialogComponent.show(this.matDialog, {
      returnRequestDetail: rrad,
    });
    
    AgFns.refreshGrid(this.rrdGridOptions, this.returnRequestDetails);
  }

  async split(rrad: ReturnRequestDetail) {
    const qty = await this.askSplitQty(rrad);
    if (qty == null) return;
    const clone = <ReturnRequestDetail>EntityFns.cloneStruct(rrad, ['id', 'qty']);
    const newRrad = this.dbSaveService.createEntity(ReturnRequestDetail, clone);
    newRrad.qty = qty;
    newRrad.proximityReturnRequestDetailId = Guid.create().toString();
    newRrad.proximityOrigReturnRequestDetailId = rrad.proximityOrigReturnRequestDetailId ?? rrad.proximityReturnRequestDetailId;
    rrad.qty = rrad.qty - qty;
    await this.dbSaveService.saveChanges();
    this.returnRequestDetails.push(newRrad);
    AgFns.refreshGrid(this.rrdGridOptions, this.returnRequestDetails);
    
  } 

  async askSplitQty(rrad: ReturnRequestDetail) {
    while (true) {
      const r = await OneFieldDialogComponent.show(this.matDialog, {
        title: `Enter quantity to split off`,
        fieldName: 'Quantity ',
        fieldType: 'integer'
      });
      if (r.index==1) {
        return null;
      }
      if (r.value > 0 && r.value < rrad.qty)  {
        return r.value;
      } 
      this.dialogService.toast({ message: `Value must be between 0 and ${rrad.qty} exclusive.`, type: ToastTypes.Error} );
    }
  }

  canPrintRepairBarcodes(rrad: ReturnRequestDetail) {
    return rrad.returnRequestDetailStatusId == ReturnRequestDetailStatusCode.ReceivedRepairPending;
  }

  async printRepairBarcodes(rrad: ReturnRequestDetail) {
    // print special bar code - just info about Item and Repair request
    await this.barcodeService.printRepairBarcode(rrad);
  }

  canPrintBarcodes(rrad: ReturnRequestDetail) {
    return rrad.returnedItemDetails?.length > 0 && (rrad.returnRequestDetailStatusId == ReturnRequestDetailStatusCode.ReceivedCreditApplied
      || rrad.returnRequestDetailStatusId == ReturnRequestDetailStatusCode.ReceivedAccountOwned);
  }

  async printBarcodes(rrad: ReturnRequestDetail) {
    const itemDetails = rrad.returnedItemDetails;
    if (itemDetails.length == 0) {
      return;
    }
    const printed = await this.barcodeService.printItemDetailBarcodes(itemDetails);
    if (printed) {
      const ynResult = await this.dialogService.askYesNo('Barcode Printing', `Did ${itemDetails.length} barcodes print correctly?`);
      if (ynResult.index === 0) {
        itemDetails.forEach(itd => itd.barcodePrintStatusId = BarcodePrintStatusCode.Stock);
        return true;
      }
    }
  }

  
}
