<app-uni-dialog title="Modify Purchase Order Detail: {{ poDetail.id }}" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <app-ag-grid style="height: 64px;"  [gridOptions]="podGridOptions" [rowData]="poDetails" [hasFooter]="false">  </app-ag-grid>

  
  <div uni-flex-row class="mt-4 mb-4">
    <div>Apply To: </div>
    <mat-radio-group aria-div="Select an option" [(ngModel)]="applyTo" (ngModelChange)="applyToChanged($event)" >
      <mat-radio-button  *ngIf="poDetail.getRemainingQty() > 0" class="ml-8" value="default">Default</mat-radio-button>
      <mat-radio-button  *ngIf="poDetail.getRemainingQty() > 0" class="ml-12" value="stock">Stock</mat-radio-button>
      <mat-radio-button  *ngIf="poDetail.getRemainingQty() > 0" class="ml-12" value="jod">Job Order Detail</mat-radio-button>
      <mat-radio-button  *ngIf="poDetail.getRemainingQty() > 0" class="ml-12" value="cancel">Cancel Order</mat-radio-button>
      <mat-radio-button  *ngIf="poDetail.getRemainingQty() > 0" class="ml-12" value="hold">Hold Order</mat-radio-button>
      <mat-radio-button *ngIf="poDetail.heldQty>0" class="ml-12" value="unhold">Unhold Order</mat-radio-button>
    </mat-radio-group>
    <mat-form-field class="ml-20" matTooltip="Quantity to apply">
      <input matInput placeholder="Quantity to apply" [(ngModel)]="applyQty" name="applyQty" (ngModelChange)="applyQtyChanged($event)" maxlength="4" size="4">
    </mat-form-field>
  </div>

  <div [hidden]="applyTo != 'jod'" style="height: calc(100% - 166px)"  >
    <div class="mr-8 mb-8">Select alternative Job Order Detail - matching styles/alt styles shown below</div>
    <app-ag-grid [gridOptions]="jodGridOptions"  [rowData]="joDetails" ></app-ag-grid>
    <ng-template #altStyleCell let-row>
      <!-- <button [ngClass]="{'btn-has-data': hasAltStyle(row)}" (click)="showAltStyle(row)">Show</button> -->
      <button *ngIf="hasAltStyle(row)" (click)="showAltStyle(row)">Show</button>
    </ng-template>
  </div>
  
  <div *ngIf="poDetail.joDetailId != null && applyTo === 'cancel'"  style="height: calc(100% - 166px);" >
    <div class="mr-8 mb-8">Select replacement for cancelled items</div>
    <app-ag-grid  [gridOptions]="issGridOptions"  ></app-ag-grid>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <button [disabled]="applyQty == null || applyQty == 0"  mat-raised-button (click)="apply()">Apply</button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>
