import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  templateUrl: './global-error-dialog.component.html'
})
export class GlobalErrorDialogComponent {

  message: string;
  stack: string;
  location: string;
  title: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<GlobalErrorDialogComponent>) {
    const error = data.error ?? { message: 'n/a', stack: 'n/a' };
    data.buttons = data.buttons || ['Yes', 'No'];

    this.title = 'Unhandled error';
    this.location = window?.location?.href ?? '[location not available]';
    const messageText = (error.message as string ?? '').toLowerCase();
    // Exceptions thrown in promises coming from ASP.NET Core are buried pretty deep.
    var actualMessage = error.rejection?.error?.Message ?? error.message;

    try {
      if (error.query) {
        const queryStr = JSON.stringify(error.query.toJSON());
        actualMessage += ' -- Query: ' + queryStr;
      }
    } catch {
      actualMessage += ' -- Query construction error on ' + error.query.resourceName;
    };

    if (error instanceof HttpErrorResponse) {
      this.message = actualMessage + '\r\n' + error.url;
      this.stack = error.error?.stack ?? error.error;
    } else if (messageText.indexOf('optimistic concurrency') != -1) {
      this.handleOptimisticConcurrencyError(data);
    } else if (messageText.indexOf('uniformax api') != -1) {
      this.handleUniformaxApiError(error, data);
    } else {
      
      this.message = actualMessage;
      this.stack = error.stack;
    }

    dialogRef.disableClose = true;
  }

  private handleOptimisticConcurrencyError(data: any) {
    this.title = 'Optimistic concurrency Error';
    this.message = `The record(s) that you are working on have either been
      
      1) modified by another user or 
      2) modified by you in another window. 
      
The changes that you have made have not been saved.
      
You will need to refresh the page before continuing.`;
    data.buttons = ['Ok'];
    this.stack = '';
    this.location = '';
  }

  private handleUniformaxApiError(error: any, data: any) {
    this.title = 'Uniformax API Exception';
    // Exceptions thrown in promises coming from ASP.NET Core are buried pretty deep.
    const actualMessage = error.rejection?.error?.Message ?? error.message;
    this.message = actualMessage + `
      
The changes that you tried to post to Uniformax were not successful.
      
You will need to refresh the page before continuing.`;
    data.buttons = ['Ok'];
    this.stack = '';
    this.location = '';
  }

  select(index: number) {
    const result = {
      button: this.data.buttons[index],
      index: index
    };
    this.dialogRef.close(result);
  }
}
