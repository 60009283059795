// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { PoTrx } from './po-trx';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class PoTrxHist extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  poTrxId: number;
  poDetailId: number;
  crtnTs: Date;
  expectedDate: Date;
  heldQty: number;
  joDetailId: number;
  orderQty: number;
  poDetailTypeId: string;
  poHeaderId: number;
  prevPoTrxId: number;
  productId: number;
  poTrx: PoTrx;
}

