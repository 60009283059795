import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActiveStatus, FeatureChoice, Feature, ProductTypeTag, AddonStation,  } from 'app/model/entities/entity-model';
import { AuthService, AuthUser } from 'app/shared/auth.service';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { UtilFns } from 'app/shared/util-fns';
import { Entity } from 'breeze-client';



export interface GenericEntityDialogParams {
  target?: Entity & {id: any};
  targetTypeDescription: string;
  targetType: new() => Entity & {id: any}
  fieldNames: string[];
  fieldLabels: string[];
  altKeyFieldNames: string[];
  newEntityStruct?: object;
  beforeSaveFn?: (entity: Entity, isAdding: boolean) => void;

}

export const ProductTypeTagDialogParams: GenericEntityDialogParams = {
  targetTypeDescription: 'Product Type Tag',
  targetType: ProductTypeTag,
  fieldNames: ['name', ],
  fieldLabels: ['Name'],
  altKeyFieldNames: ["name" ]
}

export const AddonStationDialogParams: GenericEntityDialogParams = {
  targetTypeDescription: 'Addon Station',
  targetType: AddonStation,
  fieldNames: ['name', ],
  fieldLabels: ['Name'],
  altKeyFieldNames: ["name" ]
}

export const FeatureDialogParams = {
  targetTypeDescription: 'Feature',
  targetType: Feature,
  fieldNames: ['name'],
  fieldLabels: ['Name' ],
  altKeyFieldNames: ["name" ],
  beforeSaveFn: (entity: Entity, isAdding) => {
    if (!isAdding) return;
    const f = entity as Feature;
    f._origName = f.name;
  }
}

export const FeatureChoiceDialogParams = {
  targetTypeDescription: 'Feature Choice',
  targetType:  FeatureChoice,
  fieldNames: ['choiceValue'],
  fieldLabels: ['Value' ],
  altKeyFieldNames: ['choiceValue', 'featureId' ],
}

@Component({
  templateUrl: './edit-generic-entity-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class EditGenericEntityDialogComponent  {
  
  target?: Entity & { id: any};
  targetTypeDescription: string;
  targetType: new() => Entity & {id: any};
  fieldNames: string[];
  fieldLabels: string[];
  altKeyFieldNames: string[];
  newEntityStruct?: object;
  beforeSaveFn?: (entity: Entity, isAdding: boolean) => void;

  user: AuthUser;
  isAdding: boolean;
  isPageReady = false;
  title: string;

  activeStatuses: ActiveStatus[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: GenericEntityDialogParams, 
    public dialogRef: MatDialogRef<EditGenericEntityDialogComponent>,
    public dbQueryService: DbQueryService,public dbSaveService: DbSaveService, public uow: UnitOfWork, 
    public authService: AuthService, public dialogService: DialogService, public busyService: BusyService) {

    this.user = this.authService.getUser();

    Object.assign(this, data)  ;

    
    if (this.target == null) {
      this.isAdding = true;
      this.target = this.createTarget();
    } 

    this.activeStatuses = this.dbQueryService.getAllCached(ActiveStatus);

    this.prepare();
  }

  private createTarget() {
    let struct = {
      activeStatusId: 1
    }
    if (this.newEntityStruct) {
      struct = { ...struct, ...this.newEntityStruct };
    }
    return this.uow.createEntity(this.targetType, struct as any);
  }

  private async prepare() {
    await UtilFns.wait(1);

    this.title = this.isAdding ? `Creating New ${this.targetTypeDescription}` : `Editing ${this.targetTypeDescription}`;
   
    this.isPageReady = true;
  }

 
  async ok() {
    const ok = await this.save();
    if (!ok) {
      return;
    }
    this.dialogRef.close(true);
  }

  async save() {
    // TODO: remove excess spaces
    if (this.beforeSaveFn) {
      this.beforeSaveFn(this.target, this.isAdding)
    }
    const isValid = await this.validate();
    if (!isValid) { return false; }
  
    try {
      const sr = await this.dbSaveService.saveSelectedChanges([this.target]);
      return true;
    } catch (e) {
      return false;
    }
  }

  async validate() {
    
    let matchingEntity = await this.dbQueryService.getMatchingEntity(this.targetType, this.target, this.altKeyFieldNames);
    if (matchingEntity != null) {
      await this.dialogService.showOkMessage('Duplicate', `Cannot save because this ${this.targetTypeDescription} is a duplicate of an existing ${this.targetTypeDescription}. `);
      return false;
    }

    const ea = this.target.entityAspect;
    if (! ea.validateEntity()) {
      const vErrors = ea.getValidationErrors();
      const errMsg = vErrors.map(ve => ve.errorMessage).join('<br>');
      await this.dialogService.showOkMessage('Validation Error(s)', errMsg);
      return false;
    }

    return true;
  }

  async cancel() {
    // This insures that the rejectChanges below does not cause an html error on the page. 
    this.isPageReady = false; 
    // entityAspect may not exist if it is a new object
    this.target.entityAspect?.rejectChanges();
    
    this.dialogRef.close(false);
  }


}
