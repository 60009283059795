<app-uni-dialog title="New Purchase Order" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div uni-flex-row class="ml-12" >
    <mat-form-field style="width: 375px;">
      <mat-select [(ngModel)]="poHeader.manufacturerId" placeholder="Manufacturer">
        <mat-option>
          <ngx-mat-select-search ngModel (ngModelChange)="searchManufs($event)" placeholder="Manufacturer"
            [placeholderLabel]="'Find manufacturer...'" [noEntriesFoundLabel]="'No matching manufacturers found'">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let manuf of matchingManufs" [value]="manuf.id">
          {{manuf.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="save()">Save
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>
