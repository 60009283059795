<div class="page-layout blank p-24">
    <h2>Shipping</h2>

    <h3>Rates</h3>
    <button mat-raised-button (click)="getSampleRates()">
        <span>Get Rates</span>
    </button>

    <h3>Label</h3>
    <button mat-raised-button (click)="getSampleLabel()">
        <span>Get Label</span>
    </button>
    <div *ngIf="labelUrl">
        <a [href]="labelUrl" target="labelwindow">Click to download label</a>
    </div>

    <h3>Carrier Services</h3>
    <div style="width: 200px; background-color: white">
        <mat-select [(value)]="selectedCarrierId">
            <mat-option *ngFor="let c of carrierList" [value]="c.code">{{c.name}}</mat-option>
        </mat-select>
    </div>
    <button mat-raised-button (click)="getCarrierServices()">
        <span>Get Carrier Services</span>
    </button>

    <h3>Invoice PDF</h3>
    <div style="width: 200px; background-color: white">
        <input matInput placeholder="Invoice ID" [(ngModel)]="invoiceId" />
    </div>
    <!-- <a target="pdfwin" href="{{pdfapi}}/printable-invoice/{{invoiceId}}" *ngIf="(invoiceId && invoiceId.length == 10)">
        <span>Download PDF</span>
    </a> -->

    <h3>Email</h3>
    <div style="width: 200px; background-color: white">
        <input matInput placeholder="Email" [(ngModel)]="email" />
    </div>
    <button mat-raised-button (click)="sendEmail()">
        <span>Send Email with Invoice</span>
    </button>
    <button mat-raised-button (click)="getInvoiceEmailStatus()">
        <span>Get Mail Status</span>
    </button>

    <div>
        <pre *ngIf="!!lastResponse">{{ lastResponse | json }}</pre>
    </div>
</div>