import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BusyService } from 'app/shared/busy.service';
import { IdentityRole, RoleSelection, UserWithRoles } from './identity-data';
import { UserAccountsService } from './user-accounts.service';
import { AuthService } from 'app/shared/auth.service';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';

export interface UserAccountDialogComponentData {
  user?: UserWithRoles;
  users?: UserWithRoles[];
  roles?: IdentityRole[];
  userName?: string;
  isNew?: boolean;
}

@Component({
  templateUrl: './user-account-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class UserAccountDialogComponent {
  @ViewChild('userForm') userForm: NgForm;

  user: UserWithRoles;
  users: UserWithRoles[];
  roles: IdentityRole[];
  userRoles: RoleSelection[];
  confirmPassword: string;
  isUserAdmin: boolean;
  pwRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})');
  
  // to make it available in template
  UtilFns = UtilFns;

  static async show(matDialog: MatDialog, data: UserAccountDialogComponentData) {
    const r = await matDialog
      .open(UserAccountDialogComponent, {
        disableClose: true,
        height: '600px',
        width: '800px',
        data: data,
      })
      .afterClosed()
      .toPromise();
    return <UserWithRoles> r;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private data: UserAccountDialogComponentData,
    private dialogRef: MatDialogRef<UserAccountDialogComponent>,
    private userAccountsService: UserAccountsService, private authService: AuthService, 
    public busyService: BusyService) {
    

    this.validateConfirm = this.validateConfirm.bind(this);
    this.validateInitials = this.validateInitials.bind(this);

    const authUser = authService.getUser();
    this.isUserAdmin = authUser.isUserAdmin;
    
    if (data.users) {
      this.setUsers(data.user, data.users, data.roles);
    } else {
      this.userAccountsService.getIdentityData().then(idata => {
        const user = idata.Users.find(u => u.User.UserName === data.userName);
        this.setUsers(user, idata.Users, idata.Roles);
      });
    }
  }

  setUsers(user: UserWithRoles, users: UserWithRoles[], roles: IdentityRole[]) {
    this.user = user;
    this.confirmPassword = this.user.Password;
    this.users = users;
    this.roles = roles;
    this.userRoles = this.roles.map(r => {
      return {
        name: r.Name,
        isSelected: this.user.Roles.includes(r.Name)
      };
    });
    this.userRoles = _.sortBy(this.userRoles, x => x.name);
  }

  canEditInitials() {
    return this.data.isNew;
  }

  canSave() {
    const form = this.userForm;
    var ok = form && !form.form.pristine && !form.form.invalid;
    return ok;
  }
  
  async save() {
    const form = this.userForm;
    const user = this.user;
    if (!user || !form || !form.valid) {
      return;
    }
    if (user.Password != null && user.Password !== this.confirmPassword) {
      return;
    }
    if (user.User.UserName.indexOf('@') > 0) {
      this.user.User.Email = this.user.User.UserName;
    }
    this.user.Roles = this.userRoles.filter(r => r.isSelected).map(r => r.name);
    const uwr = await this.userAccountsService.saveUser(this.user);
    this.dialogRef.close(uwr);
  }

  close() {
    this.dialogRef.close(null);
  }

  validateConfirm(ctl: UntypedFormControl) {
    if (this.user == null || this.user.Password == null) { return null; }

    const isValid = ctl.value === this.user.Password;
    if (isValid) {
      return null;
    } else {
      return {
        confirm: {
          message: 'Passwords do not match'
        }
      };
    }
  }

  validateInitials(ctl: UntypedFormControl) {
    if (!ctl.value || !this.user || !this.users) { return null; }
    const isValid = !this.users.some(u => u.Initials === ctl.value && u.id !== this.user.id);
    if (isValid) {
      return null;
    } else {
      return {
        initials: {
          message: 'Initials already in use'
        }
      };
    }
  }

}
