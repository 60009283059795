// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { FeatureChoice } from './feature-choice';
import { Product } from './product';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ProductFeatureChoice extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  productId: number;
  featureChoiceId: number;
  displayOrder: number;
  featureChoice: FeatureChoice;
  product: Product;
}

