import { MessageBus } from '../shared/message-bus';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

export enum ToastTypes {
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Error = 'error',
  Success = 'success'
}

export interface ToastConfig {
  message: string;
  type?: ToastTypes;
  displayTime?: number;
}

@Injectable({providedIn: 'root'})
export class ToastService {
  config: ToastConfig;
  subscription: Subscription;

  constructor(private snackBarRef: MatSnackBar, messageBus: MessageBus) {
    this.subscription = messageBus.messages.subscribe(msg => {
      if (msg && msg.type) {
        this.show(msg);
      }
    });
  }

  show(config: ToastConfig) {
    config.message = config.message || '...';
    config.type = config.type || ToastTypes.Info;
    config.displayTime = config.displayTime || (config.type === ToastTypes.Danger ? 4000 : 2000);
    if (config.type === ToastTypes.Error) { config.type = ToastTypes.Danger; }
    this.snackBarRef.open(config.message, null, { duration: config.displayTime, panelClass: config.type });
  }
}
