<div *ngIf="isPageReady" id="printable-po" class="standard-page page-layout carded fullwidth inner-scroll accent-blue">

  <div class="center no-print p-8" style="max-width: 8.5in; min-width: 8.5in">

    <div *ngIf="!poHeader" #printableArea class="content-card theme-default screen-p-20">
      <h2>Error: Purchase Order {{poHeaderId}} not found.</h2>
    </div>
    <div *ngIf="poHeader" #printableArea class="content-card theme-default screen-p-20" style="overflow: auto">

      
      <div uni-flex-row class="h2 mt-12 ml-12">
        <span style="flex: auto">Unitec Distribution Systems</span>
        <span>Purchase Order {{poHeader.id}}</span>
      </div>
      <div *ngIf="isSent" uni-flex-row style="margin-bottom: -20px">
        <span style="flex: auto">&nbsp;</span>
        <span *ngIf="isSent" class="h1" style="font-style: italic; color: gray">REPRINT</span>
      </div>

      <div uni-flex-row class="mt-24 ml-12" >
        <mat-form-field uni-flex-item class="no-underline">
          <textarea matInput placeholder="Manufacturer" [ngModel]="getAddress(poHeader.manufacturer, 'wh', true, true)"
            readonly="readonly" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="6"></textarea>
        </mat-form-field>
        <mat-form-field uni-flex-item class="no-underline">
          <textarea matInput matAutosize placeholder="Ship this order to"
            [ngModel]="getAddress(poHeader.shippingWarehouse, 'wh', true, true)" readonly="readonly" cdkTextareaAutosize
            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="6"></textarea>
        </mat-form-field>
      </div>

      <div uni-flex-row uni-flex-nowrap class="ml-12 mt-8" >
        <mat-form-field >
          <input matInput placeholder="Account #" [ngModel]="poHeader.manufacturer.accountReferenceName" readonly="readonly" />
        </mat-form-field>
        <mat-form-field >
          <input matInput placeholder="P/O No." [ngModel]="poHeader.id" readonly="readonly" />
        </mat-form-field>
        <mat-form-field >
          <input matInput placeholder="P/O Date/Time" [ngModel]="poHeader.poDate | date:'short'" readonly="readonly" />
        </mat-form-field>
        <mat-form-field >
          <input matInput placeholder="F.O.B" value="Destination" readonly="readonly" />
        </mat-form-field>
      </div>

      <div uni-flex-row class="mt-12 ml-12" >
        <table class="grid-print" style="font-size: 10pt">
          <thead>
            <tr>
              <th>Style</th>
              <th>Description</th>
              <th>Size</th>
              <th class="col-right">Qty</th>
              <th>Deliver By</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of flatDetails">
              <td>{{d.style}}</td>
              <td>{{d.desc}}</td>
              <td>{{d.featureChoicesExtract}}</td>
              <td class="col-right">{{d.qty}}</td>
              <td>{{d.ddate | date:'MM/dd/yyyy'}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div uni-flex-row class="mt-12 ml-12" >
        <mat-form-field uni-flex-item>
          <textarea matInput placeholder="Order note" [ngModel]="getOrderNote()" readonly="readonly"
            style="font-size: 9pt" cdkTextareaAutosize #autosize="cdkTextareaAutosize">
          </textarea>
        </mat-form-field>
      </div>
    </div>


    <div uni-flex-row class="accent no-print mb-12" >

      <div class="left-buttons">
        <button mat-raised-button [disabled]="!poHeader" (click)="showPrintDialog()">
          <span>Print / Email</span>
        </button>

        <span style="margin-left: 8px">{{email?.status}} {{email?.statusTs | date:'long'}}</span>
      </div>

      <div>
        <button mat-raised-button [disabled]="!poHeader" (click)="goToPo()">
          <span>Go to PO Details</span>
        </button>
        <button mat-raised-button (click)="goBack()">
          <span>Go Back</span>
        </button>

      </div>
    </div>

  </div>
  <!-- / CENTER -->
</div>
