
declare function require(moduleName: string): any;

const { name, version } = require('../../package.json');

export const envbase = {
  env: 'base',
  app: name,
  version: version,
  sentryDsn: 'https://2dcb2e9b1f2349dba07f03cf7f3a0003@o1261118.ingest.sentry.io/6440781',
  quickbooksUrl: 'https://app.sandbox.qbo.intuit.com/app/'
}