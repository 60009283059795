import { Location } from '@angular/common';
import { Component, ViewEncapsulation, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { GridOptions } from '@ag-grid-community/core';
import { AgFns } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { PoDetail, PoHeader, PoStatus } from 'app/model/entities/entity-model';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EditNotesDialogComponent } from 'app/shared/edit-notes-dialog.component';
import { NavFns } from 'app/shared/nav-fns';
import { multiply } from 'lodash';
import { PoDetailTypeCode } from 'app/model/enums/po-detail-type-code';
import { OneFieldDialogComponent } from 'app/shared/one-field-dialog.component';


// TODO: this component is no longer available from within the app
// it was used to create purchase orders from 'stranded' PODetails created
// when a batch was marked complete.  Now all 'stranded' PODetails are automatically
// added to a PoHeader, which is created if it doesn't exist. 
@Component({
  selector: 'app-create-purchase-orders',
  templateUrl: './create-purchase-orders.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class CreatePurchaseOrdersComponent extends DomainBaseComponent {
  @ViewChild('noteCell') noteCell: TemplateRef<any>;
  @ViewChild('editQtyCell') editQtyCell: TemplateRef<any>;

  poDetails: PoDetail[];
  podGridOptions: GridOptions;

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog,
    private location: Location ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.podGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPodGridReady,
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      suppressRowClickSelection: true,
    });

    this.poDetails = await this.dbQueryService.getOrphanPoDetails();
    this.isPageReady = true;
  }

  onPodGridReady() {
    const colDefs = [
      { colId: 'manufName',  rowGroup: true,  hide: true,
        valueGetter: (params) => {
          return _.get(params.data, 'product.productType.manufacturer.name', 'n/a');
        }
      },
      { headerName: 'Import', checkboxSelection: true },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { headerName: 'Account', field: 'joDetail.joHeader.account.accountName' },
      { headerName: 'Order Qty', field: 'orderQty' },
      { ...AgFns.createCellButtonProps('Note', this.noteCell), minWidth: 50 },
      { ...AgFns.createCellButtonProps('Edit Qty', this.editQtyCell), minWidth: 50 },
      { headerName: 'PO Detail Id', field: 'id' },
      { headerName: 'Job Order Id', 
        ...NavFns.createIdCellClickedNavProps('joDetail.joHeaderId', this.router, '/job-orders')
      },
    ];
    const sortFields = [
      // 'manufName'
      'ag-Grid-AutoColumn',  // TODO: this is a complete HACK but ag-grid's own example does this. so...
      'product.productType.description',
      'product.productType.style',
      'product.choiceValue',
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });
    
    // preselect everything
    this.podGridOptions.api.selectAll();

    AgFns.initGrid(this.podGridOptions, colDefs, sortModel, true);
  }

  async createPurchaseOrders() {
    const selectedPoDetails = this.podGridOptions.api.getSelectedRows();
    if (selectedPoDetails.length === 0) {
      await this.dialogService.showOkMessage('Nothing was selected', 'No Purchase Order Details were selected.');
      return;
    }
    const r = await this.dialogService.askYesNo('Create Purchase Orders?', 'Are you sure?');
    if (r.index === 1) {
      return;
    }
    let grpObj = _.groupBy(selectedPoDetails, pod => _.get(pod, 'product.productType.manufacturerId', 'n/a'));
    grpObj = _.pickBy(grpObj, (value, key) => key !== 'n/a');
    _.forEach(grpObj, (pods: PoDetail[], manufId: string) =>  {
      const now = new Date();
      const poStruct =  {
        shippingWarehouseId: 1, // TODO: magic number for unitec
        manufacturerId: parseInt(manufId, 10),
        poStatusId: PoStatusCode.NewUnposted,
        poDate: now,
        crtnTs: now
      };

      const po = this.uow.createEntity(PoHeader, poStruct);
      pods.forEach(pod => {
        pod.poHeaderId = po.id;
        // These notes didn't have a poHeaderId on them.
        pod.poNotes.slice().forEach(pon => pon.poHeaderId = po.id);
      });
    });
    const count = Object.keys(grpObj).length;
    if (count === 0) {
      this.dialogService.showOkMessage('Nothing to create', 'There are no valid purchase orders that can be created');
      return;
    }
    await this.dbSaveService.saveChanges();
    this.dialogService.toast(count + ' purchase orders created');
    this.poDetails = await this.dbQueryService.getOrphanPoDetails();
  }

  hasPoNotes(pod: PoDetail) {
    return pod && pod.poNotes.length > 0;
  }

  async editPoNotes(pod: PoDetail) {
    await EditNotesDialogComponent.show(this.matDialog, { detail: pod });
  }

  canEditQty(pod: PoDetail) {
    return pod != null && pod.poDetailTypeId != PoDetailTypeCode.Order;
  }

  async editQty(pod: PoDetail) {
    const r = await OneFieldDialogComponent.show(this.matDialog, {
      title: `Change PO Detail Quantity`,
      fieldName: 'New Quantity',
      fieldType: 'integer'
    });

    if (r.index === 1 || r.value == null || r.value.length === 0) {
      return;
    }
    pod.orderQty = r.value;
    await this.dbSaveService.saveSelectedChanges([pod]);
    this.podGridOptions.api?.refreshCells();
  }

  
}
