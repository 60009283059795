// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Account } from './account';
import { ItemDetail } from './item-detail';
import { JoDetail } from './jo-detail';
import { PoDetailCancel } from './po-detail-cancel';
import { PoDetailType } from './po-detail-type';
import { PoHeader } from './po-header';
import { PoNote } from './po-note';
import { PoTrx } from './po-trx';
import { Product } from './product';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
import { EntityTypeAnnotation, EntityPropertyAnnotation } from '../entity-type-annotation';
import { ItemDetailStatusCode } from '../enums/item-detail-status-code';
/// </code-import>

export class PoDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  // Note that we are using a not negative validator here - because Po mods can remove qty from an order detail leaving it with 0
  // and we need the hist trail for this.
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      new EntityPropertyAnnotation('orderQty', {
        validators: [BaseEntity.notNegativeValidator]
      }),
    ];

    return new EntityTypeAnnotation({
      validators: [],
      propertyAnnotations: propAnnotations
    });

  }

  // conceptual Note interface
  getHeader() {
    return this.poHeader;
  }

  getNotes() {
    return this.poNotes;
  }

  // next 3 all assume itemDetails have been fetched
  getReceivedQty() {
    return this.itemDetails && this.itemDetails.filter(itd => itd.itemDetailStatusId <= ItemDetailStatusCode.Shipped).length;
  }

  getShippedQty() {
    return this.itemDetails && this.itemDetails.filter(itd => itd.itemDetailStatusId === ItemDetailStatusCode.Shipped).length;
  }

  getDamagedQty() {
    return this.itemDetails && this.itemDetails.filter(itd => itd.itemDetailStatusId === ItemDetailStatusCode.DamagedOrLost).length;
  }

  // assumes poDetailCancels have been fetched
  getCanceledQty() {
    return this.poDetailCancels && _.sum(this.poDetailCancels.map(podc => podc.qty));
  }

  // next 3 all assume itemDetails and poDetailCancels have been fetched
  getUncanceledQty() {
    return this.orderQty - ( this.getCanceledQty() + this.getDamagedQty());
  }
    
  getRemainingQty() {
    return this.getUncanceledQty() - (this.getReceivedQty() + this.heldQty);
  }

  getRemainingUnshippedQty() {
    return this.getUncanceledQty() - this.getShippedQty();
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  accountId: number;
  crtnTs: Date;
  expectedShipDate: Date;
  expectedShipDateModTs: Date;
  heldQty: number;
  joDetailId: number;
  modTs: Date;
  orderQty: number;
  origProductId: number;
  poDetailTypeId: string;
  poHeaderId: number;
  poTrxId: number;
  productId: number;
  rowVersion: any;
  account: Account;
  itemDetails: ItemDetail[];
  joDetail: JoDetail;
  origProduct: Product;
  poDetailCancels: PoDetailCancel[];
  poDetailType: PoDetailType;
  poHeader: PoHeader;
  poNotes: PoNote[];
  poTrx: PoTrx;
  product: Product;
}

