<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div *ngIf="isPageReady">

    <ng-container *ngFor="let fieldName of this.fieldNames; let i = index;">
      <div uni-flex-row>
        <mat-form-field uni-flex-item [matTooltip]="target[fieldName]">
          <input matInput [placeholder]="this.fieldLabels[i]" [(ngModel)]="target[fieldName]" />
        </mat-form-field>
      </div>
      
    </ng-container>
    

    <div uni-flex-row *ngIf="target['activeStatusId']">
      <mat-form-field style="width: 200px;" [matTooltip]="target['activeStatus'].name">
        <mat-select [(ngModel)]="target['activeStatusId']" placeholder="Status">
          <mat-option [value]="activeStatus.id" *ngFor="let activeStatus of activeStatuses">
            {{activeStatus.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
   
  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>