<div>
  <image-cropper style="height: 660px; width: 860px;" [imageFile]="cropInput" [maintainAspectRatio]="false"
    [aspectRatio]="4 / 3" [canvasRotation]="canvasRotation" [transform]="transform" output="blob" format="jpeg"
    (imageCropped)="imageCropped($event)"></image-cropper>

  <div style="display: flex; margin-top: 2rem;">
    <div style="flex-grow: 1;">
      <button mat-mini-fab (click)="rotateLeft()" title="Rotate Left"><mat-icon>rotate_left</mat-icon></button>
      <button mat-mini-fab style="margin-left: 1rem" (click)="rotateRight()"
        title="Rotate Right"><mat-icon>rotate_right</mat-icon></button>
      <button mat-mini-fab style="margin-left: 1rem" (click)="flipHorizontal()"
        title="Flip Horizontal"><mat-icon>swap_horiz</mat-icon></button>
      <button mat-mini-fab style="margin-left: 1rem" (click)="flipVertical()"
        title="Filp Vertical"><mat-icon>swap_vert</mat-icon></button>
    </div>
    <div>
      <button mat-raised-button (click)="cropOk()">Ok</button>
      <button mat-raised-button style="margin-left: 1rem" (click)="cropCancel()">Cancel</button>
    </div>
  </div>
</div>