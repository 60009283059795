import { ItemDetail } from 'app/model/entities/item-detail';
import { PrintFns } from './print-fns';
import { Addon, AddonBin, AddonBinStatus, AddonStation, InvoiceDetail, InvoiceHeader, ItemDetailAddon, ReturnRequestDetail, ShipBin } from 'app/model/entities/entity-model';
import { DateFns } from './date-fns';
import { DialogService } from './dialog.service';
import { Injectable } from '@angular/core';
import { AddonBinStatusCode } from 'app/model/enums/addon-bin-status-code';
import * as _ from 'lodash';
import { BarcodePrintStatusCode } from 'app/model/enums/barcode-print-status-code';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { Entity } from 'breeze-client';

@Injectable({ providedIn: 'root' })
export class BarcodeService {
  constructor(
    private dialogService: DialogService,
    
  ) { }

  async printTestBarcode() {
    const zpl = `^XA ^CFA,25
      ^FO20,20^FDManufacturer^FS
      ^FO20,50^FDDescription^FS
      ^FO20,80^FDStyle^FS
      ^FO20,110^FDSize^FS
      ^BY3,2,80 ^FO50,180^BC^FD123456789^FS ^XZ`;
    PrintFns.printZpl(zpl, 'barcodePrinter');
  }

  async printAddonBinLabels(addonBinPkgs: { addonBin: AddonBin, addons: Addon[] }[], isReprint: boolean): Promise<any> {
    const bars = [];
    const len = bars.length = addonBinPkgs.length;
    for (let i = 0; i < len; i++) {
      const pkg = addonBinPkgs[i];
      bars[i] = this.printAddonBinLabel(pkg.addonBin, pkg.addons, isReprint);
    }
    const str = bars.join('\r\n');
    return PrintFns.printZpl(str, 'barcodePrinter');
  }

  private printAddonBinLabel(addonBin: AddonBin, addons: Addon[], isReprint: boolean): string {
    let stub = ``;
    let zpl = `
      ^XA
      ^CFA,30
      ^FO40,30^FDIN-PROCESS BIN^FS`;
      if (isReprint) {    
        zpl +=`^FO330,30^FD* REPRINT *^FS`
      };
      zpl += `^CFA,50
      ^FO40,70^FD${addonBin.id}^FS
      ^BY3,2,50
      ^FO40,130^BC,50,N,N,N,N^FD${addonBin.id}^FS
      ^CFA,30`;
      if (addonBin.addonBinStatusId === AddonBinStatusCode.Closed) {
        zpl += '^FO40,200^FD* BIN IS CLOSED *^FS'
      } else if (addonBin.pendingAddonStation) {
        zpl += `^FO40,200^FDPending for ${addonBin.pendingAddonStation?.name}^FS`
      } else if (addonBin.currentAddonStation ) {
        if (addonBin.addonBinStatusId == AddonBinStatusCode.InProcess) {
          zpl += `^FO40,200^FDIn-Process for ${addonBin.currentAddonStation.name}^FS`
        } else {
          zpl += `^FO40,200^FDWaiting at ${addonBin.currentAddonStation.name}^FS`
        }
      }
      zpl += '^CFA, 20'
      let printRow = 250;
      addons.forEach(x => {
        zpl += `^FO40,${printRow}^FD${this.getAddonDescription(x)}^FS`
        printRow += 30;
      });
      zpl += "^XZ";
    return zpl;  
  }

  private getAddonDescription(addon: Addon) {
    return addon?.addonStation.name + ' (' + addon?.nameAndLocation.trim() + ')';
  }

  // requires itemDetailAddons.addons , itemDetailAddons.addonBin and stations.
  async printItemDetailBarcodesAndNewAddonBinLabels(itemDetails: ItemDetail[]) {


/*     let addons = _.flatMap(this.itemDetails, x => x.itemDetailAddons.map(y => y.addon));
    addons = Array.from(new Set(addons)); */


    if (itemDetails.length == 0) return [];
    let itdas = _.flatMap(itemDetails, x => x.itemDetailAddons);
    itdas = Array.from(new Set(itdas));

    const addonBins = _.uniq(itdas.map(x => x.addonBin));
    const newAddonBins = addonBins.filter(x => x.addonBinStatusId === AddonBinStatusCode.New);
    const newAddonBinPkgs = newAddonBins.map(aob => {
      return {
        addonBin: aob,
        addons: itdas.filter(itda => itda.addonBinId == aob.id).map(itda => itda.addon)
      }
    })
    if (newAddonBins.length > 0) {
      await this.printAddonBinLabels(newAddonBinPkgs, false);
    }

    await this.printItemDetailBarcodes(itemDetails);
    
    const ynResult = await this.dialogService.askYesNo('Printed Ok?', 'Did the barcodes print correctly?');
    if (ynResult.index === 0) {
      itemDetails.forEach(itd => {
        itd.barcodePrintStatusId = itd.itemDetailStatusId === ItemDetailStatusCode.InInventory 
          ? BarcodePrintStatusCode.Stock 
          : BarcodePrintStatusCode.JobOrder;
        itd.itemDetailAddons
      });
      // Change from 'New' to 'Open'  - used to detemine if the addonBin label was ever printed. 
      newAddonBins.forEach(aob => aob.addonBinStatusId = AddonBinStatusCode.Open);
      const changedItems = [...itemDetails, ...newAddonBins] as Entity[];
      return changedItems;
    } else {
      return [];
    }
  }

  /** Print ZPL labels for all the selected rows */
  async printItemDetailBarcodes(itemDetails: ItemDetail[]): Promise<any> {
    const bars = [];
    const len = bars.length = itemDetails.length;
    for (let i = 0; i < len; i++) {
      
      const itd = itemDetails[i];
      const isReprint = itd.barcodePrintStatusId !== BarcodePrintStatusCode.None;
      if (itd.joDetailId != null) {
        bars[i] = this.printJobOrderItemDetailBarCode(itd, isReprint);
      } else {
        bars[i] = this.printInventoryItemDetailBarCode(itd, isReprint);
      }
    }

    const str = bars.join('\r\n');
    return PrintFns.printZpl(str, 'barcodePrinter');
  }

  async printAddonBinBarcode(addonBin: AddonBin) {
    // TODO: Jeff
  }

  private printJobOrderItemDetailBarCode(itd: ItemDetail, isReprint: boolean) {
    const pod = itd.poDetail;
    const jod = itd.joDetail;
    const joh = jod.joHeader;
    const prod = itd.product;
    const manufacturer = prod.productType.manufacturer;
    const addons = itd.itemDetailAddons;
    const addonBin = itd.itemDetailAddons[0]?.addonBin;
    const pendingAddonStation = addonBin?.pendingAddonStation;

    let zpl = `
    ^XA
    ^CFA,20
    ^FO30,30^FD${manufacturer.name}^FS
    ^FO30,50^FD${prod.productType.description}^FS
    ^FO30,70^FD${prod.productType.style}^FS
    ^FO30,90^FD${prod.getDisplayFeatureChoicesExtract()}^FS
    ^BY3,2,60 
    ^FO40,115^BC^FD${itd.id}^FS 
    ^CFA,20
    ^FO30,215^FDJ/O: ${joh?.id}^FS
    ^FO250,215^FDShip: ${DateFns.fmtDate4Short(joh.origExpectedShipDate)}^FS
    ^FO30,240^FD${joh.getFormalEmployeeName()}^FS
    ^FO250,240^FD${joh.account.accountName}^FS
    ^CFA,30
    ^FO30,270^FD${this.getAddonBinDescription(addonBin, pendingAddonStation)}^FS
    ^FO250,270^FD${this.getAddonPendingAddonStationName(pendingAddonStation)}^FS
    ^CFA,20`;
    zpl += this.printAddons(310, addons) +
    `^XZ`
    return zpl;
  }

  private getAddonBinDescription(addonBin: AddonBin, pendingAddonStation: AddonStation): string {
    if (addonBin && addonBin.addonBinStatusId === AddonBinStatusCode.Closed) {
      return `* BIN: ${addonBin.id} is CLOSED *`
    }

    if (!addonBin) {
      return `* Route directly to SHIP PREP *`
    }

    return `BIN: ${addonBin.id.toString()}`;
  }

  private getAddonPendingAddonStationName(pendingAddonStation: AddonStation): string {
    if (!pendingAddonStation) return "";
    return pendingAddonStation.name;
  }

  private printAddons(startRow: number, addons: ItemDetailAddon[]): string {
    let zplStub = "";
    addons.forEach(x => {
      zplStub += `^FO30,${startRow.toString()}^FD${x.addon.nameAndLocation}`;
      if (x.additionalInfo) {
        zplStub += ` (${x.additionalInfo})`;
      }
      zplStub += `^FS`;
      startRow += 20;
    })
    return zplStub;
  }

  /** Make ZPL label for the given ItemDetail */
  private printInventoryItemDetailBarCode(itd: ItemDetail, isReprint: boolean) {
    const mfg = itd.product.productType.manufacturer.name;
    const desc = itd.product.productType.description;
    const style = itd.product.productType.style;
    const featureChoicesExtract = itd.product.getFeatureChoicesExtract();

    let barcodePos: number;
    // font size and product info section
    let zpl = `^XA ^CFA,25
^FO20,20^FD${mfg}^FS
^FO20,50^FD${desc}^FS
^FO20,80^FD${style}^FS
^FO20,110^FD${featureChoicesExtract}^FS`;
    if (itd.joDetail && itd.joDetail.joHeader) {
      // item is on a job order, so print job order section
      const jo = itd.joDetail.joHeader;
      const ship = DateFns.fmtDate4Short(jo.origExpectedShipDate);
      // draw a line
      zpl += '^FO20,255^GB570,0,1^FS';
      let jozpl = `
^FO20,260 ^FDJ/O:${jo.id}^FS
^FO320,260 ^FDShip:${ship}^FS
^CF0,30
^FO20,295 ^FD${jo.empFirstName} ${jo.empLastName}^FS
^FO320,295 ^FD${jo.account.accountName}^FS`;

      if (itd.joDetail.joDetailAddons) {
        let count = 0, modPos = 300;
        itd.joDetail.joDetailAddons.forEach(joda => {
          jozpl += `^FO30,${modPos += 25} ^FD${++count}. ${joda.addon.nameAndLocation}${joda.additionalInfo ? ': ' + joda.additionalInfo : ''} ^FS`;
        });
      }
      zpl += jozpl;
      barcodePos = 145;
    } else {
      // item is inventory
      barcodePos = 180;
    }

    // add barcode
    zpl += `^BY3,2,80 ^FO50,${barcodePos}^BC^FD${itd.id}^FS ^XZ`;
    return zpl;
  }

  /** Print ZPL label with repair comment */
  printRepairBarcode(rrad: ReturnRequestDetail): Promise<any> {
    const zpl = this.makeRepairBarcode(rrad);
    return PrintFns.printZpl(zpl, 'barcodePrinter');
  }

  private makeRepairBarcode(rrad: ReturnRequestDetail): string {
    const jod = rrad.joDetail;
    const jo = jod.joHeader;
    const mfg = jod.product.productType.manufacturer.name;
    const desc = jod.product.productType.description;
    const style = jod.product.productType.style;
    const featureChoicesExtract = jod.product.getFeatureChoicesExtract();

    let explanation = rrad.returnExplanation;
    if (!explanation) {
      explanation = '';
    } else if (explanation.length > 240) {
      explanation = explanation.substring(0, 240);
    }

    const zpl = `^XA ^CFA,25
^FO20,20^FD${mfg}^FS
^FO20,50^FD${desc}^FS
^FO20,80^FD${style}^FS
^FO20,110^FD${featureChoicesExtract}^FS

^CFA,50 ^FO20,160^FDREPAIR^FS 
^CFA,25 ^FO280,180^FDJ/O:${jo.id}^FS


^FO20,270
^FB580,6,0,L,0^FD${explanation}^FS ^XZ`;

    return zpl;
  }


  async printShipBinBarcode(shipBin: ShipBin): Promise<any> {
    this.dialogService.toast('ShipBin Barcode printing not yet implemented');
  }

  // assumes invh.invoiceDetails have been populated
  async printInvoicePackageBarcode(invh: InvoiceHeader): Promise<any> {
    // JEFF - TODO
    const invDetails = invh.invoiceDetails;
    
    this.dialogService.toast('Invoice Package Barcode printing not yet implemented');
  }

  // assumes - 
  async printAddonBarcode(addon: Addon) {
    
    this.dialogService.toast('Addon Barcode printing not yet implemented');
  }
}
