import { GridOptions, RowDoubleClickedEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FeatureChoice, ProductType, Product, Manufacturer } from 'app/model/entities/entity-model';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DialogService } from 'app/shared/dialog.service';
import { UtilFns } from 'app/shared/util-fns';

export interface FeatureChoiceFinderDialogComponentData {
  featureId: number;
  excludedFeatureChoiceIds: number[];
  rowSelection?: 'single' | 'multiple' // default is multiple
}


@Component({
  templateUrl: './feature-choice-finder-dialog.component.html'
})
export class FeatureChoiceFinderDialogComponent {
  fcGridOptions: GridOptions;
  featureChoices: FeatureChoice[];

  static async show(matDialog: MatDialog, data: FeatureChoiceFinderDialogComponentData) {
    const r = await matDialog.open(FeatureChoiceFinderDialogComponent, {
      disableClose: true,
      height: '530px',
      width: '800px',
      data: data
    })
      .afterClosed().toPromise();
    return <FeatureChoice[]> r;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: FeatureChoiceFinderDialogComponentData, public dialogRef: MatDialogRef<FeatureChoiceFinderDialogComponent>,
    private dbQueryService: DbQueryService, private dialogService: DialogService) {
    this.prepare();

  }

  async prepare() {
    this.fcGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onIssGridReady,
      onRowDoubleClicked: this.onIssRowDoubleClick,
      rowSelection: this.data?.rowSelection ?? 'multiple',
      stopEditingWhenCellsLoseFocus: true,
    });

    this.featureChoices = await this.dbQueryService.getFeatureChoices(this.data.featureId);
    var excludedIds = new Set(this.data.excludedFeatureChoiceIds);
    this.featureChoices = this.featureChoices.filter(x => !excludedIds.has(x.id));
  }

  onIssGridReady(evt: any) {
    const allowCbSelection = this.data?.rowSelection != 'single'
    const colDefs = [
      { headerName: 'Value', field: 'choiceValue', filter: 'agTextColumnFilter', checkboxSelection: allowCbSelection, headerCheckboxSelection: allowCbSelection },
      { headerName: 'Display Order', field: 'displayOrder', hide: true },
    ];
    const sortModel = [
      { colId: 'displayOrder', sort: 'asc' },
    ] as ISortModel;
    AgFns.initGrid(this.fcGridOptions, colDefs, sortModel);
    
  }

  onIssRowDoubleClick(e: RowDoubleClickedEvent) {
    if (!e.node.isSelected()) {
      return;
    }
    const fc = e.data as FeatureChoice;
    this.dialogRef.close([fc]);
  }

  ok() {
    const r = this.fcGridOptions.api.getSelectedRows()
    this.dialogRef.close(r);
  }

  cancel() {
    this.dialogRef.close([]);
  }

}
