<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>

  <div *ngIf="isPageReady">
    <div uni-flex-row>
      <mat-form-field style="width: 400px;">
        <input matInput placeholder="Feature" [ngModel]="featureChoice.feature.name" readonly="readonly" />
      </mat-form-field>
    </div>

    <div uni-flex-row>
      <mat-form-field style="width: 400px;" [matTooltip]="featureChoice.choiceValue">
        <input matInput placeholder="Choice Value" [(ngModel)]="featureChoice.choiceValue" />
      </mat-form-field>
      <mat-form-field style="width: 400px;">
        <mat-select [(ngModel)]="featureChoice.reorderFeatureChoiceId" placeholder="Reorder Feature Choice">
          <mat-option [value]="featureChoice.id" *ngFor="let featureChoice of availableFeatureChoices">
            <span>{{featureChoice.choiceValue}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div uni-flex-row>
      <mat-form-field style="width: 200px;" >
        <input matInput type="number" placeholder="Display Order" [(ngModel)]="featureChoice.displayOrder" />
      </mat-form-field>

      <mat-form-field style="width: 200px;" [matTooltip]="featureChoice.activeStatus.name">
        <mat-select [disabled]="!this.user.isProductAdmin" [(ngModel)]="featureChoice.activeStatusId" placeholder="Status">
          <mat-option [value]="activeStatus.id" *ngFor="let activeStatus of activeStatuses">
            {{activeStatus.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>