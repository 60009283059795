
// Copied and modified from https://www.ag-grid.com/example-angular-material-design/

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'ag-checkbox-cell',
  template: `
    <mat-checkbox [disabled]="isDisabled()" [ngModel]="checked" (ngModelChange)="onChange($event)"></mat-checkbox>
  `,
  styles: [
    `
      ::ng-deep .mat-checkbox-layout {
        /* horizontally align the checkbox */
        width: 100%;
        display: inline-block !important;
        text-align: center;
        margin-top: -4px; /* to offset the cells internal padding - could be done in cells CSS instead*/

        /* vertically align the checkbox when not using the ag-material theme - should be the same as the
                rowHeight - cell padding
                   (you can of course alter the cell padding via CSS instead if you'd prefer)
                line-height: 42px;
                 */
      }
    `,
  ],
})
export class AgCheckboxCellComponent implements ICellRendererAngularComp {
  private params: any;

  checked = false;

  agInit(params: any): void {
    this.params = params;
    this.checked = this.params.value === true;
  }

  // demonstrates how you can do "inline" editing of a cell
  onChange(checked: boolean) {
    this.checked = checked;
    this.params.node.setDataValue(this.params.colDef.field, this.checked);
  }

  // needed because using editable with function does not work for this component.. ugh...
  isDisabled() {
    // you can add an isDisabled(entity) function to the column def
    const p = this.params;
    if (p.colDef.editable === false) {
     return true;
    }
    if (p.colDef.isDisabled && p.colDef.isDisabled(p.data)) {
      return true;
    }
    return false;
  }

  refresh(params: any): boolean {
    return false;
  }
}

// import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
// import { ICellRendererAngularComp } from '@ag-grid-community/angular';
// import { ICellRendererParams } from '@ag-grid-community/core';

// @Component({
//   selector: 'ag-checkbox-cell',
//   template: `
//     <input type="checkbox" [checked]="params.value" (change)="onChange($event)" />
//   `,
//   // styleUrls: ['./checkbox-cell.component.css'],
// })
// export class AgCheckboxCellComponent { // implements AfterViewInit, ICellRendererAngularComp {
//   @ViewChild('.checkbox') checkbox: ElementRef;

//   public params: ICellRendererParams;

//   constructor() {}

//   agInit(params: ICellRendererParams): void {
//     this.params = params;
//   }

//   public onChange(event) {
//     this.params.data[this.params.colDef.field] = event.currentTarget.checked;
//   }
// }
