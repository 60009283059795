import { Component, Inject, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GridOptions, CellValueChangedEvent } from '@ag-grid-community/core';
import { AgFns } from 'app/shared/ag-fns';
import { AuthService } from 'app/shared/auth.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { UtilFns } from 'app/shared/util-fns';
import { PoHeader, PoNote, PoNoteType, PoDetail, JoHeader, JoDetail, JoNoteType, JoNote, EntityIssue } from 'app/model/entities/entity-model';
import { PoNoteTypeCode } from 'app/model/enums/po-note-type-code';
import { EntityState, Entity } from 'breeze-client';
import { JoNoteTypeCode } from 'app/model/enums/jo-note-type-code';
import * as _ from 'lodash';
import { AgCheckboxCellComponent } from './ag-checkbox-cell.component';
import { WarningTypeCode } from 'app/model/enums/warning-type-code';

// Note: this dialog assumes that if we are editing/creating a poDetail note
// that the joDetail and joHeader corresponding to this poDetail has been fetched
@Component({
  templateUrl: './edit-notes-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class EditNotesDialogComponent {
  @ViewChild('buttonsCell') buttonsCell: TemplateRef<any>;

  typeInit: 'P' | 'J';
  header: JoHeader | PoHeader;
  detail: JoDetail | PoDetail;
  notes: JoNote[] | PoNote[];
  accountId: number;
  isEditingAllowed = false;

  title: string;
  notesGridOptions: GridOptions;
  userInit: string;

  isPageReady = false;
  noteTypeMap: object;

  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(EditNotesDialogComponent, {
        disableClose: true,
        height: '600px',
        width: '900px',
        minHeight: '600px',
        minWidth: '900px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditNotesDialogComponent>,
    public uow: UnitOfWork,
    private dbQueryService: DbQueryService,
    private dbSaveService: DbSaveService,
    private dialogService: DialogService,
    private authService: AuthService
  ) {

    this.header = this.data.header;
    this.detail = this.data.detail;
    this.userInit = this.authService.getUser().initials;
    if (this.header != null) {
      this.typeInit = this.header.entityType.shortName === 'PoHeader' ? 'P' : 'J';
    } else {
      this.typeInit = this.detail.entityType.shortName === 'PoDetail' ? 'P' : 'J';
    }
    this.prepare();
  }

  async prepare() {
    this.canEditCell = this.canEditCell.bind(this);
    this.isCellDisabled = this.isCellDisabled.bind(this);

    this.notesGridOptions = AgFns.initGridOptions( this, {
      onGridReady: this.onNotesGridReady,
      onCellValueChanged: this.onCellValueChanged,
      onModelUpdated: (p) => {} // to suppress automatic autosize
    });

    let noteTypes: JoNoteType[] | PoNoteType[];
    let typeDescr: string;
    if (this.typeInit === 'P') {
      typeDescr = 'Purchase Order';
      noteTypes = await this.dbQueryService.cacheAndGetAll(PoNoteType);
      if (this.detail == null) {
        this.notes = await this.dbQueryService.getPoNotesForHeader(this.header.id);
        this.accountId = null;
      } else {
        this.notes = await this.dbQueryService.getPoNotesForDetail(this.detail.id);
        // Assumes that both JoDetail and JoHeader have also been retrieved.
        const poDetail = <PoDetail> this.detail;
        if (poDetail.joDetail != null) {
          this.accountId = poDetail.joDetail.joHeader.accountId;
        } else {
          // this can happen for Stock and Special orders
          this.accountId = null;
        }
      }
    } else {
      typeDescr = 'Job Order';
      noteTypes = await this.dbQueryService.cacheAndGetAll(JoNoteType);
      if (this.detail == null) {
        this.notes = await this.dbQueryService.getJoNotesForHeader(this.header.id);
        this.accountId = (<JoHeader> this.header).accountId;
      } else {
        this.notes = await this.dbQueryService.getJoNotesForDetail(this.detail.id);
        this.accountId = (<JoDetail> this.detail).joHeader.accountId;
      }
    }
    this.noteTypeMap = UtilFns.objectArrayToObject(noteTypes, 'id', 'name');

    if (this.detail == null) {
      this.header = this.data.header;
      this.title = `Notes for ${typeDescr}: ${ this.header.id }`;
    } else {
      this.header = this.detail.getHeader();
      this.title = `Notes for ${typeDescr} Detail: ${ this.detail.id }`;
    }
    this.isPageReady = true;
  }


  onNotesGridReady() {
    let fldNoteTypeId: string;
    let fldDetailId: string;
    let title: string;
    if (this.typeInit === 'P') {
      title = 'PO Detail';
      fldNoteTypeId = 'poNoteTypeId';
      fldDetailId = 'poDetailId';
    } else {
      fldNoteTypeId = 'joNoteTypeId';
      fldDetailId = 'joDetailId';
    }
    const colDefs = [
      { ...AgFns.createCellButtonProps('', this.buttonsCell, 'del'), maxWidth: 40 },
      // { headerName: 'Note Type', field: fldNoteTypeId, editable: false, maxWidth: 120,
      //   ...AgFns.createDropdownEditorProps(this.noteTypeMap),
      // },
      { headerName: 'Note Type', field: fldNoteTypeId, editable: false, maxWidth: 120,
        ...AgFns.createDropdownEditorPropsFromMapObj(this.noteTypeMap),
      },
      { headerName: 'Note', field: 'note',   width: 550,
        cellEditor: 'agLargeTextCellEditor',  cellEditorParams: { maxLength: 1024 }, 
        editable: this.canEditCell, tooltipField: 'note', 
        autoHeight: true, wrapText: true  },
      { headerName: 'Is Issue', field: 'isIssue', maxWidth: 75,
        cellRenderer: AgCheckboxCellComponent ,
        isDisabled: this.isCellDisabled,
        // editable: this.canEditCell,
       },
      { headerName: 'Created', field: 'crtnTs',  },
      { headerName: 'User', field: 'crtnUserInit', maxWidth: 60  },
      { headerName: title, field: fldDetailId, width: 100 },
      { headerName: 'Modified', field: 'modTs', },
      { headerName: 'User', field: 'modUserInit', maxWidth: 60 },
    ];
    const sortModel = [{ colId: 'crtnTs', sort: 'desc' as const }];
    AgFns.initGrid(this.notesGridOptions, colDefs, sortModel);
    this.updateColumnVisibility();
    
  }

  onCellValueChanged(e: CellValueChangedEvent) {
    if (e.colDef.field === 'isIssue') {
      this.notesGridOptions.api.redrawRows();
    }
  }

  addNote() {
    this.notesGridOptions.api.stopEditing();
    if (this.typeInit === 'P') {
      this.createPoNote();
    } else {
      this.createJoNote();
    }

    this.refreshUI();
    this.notesGridOptions.api.setFocusedCell(0, 'note');
  }

  allowEditing() {
    if (this.authService.getUser().isUserAdmin) {
      this.isEditingAllowed = true;
      this.updateColumnVisibility();
    } else {
      this.dialogService.showOkMessage('Insufficient Access Rights', 'Only users with "userAdmin" rights can edit notes');
    }
  }

  disallowEditing() {
    this.isEditingAllowed = false;
    this.updateColumnVisibility();
  }

  updateColumnVisibility() {
    this.notesGridOptions.columnApi.setColumnsVisible(['del'], this.isEditingAllowed);
    this.notesGridOptions.columnApi.setColumnWidth('del', 40);
    this.notesGridOptions.api.refreshCells( { force: true });
  }

  createPoNote() {
    const now = new Date();
    const struct = <PoNote> {
      poNoteTypeId: PoNoteTypeCode.Other,
      crtnTs: now,
      crtnUserInit: this.userInit,
      modTs: now,
      modUserInit: this.userInit,
      note: '',
    };
    const poDetail = this.detail as PoDetail;
    if (poDetail == null) {
      struct.poHeaderId = this.header.id;
    } else {
      struct.poDetailId = poDetail.id;
      // a PoDetail can be missing PoHeader if not yet imported.cr
      struct.poHeaderId = poDetail.poHeader?.id;
    }
    this.uow.createEntity(PoNote, struct);
  }

  createJoNote() {
    const now = new Date();
    const struct = <JoNote> {
      joNoteTypeId: JoNoteTypeCode.Other,
      crtnTs: new Date(),
      crtnUserInit: this.userInit,
      modTs: now,
      modUserInit: this.userInit,
      note: '',
    };
    if (this.detail == null) {
      struct.joHeaderId = this.header.id;
    } else {
      struct.joDetailId = this.detail.id;
      struct.joHeaderId = (this.detail as JoDetail).joHeader.id;
    }
    this.uow.createEntity(JoNote, struct);
  }

  deleteNote(note: JoNote | PoNote) {
    if (this.authService.getUser().isUserAdmin) {
      note.entityAspect.setDeleted();
      this.refreshUI();
    } else {
      this.dialogService.showOkMessage('Insufficient Access Rights', 'Only users with "userAdmin" rights can delete notes');
    }
  }

  async save() {
    this.notesGridOptions.api.stopEditing();
    // Don't bother saving 'empty' notes.
    let notes = this.getSaveableNotes();
    notes = notes.slice().filter((note: PoNote | JoNote) => {
      if (note.note.trim().length === 0) {
        note.entityAspect.rejectChanges();
        return false;
      }
      return true;
    });

    const addedNotes = notes.filter(n => n.entityAspect.entityState.isAdded());
    // await this.updateAddedNotes(addedNotes);
    const modifiedNotes = notes.filter(n => n.entityAspect.entityState.isModified());
    await this.updateModifiedNotes(modifiedNotes);

    await this.addUpdateEntityIssues(notes);

    this.dbSaveService.saveChanges();

    this.dialogRef.close(null);
  }

  // Removed per discussion with Jeff on 4/18/2022
  // private async updateAddedNotes(notes: (JoNote | PoNote)[]) {
  //   // If this is a PoDetail note and references a JoDetail then copy this note to
  //   // a new JoDetail note
  //   const poNotes = <PoNote[]>notes.filter(n => n.entityType.shortName === 'PoNote');
  //   const poDetailNotes = poNotes.filter(n => n.poDetailId != null && n.poDetail.joDetailId != null);
  //   poDetailNotes.forEach(pon => {
  //     const struct = <JoNote>{
  //       joNoteTypeId: JoNoteTypeCode.PodNote,
  //       crtnTs: pon.crtnTs,
  //       crtnUserInit: this.userInit,
  //       modTs: pon.crtnTs,
  //       modUserInit: this.userInit,
  //       note: '',
  //     };
  //     struct.joDetailId = pon.poDetail.joDetailId;
  //     // Note: next line assumes that the joDetail corresponding to this joDetail.note has been fetched
  //     struct.joHeaderId = pon.poDetail.joDetail.joHeaderId;
  //     struct.note = 'Copied from PO Detail note: ' + pon.note; // copy the poDetail note
  //     this.uow.createEntity(JoNote, struct);
  //   });

  // }

  private async updateModifiedNotes(notes: (JoNote | PoNote)[]) {
    notes.forEach(n => {
      n.modTs = new Date();
      n.modUserInit = this.userInit;
    });

  }

  async addUpdateEntityIssues(notes: (PoNote | JoNote)[]) {
    let issueNotes = notes.filter(n => n.isIssue && n.entityAspect.entityState.isAdded());
    issueNotes.forEach(n => this.createIssue(n));
    issueNotes = notes.filter(n => n.entityAspect.entityState.isModified());
    const promises = issueNotes.map(async n => {
      const issue = await this.getIssue(n);
      if (issue == null && n.isIssue) {
        this.createIssue(n);
      } else if ( issue != null && !n.isIssue) {
        issue.entityAspect.setDeleted();
      } else if ( issue != null && n.note !== issue.description ) {
        issue.description = n.note;
      }
      return issue;
    });
    await Promise.all(promises);
  }

  createIssue(note: JoNote | PoNote) {
    const issueStruct = <EntityIssue> {};
    issueStruct.crtnTs = note.crtnTs;
    issueStruct.accountId = this.accountId;
    issueStruct.crtnUserInit = note.crtnUserInit;
    issueStruct.description = note.note;
    issueStruct.warningTypeId = WarningTypeCode.Warning;
    const ei = this.getEntityInfo(note);
    issueStruct.entityTypeName = ei.entityTypeName;
    issueStruct.entityId =  ei.entityId;
    const issue = this.uow.createEntity(EntityIssue, issueStruct);
  }

  async getIssue(note: PoNote | JoNote) {
    const ei = this.getEntityInfo(note);
    let  issues = await this.dbQueryService.getEntityIssues(ei.entityTypeName, ei.entityId);
    // cannot compare javascript dates with ===
    issues = issues.filter(iss => iss.crtnTs.getTime() === note.crtnTs.getTime());
    return issues.length ? issues[0] : null;
  }

  getEntityInfo(note: PoNote | JoNote) {
    const headerId = note['joHeaderId'] || note['poHeaderId'];
    const detailId = note['joDetailId'] || note['poDetailId'];
    const prefix = note.entityType.shortName.substr(0, 2); // Jo or Po
    const suffix =  detailId != null ? 'Detail' : 'Header';
    const entityTypeName = prefix + suffix;
    const entityId =  (detailId != null ? detailId : headerId).toString();
    return { entityTypeName: entityTypeName, entityId: entityId};
  }

  undo(shouldExit: boolean) {
    this.notesGridOptions.api.stopEditing();
    const notes = this.getSaveableNotes(true);

    notes.slice().forEach(note => note.entityAspect.rejectChanges());
    if (shouldExit) {
      this.dialogRef.close(null);
    } else {
      this.refreshUI();
    }
  }

  private getSaveableNotes(includeDeleted = false) {
    let typeName: string;
    if (this.typeInit === 'P') {
      typeName = 'PoNote';
    } else {
      typeName = 'JoNote';
    }
    this.notesGridOptions.api.stopEditing();
    const entityStates = [EntityState.Added, EntityState.Modified];
    if (includeDeleted) {
      entityStates.push(EntityState.Deleted);
    }
    const notes = <(PoNote | JoNote)[]> this.uow.manager.getEntities(typeName, entityStates );
    return notes;
  }

  canEditEntity(entity: Entity) {
    const es = entity.entityAspect.entityState;
    if (es.isAdded()) {
      return true;
    }
    if (es.isUnchangedOrModified()) {
      return this.isEditingAllowed;
    }
  }

  canEditCell(params) {
    const entity = <Entity> params.node.data;
    return this.canEditEntity(entity);
  }

  isCellDisabled(data: JoNote | PoNote) {
    return !this.canEditEntity(data);
  }

  isRowDisabled(entity: Entity) {
    return !entity.entityAspect.entityState.isAdded();
  }

  private refreshUI() {
    let notes = <(JoNote | PoNote)[]> (this.detail != null ? this.detail.getNotes() : this.header.getNotes());
    notes = _.orderBy(notes, n => n.crtnTs, ['desc']);
    this.notesGridOptions.api.setRowData(notes);
  }
}
