import { CellClickedEvent, GridOptions } from '@ag-grid-community/core';
import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { EntityIssue, JoNote, PoNote } from 'app/model/entities/entity-model';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { NavFns } from 'app/shared/nav-fns';
import { LinkHelpTooltipComponent } from 'app/shared/tooltip.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'entity-issue',
  templateUrl: './entity-issue.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class EntityIssueComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('eiButtonsCell') eiButtonsCell: TemplateRef<any>;

  eiGridOptions: GridOptions;
  entityIssues: EntityIssue[];

  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,
    protected location: Location
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.eiGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onEiGridReady,
      onModelUpdated: (p) => {}, // need to suppress automatic autosize that occurs here.
      getRowHeight: undefined, // // we want autoHeight for this grid
    })

    this.entityIssues = await this.dbQueryService.getEntityIssues();
    this.isPageReady = true;
  }

  canDeactivate() {
    this.uow.clearEntities(EntityIssue);
    return true;
  }

  async onEiGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Entity Type', field: 'entityTypeName', filter: 'agTextColumnFilter', width: 100 },
      { headerName: 'Entity Id', field: 'entityId', filter: 'agTextColumnFilter', width: 100,
        cellStyle: { color: 'blue', cursor: 'pointer'},
        onCellClicked: (event) => this.gotoIssue(event, event.data as EntityIssue),   tooltipComponent: LinkHelpTooltipComponent,
        tooltipField: 'entityId'},
      // { headerName: 'Length', field: 'descLength', filter: 'agTextColumnFilter' },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'description', filter: 'agTextColumnFilter', width: 500,
      tooltipField: 'description', cellStyle: {'white-space': 'normal' }, autoHeight: true, wrapText: true },
      { headerName: 'Type', field: 'warningType.name', filter: 'agTextColumnFilter', width: 100 },
      { headerName: 'Created By', field: 'crtnUserInit', filter: 'agTextColumnFilter', width: 100 },
      { headerName: 'Created On', field: 'crtnTs', width: 140 },
      { ...AgFns.createCellButtonProps('Audit', this.eiButtonsCell), minWidth: 50 },
    ];


    const sortModel = [
      { colId: 'crtnTs', sort: 'desc' },
    ] as ISortModel;

    AgFns.initGrid(this.eiGridOptions, colDefs, sortModel, false);
    AgFns.sizeColumnsToFit(this.eiGridOptions);

    this.eiGridOptions.api.resetRowHeights();
  }

  async gotoIssue(event: CellClickedEvent, issue: EntityIssue) {
    if (issue.entityTypeName === 'JoHeader') {
      this.gotoNavPath(event, '/job-orders', issue.entityId);
    } else if (issue.entityTypeName === 'PoHeader') {
      this.gotoNavPath(event, '/purchase-orders', issue.entityId);
    } else if (issue.entityTypeName === 'JoDetail') {
      const jod = await this.dbQueryService.getJoDetail( +issue.entityId);
      if (jod != null) {
        this.gotoNavPath(event, '/job-orders', jod.joHeaderId);
      }
    } else if (issue.entityTypeName === 'PoDetail') {
      const pod = await this.dbQueryService.getPoDetail( +issue.entityId);
      if (pod != null) {
        this.gotoNavPath(event, '/purchase-orders', pod.poHeaderId);
      }
    } else if (issue.entityTypeName === 'InvoiceHeader') {
      this.router.navigate(['/printable-invoice', issue.entityId]);
    } else if (issue.entityTypeName === 'Manifest') {
      this.gotoManifest(event, issue.entityId);
    } else {
      return await this.dialogService.showOkMessage('Not yet implemented', 'This feature has not yet been implemented');
    }
  }

  private gotoNavPath(event, navPath: string, id: any) {
    const fm = AgFns.addFilterClause({}, 'id', 'number', id);
    const urlSuffix = encodeURIComponent(JSON.stringify(fm));
    const params = {
      queryParams: {
        fm: urlSuffix,
        key: id.toString()
      }
    };
    NavFns.navigateWithClickEvent(event, this.router, navPath, params);
  }

  private gotoManifest(event, proximityManifestId: string) {
    const fm = AgFns.addFilterClause({}, 'proximityManifestId', 'text', proximityManifestId);
    const urlSuffix = encodeURIComponent(JSON.stringify(fm));
    const params = {
      queryParams: {
        fm: urlSuffix,
        key: proximityManifestId
      }
    };
    NavFns.navigateWithClickEvent(event, this.router, '/manifests', params);
  }

  async removeIssue(issue: EntityIssue) {
    const res = await this.dialogService.askYesNo('Remove Issue', 'Are you sure?');
    if (res.index !== 0) {
      return;
    }
    issue.entityAspect.setDeleted();
    await this.removeIssueFromNote(issue);
    await this.dbSaveService.saveChanges();
    this.entityIssues = await this.dbQueryService.getEntityIssues();
  }

  async removeIssueFromNote(issue: EntityIssue) {
    const typeName = issue.entityTypeName;
    let note: PoNote | JoNote;
    if (typeName === 'JoHeader' || typeName === 'JoDetail') {
      note  = await this.dbQueryService.findJoNoteForIssue(issue);
    } else if ( typeName === 'PoDetail') {
      // note = await this.dbQueryService.findPoNoteForIssue(issue);
    }
    if (note != null) {
      note.isIssue = false;
    }
  }

  
}
