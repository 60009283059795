<span *ngIf="entity">
  <div class="mt-12" uni-flex-row>
    <mat-form-field style="width: 250px" [matTooltip]="entity[prefix + 'Name']">
      <input matInput placeholder="Name" [(ngModel)]="entity[prefix + 'Name']" [readonly]="isReadonly" />
      <mat-hint class="error-hint" *ngIf="getErrorMessage('Name')">{{ getErrorMessage('Name') }}</mat-hint>
    </mat-form-field>
    <mat-form-field style="width: 250px" [matTooltip]="entity[prefix + 'Address1']">
      <input matInput placeholder="Address 1" [(ngModel)]="entity[prefix + 'Address1']" [readonly]="isReadonly" />
      <mat-hint class="error-hint" *ngIf="getErrorMessage('Address1')">{{ getErrorMessage('Address1') }}</mat-hint>
    </mat-form-field>
    <mat-form-field style="width: 250px" [matTooltip]="entity[prefix + 'Address2']">
      <input matInput placeholder="Address 2" [(ngModel)]="entity[prefix + 'Address2']" [readonly]="isReadonly" />
      <mat-hint class="error-hint" *ngIf="getErrorMessage('Address2')">{{ getErrorMessage('Address2') }}</mat-hint>
    </mat-form-field>
  </div>
  <div uni-flex-row>
    <mat-form-field style="width: 250px">
      <input matInput placeholder="City" [(ngModel)]="entity[prefix + 'City']" [readonly]="isReadonly" />
      <mat-hint class="error-hint" *ngIf="getErrorMessage('City')">{{ getErrorMessage('City') }}</mat-hint>
    </mat-form-field>
    <mat-form-field style="width: 40px">
      <input matInput placeholder="State" [(ngModel)]="entity[prefix + 'State']" [readonly]="isReadonly" />
      <mat-hint class="error-hint" *ngIf="getErrorMessage('State')">{{ getErrorMessage('State') }}</mat-hint>
    </mat-form-field>
    <mat-form-field style="width: 100px">
      <input matInput placeholder="Zip" [(ngModel)]="entity[prefix + 'ZipCode']" size=5 [readonly]="isReadonly" />
      <mat-hint class="error-hint" *ngIf="getErrorMessage('ZipCode')">{{ getErrorMessage('ZipCode') }}</mat-hint>
    </mat-form-field>
    <mat-form-field *ngIf="hasField('Telephone')">
      <input matInput placeholder="Phone" [(ngModel)]="entity[prefix + 'Telephone']" [readonly]="isReadonly" />
      <mat-hint class="error-hint" *ngIf="getErrorMessage('Telephone')">{{ getErrorMessage('Telephone') }}</mat-hint>
    </mat-form-field>
    <mat-form-field *ngIf="hasField('Ext')">
      <input matInput placeholder="Phone Extn." [(ngModel)]="entity[prefix + 'Ext']" [readonly]="isReadonly" />
      <mat-hint class="error-hint" *ngIf="getErrorMessage('Ext')">{{ getErrorMessage('Ext') }}</mat-hint>
    </mat-form-field>
  </div>
</span>
<span *ngIf="!entity">
  <div class="mt-12 ml-12">
    <h3>No Address Found</h3>
  </div>
</span>
