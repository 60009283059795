<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div uni-flex-row>
    <mat-form-field>
      <mat-label>Carriers</mat-label>
      <mat-select style="width: 260px;" [ngModel]="selectedCarrier" (ngModelChange)="onCarrierChange($event)">
        <mat-option [value]="carrier" *ngFor="let carrier of carriers">{{carrier.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Unit Of Measure</mat-label>
      <mat-select style="width: 260px;" [ngModel]="uomId" (ngModelChange)="onUomChange($event)">
        <mat-option [value]="uom[0]" *ngFor="let uom of uomList">{{uom[1]}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="content-card" >
    <div class="h3">Box Weights</div>
    <app-ag-grid style="height: 180px" [gridOptions]="boxWeightGridOptions" [rowData]="boxes">
    </app-ag-grid>
    <ng-template #deleteBoxCell let-row>
      <button class="mr-8" (click)="deleteBox(row)">Del</button>
    </ng-template>
  </div>

  <div class="content-card my-8" >
    <div class="h3">Rates</div>
    <app-ag-grid style="height: 180px" [gridOptions]="rateGridOptions" [rowData]="rates" [hasFooter]=false>
    </app-ag-grid>
  </div>

  <div uni-flex-row>

    <mat-form-field style="width: 260px;">
      <mat-label>Service</mat-label>
      <input matInput placeholder="Service" [value]="selectedService?.name" readonly="true" />
    </mat-form-field>

    <mat-form-field style="width: 260px;">
      <mat-label>Service Code - (ShipEngine)</mat-label>
      <input matInput placeholder="Service (shipEngine)" [value]="selectedService?.code" readonly="true" />
    </mat-form-field>
  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="addBox()">Add Box
  </button>
  <button mat-raised-button [disabled]="!canGetRates()" (click)="lookupRates()">Get Rates
  </button>
  
  <button class="ml-8" mat-raised-button [disabled]="!canClickOk()" (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>
