<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div uni-flex-row>
    <mat-form-field style="width: 350px">
      <input matInput placeholder="Manufacturer" [ngModel]="itemDetail.product.productType.manufacturer.name"
        readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 350px">
      <input matInput placeholder="Style" [ngModel]="itemDetail.product.productType.description"
        readonly="readonly" />
    </mat-form-field>
  </div>

  <div uni-flex-row>
    <mat-form-field style="width: 350px">
      <input matInput placeholder="Style" [ngModel]="itemDetail.product.productType.style" readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 350px">
      <input matInput placeholder="Features" [ngModel]="itemDetail.product.featureChoicesExtract" readonly="readonly" />
    </mat-form-field>
    <!-- <ng-container *ngFor="let pfc of this.itemDetail.product.productFeatureChoices; let i = index;">
      <mat-form-field style="width: 100px">
        <input matInput placeholder="Size" [ngModel]="pfc.featureChoice.choiceValue" readonly="readonly" />
      </mat-form-field>
    </ng-container> -->
  </div>

  <div uni-flex-row>
    <mat-form-field style="width: 350px">
      <input matInput placeholder="Account" [ngModel]="itemDetail.account?.accountName" readonly="readonly" />
    </mat-form-field>
  </div>


  <div uni-flex-row>
    <mat-form-field>
      <mat-label>Damaged/Lost type</mat-label>
      <mat-select style="width: 350px;" [ngModel]="itemReplacementType" (ngModelChange)="onItemReplacementTypeChange($event)">
        <mat-option [value]="itemReplacementType" *ngFor="let itemReplacementType of itemReplacementTypes">
          {{itemReplacementType.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [matTooltip]="itemReplacementStatus?.name">
      <mat-label>Status</mat-label>
      <mat-select style="width: 350px;" [disabled]="itemReplacementType == null || (itemReplacementType.isManufacturerRelated === 'N')" [(ngModel)]="itemReplacementStatus">
        <mat-option [value]="itemReplacementStatus" *ngFor="let itemReplacementStatus of itemReplacementStatuses">
          {{itemReplacementStatus.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</ng-template>

<ng-template #footerTemplate>
  <button [disabled]="itemReplacementType == null" mat-raised-button (click)="apply()">Apply</button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>
