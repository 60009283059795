import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { EntityAspect } from 'breeze-client';

/**
 * BreezeValidator - Uses breeze entity validation to validate an Angular FormControl
 * @example
 * <mat-form-field>
 *   <input matInput placeholder="EOQ Size" [(ngModel)]="product.eOQSize" [bzModel]="product" bzProperty="eOQSize" #eoqsize="ngModel" />
 *   <mat-error>{{eoqsize?.errors?.bzErrors}}</mat-error>
 * </mat-form-field>
 */
@Directive({
  selector: '[bzModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: BreezeValidator, multi: true }
  ]
})
export class BreezeValidator implements Validator {
  // tslint:disable: no-input-rename
  @Input('bzModel') bzModel: any; // the breeze entity classentity
  @Input('bzProperty') bzProperty: string;
  @Input('name') name: string;
  @Input('placeholder') placeholder: string;

  constructor() { }

  /** @returns null if no errors; otherwise, an object with a 'bzErrors' property containing the message */
  validate(c: AbstractControl) {
    if (c.pristine) { return null; }
    const aspect = this.bzModel.entityAspect as EntityAspect;
    if (!aspect) { return null; }

    // we need to call the property validator manually, 
    // because the entity is not updated until after the validator runs
    const property = aspect.entityGroup.entityType.getProperty(this.bzProperty ?? this.name);
    const displayName = this.placeholder || property.displayName || this.bzProperty;
    const errors = property.validators.map(v => v.validate(c.value, { displayName: displayName })).filter(r => !!r);

    if (errors && errors.length) {
      const message = errors.map(e => e.errorMessage).join('; ');
      return { 'bzErrors': message };
    } else {
      return null;
    }
  }
}
