<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>



  <mat-tab-group *ngIf="manufacturer" (selectedTabChange)="onTabChanged($event)">
    <mat-tab label="General Info">
      <div class="mt-12" uni-flex-row>
        <mat-form-field style="width: 400px;" [matTooltip]="manufacturer.name">
          <input matInput placeholder="Name" [(ngModel)]="manufacturer.name"  />
        </mat-form-field>
        <mat-form-field style="width: 200px;" [matTooltip]="manufacturer?.activeStatus?.name">
          <mat-select [disabled]="!this.user.isProductAdmin" [(ngModel)]="manufacturer.activeStatusId" placeholder="Status">
            <mat-option [value]="activeStatus.id" *ngFor="let activeStatus of activeStatuses">
              {{activeStatus.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div uni-flex-row>
        <mat-form-field style="width: 400px;" [matTooltip]="manufacturer.description">
          <input matInput placeholder="Description" [(ngModel)]="manufacturer.description"  />
        </mat-form-field>
      </div>
      <div uni-flex-row>
        <mat-form-field style="width: 200px;" [matTooltip]="manufacturer.leadTimeDays?.toString()">
          <input matInput type="number" placeholder="Lead Time (Days)" [(ngModel)]="manufacturer.leadTimeDays"  />
        </mat-form-field>
        <mat-form-field style="width: 200px;">
          <mat-select [(ngModel)]="manufacturer.poDocSubmitType"   placeholder="Doc Submit Type">
            <mat-option [value]="dst" *ngFor="let dst of docSubmitTypes">
              <span>{{dst.name}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div uni-flex-row>
        <mat-form-field style="width: 200px;" [matTooltip]="manufacturer.accountReferenceName">
          <input matInput placeholder="Account Reference" [(ngModel)]="manufacturer.accountReferenceName"  />
        </mat-form-field>
        <div style="width: 400px">
          <app-image-handler [oldImages]="manufacturer.manufacturerImages" (createJoinEntity)="createImageJoinEntity($event)"></app-image-handler>
        </div>
    </div>
    </mat-tab>
    <mat-tab label="Billing Info">
      <app-address-component [entity]="manufacturer" [prefix]="'billing'" [isReadonly]="false"></app-address-component>
    </mat-tab>
    <mat-tab label="Warehouse Info">
      <app-address-component [entity]="manufacturer" [prefix]="'wh'" [isReadonly]="false"></app-address-component>
    </mat-tab>

  </mat-tab-group>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>