// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Manufacturer } from './manufacturer';
import { PoDetail } from './po-detail';
import { PoNote } from './po-note';
import { PoStatus } from './po-status';
import { Warehouse } from './warehouse';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class PoHeader extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  // conceptual Note interface
  getNotes() {
    return this.poNotes;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  crtnTs: Date;
  instructions: string;
  isLocked: boolean;
  manufacturerId: number;
  poDate: Date;
  poStatusId: string;
  rowVersion: any;
  shippingWarehouseId: number;
  manufacturer: Manufacturer;
  poDetails: PoDetail[];
  poNotes: PoNote[];
  poStatus: PoStatus;
  shippingWarehouse: Warehouse;
}

