import { Component, Input, OnInit } from '@angular/core';
import { ColDef, ColGroupDef, ColumnApi, GetRowIdParams, GridApi, GridOptions, IGetRowsParams, RowDoubleClickedEvent } from '@ag-grid-community/core';
import { UtilFns } from './util-fns';
import * as _ from 'lodash';

@Component({
  selector: 'app-ag-grid',
  templateUrl: './ag-grid-wrapper.component.html'
})
export class AgGridWrapperComponent implements OnInit {
  @Input() gridOptions: GridOptions;
  @Input() rowSelection?: 'single' | 'multiple';
  @Input() rowData?: any[];
  @Input() hasFooter = true;
  constructor() {

  }

  ngOnInit() {
    this.rowSelection = this.gridOptions.rowSelection;
  }

  hasFilters() {
    const fm = this.gridOptions?.api?.getFilterModel();
    return !_.isEmpty(fm);
  }

  // // type guard
  // isColDef(toBeDetermined: ColDef | ColGroupDef): toBeDetermined is ColDef {
  //   return ((toBeDetermined as ColGroupDef).children) ? false : true;
  // }

  clearAllFilters() {
    this.gridOptions?.api?.setFilterModel(null);
    this.gridOptions?.api?.deselectAll();
  }


}
