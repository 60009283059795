<div>
  <button mat-icon-button class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="ml-12">
    <h2>Printer Setup</h2>
  </div>

  <div *ngIf="errorMessage" class="ml-12">
    <h3 style="color: darkred">{{errorMessage}}</h3>
    <div *ngIf="showLink">
      Visit <a href="https://qz.io/" target="_blank">https://qz.io/</a> to install QZ Tray.
    </div>
  </div>

  <div *ngIf="clientPrinters">
    <div uni-flex-row class="mt-12 ml-12">
      <mat-form-field>
        <mat-label>Barcode Printer (ZPL only)</mat-label>
        <mat-select style="width: 300px;" [(ngModel)]="barcodePrinter" (selectionChange)="save()">
          <mat-option [value]="printer.name" *ngFor="let printer of clientPrinters">{{printer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div uni-flex-row class="mt-12 ml-12">
      <mat-form-field>
        <mat-label>Invoice Printer</mat-label>
        <mat-select style="width: 300px;" [(ngModel)]="invoicePrinter" (selectionChange)="save()">
          <mat-option [value]="printer.name" *ngFor="let printer of clientPrinters">{{printer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div uni-flex-row class="mt-12 ml-12">
      <mat-form-field>
        <mat-label>Shipping Label Printer</mat-label>
        <mat-select style="width: 300px;" [(ngModel)]="labelPrinter" (selectionChange)="save()">
          <mat-option [value]="printer.name" *ngFor="let printer of clientPrinters">{{printer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div uni-flex-row class="mt-12 ml-12">
    <mat-form-field>
      <mat-label>Shipping Label Format</mat-label>
      <mat-select style="width: 300px;" [(ngModel)]="labelFormat" (selectionChange)="save()">
        <mat-option [value]="format" *ngFor="let format of labelFormats">{{format}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div uni-flex-row class="m-12">
  </div>

  <hr/>
  <div class="m-12">
    <div class="pt-12">
      <button mat-raised-button (click)="testBarcode()">
        <span>Test Barcode Printer</span>
      </button><br/>
      Sends ZPL to Barcode Printer
    </div>
    <div class="mt-12">
      <button mat-raised-button (click)="testShippingLabel()">
        <span>Test Shipping Label</span>
      </button><br/>
      Downloads from ShipEngine in Label Format
    </div>

    <div class="mt-12">
      <button mat-raised-button (click)="testEmail()">
        <span>Send Test Email</span>
      </button><br/>
      Sends a test email to yourself <br/>
      {{ emailStatus }}
    </div>

    <div class="mt-12">
      <button mat-raised-button (click)="testQuickBooks()">
        <span>Test Quickbooks</span>
      </button><br/>
      Gets company info from QuickBooks <br/>
      {{ qbStatus }}
    </div>

  </div>

</div>