// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Feature } from './feature';
import { ProductType } from './product-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ProductTypeFeatureMap extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  productTypeId: number;
  featureId: number;
  displayOrder: number;
  feature: Feature;
  productType: ProductType;
}

