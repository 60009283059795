<ag-grid-angular 
  style="width: 100%; height: 100%;" 
  class="ag-theme-balham" 
  [rowHeight]="27" 
  animateRows
  [pagination]="hasFooter" 
  
  [gridOptions]="gridOptions" 
  [rowSelection]="rowSelection"
  [rowData]="rowData">
</ag-grid-angular>
<div *ngIf="hasFooter" class="grid-footer">
  <a (click)="this.gridOptions?.columnApi?.autoSizeAllColumns()">[ Autosize Columns ]</a>
  <a class="ml-12" (click)="this.gridOptions?.api?.sizeColumnsToFit()">[ Size to Fit ]</a>
  <a class="ml-12" [hidden]="!hasFilters()" (click)="this.clearAllFilters()">[ Clear all filters ]</a>
</div>  