
import {Component} from '@angular/core';
import {ITooltipParams, ITooltipComp} from "@ag-grid-community/core";
import {ITooltipAngularComp} from "@ag-grid-community/angular";

// @Component({
//   selector: 'tooltip-component',
//   template: `
//      <div class="custom-tooltip" [style.background-color]="color">
//          help
//      </div>`,
//   styles: [
//     `
//            :host {
//                position: absolute;
//                width: 150px;
//                height: 70px;
//                pointer-events: none;
//                transition: opacity 1s;
//            }

//            :host.ag-tooltip-hiding {
//                opacity: 0;
//            }

//            .custom-tooltip p {
//                margin: 5px;
//                white-space: nowrap;
//            }

//            .custom-tooltip p:first-of-type {
//                font-weight: bold;
//            }
//        `
//   ]
// })
// export class AltBaseTooltipComponent implements ITooltipAngularComp {
//   private params: { color: string } & ITooltipParams;
//   data: any[];
//   color: string;

//   agInit(params: { color: string } & ITooltipParams): void {
//     this.params = params;

//     this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
//     this.color = this.params.color || 'white';
//   }
// }

export class BaseTooltipComponent implements ITooltipComp {
  eGui: HTMLDivElement;
  init(params: ITooltipParams) {
    const eGui = this.eGui = document.createElement('div');
    const color = 'yellow';

    eGui.classList.add('custom-tooltip');
    eGui.style['background-color'] = color;
    eGui.innerHTML = this.renderDataAsHTML(params) 
      
  }

  getGui() {
    return this.eGui;
  }

  renderDataAsHTML(params: ITooltipParams) {
    return `<p>The 'renderDataAsHTML' method should be overriden - It has not been. `
  }
  
}

export class LinkHelpTooltipComponent extends BaseTooltipComponent {
  renderDataAsHTML(params: ITooltipParams): string {
    // const data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    // this.eGui.style['background-color'] = 'red';
    return 'Hold the Ctrl key when clicking to open this link in a new window';
  }
} 

