import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActiveStatus, FeatureChoice, Product, Manufacturer, ProductFeatureChoice, Feature } from 'app/model/entities/entity-model';
import { ProductType } from 'app/model/entities/product-type';
import { AgFns } from 'app/shared/ag-fns';
import { AuthService, AuthUser } from 'app/shared/auth.service';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';

@Component({
  templateUrl: './edit-feature-choice-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class EditFeatureChoiceDialogComponent {
  user: AuthUser;
  activeStatuses: ActiveStatus[];
  featureChoice: FeatureChoice;
  feature: Feature;
  availableFeatureChoices: FeatureChoice[];
  isAdding: boolean;
  title: string;
  isPageReady = false;

  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(EditFeatureChoiceDialogComponent, {
        disableClose: true,
        height: '600px',
        width: '900px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditFeatureChoiceDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, private uow: UnitOfWork, 
    private authService: AuthService, private dialogService: DialogService, public busyService: BusyService) {

    this.user = this.authService.getUser();

    this.activeStatuses = this.dbQueryService.getAllCached(ActiveStatus);

    this.prepare();
  }

  private async prepare() {
    

    this.featureChoice = this.data.featureChoice;
    if (this.featureChoice == null) {
      this.isAdding = true;
      this.feature = this.data.feature;
      this.featureChoice = this.createNewFeatureChoice();
    } else {
      this.feature = this.featureChoice.feature;
    }

    
    this.availableFeatureChoices = await this.dbQueryService.getFeatureChoices(this.feature.id);
    this.availableFeatureChoices.filter(x => x.id == this.featureChoice.id);
    this.availableFeatureChoices = _.sortBy(this.availableFeatureChoices, x => x.displayOrder);
    this.availableFeatureChoices.unshift( { id: null as number, choiceValue: '- None -' } as FeatureChoice )

    this.title = this.isAdding ? 'Creating New Feature Choice' : 'Editing Feature Choice';

    UtilFns.wait(0);
    
    this.isPageReady = true;
  }

  private createNewFeatureChoice() {
    const fc = this.dbSaveService.createEntity(FeatureChoice, {
      featureId: this.feature.id,
      activeStatusId: 1,
    });
    
    return fc;
  }

    
  async ok() {
    const ok = await this.save();
    if (!ok) {
      return;
    }
    this.dialogRef.close(true);
  }

  async save() {
    if (this.isAdding) {
      this.featureChoice._origChoiceValue = this.featureChoice.choiceValue;
    }
    const isValid = await this.validate();
    if (!isValid) { return false; }
  
    try {
      const sr = await this.dbSaveService.saveChanges();
    } catch (e) {
      if (this.isAdding) {
        this.uow.rollback();
      }
      return false;
    }
    return true;
  }

  async validate() {
    
    this.featureChoice._origChoiceValue = this.featureChoice._origChoiceValue ?? this.featureChoice.choiceValue;
    
    let matchingEntity = await this.dbQueryService.getMatchingEntity(FeatureChoice, this.featureChoice,  ['choiceValue', 'featureId' ],);
    if (matchingEntity != null) {
      await this.dialogService.showOkMessage('Duplicate', `Cannot save because this Feature Choice is a duplicate of an existing FeatureChoice. `);
      return false;
    }


    const ea = this.featureChoice.entityAspect;
    if (! ea.validateEntity()) {
      const vErrors = ea.getValidationErrors();
      const errMsg = vErrors.map(ve => ve.errorMessage).join('<br>');
      await this.dialogService.showOkMessage('Validation Error(s)', errMsg);
      return false;
    }


    return true;
  }

  async cancel() {
    // insures that we don't try to redisplay the page after the reject changes.
    this.isPageReady = false;
    // entityAspect may not exist if it is a new object
    this.featureChoice.entityAspect?.rejectChanges();
    this.dialogRef.close(false);
  }


}
