<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div class="content-card" style="height: calc(100% - 30px);">
    <app-ag-grid [gridOptions]="sfGridOptions" [rowData]="shipFrequencies">
    </app-ag-grid>
  </div>



</ng-template>

<ng-template #footerTemplate>
  
  
  <button class="ml-8" mat-raised-button [disabled]="!hasChanges()" (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>
