import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr';

import { LicenseManager } from '@ag-grid-enterprise/core';
import * as Sentry from "@sentry/angular-ivy";
// import { BrowserTracing } from "@sentry/tracing";

LicenseManager.setLicenseKey(
  //'CompanyName=Unitec Distribution Systems,LicensedApplication=prox,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-016024,ExpiryDate=28_May_2022_[v2]_MTY1MzY5MjQwMDAwMA==55b308776dbcdd842ceace64371b625e'
  'Using_this_AG_Grid_Enterprise_key_( AG-043913 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Unitec Distribution Systems )_is_granted_a_( Single Application )_Developer_License_for_the_application_( prox )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( prox )_need_to_be_licensed___( prox )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 3 August 2024 )____[v2]_MTcyMjYzOTYwMDAwMA==741f42928929fe4e09131e7eea8106da'
);

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    // new BrowserTracing({
    //   tracingOrigins: ["localhost", "uniformax.ideablade.com"],
    //   routingInstrumentation: Sentry.routingInstrumentation,
    // }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
  autoSessionTracking: false,

  attachStacktrace: true,
  maxValueLength: 500,

  environment: environment.env,
  release: environment.app + '@' + environment.version,
});

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch(err => console.log(err));
}
