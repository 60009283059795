import { Component, Input, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { JoHeader } from 'app/model/entities/jo-header';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-jo-info',
  templateUrl: './jo-info.component.html',
  animations: fuseAnimations,
})
export class JobOrderInfoComponent extends DomainBaseComponent{
  @Input() entity: JoHeader;

  constructor(
    public uow: UnitOfWork,
    protected domainService: DomainService,
    protected route: ActivatedRoute,
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {}
}
