// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { PoHeader } from './po-header';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Warehouse extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  rowVersion: any;
  whAddress1: string;
  whAddress2: string;
  whCity: string;
  whFax: string;
  whName: string;
  whState: string;
  whTelephone: string;
  whZipCode: string;
  poHeaders: PoHeader[];
}

