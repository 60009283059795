// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Account } from './account';
import { WarningType } from './warning-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class EntityIssue extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  // descLength is a [NotMapped] property on the server.  
  // Must be initialized to a value here to be recognized
  // TODO - demo only, remove after 2019
  descLength = 0;
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  accountId: number;
  applicationContext: string;
  crtnTs: Date;
  crtnUserInit: string;
  description: string;
  entityId: string;
  entityTypeName: string;
  warningTypeId: number;
  account: Account;
  warningType: WarningType;
}

