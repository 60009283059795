// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { CreditMemo } from './credit-memo';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class CreditMemoStatus extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  name: string;
  creditMemos: CreditMemo[];
}

