// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { JoDetail } from './jo-detail';
import { JoHeader } from './jo-header';
import { JoNoteType } from './jo-note-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class JoNote extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  crtnTs: Date;
  crtnUserInit: string;
  isIssue: boolean;
  joDetailId: number;
  joHeaderId: number;
  joNoteTypeId: string;
  modTs: Date;
  modUserInit: string;
  note: string;
  rowVersion: any;
  joDetail: JoDetail;
  joHeader: JoHeader;
  joNoteType: JoNoteType;
}

