import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastTypes } from './toast.service';


export interface IMessage {
    from?: string;
    message: string;
    type?: ToastTypes;
}

@Injectable({providedIn: 'root'})
export class MessageBus {
  private messageSubject = new BehaviorSubject<IMessage>(null);

  public notify(message: IMessage) {
    this.messageSubject.next(message);
  }

  public get messages() {
    return this.messageSubject.asObservable();
  }
}
