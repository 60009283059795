import { agCharts } from '@ag-grid-enterprise/charts';
import { Location } from '@angular/common';
import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AgCartesianSeriesTooltipRendererParams } from 'ag-charts-community';
import { Account } from 'app/model/entities/entity-model';

import { DbQueryService } from 'app/shared/db-query.service';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { JoStatsDialogComponent } from './jo-stats-dialog.component';
import { DateFns } from 'app/shared/date-fns';


@Component({
  selector: 'app-chart-jo-stats',
  templateUrl: './chart-jo-stats.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ChartJoStatsComponent extends DomainBaseComponent implements OnInit {
  @Input() account: Account;
  title: string;
  options: any;
  
  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,
    protected location: Location,
    protected dbQueryService: DbQueryService,
  ) {
    super(domainService);

  }

  async ngOnInit() {
    await this.processChartData();
    this.isPageReady = true;
  }

     //this will called when data is passed from parent to child.
  async ngOnChanges(val){
    this.isPageReady = false;
    await this.processChartData();
    this.isPageReady = true;  
  }

  private async processChartData() {
    // this.title = this.getTitle();
    const data = await this.dbQueryService.getJobOrdersByStatusForChart();
    DateFns.fixupDatesInProjection(data, ['monthStart']);

    // reformat data for chart
    const oData = {};
    data.forEach(d => {
      const fDate = this.formatDateAsMonth(d.monthStart);
      let cell = oData[ fDate ];
      if (cell == null) {
        cell = { sortKey: d.monthStart, monthStart: fDate, '2000.00': 0, '2100.00': 0};
        oData[fDate] = cell;
      }
      cell[ d.joStatusId] = d.count;
    });
    let rData = Object.values(oData);
    rData = _.sortBy(rData, (x: any) => x.sortKey);

    this.options = {
      title: {
        text: this.getTitle(),
      },
      data: rData,
      
      series: [
        {
          type: 'column',
          xKey: 'monthStart',
          yKey: '2000.00',
          yName: 'Incomplete',
          tooltip: { renderer: this.renderer.bind(this) },
          listeners: {
            nodeClick: async (event: any) => {
              const data = { joStatusId: event.yKey, dateInMonth: event.datum.sortKey };
              await JoStatsDialogComponent.show(this.matDialog, data);
            },
          },
        },
        {
          type: 'column',
          xKey: 'monthStart',
          yKey: '2100.00',
          yName: 'Complete',
          tooltip: { renderer: this.renderer.bind(this) },
        },
      ],
      
    };
  }


  getTitle() {
    return `Current Job Orders by Age and Status`;
  }

  renderer(params: AgCartesianSeriesTooltipRendererParams) {
    return {
      title: params.yName,
      content: 'Created on ' + params.xValue + ': ' + params.yValue + '<br><b>Click to see details</b>'
    };
  }

  formatDateAsMonth(date: Date) {
    const options = { year: 'numeric', month: 'long',  } as any;
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }


  
}
