import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { UserAccountDialogComponent } from 'app/admin/user-account-dialog.component';
import { AuthUser } from 'app/shared/auth.service';
import { DomainService } from 'app/shared/domain.service';

@Component({
  selector: 'in-process',
  templateUrl: './in-process.component.html',
  styleUrls: ['./home.component.scss']
})
export class InProcessComponent {
  user: AuthUser;

  constructor(
    protected domainService: DomainService,
    private _matDialog: MatDialog
  ) {

    this.user = domainService.authService.getUser();
  }

  goBack() {
    this.domainService.location.back();
  }
  
}
