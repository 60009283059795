import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { CellClickedEvent, ColDef, GridOptions, RowSelectedEvent, GetRowIdParams } from '@ag-grid-community/core';
import { PoDetailTypeCode } from 'app/model/enums/po-detail-type-code';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { AgFns, GridState, ISortModel } from 'app/shared/ag-fns';
import { EditNotesDialogComponent } from 'app/shared/edit-notes-dialog.component';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Account, AddonStation, ItemDetail, JoHeader, Manufacturer, PoDetail, PoDetailCancel, PoHeader, PoNote, PoStatus, Product, ProductTypeTag } from '../model/entities/entity-model';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { CombinePurchaseOrdersDialogComponent } from './combine-purchase-orders-dialog.component';
import { CreatePoDialogComponent } from './create-po-dialog.component';
import { EditPodDialogComponent } from './edit-pod-dialog.component';
import { PoPrintDialogComponent } from './po-print-dialog.component';
import { SplitPurchaseOrdersDialogComponent } from './split-purchase-orders-dialog.component';
import { NavFns } from 'app/shared/nav-fns';
import { EditPoInstructionsDialogComponent } from './edit-po-instructions-dialog.component';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgButtonProps } from 'app/shared/button-renderer.component';

class PodGroup {
  origProductId: number;
  origProduct?: Product;
  productId: number; 
  product: Product;
  qty: number;
  heldQty: number;
  receivedQty: number;
  canceledQty: number;
  poDetails: PoDetail[];
}

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class PurchaseOrdersComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('lockPoCell') lockPoCell: TemplateRef<any>;
  @ViewChild('combineCell') combineCell: TemplateRef<any>;
  @ViewChild('editPoNotesCell') editPoNotesCell: TemplateRef<any>;
  @ViewChild('editPoInstructionsCell') editPoInstructionsCell: TemplateRef<any>;
  @ViewChild('editPodNotesCell') editPodNotesCell: TemplateRef<any>;
  @ViewChild('receivePoCell') receivePoCell: TemplateRef<any>;
  @ViewChild('printCell') printCell: TemplateRef<any>;
  @ViewChild('addPodCell') addPodCell: TemplateRef<any>;
  @ViewChild('editPodCell') editPodCell: TemplateRef<any>;

  poHeaders: PoHeader[];
  poGridOptions: GridOptions;


  podGroups: PodGroup[] = [];
  podGroupGridOptions: GridOptions;

  jobOrders: JoHeader[] = [];
  joGridOptions: GridOptions;

  allStatuses: PoStatus[];
  allStatusesExt: PoStatus[];
  nullPoStatus = <PoStatus>{ id: '', name: 'All' };
  selectedStatuses: PoStatus[] = [this.nullPoStatus];

  currentPoHeader: PoHeader;

  isUnpostedOnly: boolean;
  isOpenOnly: boolean;


  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
    
  }

  async updateFromContext() {
    // this.gridState = AgFns.createGridState(this.route.snapshot.queryParams, 'id');
    this.allStatuses = this.dbQueryService.getAllCached(PoStatus);
    this.allStatusesExt = this.allStatuses.slice();
    this.allStatusesExt.unshift(this.nullPoStatus);

    this.poGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPoGridReady,
      onRowSelected: this.onRowSelected,
      onModelUpdated: this.onPoModelUpdated, 
      onFilterChanged: this.onFilterChanged,
      rowModelType: 'serverSide',
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.clearCurrentSelection();
        }
      },
    })
    AgFns.captureGridRouteParams(this.poGridOptions, this.route, 'id');

    if (this.poGridOptions.context?.gridState?.fm == null) {
      this.selectedStatuses = [this.nullPoStatus];
    }

    this.podGroupGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPodGridReady,
      getRowId: (x) => {
        return (x.data as PodGroup).origProductId.toString();
      }
    }, { detailProperty:  'poDetails' });

    this.joGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJoGridReady,
      
    });

    this.isPageReady = true;
  }

  canDeactivate() {
    this.uow.clearEntities(PoHeader);
    this.uow.clearEntities(PoDetail);
    this.uow.clearEntities(Account);
    this.uow.clearEntities(Manufacturer);
    this.uow.clearEntities(ItemDetail);
    this.uow.clearEntities(PoNote);
    this.uow.clearEntities(PoDetailCancel);
    return true;
  }

  // This method is called AFTER ngAfterViewInit - this is important
  // because the ColDef's here reference TemplateRef's above that will not be resolved until here.
  onPoGridReady(evt: any) {
    const receiveOpbuttonProps: AgButtonProps = {
      calcDisabled: this.areReceiveOpsDisabled.bind(this)
    }
    const colDefs = [
      { headerName: 'Purchase Order', field: 'id', filter: 'agNumberColumnFilter' },
      { headerName: 'Manufacturer', field: 'manufacturer.name', filter: 'agTextColumnFilter' },
      { ...AgFns.createSetFilterObjectProps('Status', 'poStatus.name', this.allStatuses) },
      { headerName: 'PO Date', field: 'poDate', filter: 'agDateColumnFilter' },
      { ...AgFns.createButtonProps('Lock', this.lockPo.bind(this), {
          calcLabel: this.calcLockPoLabel.bind(this), ...receiveOpbuttonProps
        } ), maxWidth: 70, minWidth: 70 
      },
      { ...AgFns.createButtonProps('Combine', this.combine.bind(this), receiveOpbuttonProps), maxWidth: 90, minWidth: 90 },
      { ...AgFns.createButtonProps('Split', this.split.bind(this), receiveOpbuttonProps ) , maxWidth: 80, minWidth: 80   }, 
      { ...AgFns.createButtonProps('Receive', this.receivePo.bind(this), receiveOpbuttonProps ) , maxWidth: 90, minWidth: 90   }, 
      { ...AgFns.createButtonProps('Notes', this.editPoNotes.bind(this), {
        calcClass: this.calcEditPoNotesClass.bind(this)
        }), maxWidth: 90, minWidth: 90  
       }, 
      { ...AgFns.createButtonProps('Instructions', this.editPoInstructions.bind(this), {
        calcClass: this.calcEditPoInstructionsClass.bind(this)
        }), maxWidth: 110, minWidth: 110  
      }, 
      { ...AgFns.createButtonProps('Print', this.printPo.bind(this), receiveOpbuttonProps ) , maxWidth: 90, minWidth: 90   }, 
      { ...AgFns.createButtonProps('Add Stock', this.addPod.bind(this),{
        label: `Add 'Stock' Detail`, ...receiveOpbuttonProps 
      }) 
        , maxWidth: 120, minWidth: 120   }, 
      
    ];

    const sortModel = [
        { colId: 'manufacturer.name', sort: 'asc' },
        { colId: 'id', sort: 'desc' }
      ] as ISortModel;
    
    // next line is needed by the buildDatasource method
    AgFns.initGrid(this.poGridOptions, colDefs, sortModel);

    
    // This needs to be done last in order to avoid excess query calls
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.poGridOptions);

    this.updateColumnVisibility();
  }

  
  
  areReceiveOpsDisabled() {
    return !this.authUser.isReceiveAdmin;
  }

  onPodGridReady(evt: any) {
    const colDefs: ColDef[] = [
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style', ...NavFns.createCellClickedCalcNavProps(this.router,
        (event) => { 
          const podg = <PodGroup> event.data;
          return { navPath: `/product-type/${ podg.product.productTypeId }/` }; 
        }) },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { ...AgFns.createButtonProps('Update for reorder', this.onUpdateToReorderProduct.bind(this), 
         { calcLabel: this.getProductChangeLabel.bind(this),  calcDisabled: this.isProductChangeDisabled.bind(this) }), 
         width: 160
      },
      { headerName: 'Orig. Features', valueGetter: params => {
        const podg = <PodGroup> params.data;
        if (podg.productId == podg.origProductId) {
          return '[same]';
        } else {
          return podg.origProduct.getFeatureChoicesExtract();
        }
      }
      },
      { headerName: 'Order Qty', field: 'qty', type: 'numericColumn', maxWidth: 100 },
      { headerName: 'Held Qty', field: 'heldQty', type: 'numericColumn', maxWidth: 100 },
      { headerName: 'Received Qty', field: 'receivedQty', type: 'numericColumn', maxWidth: 100 },
      { headerName: 'Canceled Qty', field: 'canceledQty', type: 'numericColumn', maxWidth: 100 },
    ];
    const sortFields = [
      'product.productType.manufacturer.name',
      'product.productType.description',
      'product.productType.style',
      'product.getSortOrder()'
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });
    
    this.podGroupGridOptions.getRowClass = function(params) {
      const pod = params.node.data as PodGroup
      if (pod && (pod.qty - (pod.receivedQty + pod.canceledQty)) <= 0) {
        return 'rs-completed';
      }
    };
    this.updatePodMasterDetail(this.podGroupGridOptions);
    AgFns.initGrid(this.podGroupGridOptions, colDefs, sortModel, true);
  }

  isProductChangeDisabled(podGroup: PodGroup) {
    if (podGroup.origProductId != podGroup.productId) {
      return false;
    }
    
    if (!podGroup.product?.productType.allowReorderFeatureChoices) {
      return true;
    }
    if (podGroup.product.productFeatureChoices.every(pfc => pfc.featureChoice.reorderFeatureChoiceId == null)) {
      return true;
    }
    return false;
  }

  getProductChangeLabel(podGroup: PodGroup) {
    if (podGroup.origProductId == podGroup.productId) {
      return 'Update for reorder'
    } else {
      return 'Revert to original'
    }
  }

  updatePodMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'PO Detail Id', field: 'id', width: 90 },
      { headerName: 'Expected Date', field: 'expectedDate', width: 110 },
      { headerName: 'Type', field: 'poDetailType.name', width: 80 },
      { ...AgFns.createCellButtonProps('Edit', this.editPodCell, null, 120) },
      { ...AgFns.createCellButtonProps('Notes', this.editPodNotesCell, null, 80) },
      { headerName: 'Order Qty', field: 'orderQty', width: 80, type: 'numericColumn' },
      { headerName: 'Held Qty', field: 'heldQty', width: 80, type: 'numericColumn' },
      {
        headerName: 'Received', width: 80, type: 'numericColumn',
        valueGetter: params => {
          const pod = <PoDetail>params.data;
          return pod.getReceivedQty();
        },
      },
      {
        headerName: 'Canceled', width: 80, type: 'numericColumn',
        valueGetter: params => {
          const pod = <PoDetail>params.data;
          return pod.getCanceledQty();
        },
      },
      { headerName: 'JO Detail Id', field: 'joDetailId', width: 100 },
      { headerName: 'JO Header Id', 
        ...NavFns.createIdCellClickedNavProps('joDetail.joHeaderId', this.router, '/job-orders'), width: 100,  
      },
      { headerName: 'JO Status', field: 'joDetail.joHeader.joStatus.name', width: 90 },
      { headerName: 'JO Ship By', field: 'joDetail.joHeader.shipByDate', width: 90 },
      { headerName: 'Account', field: 'joDetail.joHeader.account.accountName', width: 200 },
    ];
      
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const podGroup = params.data as PodGroup;
        params.successCallback(podGroup.poDetails);
      },
    };
  }

  onJoGridReady(evt: any) {
    const colDefs = [
      // { headerName: 'Job Order', field: 'id' },
      { headerName: 'Job Order', 
        ...NavFns.createIdCellClickedNavProps('id', this.router, '/job-orders'),  
      },
      { headerName: 'Account', field: 'account.accountName' },
      { headerName: 'JO Date', field: 'joDate' },
      { headerName: 'Days to Ship', field: 'numDaysToShip', type: 'numericColumn' },
      { headerName: 'JO Status', field: 'joStatus.name' },
    ];
    const sortModel = [
      { colId: 'account.accountName', sort: 'asc' }, 
      { colId: 'joDate', sort: 'asc' }
    ] as ISortModel;
    AgFns.initGrid(this.joGridOptions, colDefs, sortModel);
  }

  get buttonPoStatus() {
    const statuses = this.selectedStatuses;
    return statuses && statuses.length === 1 ? statuses[0] : this.nullPoStatus;
  }
  set buttonPoStatus(value: PoStatus) {
    if (value === undefined) {
      return;
    }
    if (this.buttonPoStatus !== value) {
      this.selectedStatuses = [value];
      this.filterByPoStatus([value]);
    }
  }

  private filterByPoStatus(poStatuses: PoStatus[]) {
    if (this.poGridOptions.api == null) {
      return;
    }
    if (poStatuses.length > 0 && poStatuses[0] === this.nullPoStatus) {
      poStatuses = [];
    }

    const filter = this.poGridOptions.api.getFilterInstance('poStatus.name');
    if (poStatuses.length === 0) {
      if (filter.getModel() == null) {
        return;
      }
      filter.setModel(null);
    } else {
      filter.setModel({
        filterType: 'set',
        values: poStatuses.map(pos => pos.name),
      });
    }

    this.poGridOptions.api.onFilterChanged();
  }

  onPoModelUpdated() {
    this.clearCurrentSelection();
  }

  async onUpdateToReorderProduct(podGroup: PodGroup) {
    if (podGroup.productId == podGroup.origProductId) {
      await this.dbQueryService.getProductType(podGroup.product.productTypeId);
      podGroup.product = podGroup.origProduct.getReorderProduct();
      podGroup.productId = podGroup.product.id;
      if (podGroup.productId == podGroup.origProductId) {
        this.dialogService.showOkMessage('No Reorder Feature Choices', 'There are no reorder feature choices for this product.');
      } else {
        podGroup.poDetails.forEach(x => x.productId = podGroup.productId);
        this.dbSaveService.saveSelectedChanges(podGroup.poDetails);
      }
    } else {
      podGroup.product = podGroup.origProduct;
      podGroup.productId = podGroup.origProductId;
      podGroup.poDetails.forEach(x => x.productId = podGroup.origProductId);
      this.dbSaveService.saveSelectedChanges(podGroup.poDetails);
    }
    AgFns.refreshGrid(this.podGroupGridOptions, this.podGroups);
  }

  onFilterChanged() {
    const filter = this.poGridOptions.api.getFilterInstance('poStatus.name');
    const model = filter.getModel();
    const selectedStatusNames = <string[]>(model ? model.values : []);
    if (selectedStatusNames.length === 0) {
      this.selectedStatuses = [this.nullPoStatus];
    } else {
      this.selectedStatuses = selectedStatusNames.map(sn => this.allStatuses.find(pos => pos.name === sn));
    }
    this.clearCurrentSelection();
    this.updateColumnVisibility();
  }

  clearCurrentSelection() {
    // insure that bound vars are cleared after any sorts or filters. - because selection is lost

    // HACK: 
    // this insures that we don't reenter here after the deselectAll call below which causes a modelUpdated event
    // which calls this.  The problem is when returning to the page this method is called before the 'key' row is selected
    // but then is called again after the row is selected ( because deselectAll causes modelUpdated after a timeout) and this 
    // 2nd call clears the row that has just been selected. UGH.
    if (this.currentPoHeader === null) {
      return;
    }
    this.currentPoHeader = null;

    this.podGroups = [];
    this.jobOrders = [];
    
    this.updateLocation();
    this.poGridOptions.api?.deselectAll();
    
  }

  updateColumnVisibility() {
    this.isUnpostedOnly =
      this.selectedStatuses.length === 1 && this.selectedStatuses[0].id === PoStatusCode.NewUnposted;
    this.isOpenOnly =
      this.selectedStatuses.length === 1 && this.selectedStatuses[0].id === PoStatusCode.OpenIncomplete;

    const poColApi = this.poGridOptions.columnApi;
    if (poColApi) {
      poColApi.setColumnsVisible(['combine'], this.isUnpostedOnly);
      poColApi.setColumnsVisible(['split'], this.isUnpostedOnly);
      poColApi.setColumnsVisible(['isLocked'], this.isUnpostedOnly);
      poColApi.setColumnsVisible(['addStock'], this.isUnpostedOnly);
      poColApi.setColumnsVisible(['receive'], this.isOpenOnly);
    }
    const podgColApi = this.podGroupGridOptions.columnApi;
    if (podgColApi) {
      podgColApi.setColumnsVisible(['updateForReorder'], this.isUnpostedOnly);
    }
  }

  updateDatasource(returnFn?: (r: PoHeader[]) => void) {
    const gridApi = this.poGridOptions.api;
    if (gridApi == null) {
      return;
    }
    const opts = {
      returnFn: returnFn
    };
    const ds = AgFns.buildDatasource(() => this.dbQueryService.createPoHeaderQuery(null), null, opts)
    gridApi.setServerSideDatasource(ds);
  }

  refreshDatasource() {
    const returnFn = async (r: PoHeader[]) => {
      if (this.currentPoHeader != null) {
        await this.queryPoDetails(this.currentPoHeader, true);
        
        const rowNode = this.poGridOptions.api.getRowNode(this.currentPoHeader.id.toString());
        if (rowNode != null) {
          this.poGridOptions.api.ensureIndexVisible(rowNode.rowIndex, 'middle');
          rowNode.setSelected(true);
        }
      }
    }
    this.updateDatasource(returnFn);
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/purchase-orders']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.poGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  getTitle() {
    const names = this.selectedStatuses.map(pos => pos.name);
    if (names && names.length > 0) {
      return ' - ' + names.join(', ');
    } else {
      return ' - All statuses';
    }
  }

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const po = e.data as PoHeader;
    if (!po) {
      return;
    }
    this.currentPoHeader = po;

    this.updateLocation(po.id);

    await this.queryPoDetails(po);
    const podColApi = this.podGroupGridOptions.columnApi;
    if (podColApi) {
      podColApi.setColumnsVisible(['edit'], this.isUnpostedOnly);
    }
  }

  calcLockPoLabel(poh: PoHeader) {
    return poh.isLocked ? 'Unlock' : 'Lock';
  }


  async lockPo(poh: PoHeader) {
    await AgFns.busyGrid([ this.poGridOptions], this.busyService, async () => { 
      poh.isLocked = !poh.isLocked;
      await this.dbSaveService.saveSelectedChanges([poh]);
    });
  }

  async queryPoDetails(poh: PoHeader, shouldForceQuery = false) {
    return await AgFns.busyGrid([ this.podGroupGridOptions, this.joGridOptions], this.busyService, () => this.queryPoDetailsCore(poh, shouldForceQuery) );
  }

  async queryPoDetailsCore(poh: PoHeader, shouldForceQuery = false) {
    
    // Can't use breeze.isNavigationLoaded here because we need to check for deeper
    // navigation than just joDetails.
    let poDetails: PoDetail[];
    
    if (shouldForceQuery || !poh['arePoDetailsLoaded']) {
      poDetails = await this.dbQueryService.getPoDetailsExpanded(poh.id);
      poh['arePoDetailsLoaded'] = true;
    } else {
      poDetails = poh.poDetails;
    }
    // group poDetails into PodGroups
    const podGroups = [] as PodGroup[];
    poDetails.forEach(pod => {
      let podGroup = podGroups.find(x => x.origProductId === pod.origProductId);
      if (!podGroup) {
        podGroup = {
          origProductId: pod.origProductId,
          origProduct: pod.origProduct,
          productId: pod.productId, // used to insure uniqueness,
          product: pod.product,
          qty: 0,
          heldQty: 0,
          receivedQty: 0,
          canceledQty: 0,
          poDetails: []
        };
        podGroups.push(podGroup);
      }
      podGroup.poDetails.push(pod);
      podGroup.qty += pod.orderQty;
      podGroup.heldQty += pod.heldQty;
      podGroup.receivedQty += pod.getReceivedQty();
      podGroup.canceledQty += pod.getCanceledQty();
    });
    
    this.podGroups = podGroups;
    this.jobOrders = _.chain(poDetails)
      .filter(pod => pod.joDetail != null)
      .map(pod => pod.joDetail.joHeader)
      .uniq()
      .value();
    
    this.podGroupGridOptions?.columnApi.setColumnsVisible(['updateForReorder'], this.isUnpostedOnly);
  }

  async combine(poh: PoHeader) {
    const r = await CombinePurchaseOrdersDialogComponent.show(this.matDialog, {
      manufacturer: poh.manufacturer,
      destPoHeader: poh,
    });
    if (r) {
      this.refreshDatasource();
    }
    return <boolean>r;
  }

  async split(poh: PoHeader) {
    // insure that all of the details have been queried - removed 4/11/22
    // await this.queryPoDetails(poh, true);
    const r = await SplitPurchaseOrdersDialogComponent.show(this.matDialog, { poHeader: poh });
    if (r) {
      this.refreshDatasource();
    }
    return <boolean> r;
  }

  calcEditPoNotesClass(poh: PoHeader) {
    return this.hasPoNotes(poh) ? 'btn-has-data' : '';
  }

  calcEditPoInstructionsClass(poh: PoHeader) {
    return this.hasPoInstructions(poh) ? 'btn-has-data' : '';
  }

  hasPoInstructions(poh: PoHeader) {
    return poh && poh.instructions && poh.instructions.length > 0;
  }

  hasPoNotes(poh: PoHeader) {
    return poh && poh.poNotes.length > 0;
  }

  hasPodNotes(pod: PoDetail) {
    return pod && pod.poNotes.length > 0;
  }

  isOrderPod(pod: PoDetail) {
    return pod && pod.poDetailTypeId === PoDetailTypeCode.Order;
  }

  async deletePod(pod: PoDetail) {
    const poh = pod.poHeader;
    pod.entityAspect.setDeleted();
    await this.dbSaveService.saveChanges();
    await this.refreshPods(poh);
  }

  async editPoNotes(poh: PoHeader) {
    await EditNotesDialogComponent.show(this.matDialog, { header: poh });
  }

  async editPoInstructions(poh: PoHeader) {
    const r = await EditPoInstructionsDialogComponent.show(this.matDialog, { poHeader: poh });
    if (r) {
      this.refreshDatasource();
    }
  }

  async editPodNotes(pod: PoDetail) {
    await EditNotesDialogComponent.show(this.matDialog, { detail: pod });
  }

  receivePo(poh: PoHeader) {
    this.router.navigate(['/purchase-order-receive', poh.id]);
  }

  async createNewPo() {
    const newPoHeader = await CreatePoDialogComponent.show(this.matDialog, {});
    if (this.poGridOptions.api) {
      this.poGridOptions.api.refreshCells();
    }
    if (newPoHeader!= null) {
      // this.currentPoHeader = newPoHeader;
      this.updateLocation(newPoHeader.id);
      this.refreshDatasource();
      await this.addPod(newPoHeader);
    }
    return newPoHeader;
  }

  async printPo(poh: PoHeader) {
    const r = await PoPrintDialogComponent.show(this.matDialog, {
      poHeader: poh,
      canPreview: true
    });
    if (this.poGridOptions.api) {
      this.poGridOptions.api.refreshCells();
    }
    return <boolean>r;
  }

  async addPod(poh: PoHeader) {
    const wasSaved = await EditPodDialogComponent.show(this.matDialog, {
      poHeader: poh,
    });
    if (wasSaved) {
      await this.refreshPods(poh);
    }
  }

  private async refreshPods(poh: PoHeader) {
    await this.queryPoDetails(poh, true);
    if (this.podGroupGridOptions.api) {
      this.podGroupGridOptions.api.refreshCells();
    }
  }

  canEditPod(pod: PoDetail) {
    return pod != null && pod.poHeader != null && pod.poHeader.poStatusId !== PoStatusCode.Complete;
  }

  canDeletePod(pod: PoDetail) {
    return this.canEditPod(pod) && !this.isOrderPod(pod);
  }

  async editPod(pod: PoDetail) {
    const wasSaved = await EditPodDialogComponent.show(this.matDialog, {
      poHeader: pod.poHeader,
      poDetail: pod
    });
    if (wasSaved) {
      await this.queryPoDetails(pod.poHeader, true);
      const detailGridInfo = this.podGroupGridOptions.api.getDetailGridInfo('detail_' + pod.productId.toString());
      detailGridInfo?.api?.refreshCells();
    }
  }

  // Removed for now - because PO's get created during pull process ( and return to inventory/damaged)
  async createPurchaseOrders() {
    this.router.navigate(['/create-purchase-orders']);
  }

  async viewEOQPurchaseOrdersNeeded() {
    this.router.navigate(['/purchase-eoq']);
  }

  async viewBackorders() {
    this.router.navigate(['/backorders']);
  }

  async onTabChanged(evt: any) {
    const currentTabLabel = evt.tab.textLabel;
    // HACK: needed because AgGrid loses buttons when tabbing from one tab to another and then back.
    this.podGroups = this.podGroups.slice(0);
    this.jobOrders = this.jobOrders.slice(0);

  }
  
}
