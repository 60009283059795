import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { GridOptions } from '@ag-grid-community/core';
import { JoPullBatch, JoStatus } from 'app/model/entities/entity-model';
import { AgFns } from 'app/shared/ag-fns';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { DialogService } from 'app/shared/dialog.service';

export interface ChangeJoPullBatchDialogComponentData {
  joPullBatchId: number;
}

@Component({
  templateUrl: './change-jo-pull-batch-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class ChangeJoPullBatchDialogComponent {

  joPullBatchId: number;
  joPullBatches: JoPullBatch[];
  jopbGridOptions: GridOptions;
  isPageReady = false;

  static async show(matDialog: MatDialog, data: ChangeJoPullBatchDialogComponentData) {
    return await matDialog
      .open(ChangeJoPullBatchDialogComponent, {
        disableClose: true,
        height: '510px',
        width: '830px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: ChangeJoPullBatchDialogComponentData, public dialogRef: MatDialogRef<ChangeJoPullBatchDialogComponent>,
     private router: Router, private dbQueryService: DbQueryService, private dialogService: DialogService, private uow: UnitOfWork) {

    this.joPullBatchId = data.joPullBatchId;
    this.prepare();
  }

  async prepare() {
    this.jopbGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJopbGridReady,
      onRowDoubleClicked: this.selectBatch,
    }, { detailProperty: 'joHeaderPulls' });

    this.joPullBatches = await this.dbQueryService.getJoPullBatches();
    this.isPageReady = true;
  }

  onJopbGridReady() {
    const colDefs = [
      { headerName: 'Batch Id', field: 'id', filter: 'agNumberColumnFilter', cellRenderer: 'agGroupCellRenderer'  },
      { headerName: 'User Init', field: 'userInit', filter: 'agTextColumnFilter' },
    ];
    this.updateJopbMasterDetail(this.jopbGridOptions);
    AgFns.initGrid(this.jopbGridOptions, colDefs);
    AgFns.sizeColumnsToFit(this.jopbGridOptions);
  }

  updateJopbMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Job Order Id', field: 'joHeaderId', width: 100 },
      { headerName: 'Account', field: 'joHeader.account.accountName', filter: 'agTextColumnFilter' },
      { ...AgFns.createSetFilterObjectProps('Status', 'joHeader.joStatus.name', this.dbQueryService.getAllCached(JoStatus)) },
      { headerName: 'JO Date', field: 'joHeader.joDate', filter: 'agDateColumnFilter' },
      
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const jopb = params.data as JoPullBatch;
        params.successCallback(jopb.joHeaderPulls);
      },
    };
  }

  async selectBatch() {
    const selectedJoPullBatches = <JoPullBatch[]> this.jopbGridOptions.api.getSelectedRows();
    if (selectedJoPullBatches.length === 0) {
      await this.dialogService.showOkMessage('Nothing was selected','No Job Order Pull Batch was selected');
      return;
    }
    this.dialogRef.close( { joPullBatchId: selectedJoPullBatches[0].id });
  }

  close() {
    this.dialogRef.close(null);
  }

}
