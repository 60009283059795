import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { AuthService } from 'app/shared/auth.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { AgFns, GridOverlay, ISortModel } from 'app/shared/ag-fns';
import { ProductType } from 'app/model/entities/product-type';
import { ProductTypeAlt, ItemDetail } from 'app/model/entities/entity-model';
import * as _ from 'lodash';
import { UtilFns } from 'app/shared/util-fns';

@Component({
  templateUrl: './alt-product-type-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class AltProductTypeDialogComponent {

  productType: ProductType;
  itsGridOptions: GridOptions;
  altProductType: ProductType;
  isPageReady = false;


  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(AltProductTypeDialogComponent, {
        disableClose: true,
        height: '570px',
        width: '1000px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AltProductTypeDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService,
    private uow: UnitOfWork, private authService: AuthService, private dialogService: DialogService) {

    // this assumes that AltProductTypes have already been populated for this productType
    this.productType = this.data.productType;
    this.prepare();

  }

  async prepare() {
    this.itsGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItsGridReady,
      onRowSelected: this.onItsRowSelected,
      rowModelType: 'serverSide',
      rowSelection: 'multiple',
    });
    this.isPageReady = true;
  }

  async onItsGridReady(evt: any) {

    const colDefs = [
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter', checkboxSelection: true },
      { headerName: 'Manufacturer', field: 'manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'description', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'style', filter: 'agTextColumnFilter' },
    ];
    const sortModel = [
      { colId: 'manufacturer.name', sort: 'asc' },
      { colId: 'description', sort: 'asc' },
      { colId: 'style', sort: 'asc' },
    ] as ISortModel;

    // next line is needed by the buildDatasource method
    AgFns.initGrid(this.itsGridOptions, colDefs, sortModel);
    this.filterByManuf();
    await UtilFns.wait(0);
    this.updateDatasource();
  }

  private filterByManuf() {
    if (this.itsGridOptions.api == null) {
      return;
    }

    const filter = this.itsGridOptions.api.getFilterInstance('manufacturer.name');
    filter.setModel({
      filterType: 'text',
      type: 'equals',
      filter: this.productType.manufacturer.name
    });
    this.itsGridOptions.api.onFilterChanged();
  }

  updateDatasource() {
    const gridApi = this.itsGridOptions.api;
    if (gridApi == null) {
      return;
    }

    const ds = AgFns.buildDatasource(() => this.dbQueryService.createProductTypesQuery(this.productType.id));
    gridApi.setServerSideDatasource(ds);
  }

  async onItsRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const its = e.data as ProductType;
    if (!its) {
      return;
    }
  }

  addAltStyle(altStyle: ProductType) {
    const struct = <ProductTypeAlt> {};
    struct.productTypeId = this.productType.id;
    struct.altProductTypeId = altStyle.id;
    struct.fitness = 0;
    // struct.crtnTs = new Date();
    // struct.crtnUserInit = this.authService.getUser().userInit,
    const newAlt = this.uow.createEntity(ProductTypeAlt, struct );
  }

  async ok() {
    const itss = this.itsGridOptions.api.getSelectedRows();
    let count = 0;
    itss.forEach(altStyle => {
      const alreadyExists = this.productType.productTypeAlts.some(ita => ita.altProductTypeId === altStyle.id );
      if (!alreadyExists) {
        count++;
        this.addAltStyle(altStyle);
      }
    })
    if (this.uow.hasChanges()) {
      await this.dbSaveService.saveChanges();
    }
    this.dialogService.toast(`${count} alternative style(s) added`);
    this.dialogRef.close(count > 0);
    
  }

  async cancel() {
    this.dialogRef.close(false);
  }

}
