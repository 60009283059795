<div  class="standard-page page-layout carded fullwidth inner-scroll">  
  <!-- TOP BACKGROUND -->

  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center accent-blue">

    <!-- HEADER -->
    <div class="sm-header accent" uni-flex-col style="justify-content: center;">

      <!-- APP TITLE -->
      <div class="logo mb-24 mb-md-0" >
        <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          {{ pageIcon || 'today'}} 
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{ title }}
        </span>
      </div>
      <!-- / APP TITLE -->
    </div>
    <!-- / HEADER -->

    <!-- CONTENT CARD -->

    <ng-container *ngTemplateOutlet="bodyTemplate ? bodyTemplate : defaultBody"></ng-container>

    <!-- / CONTENT CARD -->

    <div class="accent" style="display: flex; flex-direction: row; align-content: center; justify-content: space-between;" class="my-12"> 

      <ng-container *ngTemplateOutlet="footerTemplate ? footerTemplate : defaultFooter"></ng-container>

    </div>

  </div>
  <!-- / CENTER -->

</div>

<ng-template #defaultBody>
  <div>Default Body</div>
</ng-template>
<ng-template #defaultFooter>
  <div>Default Footer</div>
</ng-template>