<app-uni-page  [parent]="this" *ngIf="isPageReady"  title="Inventory" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">

    <as-split-area [size]="50">
      <mat-checkbox class="ml-8" [(ngModel)]="isAccountOwned" (ngModelChange)="toggleIsAccountOwned()"
      name="isAccountOwnedCb">Show Account Owned</mat-checkbox>
      <app-ag-grid [gridOptions]="idsGridOptions"  style="height: calc(100% - 55px);"></app-ag-grid>
    </as-split-area>

    <as-split-area [size]="50">
      <app-ag-grid [gridOptions]="itdGridOptions" [rowData]="itemDetails"></app-ag-grid>
      <ng-template #damageCell let-row>
        <button *ngIf="canMarkDamaged(row)" (click)="showDamaged(row)">Mark Damaged</button>
        <button *ngIf="canMarkUndamaged(row)" (click)="markUndamaged(row)">Clear Damaged</button>
        <button *ngIf="canViewDamaged(row)" (click)="showDamaged(row)">View Damaged</button>
      </ng-template>
    </as-split-area>

  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">

    <button [disabled]="!hasItdSelectedRows()" mat-raised-button (click)="printBarcodes()">
      <span>Print Barcodes</span>
    </button>
  </div>
  <div>
    
    <button mat-raised-button (click)="goInventoryScan()">
      <span>Inventory Maintenance</span>
    </button>
    <!-- <button mat-raised-button (click)="goManufacturers()">
      <span>Manufacturers</span>
    </button>
    <button id="product-types" mat-raised-button (click)="goProductTypes()">
      <span>Product Types</span>
    </button>
    <button  mat-raised-button (click)="goFeatures()">
      <span>Features</span>
    </button>
    <button  mat-raised-button (click)="goAddons()">
      <span>Addons</span>
    </button>
    <button  mat-raised-button (click)="goAddonBins()">
      <span>Addon Bins</span>
    </button> -->
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>