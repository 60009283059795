// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Image } from './image';
import { Manufacturer } from './manufacturer';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ManufacturerImage extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  imageId: number;
  isPrimary: boolean;
  isThumbnail: boolean;
  manufacturerId: number;
  image: Image;
  manufacturer: Manufacturer;
}

