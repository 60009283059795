import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Email, InvoiceHeader, JoHeader, Manifest, ManifestGroup } from 'app/model/entities/entity-model';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { EmailService } from 'app/shared/email.service';
import { PrintFns } from 'app/shared/print-fns';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { ManifestPrintDialogComponent } from './manifest-print-dialog.component';
import { DateFns } from 'app/shared/date-fns';

interface FlatInvoice {
  empName: string;
  invoiceId: string;
  invoiceDate: string;
  subTotalAmt: string;
  freightAmt: string;
  taxAmt: string;
  orderHandlingAmt: string;
  totalAmt: string;
}

@Component({
  selector: 'app-printable-manifest',
  templateUrl: './printable-manifest.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PrintableManifestComponent extends DomainBaseComponent implements OnDestroy {
  @ViewChild('printableArea') printElementRef: ElementRef;
  manifestId: number;
  manifest: Manifest;
  sentDate: Date;
  startDate: Date;
  endDate: Date;
  // only avail if called with manifestGroupId specified;
  manifestGroupId: number;
  manifestGroup: ManifestGroup;

  invoiceHeaders: InvoiceHeader[];
  flatInvoices: FlatInvoice[];
  joHeader: JoHeader;
  email: Email;
  subTotalAmt = 0;
  freightAmt = 0;
  orderHandlingAmt = 0;
  taxAmt = 0;
  totalAmt = 0;

  canSendInvoice = false;
  isPosted = false;

  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,
    private emailService: EmailService,
    private ngLocation: Location,
    private elementRef: ElementRef
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  ngOnDestroy(): void {
    // PrintFns.setPrintFn(null);
  }

  async updateFromContext() {
    // This component can get called with either a manifestId or a manifestGroupId
    // if called with a manifestId then the goal is to display the 'next pending' manifest 'group' to send
    // if called with a manifestGroupId then the goal is to display a previously sent manifestGroup.
    const params = this.route.snapshot.params;
    let temp = params['manifestId'];
    this.manifestId = (temp != null) ? parseInt(temp,10) : null;
    temp = params['manifestGroupId'];
    this.manifestGroupId = (temp != null) ? parseInt(temp,10) : null;

    const user = this.domainService.authService.getUser();
    this.canSendInvoice = user.isAccountingAdmin;

    await this.dbQueryService.cacheAll();

    if (this.manifestGroupId) {
      this.invoiceHeaders = await this.dbQueryService.getManifestInvoicesForManifestGroup(this.manifestGroupId);;
    } else {
      this.invoiceHeaders = await this.dbQueryService.getManifestInvoicesPostedButUnsent(this.manifestId);
    }

    if (this.invoiceHeaders.length == 0) {
      this.manifest = null;
      this.isPageReady = true;
      return;
    }

    if (this.manifestGroupId != null) {
      this.manifestGroup = this.invoiceHeaders[0].manifestGroupInvoices[0].manifestGroup;
      this.manifestId = this.manifestGroup.manifestId;
      this.isPosted = true;
    }

    this.manifest = await this.dbQueryService.getManifest(this.manifestId);
    if (this.manifest == null) {
      this.isPageReady = true;
      return;
    }

    const fmtCurrency = this.UtilFns.fmtCurrency;
    const fmtDate = DateFns.fmtDate4Short;
    this.flatInvoices = this.invoiceHeaders.map(i => {

      this.subTotalAmt += i.subtotalAmt;
      this.freightAmt += i.freightAmt;
      this.orderHandlingAmt += i.orderHandlingAmt;
      this.taxAmt += i.taxAmt;
      this.totalAmt += i.totalAmt;

      if (!this.joHeader && i.joHeader.billingAddress1) {
        this.joHeader = i.joHeader;
      }
      return {
        empName: this.getEmpName(i.joHeader),
        invoiceId: i.id,
        invoiceDate: fmtDate(i.invoiceDate),
        subTotalAmt: fmtCurrency(i.subtotalAmt),
        freightAmt: fmtCurrency(i.freightAmt),
        taxAmt: fmtCurrency(i.taxAmt),
        orderHandlingAmt: fmtCurrency(i.orderHandlingAmt),
        totalAmt: fmtCurrency(i.totalAmt)
      };
    });

    this.setDateRange();

    this.isPageReady = true;

    setTimeout(() => {
      // if printing or headless browser, use print view
      const mediaQueryList = window.matchMedia('print');
      if (mediaQueryList.matches || window.navigator.userAgent.includes('Headless')) {
        this.preparePrint();
      } else {
        // get email status
        this.getManifestEmailStatus();
      }
    }, 0);
  }

  private getEmpName(jo: JoHeader) {
    if (jo.empLastName || jo.empFirstName) {
      return (jo.empLastName ? jo.empLastName + ', ' : '') + (jo.empFirstName || '');
    }
    return jo.billingName || jo.shippingName;
  }

  // set startDate and endDate.  Called only after invoiceHeaders are loaded.
  private async setDateRange() {
    const manifestGroups = _.sortBy(this.manifest.manifestGroups, mg => mg.sentDate);
    if (this.manifestGroupId != null) {
      const ix = manifestGroups.findIndex(mg => mg.id == this.manifestGroupId);
      this.endDate = manifestGroups[ix].sentDate;
      if (ix > 0) {
        this.startDate = manifestGroups[ix - 1].sentDate;
        
      } else {
        this.startDate = null;
      }
    } else {
      const today = DateFns.startOfDay(new Date());
      const ix = manifestGroups.findIndex(mg => mg.sentDate > today);
      if (ix > 0) {
        this.endDate = manifestGroups[ix-1].sentDate;
        if (ix > 1) {
          this.startDate = manifestGroups[ix-2].sentDate;
        }
      } else {
        this.endDate = today;
      }
    }
  }

  async showPrintDialog() {
    if (!this.canSendInvoice) {
      this.dialogService.showOkMessage('Not authorized', 'This is an accounting function that you are not currently authorized for.');
      return false;
    }

    const r = await ManifestPrintDialogComponent.show(this.matDialog, {
      manifest: this.manifest,
      invoices: this.invoiceHeaders,
      manifestGroupId: this.manifestGroupId,
      canPreview: false
    });

    this.getManifestEmailStatus();
    return <boolean>r;
  }

  getBillingAddress() {
    if (this.joHeader) {
      return UtilFns.getAddress(this.joHeader, 'billing', false, false);
    }
    return '';
  }

  getUnitecAddress() {
    return this.dbQueryService.getUnitecAddressAsString();
  }

  preparePrint() {
    let el: HTMLElement = this.printElementRef && this.printElementRef.nativeElement;
    if (el == null) {
      el = this.elementRef && this.elementRef.nativeElement;
    }
    if (!el) {
      return;
    }
    PrintFns.printElement(el);
  }

  getManifestEmailStatus() {
    if (this.manifestGroupId) {
      this.emailService.getManifestEmailStatus(this.manifestGroupId).then(e => this.email = e);
    }
  }

  goBack() {
    this.ngLocation.back();
  }
}
