// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { PoDetail } from './po-detail';
import { PoHeader } from './po-header';
import { PoNoteType } from './po-note-type';
import { PoTrx } from './po-trx';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class PoNote extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  crtnTs: Date;
  crtnUserInit: string;
  isIssue: boolean;
  modTs: Date;
  modUserInit: string;
  note: string;
  poDetailId: number;
  poHeaderId: number;
  poNoteTypeId: string;
  poTrxId: number;
  rowVersion: any;
  poDetail: PoDetail;
  poHeader: PoHeader;
  poNoteType: PoNoteType;
  poTrx: PoTrx;
}

