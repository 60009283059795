import { Component, OnInit } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

@Component({
  selector: 'touchbar',
  templateUrl: './touchbar.component.html',
  styleUrls: ['./touchbar.component.scss']
})
export class TouchbarComponent implements OnInit {

    isRight: boolean;
    constructor(
        private _fuseSidebarService: FuseSidebarService,
    )
    {}

    ngOnInit() {
        const navbar = this._fuseSidebarService.getSidebar('navbar');
        this.isRight = (navbar && navbar.position === 'right');
    }

    toggleNavbarOpen(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

}
