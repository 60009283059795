import { GridOptions } from '@ag-grid-community/core';
import { Component, ElementRef, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ItemBin, ItemDetail, ItemDetailStatus, ProductType, Product, Manufacturer } from 'app/model/entities/entity-model';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { JoUtilsService } from 'app/shared/jo-utils.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';



@Component({
    selector: 'app-inventory-scan',
    templateUrl: './inventory-scan.component.html',
    animations   : fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class InventoryScanComponent extends DomainBaseComponent {
  @ViewChild('confirmButton') confirmButton: ElementRef;
  @ViewChild('selectItemDetailCell') selectItemDetailCell: TemplateRef<any>;

  
  itdGridOptions: GridOptions;
  itemDetails: ItemDetail[] = [];
  itemDetailId: string;
  
  editableStatuses: ItemDetailStatus[];

  allManufs: Manufacturer[];
  searchManufs: Manufacturer[];
  selectedManuf: Manufacturer;

  manufProductTypes: ProductType[];
  searchProductTypes: ProductType[];
  selectedProductType: ProductType;

  allowedStatusNames: string[];
  allowedStatusName: string;

  editableStatusCodes = [ ItemDetailStatusCode.InInventory, ItemDetailStatusCode.DamagedOrLost, ItemDetailStatusCode.MissingFromInventory, ItemDetailStatusCode.Special]

  allowedStatusStruct = {
    InventoryOnly: 'Inventory Only',
    MissingOnly: 'Missing Inventory Only',
    All: 'All'
  }

  productTypeId: number;

  errorMessage: string;

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private joUtilsService: JoUtilsService) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  canDeactivate() {
    this.uow.clearEntities(ItemDetail);
    this.uow.clearEntities(ProductType);
    this.uow.clearEntities(Product);
    return true;
  }

  async updateFromContext() {
    
    this.productTypeId = +this.route.snapshot.params['productTypeId'];

    this.editableStatuses = this.dbQueryService.getAllCached(ItemDetailStatus);
    this.editableStatuses = this.editableStatuses.filter(itds => this.editableStatusCodes.some(cd => cd == itds.id));

    const allManufs = await this.uow.queryAll(Manufacturer, 'Manufacturers');
    const itemBins = await this.uow.queryAll(ItemBin, 'ItemBins');

    this.allManufs = _.sortBy(allManufs, m => m.name);

    this.allowedStatusNames = Object.values(this.allowedStatusStruct);
    this.allowedStatusName = this.allowedStatusStruct.InventoryOnly;

    this.canEditStatus = this.canEditStatus.bind(this);
    this.setStatusStyle = this.setStatusStyle.bind(this);
    

    this.itdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdGridReady,
      getRowStyle: this.getItdRowStyle,
      onCellValueChanged: this.onItdCellValueChanged, 
    });

    if (this.productTypeId) {
      await this.getItemDetails(this.productTypeId, this.allowedStatusName);
    }
    
    this.isPageReady = true;
    
  }

  getItdRowStyle(params) {
    if (params.node.rowPinned) {
      return { 'font-weight': 'bold' };
    }
  };

  onItdGridReady(evt: any) {
    let colDefs = [
      { headerName: 'Item Detail Id', field: 'id',   },
      // { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      // { headerName: 'Style', field: 'product.productType.style', },
      { headerName: 'Features', field: 'product.featureChoicesExtract',  },
      { headerName: 'Status', field: 'itemDetailStatus.name', },
      // old code
      // { headerName: 'Update Status', field: 'updateStatus', filter: 'agTextColumnFilter', editable: this.canEditStatus, cellStyle: this.setStatusStyle, 
      // ...AgFns.createDropdownEditorEntityProps(this.editableStatuses, 'id', 'name') },
      { headerName: 'Update Status', field: 'updateStatus', filter: 'agTextColumnFilter', editable: this.canEditStatus, cellStyle: this.setStatusStyle, 
      ...AgFns.createDropdownEditorPropsFromArray(this.editableStatuses, 'id', 'name') },
      { headerName: 'Last Scanned', field: 'lastScanTs'},
      { headerName: 'JO Detail Id', field: 'joDetailId',  },
      { headerName: 'Bin', field: 'itemBin.name',  },
      { headerName: 'Account Owned', field: 'isAccountOwned', editable: false, cellRenderer: AgCheckboxCellComponent },
      // { headerName: 'Last Mod', field: 'modTs'},
      { ...AgFns.createCellButtonProps('Mark Found', this.selectItemDetailCell),  },
    ];

    // show buttons iff user has Inventory role
    if (!this.user.isInventoryAdmin) {
      colDefs = colDefs.filter(cd => cd.headerName != 'Update Status' && cd.headerName != 'Mark Found');
    }
    
    const sortFields = [
      'product.productType.description',
      'product.choiceValue'
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' };
    }) as ISortModel;
    AgFns.initGrid(this.itdGridOptions, colDefs, sortModel);
  }


  canEditStatus(params: any) {
    const itd = <ItemDetail> params.node.data;
    return this.isInEditableStatus(itd);
  }

  setStatusStyle(params: any) {
    const itd = <ItemDetail> params.node.data;
    if (this.isInEditableStatus(itd)) {
      return AgFns.setEditableStyle(params);
    } 
  }

  isInEditableStatus(itd: ItemDetail) {
    return this.editableStatusCodes.some(cd => cd == itd.itemDetailStatusId);
  }

  async onItdCellValueChanged(event: any) {
    const itd = <ItemDetail> event.node.data;
    if (event?.column?.colId == 'updateStatus') {
      if (event.newValue != itd.itemDetailStatusId) {
        itd.itemDetailStatusId = event.newValue;
        this.itdGridOptions.api.refreshCells();
      }
      await this.saveChanges();
    }
  }

  async searchManuf(query: string){
    this.productTypeId = null;
    this.itdGridOptions.api.setPinnedTopRowData([]);
    this.itemDetails = null;

    this.searchManufs = this.allManufs.filter(m => m.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    this.searchManufs = _.sortBy(this.searchManufs, x => x.name);
  }

  async onManufSelected(manuf: Manufacturer) {
    this.selectedManuf = manuf;
    this.manufProductTypes = await this.dbQueryService.getProductTypesForManuf(this.selectedManuf.id);
    this.searchProductTypes = this.manufProductTypes;
  }

  async searchProductType(stylePrefix: string){
    this.searchProductTypes = this.manufProductTypes.filter(m => m.name.toLowerCase().indexOf(stylePrefix.toLowerCase()) > -1);
  }

  onProductTypeSelected(productType: ProductType) {
    this.selectProductType(productType);
    this.itemDetailId = null;
  }

  async onAllowedStatusNameChange(name: string) {
    this.allowedStatusName = name;
    if (this.productTypeId != null) {
      await this.getItemDetails(this.productTypeId, name);
    }
  }

  async getItemDetails(productTypeId: number, allowedStatusName: string) {
    let itemDetailStatusId = null;
    if (allowedStatusName == this.allowedStatusStruct.InventoryOnly) {
      itemDetailStatusId = ItemDetailStatusCode.InInventory;
    } else if (allowedStatusName == this.allowedStatusStruct.MissingOnly) {
      itemDetailStatusId = ItemDetailStatusCode.MissingFromInventory;
    }
    this.itemDetails = await this.dbQueryService.getItemDetailsByProductTypeId(productTypeId, itemDetailStatusId);
  }

  // Hit when scanning or typing in itemDetailId
  onInputFilled(event: { target: any }, fn: (value: any) => void) {
    const input = event.target;
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;

    if (length >= maxLength) {
      // bind is needed to associate the fn returned with 'this' component.
      fn.bind(this)(input.value);
    } else {
      this.errorMessage = '';
      this.clearAll();
    }
  }

  // Hit when clicking 'Mark as scanned'
  selectItemDetail(itemDetail: ItemDetail) {
    this.itemDetailId = itemDetail.id;
    this.processItemDetailId(itemDetail.id);
  }

  async processItemDetailId(itemDetailId: string) {
    this.errorMessage = 'Searching...';
    const itd = await this.dbQueryService.getItemDetailById(itemDetailId);
    
    if (itd == null) {
      this.errorMessage = 'No matching inventory found';
      this.clearAll();
      return;
    }

    itd.lastScanTs = new Date(Date.now());
    await this.saveChanges();
    this.itemDetailId = null;
    this.itdGridOptions.api.refreshCells();

    await this.selectProductType(itd.product.productType, itd);
    
  }
  
  private async selectProductType(productType: ProductType, itemDetail?: ItemDetail) {
    if (productType.id != this.productTypeId) {
      this.productTypeId = productType.id;
      if (this.selectedManuf != productType.manufacturer) {
        this.selectedManuf = productType.manufacturer;
        this.manufProductTypes = await this.dbQueryService.getProductTypesForManuf(this.selectedManuf.id);
        
      }
      this.selectedProductType = productType;
      this.searchProductTypes = this.manufProductTypes;
      this.searchManufs = [this.selectedManuf];
      
      await this.getItemDetails(this.productTypeId, this.allowedStatusName);
    }
    if (itemDetail != null) {
      this.itdGridOptions.api.setPinnedTopRowData([itemDetail]);
    } else {
      this.itdGridOptions.api.setPinnedTopRowData([]);
    }
    this.errorMessage = '';

    this.UtilFns.wait(100);
    this.itdGridOptions.columnApi && this.itdGridOptions.columnApi.autoSizeAllColumns();
  }

  async saveChanges() {
    await AgFns.busyGrid(this.itdGridOptions, this.busyService, async () => {
      await this.dbSaveService.saveChanges();
    });
  };

  private clearAll() {
    this.productTypeId = null;
    this.searchManufs = [];
    this.searchProductTypes = [];
    this.itdGridOptions.api.setPinnedTopRowData([]);
    this.itemDetails = null;
  }

  goInventoryEoq() {
    this.router.navigate(['/inventory-eoq']);
  }

  goAddInventory() {
    this.router.navigate(['/add-inventory']);
  }

  // sendSupplierDataToProximity() {
  //   this.dbSaveService.updateProximitySupplierData();
  // }
}
