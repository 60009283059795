import { GetRowIdParams, GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ItemDetailSummary } from 'app/model/entities/-item-detail-summary';
import { ItemDetail, ItemDetailStatus, ItemReplacement, Feature, ProductType, Product } from 'app/model/entities/entity-model';
import { BarcodePrintStatusCode } from 'app/model/enums/barcode-print-status-code';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { takeUntil } from 'rxjs/operators';
import { InventoryDamageDialogComponent } from './inventory-damage-dialog.component';
import { BarcodeService } from 'app/shared/barcode.service';

@Component({
  selector: 'inventory',
  templateUrl: './inventory.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class InventoryComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('damageCell') damageCell: TemplateRef<any>;

  idsGridOptions: GridOptions;

  itdGridOptions: GridOptions;
  itemDetails: ItemDetail[];

  allItdStatuses: ItemDetailStatus[];
  selectedItdStatuses: ItemDetailStatus[];

  isAccountOwned: boolean;

  features: Feature[];

  constructor(protected domainService: DomainService, protected barcodeService: BarcodeService,
    protected route: ActivatedRoute, protected matDialog: MatDialog) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.idsGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onIdsGridReady,
      getRowId: this.getRowIdForIds,
      onRowSelected: this.onIdsRowSelected,
      onFilterChanged: this.onFilterChanged,
      rowModelType: 'serverSide',
      rowSelection: 'single'
    });
    this.idsGridOptions.onPaginationChanged = (params) => {
      if (params.newPage) {
        this.itemDetails = [];
        this.updateLocation();
      }
    };
    // TODO: 'id' in next line may not be right. 
    AgFns.captureGridRouteParams(this.idsGridOptions, this.route, this.getKeyForIds);

    this.itdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdGridReady,
      onRowSelected: this.onItdRowSelected,
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true
    },
    { detailProperty: 'itemDetailAddons' });

    this.features = await this.dbQueryService.getFeatures();
    this.allItdStatuses = this.dbQueryService.getAllCached(ItemDetailStatus)
      .filter(ids => ids.id != ItemDetailStatusCode.Shipped);
    let statusIds: number[];
    if (this.idsGridOptions.context?.gridState?.fm == null) {
      statusIds = [ItemDetailStatusCode.InInventory];
      this.selectedItdStatuses = this.allItdStatuses.filter(its => statusIds.indexOf(its.id) !== -1);
    }
    
    this.isPageReady = true;
  }

  getRowIdForIds(params: GetRowIdParams) {
    let ids = params.data as ItemDetailSummary;
    return this.getKeyForIds(ids);
  }

  getKeyForIds(ids: ItemDetailSummary) {
    if (ids == null) { return '' };
    return ids.productId.toString() + '-' + ids.itemDetailStatusId.toString() + '-' + (ids.accountId ?? '').toString();
  }

  canDeactivate() {
    this.uow.clearEntities(ItemDetail);
    this.uow.clearEntities(Product);
    this.uow.clearEntities(ProductType);
    this.uow.clearEntities(ItemReplacement);
    return true;
  }

  

  async onIdsGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'product.productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'product.productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      // { ...AgFns.createSetFilterObjectProps('Feature Names', 'product.productType.featureNamesExtract', this.features) },
      { headerName: 'Feature Choices', field: 'product.featureChoicesExtract', sortable: false },
      { ...AgFns.createSetFilterObjectProps('Status', 'itemDetailStatus.name', this.allItdStatuses) },
      { headerName: 'Qty', field: 'qty', },
      { headerName: 'Style Status', field: 'product.productType.activeStatus.name',  },
      { headerName: 'Product Id', field: 'productId', filter: 'agNumberColumnFilter'  },
      { colId: 'idsKey', 
        valueGetter: params => {
          return this.getKeyForIds(params.data);
        },
      }
    ];
    const sortModel = [
        { colId: 'product.productType.manufacturer.name', sort: 'asc' },
        { colId: 'product.productType.description', sort: 'asc' },
        { colId: 'product.productType.style', sort: 'asc' },
        // { colId: 'product.featureChoicesExtract', sort: 'asc' },
        // { colId: 'account.accountName', sort: 'asc' },
      ] as ISortModel;
    
    // next line is needed by the buildDatasource method
    AgFns.initGrid(this.idsGridOptions, colDefs, sortModel);
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.idsGridOptions);
  }
 

  async onItdGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Item Detail', field: 'id', cellRenderer: 'agGroupCellRenderer', filter: 'agNumberColumnFilter', checkboxSelection: true, headerCheckboxSelection: true },
      { headerName: 'Item Bin', field: 'itemBin.name', filter: 'agTextColumnFilter' },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter', hide: !this.isAccountOwned },
      { ...AgFns.createCellButtonProps('Mark', this.damageCell) },
      { headerName: 'JO Detail', field: 'joDetailId', filter: 'agNumberColumnFilter' },
      { headerName: 'PO Detail', field: 'poDetailId', filter: 'agNumberColumnFilter' },
      { headerName: 'Invoice', field: 'invoiceHeaderId', filter: 'agNumberColumnFilter' },
      { headerName: 'Barcode Status', field: 'barcodePrintStatus.name', filter: 'agNumberColumnFilter' },
      { headerName: 'Created', field: 'crtnTs', filter: 'agDateColumnFilter' },
      { headerName: 'Status', field: 'itemDetailStatus.name', filter: 'agNumberColumnFilter' },
    ];
    const sortModel = [
      { colId: 'account.accountName', sort: 'asc' },
      { colId: 'id', sort: 'asc' },
    ] as ISortModel;
    this.updateItdMasterDetail(this.itdGridOptions);
    AgFns.initGrid(this.itdGridOptions, colDefs, sortModel, true);
  }

  updateItdMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Addon', field: 'addon.nameAndLocation' },
      { headerName: 'Addl. Info', field: 'additionalInfo' },
      // { headerName: 'Orig Descr', field: 'addon.description' },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const itd = params.data as ItemDetail;
        params.successCallback(itd.itemDetailAddons);
      },
    };
  }

  updateDatasource() {
    const gridApi = this.idsGridOptions.api;
    if (gridApi == null) {
      return;
    } 

    const opts = {
      initFn: () => {
        // clear bottom grid after change in top grid.
        this.itemDetails = [];
      }
    }
    const ds = AgFns.buildDatasource(
      () => this.dbQueryService.createItemDetailSummaryQuery(this.isAccountOwned), null, opts)
        
    gridApi.setServerSideDatasource(ds);
    this.idsGridOptions.columnApi.setColumnVisible('account.accountName', this.isAccountOwned);
  }

  toggleIsAccountOwned() {
    this.updateDatasource();
  }

  onFilterChanged() {
    this.updateLocation();
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/inventory']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.idsGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  async onIdsRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const ids = e.data as ItemDetailSummary;
    if (!ids) {
      return;
    }
    this.itdGridOptions.api.deselectAll(); //  to avoid Expression has changed after it was checked error
    this.updateLocation(this.getKeyForIds(ids));
    await this.getItemDetails(ids);
  }

  async onItdRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
  }

  canMarkDamaged(itd: ItemDetail) {
    return itd.itemDetailStatusId !== ItemDetailStatusCode.DamagedOrLost;
  }

  canViewDamaged(itd: ItemDetail) {
    return itd.itemDetailStatusId === ItemDetailStatusCode.DamagedOrLost && itd.itemReplacement != null;
  }

  async showDamaged(itd: ItemDetail) {
    const r = await InventoryDamageDialogComponent.show(this.matDialog, {
      itemDetail: itd
    });
    if (r) {
      this.updateDatasource();
    }
    return <boolean>r;
  }

  canMarkUndamaged(itd: ItemDetail) {
    return (!this.opIsPending) && itd.itemDetailStatusId === ItemDetailStatusCode.DamagedOrLost
  }

  async markUndamaged(itd: ItemDetail) {
    itd.itemDetailStatusId = ItemDetailStatusCode.InInventory;
    await this.wrapPending( async () => {
      // also need to remove corresponding itemReplacement item.
      if (itd.itemReplacement != null) {
        itd.itemReplacement.entityAspect.setDeleted();
      }
      await this.dbSaveService.saveChanges();
      this.updateDatasource();
    });
  }


  async getItemDetails(ids: ItemDetailSummary) {
    this.itemDetails = await AgFns.busyGrid(this.itdGridOptions, this.busyService, () => {
      return this.dbQueryService.getItemDetailsByItemDetailSummary(ids);
    });
    return this.itemDetails;
  }

  hasItdSelectedRows() {
    const rows = this.itdGridOptions.api?.getSelectedRows();
    return rows && rows.length > 0;
  }

  /** Print ZPL labels for all the selected rows */
  async printBarcodes() {
    const itemDetails = this.itdGridOptions.api.getSelectedRows() as ItemDetail[];
    await this.barcodeService.printItemDetailBarcodes(itemDetails);
    
    const ynResult = await this.dialogService.askYesNo('Barcode Printing', `Did ${itemDetails.length} barcode(s) print correctly?`);
    if (ynResult.index === 0) {
      itemDetails.forEach(itd => itd.barcodePrintStatusId = BarcodePrintStatusCode.Stock);
      await this.dbSaveService.saveChanges();
      this.itdGridOptions.api.redrawRows();
      this.dialogService.toast(`Barcode print status updated for ${itemDetails.length} items`);
    }
  }

  goInventoryScan() {
    this.router.navigate(['/inventory-scan']);
  }

  // goManufacturers() {
  //   this.router.navigate(['/manufacturers']);
  // }

  // goProductTypes() {
  //   this.router.navigate(['/product-types']);
  // }

  // goFeatures() {
  //   this.router.navigate(['/feature']);
  // }

  // goAddons() {
  //   this.router.navigate(['/addons']);
  // }

  // goAddonBins() {
  //   this.router.navigate(['/addon-bins']);
  // }

  // goAddonTypes() {
  //   this.router.navigate(['/addon-types']);
  // }

  
}
