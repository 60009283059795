import { Component, OnInit } from '@angular/core';
import { Address, Carrier, ModelPackage, Weight } from 'app/model/shipEngine/models';
import { ShippingService, ShippingCarrier } from './shipping.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'shipping',
  templateUrl: './shipping.component.html',
})
export class ShippingComponent implements OnInit {

  carrierList: ShippingCarrier[];
  selectedCarrierId: string;
  lastResponse: any;
  labelUrl: string;
  email = '';
  invoiceId = '1655040-01';

  packages = [<ModelPackage>{
    weight: {
      unit: Weight.UnitEnum.Ounce,
      value: 1.0
    }
  }];

  ship_from = <Address>{
    name: 'Mickey and Minnie Mouse',
    phone: '714-781-4565',
    company_name: 'The Walt Disney Company',
    address_line1: '500 South Buena Vista Street',
    city_locality: 'Burbank',
    state_province: 'CA',
    postal_code: '91521',
    country_code: 'US'
  };

  ship_to = <Address>{
    name: 'Dade Murphy',
    phone: '512-485-4282',
    company_name: 'Zero Cool',
    address_line1: '345 Chambers Street',
    address_line2: 'Suite 100',
    city_locality: 'New York City',
    state_province: 'NY',
    postal_code: '10282',
    country_code: 'US'
  };

  constructor(private shippingService: ShippingService, private http: HttpClient) {
  }

  ngOnInit() {
    this.shippingService.getShippingCarriers().then(r => this.carrierList = r);
  }

  getSampleRates() {
    this.lastResponse = null;
    this.shippingService.getRatesOld(this.ship_from, this.ship_to, this.packages).then(r => {
      this.lastResponse = r;
    });
  }

  getSampleLabel() {
    this.lastResponse = null;
    this.shippingService.getLabelOld(this.ship_from, this.ship_to, this.packages, 'usps_priority_mail').then(r => {
      this.lastResponse = r;
      if (r.label_download) {
        this.labelUrl = r.label_download.href;
      }
    });
  }

  getCarrierServices() {
    if (!this.selectedCarrierId) {
      return;
    }
    const car = this.carrierList.find(c => c.code === this.selectedCarrierId);
    this.lastResponse = car.services;
    // this.shippingService.getCarrierServices(this.selectedCarrierId).then(r => {
    //   this.lastResponse = r;
    // });
  }

  sendEmail() {
    if (!this.email || this.email.length < 6) {
      return;
    }
    let url;
    if (this.invoiceId && this.invoiceId.length === 10) {
      url = environment.apiRoot + 'api/mail/sendinvoice?toAddress=' + this.email + '&invoiceId=' + this.invoiceId;
    } else {
      url = environment.apiRoot + 'api/mail/sendemail?toAddress=' + this.email;
    }
    this.lastResponse = null;
    this.http.get(url).toPromise().catch(value => {
      this.lastResponse = value;
    });
  }

  getInvoiceEmailStatus() {
    const url = environment.apiRoot + 'api/mail/getInvoiceEmailStatus?invoiceId=' + this.invoiceId;
    this.lastResponse = null;
    this.http.get(url).toPromise().then(value => {
      this.lastResponse = value;
    }).catch(value => {
      this.lastResponse = value;
    });
  }


}
