import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { GridOptions, IGetRowsParams, RowSelectedEvent, Grid } from '@ag-grid-community/core';
import { AgFns, GridState,  } from 'app/shared/ag-fns';
import { JoDetail } from 'app/model/entities/jo-detail';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { ItemDetail, JoHeader, PoDetail, PoDetailCancel, PoHeader, Product, ProductType, JoStatus, } from '../model/entities/entity-model';
import { NavFns } from 'app/shared/nav-fns';
import { EditNotesDialogComponent } from 'app/shared/edit-notes-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-jo-audit',
  templateUrl: './jo-audit.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class JoAuditComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('editJohNotesCell') editJohNotesCell: TemplateRef<any>;
  @ViewChild('editJodNotesCell') editJodNotesCell: TemplateRef<any>;
  @ViewChild('editPodNotesCell') editPodNotesCell: TemplateRef<any>;

  joHeaders: JoHeader[];
  johGridOptions: GridOptions;
  johErrGridOptions: GridOptions;
  joStatusNames: string[];

  joDetails: JoDetail[];
  jodGridOptions: GridOptions;

  poDetails: PoDetail[];
  podGridOptions: GridOptions;

  poDetailCancels: PoDetailCancel[];
  podcGridOptions: GridOptions;

  itemDetails: ItemDetail[];
  itdGridOptions: GridOptions;

  badJoHeaders: JoHeader[];
  _showMode: 'all' | 'err' = 'all';
  message: string;


  constructor(protected domainService: DomainService, protected route: ActivatedRoute, protected location: Location, protected matDialog: MatDialog ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    
    // this.gridState = AgFns.createGridState(this.route.snapshot.queryParams, 'id');

    this.johGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJohGridReady,
      onRowSelected: this.onJohRowSelected,
      onModelUpdated: this.clearRelatedData,
      rowModelType: 'serverSide'
    });
    AgFns.captureGridRouteParams(this.johGridOptions, this.route, 'id');

    this.johErrGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJohGridReady,
      onRowSelected: this.onJohRowSelected,
      onModelUpdated: this.clearRelatedData,
      rowModelType: 'clientSide'
    });

    this.jodGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJodGridReady,
      onRowSelected: this.onJodRowSelected,
    });

    this.podGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPodGridReady,
    });

    this.podcGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPodcGridReady,
    });

    this.itdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdGridReady,
    });

    this.isPageReady = true;
  }

  canDeactivate() {
    this.uow.clearEntities(JoDetail);
    this.uow.clearEntities(JoHeader);
    this.uow.clearEntities(PoDetail);
    this.uow.clearEntities(PoHeader);
    this.uow.clearEntities(ItemDetail);
    this.uow.clearEntities(Product);
    this.uow.clearEntities(ProductType);
    return true;
  }


  get title() {
    if (this.showMode === 'all') {
      return 'All Job Orders';
    } else if (this.showMode === 'err') {
      return 'Inconsistent Open/Completed Job Orders Only';
    } 
  }

  get showMode() {
    return this._showMode ?? 'all';
  }
  set showMode(value) {
    if (value == null || value === this._showMode) {
      return;
    }
    
    // selected -> err
    if (value === 'err' ) {
      this.useBadJoDatasource();
    } 
    // we don't need to set the regular datasource because it is always avail
    
    this._showMode = value;
  }

  // In this component this gets call each time the mode changes.
  onJohGridReady(evt: any) {
    const gridOptions = this.showMode == 'all'? this.johGridOptions : this.johErrGridOptions;

    const colDefs = [
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      // { headerName: 'Status', field: 'joStatus.name', filter: 'agTextColumnFilter' },
      { ...AgFns.createSetFilterObjectProps('Status', 'joStatus.name', this.dbQueryService.getAllCached(JoStatus)) },
      { headerName: 'JO Date', field: 'joDate', filter: 'agDateColumnFilter' },
      { headerName: 'Order Qty', type: 'numericColumn',  sortable: false,
        valueGetter: params => {
          const joh = <JoHeader> params.data;
          return joh && joh.getOrderQty();
        }
      },
      { headerName: 'Cancel Qty', type: 'numericColumn', sortable: false,
        valueGetter: params => {
          const joh = <JoHeader> params.data;
          return joh && joh.getCancelQty();
        }
      },
      // { headerName: 'Job Order', field: 'id', filter: 'agNumberColumnFilter' },
      { headerName: 'Job Order', filter: 'agNumberColumnFilter', 
        ...NavFns.createIdCellClickedNavProps('id', this.router, '/job-orders') },
      { ...AgFns.createCellButtonProps('Notes', this.editJohNotesCell)  },
    ];

    AgFns.initGrid(gridOptions, colDefs);

    if (this._showMode == 'all') {
      this.useAllJoDatasource();
    }
    AgFns.applyGridRouteParams(gridOptions);
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/jo-audit']);
    let url;
    if (this.showMode == 'all') {
      url = AgFns.buildGridRouteParamsUrl(urlTree, this.johGridOptions, key && key.toString());
    } else {
      // we cannpt return to 'err' mode because of calc time
      // so if we return it will be in 'all' mode and paging info will be 
      // wrong so we need to skip it and just use the filter.
      const fm = AgFns.addFilterClause({}, 'id', 'number', key);
      const urlSuffix = 'fm=' + encodeURIComponent(JSON.stringify(fm));
      url = urlTree.toString() + '?' + urlSuffix;
    }
    this.domainService.location.replaceState(url);
  }

  onJodGridReady(evt: any) {
    this.jodGridOptions.rowSelection = 'single';
    const colDefs = [
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name',  },
      { headerName: 'Description', field: 'product.productType.description',  },
      { headerName: 'Style', field: 'product.productType.style',  },
      { headerName: 'Features', field: 'product.featureChoicesExtract',  },
      { headerName: 'Order Qty', field: 'orderQty', type: 'numericColumn',  },
      { headerName: 'Cancel Qty', field: 'cancelQty', type: 'numericColumn',  },
      // could break this into pulled vs rcvd
      { headerName: 'Pulled/Rcvd Qty', colId: 'idtQty', type: 'numericColumn',
        headerTooltip: '(calc) Quantities already pulled or received',
        valueGetter: params => {
          const jod = <JoDetail> params.data;
          return jod.getItemDetailsRcvdQty();
        }
      },
      { headerName: 'Open PO Qty', colId: 'poQty', type: 'numericColumn',
        headerTooltip: '(calc) PO Detail quantities not yet received or canceled',
        valueGetter: params => {
          const jod = <JoDetail> params.data;
          return jod.getPoDetailsRemainingQty();
        }
      },
      { headerName: 'Held PO Qty', colId: 'poQty', type: 'numericColumn',
        headerTooltip: '(calc) PO Detail quantities held',
        valueGetter: params => {
          const jod = <JoDetail> params.data;
          return jod.getPoDetailsHeldQty();
        }
      },
      { headerName: 'Net Qty', colId: 'netQty' ,
        headerTooltip: '(calc) The Net quantities remaining after all itemDetails and unreceived purchase order details have been accounted for',
        valueGetter: params => {
          const jod = <JoDetail> params.data;
          return jod.getRemainingQty();
        }
      },
      { headerName: 'JO Detail Id', field: 'id',  },
      { headerName: 'Return Qty', field: 'returnQty', type: 'numericColumn',  },
      { headerName: 'Shipped Qty', colId: 'shippedQty', type: 'numericColumn',
        headerTooltip: '(calc) Item Details shipped for this JODetail',
        valueGetter: params => {
          const jod = <JoDetail> params.data;
          return jod.getItemDetailsShippedQty();
        }
      },
      { ...AgFns.createCellButtonProps('Notes', this.editJodNotesCell)  },
    ];
    const sortFields = [
      'id',
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });
    AgFns.initGrid(this.jodGridOptions, colDefs, sortModel, true);
  }

  onPodGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { headerName: 'Type', field: 'poDetailType.name'},
      { headerName: 'Order Qty', field: 'orderQty' },
      { headerName: 'Held Qty', field: 'heldQty' },
      { headerName: 'Rcvd Qty', type: 'numericColumn',
        headerTooltip: '(calc) Quantities already received from the manufacturer',
        valueGetter: params => {
          const pod = <PoDetail> params.data;
          return pod.getReceivedQty();
        }
      },
      { headerName: 'PO Cancel Qty', type: 'numericColumn',
        headerTooltip: '(calc) PO Detail quantities canceled',
        valueGetter: params => {
          const pod = <PoDetail> params.data;
          return pod.getCanceledQty();
        }
      },
      { headerName: 'Open PO Qty', colId: 'poQty', type: 'numericColumn',
        headerTooltip: '(calc) PO Detail quantities ordered but not yet received or canceled or held',
        valueGetter: params => {
          const pod = <PoDetail> params.data;
          return pod.getRemainingQty();
        }
      },
      { headerName: 'PO Status', field: 'poHeader.poStatus.name' },
      { headerName: 'PO Date', field: 'poHeader.poDate' },
      { headerName: 'JO Detail', field: 'joDetailId' },
      { headerName: 'PO Detail', field: 'id' },
      { headerName: 'PO Header', 
        ...NavFns.createIdCellClickedNavProps('poHeader.id', this.router, '/purchase-orders') },
      { ...AgFns.createCellButtonProps('Notes', this.editPodNotesCell)  },        

    ];
    const sortFields = [
      'joDetailId',
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });
    AgFns.initGrid(this.podGridOptions, colDefs, sortModel);
  }

  onPodcGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Qty', field: 'qty' },
      { headerName: 'Created', field: 'crtnTs' },
      { headerName: 'JO Detail', field: 'poDetail.joDetailId' },
      { headerName: 'PO Detail', field: 'poDetailId' },
    ];
    const sortFields = [
      'poDetail.joDetailId',
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const};
    });
    AgFns.initGrid(this.podcGridOptions, colDefs, sortModel);
  }

  onItdGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Item Detail', field: 'id' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { headerName: 'Status', field: 'itemDetailStatus.name'},
      { headerName: 'Jo Detail', field: 'joDetailId' },
      { headerName: 'Po Detail', field: 'poDetailId' },
    ];
    const sortFields = [
      'joDetailId',
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });
    AgFns.initGrid(this.itdGridOptions, colDefs, sortModel);
  }

  private useAllJoDatasource() {
    this.message = null;
    this.clearRelatedData();
    this.relatedGridRefresh();
    const ds = AgFns.buildDatasource(  () => this.dbQueryService.createJoHeadersForAuditQuery());
    this.johGridOptions.api?.setServerSideDatasource(ds);
  }

  private async useBadJoDatasource() {
    this.clearRelatedData();
    if (this.badJoHeaders == null) {
      this.badJoHeaders = await this.calcBadJoHeaders();
    }
    // Need this to give time for johErrGridOptions to get recreated.
    await this.UtilFns.wait(100);
    if (this.johErrGridOptions?.api == null) {
      // can happen if we navigate away from the page. 
      return
    }
    this.johErrGridOptions.api.setRowData(this.badJoHeaders);
    this.clearFilters();
    AgFns.autoSizeAllColumns(this.johErrGridOptions);
    AgFns.selectFirstRow(this.johErrGridOptions);
  }

  clearRelatedData() {
    this.joDetails = [];
    this.poDetails = [];
    this.itemDetails = [];
    this.poDetailCancels = [];
  }

  async onJohRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const joh = e.data as JoHeader;
    if (!joh) {
      return;
    }

    this.updateLocation(joh.id);

    await AgFns.busyGrid([this.jodGridOptions, this.podGridOptions, this.podcGridOptions, this.itdGridOptions], this.busyService, async () => {
      this.joDetails = await this.dbQueryService.getJoDetailsForAudit(joh.id);
    });
    this.poDetails = [];
    this.itemDetails = [];
    this.poDetailCancels = [];
    AgFns.selectFirstRow(this.jodGridOptions);
  }



  async onJodRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const jod = e.data as JoDetail;
    if (!jod) {
      return;
    }

    this.poDetails = jod.poDetails;
    // Now we show itemDetails that are associated with the pod
    this.itemDetails = _.uniq(jod.itemDetails.concat(_.flatMap(this.poDetails, pod => pod.itemDetails)));
    this.poDetailCancels = _.flatMap(this.poDetails, pod => pod.poDetailCancels);

    this.relatedGridRefresh();
  }

  relatedGridRefresh() {
    this.itdGridOptions.api.refreshCells( { force: true });
    this.podGridOptions.api.refreshCells( { force: true });
    this.podcGridOptions.api.refreshCells( { force: true });
  }

  async onTabChanged(evt: any) {
    const currentTabLabel = evt.tab.textLabel;
    // HACK: needed because AgGrid loses buttons when tabbing from one tab to another and then back.
    this.joDetails = this.joDetails.slice();
    this.poDetails = this.poDetails.slice();
    this.itemDetails = this.itemDetails.slice();
    this.poDetailCancels = this.poDetailCancels.slice();
  }

  async calcBadJoHeaders() {
    const takeCount = 25;
    let skipCount = 0;
    let badJoHeaders: JoHeader[] = [];
    let joHeaders = await this.dbQueryService.getJoHeadersForAuditCalc(takeCount, skipCount);
    while (joHeaders.length > 0) {
      this.message = `Processing ${skipCount} thru ${skipCount + takeCount}`;
      const badHeaders = joHeaders.slice().filter(joh => {
        const ok = joh.joDetails.every(jod => {
          const net = jod.getRemainingQty();
          return net === 0;
        });
        if (ok) {
          // clean up and remove good ones
          joh.joDetails.slice().forEach(jod => {
            jod.itemDetails.slice().forEach(itd => itd.entityAspect.setDetached());
            jod.poDetails.slice().forEach(pod => pod.entityAspect.setDetached());
            jod.entityAspect.setDetached();
          });
        }
        return !ok;
      });

      badJoHeaders = badJoHeaders.concat(badHeaders);
      skipCount += takeCount;
      joHeaders = await this.dbQueryService.getJoHeadersForAuditCalc(takeCount, skipCount);
    }
    this.message = null;
    return badJoHeaders;

  }

  hasJohNotes(joh: JoHeader) {
    return joh && joh.joNotes.length > 0;
  }

  async editJohNotes(joh: JoHeader) {
    await EditNotesDialogComponent.show(this.matDialog, { header: joh });
  }

  hasJodNotes(jod: JoDetail) {
    return jod && jod.joNotes.length > 0;
  }

  async editJodNotes(jod: JoDetail) {
    await EditNotesDialogComponent.show(this.matDialog, { detail: jod });
  }

  hasPodNotes(pod: PoDetail) {
    return pod && pod.poNotes.length > 0;
  }

  async editPodNotes(pod: PoDetail) {
    await EditNotesDialogComponent.show(this.matDialog, { detail: pod });
  }

  clearFilters() {
    this.johGridOptions?.api?.setFilterModel(null);
    this.johErrGridOptions?.api?.setFilterModel(null);
    this.johGridOptions?.api?.deselectAll();
    this.joDetails = [];
    this.poDetails = [];
    this.itemDetails = [];
    this.poDetailCancels = [];
    
  }

}
