import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActiveStatus, FeatureChoice, Product, Manufacturer, ProductFeatureChoice } from 'app/model/entities/entity-model';
import { ProductType } from 'app/model/entities/product-type';
import { AgFns } from 'app/shared/ag-fns';
import { AuthService, AuthUser } from 'app/shared/auth.service';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';

@Component({
  templateUrl: './edit-product-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class EditProductDialogComponent {
  user: AuthUser;
  activeStatuses: ActiveStatus[];
  product: Product;
  productType: ProductType;
  featureChoices: FeatureChoice[];
  availableFeatureChoices: FeatureChoice[];
  isAdding: boolean;
  title: string;
  isPageReady = false;
  oldUseSize: string;


  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(EditProductDialogComponent, {
        disableClose: true,
        height: '600px',
        width: '900px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditProductDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, private uow: UnitOfWork, 
    private authService: AuthService, private dialogService: DialogService, public busyService: BusyService) {

    this.user = this.authService.getUser();

    this.activeStatuses = this.dbQueryService.getAllCached(ActiveStatus);

    this.prepare();
  }

  private async prepare() {
    this.product = this.data.product;
    if (this.product == null) {
      this.isAdding = true;
      this.productType = this.data.productType;
      this.product = this.createNewProduct();
    } else {
      this.productType = this.product.productType;
    }

    const allManufs = await this.uow.queryAll(Manufacturer, 'Manufacturers');
    await this.dbQueryService.getProductType(this.productType.id);
    

    this.title = this.isAdding ? 'Creating New Product' : 'Editing Product';
    
    UtilFns.wait(0);
    
    this.isPageReady = true;
  }

  private createNewProduct() {
    const prod = this.dbSaveService.createEntity(Product, {
      modTs: new Date(),
      modUserInit: this.user.initials,
      productTypeId: this.productType.id,
      activeStatusId: 1,
    });
    const availableFcCombos = this.getAvailableFeatureChoiceCombos(this.productType);
    availableFcCombos[0].forEach(fc => {
      const pfc = this.dbSaveService.createEntity(ProductFeatureChoice, {
        productId: prod.id,
        featureChoiceId: fc.id
      });
    });
    return prod;
  }

  getAllFeatureChoiceCombos(productType: ProductType) {
    const choiceLists = productType.productTypeFeatureMaps.map(ptfMap => {
      return ptfMap.feature.featureChoices;
    });
    return UtilFns.cartesianProduct(choiceLists) as FeatureChoice[][];
  }

  getAvailableFeatureChoiceCombos(productType: ProductType ) {
    const allCombos = this.getAllFeatureChoiceCombos(productType);
    const curCombos = productType.products.map(p => p.productFeatureChoices.map(x => x.featureChoice));
    const availableCombos = allCombos.filter(ac => !curCombos.some(cc => this.areFcCombosEqual(ac, cc)));
    return availableCombos;
  }

  areFcCombosEqual(c1: FeatureChoice[], c2: FeatureChoice[]) {
    return c1.every(fc => c2.includes(fc));
  }
    
  async ok() {
    const ok = await this.save();
    if (!ok) {
      return;
    }
    this.dialogRef.close(true);
  }

  async save() {
    // remove excess spaces
    const prod = this.product;

    const isValid = await this.validate();
    if (!isValid) { return false; }
  
    try {
      const sr = await this.dbSaveService.saveChanges();
    } catch (e) {
      if (this.isAdding) {
        this.uow.rollback();
      }
      return false;
    }
    return true;
  }

  async validate() {
    const prod = this.product;
    if (prod.productFeatureChoices.length == 0) {
      await this.dialogService.showOkMessage('Missing Feature Choice', `Cannot save because you have not specified a Feature Choice.`);
      return false;
    }
    
    let matchingEntity = await this.dbQueryService.getMatchingProduct(prod.productTypeId, prod.productFeatureChoices);
    if (matchingEntity != null && matchingEntity !== prod) {
      await this.dialogService.showOkMessage('Duplicate Feature set', `Cannot save because this is a duplicate of an existing feature set for this product type.`);
      return false;
    }

    const ea = this.product.entityAspect;
    if (! ea.validateEntity()) {
      const vErrors = ea.getValidationErrors();
      const errMsg = vErrors.map(ve => ve.errorMessage).join('<br>');
      await this.dialogService.showOkMessage('Validation Error(s)', errMsg);
      return false;
    }
    return true;
  }

  async cancel() {
    // insures that we don't try to redisplay the page after the reject changes.
    this.isPageReady = false;
    // entityAspect may not exist if it is a new object
    this.product.entityAspect?.rejectChanges();
    this.dialogRef.close(false);
  }


}
