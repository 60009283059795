// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { envbase } from "./envbase";

export const environment = {
  ...envbase,
  env: 'azure',
  production: true,
  hmr: false,
  apiRoot: 'https://uniformax-server.ideablade.com/',
  entitiesApiRoot: 'https://uniformax-server.ideablade.com/breeze/Uniformax',
  helpRoot: 'https://uniformax.ideablade.com/InsightHelp/'
};
