<div id="home" class="page-layout simple fullwidth">

  <div class="header accent p-24 h-160" style="display: flex;  flex-flow: row wrap;  justify-content: space-between;" >

    <div >
      <div class="h2 mt-16">Home</div>
    </div>

  </div>

  <div class="content">
    <div class="cards">
      <mat-card routerLink="/job-orders">
        <div class="h1">
          <mat-icon>assignment</mat-icon>
          Job Orders
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>

      <mat-card routerLink="/purchase-orders">
        <div class="h1">
          <mat-icon>shopping_cart</mat-icon>
          Purchasing
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
      <mat-card routerLink="/in-process">
        <div class="h1">
          <mat-icon>work</mat-icon>
          In Process
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
      
    </div>
    <div class="cards">
      <mat-card routerLink="/inventory">
        <div class="h1">
          <mat-icon>assessment</mat-icon>
          Inventory
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
      <mat-card routerLink="/references">
        <div class="h1">
          <mat-icon>work</mat-icon>
          References
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
      <mat-card routerLink="/accounts">
        <div class="h1">
          <mat-icon>work</mat-icon>
          Accounts
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
    </div>
    <div class="cards">
      <mat-card routerLink="/entity-issue">
        <div class="h1">
          <mat-icon>report_problem</mat-icon>
          Issues
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>

      <mat-card *ngIf="user.isUserAdmin" routerLink="/user-accounts">
        <div class="h1">
          <mat-icon>person</mat-icon>
          Users
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
      <mat-card *ngIf="!user.isUserAdmin" (click)="showUser()">
        <div class="h1">
          <mat-icon>person</mat-icon>
          User
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
      <mat-card routerLink="/charts-container">
        <div class="h1">
          <mat-icon>pie_chart</mat-icon>
          Chart
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
  </div>

</div>
