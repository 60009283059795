<app-uni-dialog title="Switch to batch:" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div class="content-card" style="height: 100%" >
    <span *ngIf="joPullBatches.length == 0">
      <div uni-flex-row>
        <div class="h3">No outstanding Job Order Pull Batches found</div>
      </div>
    </span>
    <app-ag-grid style="height: 100%" *ngIf="joPullBatches.length > 0" [gridOptions]="jopbGridOptions" [rowData]="joPullBatches"></app-ag-grid>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="selectBatch()">Select Batch</button>
  <button mat-raised-button (click)="close()">Cancel</button>
</ng-template>
