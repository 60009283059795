import { GetRowIdParams, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { takeUntil } from 'rxjs/operators';
import { PoDetail } from '../model/entities/entity-model';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { PoBackorder } from 'app/model/entities/-po-backorder';

@Component({
  selector: 'app-account-backorders',
  templateUrl: './account-backorders.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AccountBackordersComponent extends DomainBaseComponent implements OnInit { 
  podGridOptions; GridOptions;
  shouldShowIdColumns = true;
  rowGroupPanelShow = 'always';
  accountGuid: string;

  currentPoDetail: PoDetail;
  // used only when rowModelType != infinite
  podItems: PoBackorder[];

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog, router: Router) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.accountGuid = this.route.snapshot.params['guid'];
    // this.gridState = AgFns.createGridState(this.route.snapshot.queryParams, 'accountGuid');

    this.podGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPodGridReady,
      onRowSelected: this.onRowSelected,
      getRowId: (p) => p.data?.poDetailId,
      rowGroupPanelShow: 'always',
      stopEditingWhenCellsLoseFocus: true,
      suppressDragLeaveHidesColumns: false,
      suppressMakeColumnVisibleAfterUnGroup: false,
    });
    AgFns.captureGridRouteParams(this.podGridOptions, this.route, 'accountGuid');

    try {
      this.podItems = <PoBackorder[]> <any> await this.dbQueryService.getPoDetailAccountBackorders(this.accountGuid);
    } catch (e) {
      this.router.navigate(['/login'], {skipLocationChange: false});
      return;
    }

    this.isPageReady = true;
  }

  canDeactivate() {
    return true;
  }

  // This method is called AFTER ngAfterViewInit - this is important
  // because the ColDef's here reference TemplateRef's above that will not be resolved until here.
  onPodGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', filter: 'agTextColumnFilter', enableRowGroup: true, },
      { headerName: 'Style', field: 'product.productType.style', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Features', field: 'product.featureChoicesExtract', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Description', field: 'product.productType.description', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Order Qty', field: 'orderQty', filter: 'agNumberColumnFilter' },
      { headerName: 'Received Qty', field: 'receivedQty' },
      { headerName: 'Canceled Qty', field: 'canceledQty' },
      { headerName: 'JO Orig Ship By Date', field: 'joDetail.joHeader.origShipByDate', filter: 'agDateColumnFilter', },
      { headerName: 'JO Expected Date', field: 'joDetail.expectedShipDate', filter: 'agDateColumnFilter', cellEditor: "datePicker" },
      { headerName: 'Manufacturer Expected Date', field: 'poDetail.expectedShipDate', filter: 'agDateColumnFilter', cellEditor: "datePicker" },
      { headerName: 'Manuf Last Updated Ts', field: 'poDetail.expectedShipDateModTs', filter: 'agDateColumnFilter' },
      { headerName: 'JO Header Id', field: 'joDetail.joHeaderId', filter: 'agNumberColumnFilter' },
      { headerName: 'JO Detail Id', field: 'joDetailId', filter: 'agNumberColumnFilter' },
    ];

    const sortModel = [
        { colId: 'product.productType.manufacturer.name', sort: 'asc' },
        { colId: 'product.productType.style', sort: 'asc' },
        
      ] as ISortModel;
    
    // next line is needed by the buildDatasource method
    AgFns.initGrid(this.podGridOptions, colDefs, sortModel);
    this.showHideIdColumns(false);

    AgFns.autoSizeAllColumns(this.podGridOptions);
    AgFns.applyGridRouteParams(this.podGridOptions);
    
  }

  showHideIdColumns(forceShowHide: boolean = null) {
    if (forceShowHide == null) {
      this.shouldShowIdColumns = !this.shouldShowIdColumns;
    } else {
      this.shouldShowIdColumns = forceShowHide;
    }
    const poColApi = this.podGridOptions.columnApi;
    if (poColApi) {
      poColApi.setColumnsVisible(
        ['joDetail.joHeaderId', 'joDetailId'], this.shouldShowIdColumns);
    }
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/account-backorders']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.podGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  getTitle() {
    if (!this.podItems?.length) { return; }
    return this.podItems[0].account.accountName;
  }

  

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const pod = e.data as PoDetail;
    if (!pod) {
      return;
    }
    this.currentPoDetail = pod;
  }

  goBack() {
    this.domainService.location.back();
  }
}
