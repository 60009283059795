import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Product, JoDetail, PoDetail } from 'app/model/entities/entity-model';
import { AgFns } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';

import { BusyService } from 'app/shared/busy.service';
import { PoUtilsService } from 'app/shared/po-utils.service';

export interface PurchaseOrderApplyChangeDialogComponentData {
  poDetail: PoDetail,
  pohRemainingQty: number;
  pohIsHeld: boolean;
}


@Component({
  templateUrl: './purchase-order-apply-change-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class PurchaseOrderApplyChangeDialogComponent {
  @ViewChild('altStyleCell') altStyleCell: TemplateRef<any>;

  poDetail: PoDetail;
  poDetails: PoDetail[];
  podGridOptions: GridOptions;

  joDetails: JoDetail[] = [];
  jodGridOptions: GridOptions;
  joDetailId: number;

  issGridOptions: GridOptions;

  
  pohRemainingQty: number;
  pohIsHeld: boolean;

  applyTo: string;
  applyQty: number;
  applyJod: JoDetail;
  applyIss: Product;

  isPageReady = false;

  static async show(matDialog: MatDialog, data: PurchaseOrderApplyChangeDialogComponentData) {
    return await matDialog
      .open(PurchaseOrderApplyChangeDialogComponent, {
        disableClose: true,
        height: '600px',
        width: '1200px',
        data: data
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: PurchaseOrderApplyChangeDialogComponentData, public dialogRef: MatDialogRef<PurchaseOrderApplyChangeDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, private poUtilsService: PoUtilsService, private dialogService: DialogService, 
    public busyService: BusyService) {


    this.poDetail = this.data.poDetail;
    this.pohRemainingQty = this.data.pohRemainingQty;
    this.pohIsHeld = this.data.pohIsHeld;
    
    this.poDetails = [ this.poDetail ];
    this.applyTo = 'default';
    
    this.prepare();      
    
  }

  async prepare() {
    this.podGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPodGridReady,
    });

    this.jodGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJodGridReady,
      onRowSelected: this.onJodRowSelected,
    }, { detailProperty: 'poDetails' });

    this.issGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onIssGridReady,
      onRowSelected: this.onIssRowSelected,
      rowModelType: 'serverSide'
    })

    this.isPageReady = true;
  }

  

  onPodGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Account', field: 'joDetail.joHeader.account.accountName' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { headerName: 'Order', field: 'orderQty', maxWidth: 75, type: 'numericColumn' },
      { headerName: 'Held', field: 'heldQty', maxWidth: 75, type: 'numericColumn' },
      { headerName: 'Needed', type: 'numericColumn', maxWidth: 75,
        valueGetter: params => {
          const pod = <PoDetail> params.data;
          return pod.getRemainingQty();
        },
      },
    ];
    AgFns.initGrid(this.podGridOptions, colDefs, null, false);
    AgFns.sizeColumnsToFit(this.podGridOptions);
  }


  onJodGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Account', field: 'joHeader.account.accountName', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'JO Detail Id', field: 'id', filter: 'agTextColumnFilter' },
      { headerName: 'JO Status', field: 'joHeader.joStatus.name',  },
      // { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', filter: 'agTextColumnFilter' },
      // { headerName: 'Description', field: 'product.productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'product.productType.style', filter: 'agTextColumnFilter' }, // may be an alt style
      { headerName: 'Features', field: 'product.featureChoicesExtract', filter: 'agTextColumnFilter' },
      { headerName: 'Remaining Qty', colId: 'remainQty',
        valueGetter: params => {
          const jod = <JoDetail> params.data;
          return jod.getPoDetailsRemainingQty();
        }
      },
      { headerName: 'Order Qty', field: 'orderQty', hide: true },
      { headerName: 'Applied Qty', colId: 'appliedQty', hide: true, valueGetter: params => {
          const jod = <JoDetail> params.data;
          return jod.getItemDetailsRcvdQty();
        },
      },
      { headerName: 'Cancel Qty', field: 'cancelQty', hide: true },
      { headerName: 'Return Qty', field: 'returnQty', hide: true },
      { ...AgFns.createCellButtonProps('Alt Style', this.altStyleCell), },
    ];
    const sortFields = [ 'product.choiceValue' ];
    const sortModel = sortFields.map(sf => {
       return { colId: sf, sort: 'asc' as const} ;
    });
    
    this.updateJodMasterDetail(this.jodGridOptions);
    AgFns.initGrid(this.jodGridOptions, colDefs, sortModel );
  }

  updateJodMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Po Detail Id', field: 'id' },
      { headerName: 'Po Header Id', field: 'poHeaderId' },
      { headerName: 'Po Status', field: 'poHeader.poStatus.name' },
      { headerName: 'Order Qty', field: 'orderQty'}
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      refreshStrategy: 'everything',
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const joDetail = params.data as JoDetail;
        params.successCallback(joDetail.poDetails);
      },
    };      
  }

  onIssGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'productType.manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Features', field: 'featureChoicesExtract', filter: 'agTextColumnFilter' },
    ];
    const sortFields = [ 'productType.manufacturer.name', 'productType.description', 'productType.style', 'choiceValue' ];
    const sortModel = sortFields.map(sf => {
       return { colId: sf, sort: 'asc' as const} ;
    });
    AgFns.initGrid(this.issGridOptions, colDefs, sortModel );

    const gridApi = this.issGridOptions.api;
    const filter1 = gridApi.getFilterInstance('productType.manufacturer.name');
    filter1.setModel({
      filterType: 'text',
      type: 'equals',
      filter: this.poDetail.product.productType.manufacturer.name
    });
    const filter2 = gridApi.getFilterInstance('productType.style');
    filter2.setModel({
      filterType: 'text',
      type: 'equals',
      filter: this.poDetail.product.productType.style
    });
    gridApi.onFilterChanged();

    const ds = AgFns.buildDatasource(() => this.dbQueryService.createProductQuery());
    gridApi.setServerSideDatasource(ds);
  }

  onIssRowSelected(e: RowSelectedEvent) {
    if (!e.node.isSelected()) {
      return;
    }
    this.applyIss = e.data as Product;
  }

  onJodRowSelected(e: RowSelectedEvent) {
    if (!e.node.isSelected()) {
      return;
    }
    this.applyJod = e.data as JoDetail;
  }

  applyToChanged(value: string) {
    if (value === 'jod') {
      this.findMatching();
    }
  }

  async applyQtyChanged(value: string) {
    if (value === '' || value == null) { return; }
    const val = Number.parseInt(value, 10);
    await this.checkApplyQty(val);
    
  }

  async checkApplyQty(val: number) {
    const remainingQty = this.poDetail.getRemainingQty();
    const maxQty = this.applyTo != 'unhold' ? remainingQty : this.poDetail.heldQty;
    if ((val <= 0) || ( val > maxQty)) {
      await this.dialogService.showOkMessage('Invalid Quantity',
        `Quantity must be between than 1 and ${ maxQty } inclusive.`);
      return false;  
    } else {
      return true;
    }
  }

  async findMatching() {
    this.joDetailId = null;
    this.applyJod = null;
    await AgFns.waitForGrid(this.jodGridOptions);
    await AgFns.busyGrid(this.jodGridOptions, this.busyService, async () => {
      // Finds all JoDetails that match on style or altStyle
      const joDetails = await this.dbQueryService.getJoDetailsForPoModification(this.poDetail.product.productTypeId);
      // problem here is that we really only want to steal from one other pod not multiple ones.
      this.joDetails = joDetails.filter(jod => jod.getPoDetailsRemainingQty() > 0 && jod.id !== this.poDetail.joDetailId);
      AgFns.autoSizeAllColumns(this.jodGridOptions);
    });
    if (this.joDetails.length === 0) {
      this.dialogService.showOkMessage('None found', 'No matching unfulfilled JoDetails found.');
    }
  }

  hasAltStyle(jod: JoDetail) {
    return jod.product.productTypeId !== this.poDetail.product.productTypeId;
  }

  showAltStyle(jod: JoDetail) {
    const its = jod.product.productType;
    this.dialogService.showOkMessage('Alt Style', `
      Manufacturer: ${ its.manufacturer.name}<br>
      Description: ${ its.description}<br>
      Style: ${ its.style }
      `
    );
  }

  async apply() {
    let ok: boolean;
    ok = await this.checkApplyQty(this.applyQty);
    if (!ok) return false;
    
    
    if (this.applyTo === 'jod') {
      ok = await this.applyToJod();
    } else if (this.applyTo === 'stock') {
      ok = await this.applyToStock();
    } else if (this.applyTo === 'cancel') {
      ok = await this.applyToCancel();
    } else if (this.applyTo === 'hold') {
      ok = await this.applyToHold(1);
    } else if (this.applyTo === 'unhold') {
      ok = await this.applyToHold(-1);  
    } else {
      ok = await this.applyToDefault();
    }
    if (ok) {
      this.dialogRef.close(true);
    }
  }

  private async confirmContinue() {
    if (this.pohRemainingQty > this.applyQty || this.pohIsHeld) {
      const yn = await this.dialogService.askYesNo('Confirm', 'Are you sure?') ;
      return yn.index === 0;
    } else {
      const yn = await this.dialogService.askYesNo('Confirm',
        'This operation will mark this PO complete.  You will not be able to undo any part of this PO after this is completed. Do you want to continue?');
      return yn.index === 0;
    }
  }

  async applyToJod() {
    if (this.applyJod == null) {
      await this.dialogService.showOkMessage('No JoDetail selected', 'You must select a JoDetail record');
      return false;
    }
    

    if (this.applyJod.getPoDetailsRemainingQty() < this.applyQty) {
      await this.dialogService.showOkMessage('Too many for this Job Order',
        `This Job Order Detail only requires ${this.applyJod.getPoDetailsRemainingQty()} items`);
      return false;
    }
    if (!await this.confirmContinue()) { return false; }
    return await this.poUtilsService.applyPodToJod(this.poDetail, this.applyJod, this.applyQty);
  }

  async applyToStock() {
    if (!await this.confirmContinue()) { return false; }
    return await this.poUtilsService.applyPodToStock(this.poDetail, this.applyQty);
  }

  async applyToDefault() {
    if (!await this.confirmContinue()) { return false; }
    return await this.poUtilsService.applyPodToDefault(this.poDetail, this.applyQty);
  }

  async applyToHold(sign: 1 | -1) {
    if (!await this.confirmContinue()) { return false; }
    return await this.poUtilsService.applyPodToHold(this.poDetail, sign * this.applyQty);
  }

  async applyToCancel() {
    if (this.poDetail.joDetailId != null) {
      if (this.applyIss == null) {
        await this.dialogService.showOkMessage('No Replacement Item Style/Size selected', 'You must select a Style and Size.');
        return false;
      }
    } else {
      this.applyIss = null;
    }
    if (!await this.confirmContinue()) { return false; }
    return await this.poUtilsService.applyPodToCancel(this.poDetail, this.applyIss, this.applyQty );
  }


  cancel() {
    this.dialogRef.close(null);
  }

}
