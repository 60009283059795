<app-uni-dialog title="Event Schedule" [parent]="this" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>

  <div *ngIf="rruleDates != null && rruleDates.length > 0" class="content-card" >
    <div class="h3 mt-20 ml-12 mb-4">Event Dates: {{rruleText}} {{ hasEnd ? '' : ' ( only first 10 shown ) ' }} </div>
    <app-ag-grid style="height: 330px;" [gridOptions]="rruleGridOptions" [rowData]="rruleDates" [hasFooter]=false>
    </app-ag-grid>
  </div>

  <div fxLayout="row wrap" class="mt-8 ml-12" fxLayoutAlign="left center" fxLayoutGap="20px" fxLayout.cs="Column">
    <span>RRule: {{ rrule.toString() }}</span>
  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
</ng-template>
