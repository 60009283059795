<app-uni-dialog [title]="title" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <app-ag-grid  [gridOptions]="johGridOptions"> </app-ag-grid>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
</ng-template>
