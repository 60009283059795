import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActiveStatus, Feature } from 'app/model/entities/entity-model';
import { AuthService, AuthUser } from 'app/shared/auth.service';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';

@Component({
  templateUrl: './edit-feature-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class EditFeatureDialogComponent {
  
  user: AuthUser;
  feature: Feature;

  activeStatuses: ActiveStatus[];

  isAdding: boolean;
  title: string;
  isPageReady = false;


  static async show(matDialog: MatDialog, data: any) {

    return await matDialog
      .open(EditFeatureDialogComponent, {
        disableClose: true,
        height: '650px',
        width: '850px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditFeatureDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, private uow: UnitOfWork,
    private authService: AuthService, private dialogService: DialogService, public busyService: BusyService, public matDialog: MatDialog) {

    this.user = this.authService.getUser();

    this.feature = this.data.feature;
    if (this.feature == null) {
      this.isAdding = true;
      this.feature = this.createNewFeature();
    }

    this.title = this.isAdding ? 'Creating New Feature' : 'Editing Feature';

    this.prepare();
  }

  private createNewFeature() {
    return this.uow.createEntity(Feature, {
      mismatchAddonIsCrossAccount: false,
      activeStatusId: 1
    });
  }

  private async prepare() {

    this.activeStatuses = this.dbQueryService.getAllCached(ActiveStatus);

    this.isPageReady = true;
  }

  async ok() {
    const ok = await this.save();
    if (!ok) {
      return;
    }
    this.dialogRef.close(true);
  }

  async save() {
    // remove excess spaces
    this.feature.name = this.feature.name?.trim();
    if (this.feature._origName == null) {
      this.feature._origName = this.feature.name;
    }

    const isValid = await this.validate();
    if (!isValid) { return false; }


    try {
      const sr = await this.dbSaveService.saveChanges();
      return true;
    } catch (e) {
      return false;
    }
  }

  async validate() {

    let matchingEntity = await this.dbQueryService.getMatchingEntity(Feature, this.feature, ['name']);
    if (matchingEntity != null) {
      await this.dialogService.showOkMessage('Duplicate', `Cannot save because this feature is a duplicate of an existing feature. `);
      return false;
    }
    
    
    const ea = this.feature.entityAspect;
    if (! ea.validateEntity()) {
      const vErrors = ea.getValidationErrors();
      const errMsg = vErrors.map(ve => ve.errorMessage).join('<br>');
      await this.dialogService.showOkMessage('Validation Error(s)', errMsg);
      return false;
    }
    return true;
  }

  async cancel() {
    // This insures that the rejectChanges below does not cause an html error on the page.
    this.isPageReady = false;
    // entityAspect may not exist if it is a new object
    this.feature.entityAspect?.rejectChanges();

    this.dialogRef.close(false);
  }

  

}
