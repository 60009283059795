import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { BusyService } from './busy.service';

@Component({
  selector: 'app-uni-dialog',
  templateUrl: './uni-dialog.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class UniDialogComponent implements OnInit {
  @Input() pageIcon: string;
  @Input() parent: any;
  @Input() title: string;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;
  @Input() ignoreBusy: boolean;  

  constructor(public busyService: BusyService) {

  }

  ngOnInit() {
  
  }

}
