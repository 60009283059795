// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Manifest } from './manifest';
import { ManifestGroupInvoice } from './manifest-group-invoice';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ManifestGroup extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  manifestId: number;
  sentDate: Date;
  manifest: Manifest;
  manifestGroupInvoices: ManifestGroupInvoice[];
}

