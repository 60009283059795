import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { EOQType, Feature, ProductType, Product, Manufacturer } from 'app/model/entities/entity-model';
import { AgFns, GridState, ISortModel } from 'app/shared/ag-fns';
// import { UniAgFns } from 'app/shared/uni-ag-fns'
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { takeUntil } from 'rxjs/operators';
import { UniAgFns } from 'app/shared/uni-ag-fns';

@Component({
  selector: 'app-product-types',
  templateUrl: './product-types.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ProductTypesComponent extends DomainBaseComponent implements OnInit {
  _isShowingEmptyProductTypes = false;
  gridState: GridState;

  itsGridOptions: GridOptions;
  itsEmptyGridOptions: GridOptions;
  productTypes: ProductType[] = [];
  selectedProductType: ProductType;

  altItsGridOptions: GridOptions;
  altProductTypes: ProductType[] = [];

  issGridOptions: GridOptions;
  products: Product[];

  features: Feature[];
  eOQTypes: EOQType[];

  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,

  ) {
    super(domainService);
    this.user = domainService.authService.getUser();
    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  get isShowingEmptyProductTypes() {
    return this._isShowingEmptyProductTypes;
  }
  set isShowingEmptyProductTypes(value: boolean) {
    if (value == null || value == this._isShowingEmptyProductTypes) {
      return;
    }
    this.saveGridState();
    this._isShowingEmptyProductTypes = value;
    this.restoreGridState();
  }

  async updateFromContext() {
    this.itsGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItsGridReady,
      onRowSelected: this.onItsRowSelected,
      onFilterChanged: this.onFilterChanged,
      onModelUpdated: this.onModelUpdated,
      rowModelType: 'serverSide',
    })
    this.itsGridOptions.onPaginationChanged = (params) => {
      if (params.newPage) {
        this.selectedProductType = null;
        this.updateLocation();
      }
      AgFns.refreshGrid(this.issGridOptions, []);
      AgFns.refreshGrid(this.altItsGridOptions, []);
    };
    AgFns.captureGridRouteParams(this.itsGridOptions, this.route, 'id');

    this.itsEmptyGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItsGridReady,
      onRowSelected: this.onItsRowSelected,
    });

    this.altItsGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAltItsGridReady
    });

    this.issGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onIssGridReady
    });

    
    this.features = await this.dbQueryService.getFeatures();
    this.eOQTypes = this.dbQueryService.getAllCached(EOQType);
    this.isPageReady = true;
  }

  canDeactivate() {
    this.uow.clearEntities(Product);
    this.uow.clearEntities(ProductType);
    this.uow.clearEntities(Manufacturer);
    return true;
  }

  async onItsGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'description', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'style', filter: 'agTextColumnFilter' },
      { headerName: 'Feature Names', field: 'featureNamesExtract', sortable: false,  },
      { headerName: 'EOQ Type', field: 'eOQType.name' },
      { ...AgFns.createButtonProps('', this.editProductType.bind(this), { label: 'Edit Product Type'} ) },
      { headerName: 'Status', field: 'activeStatus.name',  },
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter' },
    ];

    const sortModel =  [
        { colId: 'manufacturer.name', sort: 'asc' },
        { colId: 'description', sort: 'asc' },
        { colId: 'style', sort: 'asc' },
      ] as ISortModel;
    

    if (this.isShowingEmptyProductTypes) {
      AgFns.initGrid(this.itsEmptyGridOptions, colDefs, sortModel);
      await this.refreshEmptyProductTypes();
      AgFns.applyGridRouteParams(this.itsEmptyGridOptions);
      
    } else {
      AgFns.initGrid(this.itsGridOptions, colDefs, sortModel);
      this.updateDatasource();
      AgFns.applyGridRouteParams(this.itsGridOptions);
    }
    // AgFns.selectDefaultRow(this.itsGridOptions);
  }

  async onAltItsGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'description', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'style', filter: 'agTextColumnFilter' },
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter' },
    ];
    
    const sortModel = [
      { colId: 'manufacturer.name', sort: 'asc' },
      { colId: 'description', sort: 'asc' },
      { colId: 'style', sort: 'asc' },
    ] as ISortModel;
    AgFns.initGrid(this.altItsGridOptions, colDefs, sortModel);
  }

  async onIssGridReady(evt: any) {
    
    const colDefs = [
      UniAgFns.getProductSortOrderColDef(x => x.data, 'productSortOrder', false) , 
      { headerName: 'Features', field: 'featureChoicesExtract', filter: 'agTextColumnFilter' },
      
      { headerName: 'Reorder Product Features', filter: 'agTextColumnFilter', 
        valueGetter: params => {
          const prod= <Product> params.data;
          return prod && prod.getReorderProduct().getFeatureChoicesExtract();
        }
      },
      { headerName: 'Status', field: 'activeStatus.name' },
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter' },
    ];

    
    const sortModel = [ { colId: 'productSortOrder', sort: 'asc'} ] as ISortModel;
    AgFns.initGrid(this.issGridOptions, colDefs, sortModel, true);
    
  }

  updateDatasource() {
    const gridApi = this.itsGridOptions.api;
    if (gridApi == null) {
      return;
    }

    const ds = AgFns.buildDatasource(() => this.dbQueryService.createProductTypesQuery());
    gridApi.setServerSideDatasource(ds);

    AgFns.applyGridRouteParams(this.itsGridOptions);
  }

  onFilterChanged() {
    this.updateLocation();
  }

  async onItsRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const its = e.data as ProductType;
    if (!its) {
      return;
    }
    this.updateLocation(its.id);
    this.selectedProductType = its;
    
    // next two are both async but we don't need to wait.
    this.getAltProductTypes(its);
    this.getProducts(its);
  }

  saveGridState(key: string = null) {
    const gridInfo = this.getCurrentGridInfo();
    const gridState = AgFns.getAndAttachGridState(gridInfo.gridOptions, key ?? this.selectedProductType?.id.toString(), 'id');
  }

  restoreGridState() {
    const gridInfo = this.getCurrentGridInfo();
    this.gridState = gridInfo.gridOptions?.context?.gridState ?? this.gridState;
  }

  onModelUpdated() {
    this.altProductTypes = [];
    this.products = [];
  }

  updateLocation(key: any = null, shouldReplace = true) {
    const urlTree = this.router.createUrlTree(['/product-types']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.itsGridOptions, key && key.toString());
    if (shouldReplace) {
      this.domainService.location.replaceState(url);
    } else {
      this.domainService.location.go(url);
    }
  }

  async getAltProductTypes(its: ProductType) {
    this.altProductTypes = await AgFns.busyGrid(this.altItsGridOptions, this.busyService, () => {
      return this.dbQueryService.getAltProductTypesForProductType(its.id);
    });
    return this.altProductTypes;
  }

  async getProducts(its: ProductType) {
    this.products = await AgFns.busyGrid(this.issGridOptions, this.busyService, () => {
      return this.dbQueryService.getProductsForProductType(its.id);
    });
    // This can occur async with the rest of the app
    // const featureChoices = await this.dbQueryService.getFeatureChoices(its.featureId);
    return this.products;
  }

  

  private async refreshEmptyProductTypes() {
    this.productTypes = await this.dbQueryService.getEmptyProductTypes();
    this.products = [];
    this.altProductTypes = [];
  }

  // No longer supported.
  // async deleteStyle(its: ProductType) {
  //   this.productTypes = await this.dbQueryService.getEmptyProductTypes();
  //   if (this.productTypes.find(productType => productType === its) == null) {
  //     this.dialogService.showOkMessage(
  //       'Cannot delete',
  //       `This productType cannot be deleted because it is now either attached to some Job Order,
  //     Purchase Order, or Item Detail or it has already been deleted. `
  //     );
  //     return false;
  //   }
  //   its.productTypeAlts.slice().forEach(isa => isa.entityAspect.setDeleted());
  //   its.products.slice().forEach(iss => iss.entityAspect.setDeleted());
  //   its.entityAspect.setDeleted();

  //   const gridInfo = this.getCurrentGridInfo();
  //   const gridOptions = gridInfo.gridOptions;
  //   await AgFns.busyGrid(gridOptions, this.busyService, async () => {
  //     try {
  //       const sr = await this.dbSaveService.saveChanges();
  //     } catch (e) {
  //       // keep going - save will have been rolled back
  //       // We eat the delete because this probably means ???
  //     }
  //     // update the gridState then requery for the same location.
  //     const node = AgFns.getNextToSelectedNode(gridOptions);
  //     const value = node?.data?.id;
  //     this.saveGridState(value);
  //     if (this.isShowingEmptyProductTypes) {
  //       await this.refreshEmptyProductTypes();
  //     } else {
  //       this.updateDatasource();
  //     }
  //   });
  // }

  async onTabChanged(evt: any) {
    const currentTabLabel = evt.tab.textLabel;
    // HACK: needed because AgGrid loses buttons when tabbing from one tab to another and then back.
    this.products = this.products.slice();
    this.altProductTypes = this.altProductTypes.slice();
  }

  getCurrentGridInfo() {
    return this.isShowingEmptyProductTypes 
      ? { name: 'its-empty', gridOptions: this.itsEmptyGridOptions }
      : { name: 'its', gridOptions: this.itsGridOptions}
  }

  addNewProductType() {
    this.router.navigate(['./product-type', 'add']);
  }

  editProductType(its: ProductType) {
    this.updateLocation(its.id);
    this.router.navigate(['./product-type', its.id]);
    return;
  }

  // async editTemplate(its: ProductType) {
  //   this.updateLocation(its.id);
  //   const templateId = its.featureId;
  //   this.router.navigate(['/feature'],  {
  //     queryParams: {
  //       key: templateId
  //     }
  //   });
  // }

  goProductTypeTags() {
    this.router.navigate(['/product-type-tags']);
  }

  goFeatures() {
    this.router.navigate(['/feature']);
  }


}
