import { Injectable, NgZone } from '@angular/core';
import { MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AppConfigDialogComponent } from 'app/admin/app-config-dialog.component';
import { UserAccountDialogComponent } from 'app/admin/user-account-dialog.component';
import { anonNavigation, defaultNavigation } from 'app/navigation/navigation';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { AuthService } from './auth.service';
import { UtilFns } from './util-fns';


@Injectable({ providedIn: 'root' })
export class FuseUtilService {

  constructor(private _fuseNavigationService: FuseNavigationService, private _fuseSidebarService: FuseSidebarService,
    private _authService: AuthService, private _router: Router, private _matDialog: MatDialog, private _ngZone: NgZone) {
  }

  async setupNavigation() {

    const isLoggedIn = await this._authService.isLoggedIn();
    if (!isLoggedIn) return;

    if (this._fuseNavigationService.getNavigation('main')) {
      this._fuseNavigationService.unregister('main');
    }
    // Register the navigation to the service
    // Need to use _cloneDeep here because defaultNavigation is a nested array and slice will only clone top level.
    this._fuseNavigationService.register('main', _.cloneDeep(defaultNavigation));

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    this.updateDefaultNavigation()

    this.addOptionsNavigation();

  }

  private updateDefaultNavigation() {
    const user = this._authService.getUser();

    if (user.isUserAdmin) {
      this._fuseNavigationService.removeNavigationItem('user');
    } else {
      this._fuseNavigationService.removeNavigationItem('users');
      const userItem = this._fuseNavigationService.getNavigationItem('user');
      userItem.function = () => {
        const user = this._authService.getUser();
        return UserAccountDialogComponent.show(this._matDialog, { userName: user.loginName });
      }
    }
  }

  private addOptionsNavigation() {
    const key = "options";
    let item = this._fuseNavigationService.getNavigationItem(key);
    if (item) {
      this._fuseNavigationService.removeNavigationItem(key);
    }
    // if (item != null) { return; }
    const customFunctionNavItem = {
      'id': key,
      'title': 'Options',
      'type': 'group',
      'icon': 'settings',
      'children': <any> [
        {
          'id': 'printersetup',
          'title': 'Printer Setup',
          'type': 'item',
          'icon': 'settings',
          'function': () => {
            this.toggleSidebarOpen('themeOptionsPanel');
          }
        },

        {
          'id': 'logout',
          'title': 'Logout',
          'type': 'item',
          'icon': 'exit_to_app',
          'function': () => {
            this._authService.logout();
          }
        }
      ]
    };

    const user = this._authService.getUser();

    if (user.isAccountingAdmin) {
      customFunctionNavItem.children.splice(1, 0, {
        id: 'quickbooks',
        title: 'Quickbooks',
        type: 'item',
        icon: 'extension',
        url: 'https://app.sandbox.qbo.intuit.com/app/homepage',
        externalUrl: true,
        openInNewTab: true,
      });
    }

    if (user.isUserAdmin) {
      customFunctionNavItem.children.splice(1, 0, {
        'id': 'configuration',
        'title': 'Configuration',
        'type': 'item',
        'icon': 'supervisor_account',
        'function': () => {
            AppConfigDialogComponent.show(this._matDialog, {});
          }
        },
        {
          id: 'sentry',
          title: 'Sentry',
          type: 'item',
          icon: 'extension',
          url: 'https://sentry.io/organizations/unitec-distribution-sytems/issues/?project=6440781',
          externalUrl: true,
          openInNewTab: true,
        },
        {
          id: 'serversetup',
          title: 'Server Setup',
          type: 'item',
          icon: 'extension',
          url: environment.apiRoot + '/index.html',
          externalUrl: true,
          openInNewTab: true,
        },
      );
    }

    this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
  }

  /** Expose window.navigateByUrl function to be called by Puppeteer */
  private setupWindowRouting() {
    const win = window as any;
    win.navigateByUrl = this.navigateByUrl.bind(this);
  }

  navigateByUrl(url: string) {
    url = UtilFns.trimBasePrefixFromPath(url);
    return this._ngZone.run(() => this._router.navigateByUrl(url));
  }



  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

}
