import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Email, PoHeader } from 'app/model/entities/entity-model';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { EmailService } from 'app/shared/email.service';
import { PrintFns } from 'app/shared/print-fns';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { PoPrintDialogComponent } from './po-print-dialog.component';

interface FlatDetail {
  style: string;
  desc: string;
  featureChoicesExtract: string;
  qty: number;
  ddate: Date;
}

@Component({
  selector: 'app-printable-po',
  templateUrl: './printable-po.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PrintablePoComponent extends DomainBaseComponent implements OnDestroy {
  @ViewChild('printableArea') printElementRef: ElementRef;
  poHeaderId: number;
  poHeader: PoHeader;
  email: Email;
  flatDetails: FlatDetail[];
  getAddress = UtilFns.getAddress;

  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,
    private emailService: EmailService,
    private elementRef: ElementRef,
    private location: Location
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  ngOnDestroy(): void {
    // PrintFns.setPrintFn(null);
  }

  get isSent() {
    return this.poHeader && this.poHeader.poStatusId >= PoStatusCode.OpenIncomplete;
  }

  async updateFromContext() {
    this.poHeaderId = +this.route.snapshot.params['poHeaderId'];
    await this.dbQueryService.cacheAll();
    this.poHeader = await this.dbQueryService.getPoHeader(this.poHeaderId);
    if (this.poHeader == null) {
      this.isPageReady = true;
      return;
    }

    const poDetails = await this.dbQueryService.getPoDetails(this.poHeaderId);

    const fmtCurrency = this.UtilFns.fmtCurrency;
    let details = poDetails.map(d => {
      const jo = d.joDetail;
      const style = d.product.productType;
      return {
        style: style.style,
        desc: style.description,
        featureChoicesExtract: d.product.featureChoicesExtract,
        qty: d.orderQty,
        ddate: d.expectedShipDate
      };
    });

    // group the rows and sum the quantities
    const map = {};
    details = _.sortBy(details, d => d.style + d.featureChoicesExtract + d.ddate);
    details.forEach(d => {
      const key = d.style + d.featureChoicesExtract + d.ddate;
      if (map[key]) {
        map[key].qty += d.qty;
      } else {
        map[key] = d;
      }
    });
    this.flatDetails = Object.values(map);

    this.isPageReady = true;

    setTimeout(() => {
      // if printing or headless browser, use print view
      const mediaQueryList = window.matchMedia('print');
      if (mediaQueryList.matches || window.navigator.userAgent.includes('Headless')) {
        this.preparePrint();
      } else {
        // get email status
        this.getPoEmailStatus();
      }
    }, 0);
  }

  async showPrintDialog() {
    const r = await PoPrintDialogComponent.show(this.matDialog, {
      poHeader: this.poHeader,
      canPreview: false
    });

    this.getPoEmailStatus();
    return <boolean>r;
  }

  preparePrint() {
    let el: HTMLElement = this.printElementRef && this.printElementRef.nativeElement;
    if (el == null) {
      el = this.elementRef && this.elementRef.nativeElement;
    }
    if (!el) {
      return;
    }
    PrintFns.printElement(el);
  }

  getPoEmailStatus() {
    this.emailService.getPoEmailStatus(this.poHeaderId).then(e => this.email = e);
  }

  getOrderNote() {
return (this.poHeader.instructions ? this.poHeader.instructions + `

` : '') +
`If not able to meet expected delivery dates, please contact our Purchasing Department immediately. Thank you.

Acceptance certifies compliance with federal and state regulations regarding equal opportunity without regard to race, religion, national origin, or sex. EEO/AA M/F/D/V`
  }

  private gotoNavPath(navPath: string, id: any) {
    const fm = this.AgFns.addFilterClause({}, 'id', 'number', id);
    const urlSuffix = encodeURIComponent(JSON.stringify(fm));
    this.router.navigate([navPath], {
      queryParams: {
        fm: urlSuffix,
        key: id.toString()
      }
    });
  }

  goToPo() {
    this.gotoNavPath('/purchase-orders', this.poHeaderId);
  }

  goBack() {
    this.location.back();
  }
}
