// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { PoDetail } from './po-detail';
import { PoTrx } from './po-trx';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class PoDetailCancel extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  poDetailId: number;
  crtnTs: Date;
  poTrxId: number;
  qty: number;
  poDetail: PoDetail;
  poTrx: PoTrx;
}

