import { GridOptions } from '@ag-grid-community/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { InventoryDamageDialogComponent } from 'app/inventory/inventory-damage-dialog.component';
import { ItemDetailAddon, AddonBin, ItemDetail, AddonStation } from 'app/model/entities/entity-model';
import { AddonBinStationStatusCode } from 'app/model/enums/addon-bin-station-status-code';
import { AddonBinStatusCode } from 'app/model/enums/addon-bin-status-code';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { AddonUtilsService } from 'app/shared/addon-utils.service';
import { AgFns } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { FileViewDialogComponent, FileViewDialogData } from 'app/shared/file-view-dialog.component';
import { NavFns } from 'app/shared/nav-fns';
import { UnitOfWork } from 'app/shared/unit-of-work';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-addon-bin-process',
  templateUrl: './addon-bin-process.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class AddonBinProcessComponent extends DomainBaseComponent {

  addonBinId: number;
  addonBin: AddonBin;
  shouldShowAllItemDetailAddons = true;


  detailGridOptions: GridOptions;
  itemDetailAddons: ItemDetailAddon[];

  scannedItemDetailId: string;
  scanErrorMessage = '';
  AddonBinStationStatusCode = AddonBinStationStatusCode;

  constructor(
    public uow: UnitOfWork,
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected addonUtilsService: AddonUtilsService,
    private matDialog: MatDialog
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  canDeactivate() {
    this.uow.clearEntities(AddonBin);
    this.uow.clearEntities(ItemDetailAddon);

    return true;
  }

  async updateFromContext() {
    this.addonBinId = +this.route.snapshot.params['addonBinId'];


    this.detailGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onDetailGridReady,
    });

    this.addonBin = await this.dbQueryService.getAddonBin(this.addonBinId);
    await this.refreshGrid();

    this.isPageReady = true;
  }



  onDetailGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Item Detail Id', field: 'itemDetail.id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Manufacturer', field: 'itemDetail.product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'itemDetail.product.productType.description' },
      { headerName: 'Style', field: 'itemDetail.product.productType.style' },
      { headerName: 'Features', field: 'itemDetail.product.featureChoicesExtract' },
      { headerName: 'Addon', field: 'addon.nameAndLocation' },
      { headerName: 'Station', field: 'addon.addonStation.name' },
      { headerName: 'Instructions', field: 'addon.instructions' },
      { headerName: 'Addl. Info', field: 'additionalInfo' },
      { headerName: 'Status', field: 'addonBinStationStatus.name' },
      {
        ...AgFns.createButtonProps('Mark damaged', this.markDamaged.bind(this), { label: 'Mark Damaged' }),
        colId: 'markDamaged', width: 150
      },
      {
        ...AgFns.createButtonProps('Mark as scanned', this.markAsScanned.bind(this), { label: 'Mark As scanned', canDisplay: this.canMarkAsScanned.bind(this) }),
        colId: 'canMarkAsScanned', width: 150
      },
      {
        headerName: 'JO Header Id',
        ...NavFns.createIdCellClickedNavProps('itemDetail.joDetail.joHeaderId', this.router, '/job-orders'), width: 100,
      },
      { headerName: 'Jo Detail Id', field: 'itemDetail.joDetail.id' },
    ];

    const sortModel = [
      { colId: 'itemDetail.id', sort: 'asc' as const },
    ];


    AgFns.initGrid(this.detailGridOptions, colDefs, sortModel);
    AgFns.autoSizeAllColumns(this.detailGridOptions);
  }

  async refreshGrid() {
    this.itemDetailAddons = await this.dbQueryService.getItemDetailAddonsForAddonBin(this.addonBin.id);
    if (!this.shouldShowAllItemDetailAddons) {
      this.itemDetailAddons = this.itemDetailAddons.filter(x => x.addon.addonStationId == this.addonBin.currentAddonStationId 
        || x.addon.addonStationId == this.addonBin.pendingAddonStationId );
    }
    this.AgFns.refreshGrid(this.detailGridOptions, this.itemDetailAddons);
  }

  hasAddonDocs(itdAddon: ItemDetailAddon) {
    return itdAddon?.addon.addonDocMaps.length > 0;
  }

  async viewAddonDocs(itdAddon: ItemDetailAddon) {
    const data: FileViewDialogData = {
      title: 'View Addon Document',
      docs: itdAddon.addon.addonDocMaps.map(x => x.addonDoc)
    };
    await FileViewDialogComponent.show(this.matDialog, data);
  }

  onInputFilled(event: { target: any }, fn: (value: any) => void) {
    const input = event.target;
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;

    if (length >= maxLength) {
      // bind is needed to associate the fn returned with 'this' component.
      fn.bind(this)(input.value);
    } else {
      this.scanErrorMessage = '';
    }
  }

  async scanItemDetailId(itemDetailId: string) {
    
    const itdas = this.itemDetailAddons.filter(x => x.itemDetailId == itemDetailId 
        && x.addon.addonStationId == this.addonBin.pendingAddonStationId);
    
    if (itdas.length == 0) {
      this.scanErrorMessage = 'Invalid item detail id for this bin.'
      return;

    }
    itdas.forEach(x => x.addonBinStationStatusId = AddonBinStationStatusCode.Await);
    // update itd to InProcess-addon
    new Set(itdas.map(x => x.itemDetail)).forEach(x => {
      x.itemDetailStatusId = ItemDetailStatusCode.InProcessAddon;
    })
    
    const isPendingStationAllScanned = this.itemDetailAddons
        .filter(x => x.addon.addonStationId == this.addonBin.pendingAddonStationId)
        .every(x => x.addonBinStationStatusId == AddonBinStationStatusCode.Await);
    
    if (isPendingStationAllScanned) {
      this.addonBin.currentAddonStationId = this.addonBin.pendingAddonStationId;
      this.addonBin.pendingAddonStationId = null;
      this.addonBin.currentAddonBinStationStatusId = AddonBinStationStatusCode.Await;
    }

    await this.dbSaveService.saveChanges();
    this.scannedItemDetailId = '';
    AgFns.refreshGrid(this.detailGridOptions, this.itemDetailAddons);
  }

  canMarkAsScanned(itda: ItemDetailAddon) {
    return itda?.addon?.addonStationId == this.addonBin.pendingAddonStationId;
  }

  async markAsScanned(itda: ItemDetailAddon) {
    this.scanItemDetailId(itda.itemDetailId);
  }

  canScanIntoStation() {
    return this.addonBin.pendingAddonStationId != null;
  }

  canMarkStationInProcess() {
    return this.addonBin.currentAddonBinStationStatusId == AddonBinStationStatusCode.Await
      || this.addonBin.currentAddonBinStationStatusId == AddonBinStationStatusCode.OnHold;
  }


  canMarkStationComplete() {
    return this.addonBin.currentAddonBinStationStatusId == AddonBinStationStatusCode.InProcess;
  }


  canMarkStationOnHold() {
    return this.addonBin.currentAddonBinStationStatusId == AddonBinStationStatusCode.Await
      || this.addonBin.currentAddonBinStationStatusId == AddonBinStationStatusCode.InProcess
  }

  async markStation(stationStatusCode: AddonBinStationStatusCode) {
    
    this.addonBin.currentAddonBinStationStatusId = stationStatusCode;
    const itdas = this.itemDetailAddons.filter(x =>
      x.addon.addonStationId == this.addonBin.currentAddonStationId);
    itdas.forEach(x => x.addonBinStationStatusId = stationStatusCode);
    AgFns.refreshGrid(this.detailGridOptions, this.itemDetailAddons);
    if (stationStatusCode == AddonBinStationStatusCode.Closed) {
      this.addonUtilsService.updatePendingAddonStation(this.addonBin);
    } else if (stationStatusCode == AddonBinStationStatusCode.InProcess) {
      this.addonBin.addonBinStatusId = AddonBinStatusCode.InProcess;
    } else if (stationStatusCode = AddonBinStationStatusCode.OnHold) {
      this.addonBin.addonBinStatusId = AddonBinStatusCode.OnHold;
    }
    await this.dbSaveService.saveChanges();
  }

  async markDamaged(itda: ItemDetailAddon) {
    const r = await InventoryDamageDialogComponent.show(this.matDialog, {
      itemDetail: itda.itemDetail
    });
    if (r) {
      this.refreshGrid();
      
    }
  }

}
