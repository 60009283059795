import { Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Account, DocSubmitType } from 'app/model/entities/entity-model';
import { DocSubmitTypeCode } from 'app/model/enums/doc-submit-type-code';
import { AuthService } from 'app/shared/auth.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';

interface EditAccountDialogData {
  account: Account;
}

@Component({
  templateUrl: './edit-account-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class EditAccountDialogComponent {
  @ViewChild('form') form: NgForm;

  account: Account;
  docSubmitTypes: DocSubmitType[];
  isPageReady = false;


  static async show(matDialog: MatDialog, data: EditAccountDialogData) {
    return await matDialog
      .open(EditAccountDialogComponent, {
        disableClose: true,
        height: '570px',
        width: '900px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditAccountDialogData, public dialogRef: MatDialogRef<EditAccountDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, 
    private uow: UnitOfWork, private authService: AuthService, private dialogService: DialogService) {

    this.prepare();
  }

  // 
  private async prepare() {
    this.account = this.data.account;
    this.docSubmitTypes = this.dbQueryService.getAllCached(DocSubmitType)
    
    this.isPageReady = true;
  }

  unformatCurrency(value: string) {
    const newValue = value.replace(/[$,]/g, "");
    const num = Number(newValue);
    
    return Number.isNaN(num) ? null : num;
  }

  canSave() {
    return this.uow.hasChanges();
  }
  
  async ok() {
    if (this.account.invoiceDocSubmitTypeId == DocSubmitTypeCode.Both || this.account.invoiceDocSubmitTypeId == DocSubmitTypeCode.Email) {
      if (this.account.invoiceEmail == null) {
        await this.dialogService.showOkMessage('Unable to save', 'You must have an Invoice Email for this Invoice Doc Submit Type');
        return false;
      }
    }
    this.account.modTs = new Date();
    const ea = this.account.entityAspect;
    if (!ea.validateEntity()) {
      const vErrors = ea.getValidationErrors();
      const errMsg = vErrors.map(ve => ve.errorMessage).join('<br>');
      await this.dialogService.showOkMessage('Cannot save - Validation Errors', errMsg);
      return false;
    }
    
    // TODO: run validations locally first 
    await this.dbSaveService.saveChanges();
    
    this.dialogRef.close(true);
  }

  async cancel() {
    this.dialogRef.close(false);
  }


}
