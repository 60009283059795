<div style="height: 100%; padding: 2rem 2rem 2rem 2rem; display: flex; flex-direction: column;">
  <div style="display: flex">
    <h2 class="mat-dialog-title" style="margin-top: 0; padding-left: 0.5rem; flex-grow: 1; background-color: wheat;">
      View Images
    </h2>
    <button style="margin-left: 1.5rem;" mat-mini-fab color="white" (click)="close()"
      title="Close"><mat-icon>close</mat-icon></button>
  </div>

  <div style="flex-grow: 1;">

    <!-- See https://github.com/MurhafSousli/ngx-gallery/wiki/Custom-templates-usage-(deprecated) -->
    <gallery *ngIf="showGallery" [items]="galleryImages" [thumbTemplate]="thumbTemplate" [itemTemplate]="itemTemplate"
      style="height: 100%;" (indexChange)="indexChange($event)">

      <ng-template #thumbTemplate let-data="data" let-type="type">
        <div class="absolute top-0 flex">
          <div *ngIf="data.appImage.isPrimary" class="prx-mini-icon bg-primary" title="Primary">
            <i class="pi pi-check"></i>
          </div>
          <div *ngIf="data.appImage.isThumbnail" class="prx-mini-icon bg-green-500" title="Thumbnail">
            <i class="pi pi-thumbs-up"></i>
          </div>
        </div>
      </ng-template>

      <ng-template #itemTemplate let-index="index" let-type="type" let-data="data" let-currIndex="currIndex">
        <div style="flex-grow: 1;"></div>
        <div style="width: 100%; display: flex; align-items: center; background-color: rgba(127,127,127,.5);">
          <div style="flex-grow: 1; margin-left: 2rem;">{{ data.appImage.fileName }}</div>
          <div style="margin: 0 2rem 0 2rem;">
            <!-- <span class="text-500 mr-3 mb-2 inline-block">{{item.width}} x {{item.height}}</span> -->
            <mat-checkbox [(ngModel)]="data.appImage.isPrimary"
              (onChange)="primaryChange(data.appImage)">Primary</mat-checkbox>
            <button style="margin-left: 1rem" mat-raised-button [disabled]="!canMakeThumbnail(data.appImage)"
              (click)="makeThumbnail(data.appImage)"><mat-icon>thumb_up</mat-icon>Make Thumbnail</button>
            <button style="margin-left: 1rem" [disabled]="data.appImage.isThumbnail" mat-mini-fab color="white"
              (click)="edit(data.appImage)" title="Edit"><mat-icon>edit</mat-icon></button>
            <button *ngIf="data.canDelete" style="margin-left: 1rem" mat-mini-fab color="white" (click)="delete(data.appImage)"
              title="Delete"><mat-icon>delete</mat-icon></button>
            <button mat-mini-fab color="white" style="margin-left: 1rem;" (click)="gal_file.click()" title="Upload">
              <mat-icon>image_search</mat-icon>
            </button>
            <input style="visibility: hidden; width: 1px; height: 1px; padding: 0" type="file"
              accept="image/png,image/jpeg,image/webp,.png,.jpeg,.jpg,.webp" id="gal_file" #gal_file name="gal_file"
              multiple (change)="addFiles($event)" />
            <a style="margin-left: 1rem" mat-mini-fab color="white" [href]="data.appImage.safeUrl" target="_self"
              [download]="data.appImage.fileName" title="Download"><mat-icon>cloud_download</mat-icon></a>
          </div>
        </div>
      </ng-template>
    </gallery>

    <app-image-edit *ngIf="showEdit" [cropInput]="cropInput" (cancel)="cropCancel()"
      (ok)="cropOk($event)"></app-image-edit>

  </div>
</div>