import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Image } from 'app/model/entities/entity-model';
import { Entity, EntityState } from 'breeze-client';
import * as _ from 'lodash';
import { DbQueryService } from '../db-query.service';
import { DbSaveService } from '../db-save.service';
import { FileGalleryDialogComponent, FileGalleryDialogData } from './file-gallery-dialog.component';
import { AppImage, ImageFns } from './image-fns';

/** Implemented by AddonTypeImage, ProductTypeImage, ManufacturerImage */
interface RelatedImage extends Entity {
  imageId: number;
  image: Image;
  isPrimary: boolean;
  isThumbnail: boolean;
}

/** Handles image uploads and interactions with image gallery */
@Component({
  selector: 'app-image-handler',
  template: `<div style="width: 400px; display: flex">
    <button mat-raised-button class="accent-blue" style="margin-left: 1rem; margin-bottom: 1rem;"
      [disabled]="this.isReadOnly" (click)="doc_file.click()">
      <mat-icon>image_search</mat-icon>Choose images
    </button>
    <input [disabled]="this.isReadOnly" style="visibility: hidden; width: 1px; height: 1px; padding: 0" type="file"
        accept="image/png,image/jpeg,image/webp,.png,.jpeg,.jpg,.webp"
        id="doc_file" #doc_file name="doc_file" multiple
        (change)="addFiles($event)" />
    <button (click)="openFileViewDialog()" [disabled]="!imageCount"
      mat-raised-button class="accent-blue" style="margin-left: 1rem; margin-bottom: 1rem;"
    ><mat-icon>image</mat-icon> View {{ imageCount }} Images</button>
  </div> `,
})
export class ImageHandlerComponent {
  /** Linking entity collection on the parent entity, e.g. manufacturer.manufacturerImages */
  @Input() oldImages!: RelatedImage[];
  @Input() isReadOnly = false;
  /** Create the join entity between Image and parent entity.  Example implementation:
   * this.dbSaveService.createEntity(ManufacturerImage, {
   *   manufacturerId: this.manufacturer.id,
   *   imageId: event.imageId,
   *   isPrimary: event.isPrimary,
   *   isThumbnail: event.isThumbnail
   * });
   */
  @Output() createJoinEntity = new EventEmitter<{
    imageId: number;
    isPrimary: boolean;
    isThumbnail: boolean;
  }>();

  newImages: AppImage[] = [];

  constructor(
    private dbQueryService: DbQueryService,
    private dbSaveService: DbSaveService,
    private matDialog: MatDialog,
  ) { }

  /** Add files chosen by the file control to the newImages collection */
  async addFiles(event: Event) {
    ImageFns.addFiles(event, this.newImages, this.dbSaveService.uow);
  }

  /** Images that are not deleted or detached */
  get filteredNewImages(): ReadonlyArray<AppImage> {
    return this.newImages.filter(
      (x) =>
        !x.entityAspect ||
        (x.entityAspect.entityState != EntityState.Deleted &&
          x.entityAspect.entityState != EntityState.Detached)
    );
  }

  get imageCount(): number {
    // files just added + files already attached to this product type
    return this.filteredNewImages.length + this.oldImages.length;
  }

  async openFileViewDialog() {
    if (this.oldImages.some((pti) => pti.image == null)) {
      // load the Images
      const imageIds = this.oldImages.map((pti) => pti.imageId);
      await this.dbQueryService.getImages(...imageIds);
    }
    const images = this.oldImages.map((pti) => {
      const image = pti.image as AppImage;
      // image.isPrimary = pti.isPrimary;
      image.isThumbnail = pti.isThumbnail;
      return image;
    });
    this.newImages = this.filteredNewImages.slice();

    const data: FileGalleryDialogData = {
      newImages: this.newImages,
      oldImages: images,
      uow: this.dbQueryService.uow,
      onDelete: this.deleteFile.bind(this),
      onChange: this.fileChange.bind(this),
    };

    return FileGalleryDialogComponent.show(this.matDialog, data);
  }

  /** Create Images from the AppImages, and create join entities for each */
  async attachFiles() {
    for (const img of this.filteredNewImages) {
      const si = await ImageFns.makeImage(img, this.dbSaveService.uow);
      const re = this.createJoinEntity.emit({
        imageId: si.id,
        isPrimary: img.isPrimary || false,
        isThumbnail: img.isThumbnail || false,
      });
    }

    this.newImages = [];
  }

  /** When an image is deleted from the gallery dialog, mark it deleted here */
  private deleteFile(image: AppImage) {
    if (image instanceof Image) {
      const pti = this.oldImages.find((pti) => pti.image == image);
      if (pti) {
        pti.entityAspect.setDeleted();
      }
      image.entityAspect.setDeleted();
    } else if (image.file) {
      _.remove(this.newImages, (f) => f === image);
    }
  }

  /** When an image is added or changed in the gallery dialog, change it here */
  private fileChange(image: AppImage) {
    if (image instanceof Image) {
      const pti = this.oldImages.find((pti) => pti.image == image);
      if (pti) {
        pti.isPrimary = !!(image as AppImage).isPrimary;
      }
    }
  }

}
