/**
 * ShipEngine API
 * ShipEngine is the world's best multi-carrier shipping API, with more than 20 supported shipping carriers.  You can easily get and compare shipping rates, print labels, track your shipments, and validate addresses anywhere in the world.  ### Worldwide Major Carrier Support Access the top global carriers in minutes—instead of weeks. Simply [connect your existing carrier accounts](https://docs.shipengine.com/docs/setup-a-carrier) in the API dashboard, and then [begin creating labels](https://docs.shipengine.com/docs/use-a-specific-service).  ### Real-Time Tracking [Track packages](https://docs.shipengine.com/docs/track-a-package) across any of our 20+ supported carrier accounts and create tracking events to keep your customers up-to-date. Easily integrate real-time tracking information for shipments into your app, email, or SMS.  ### Get Rates Across Carriers Make sure you ship as cost-effectively as possible by [quickly comparing rates](https://docs.shipengine.com/docs/get-shipping-rates) using the ShipEngine Rates API. As long as you have the carrier connected to your account, you'll be able to see and compare different rates and services.  ### Global Address Validation No matter your shipping volume, failed deliveries and address change surcharges cut into your bottom line and damage perception with customers. Our address validation services ensure your packages make it to the right place the first time. [Learn how to leverage our address validation services here.](https://docs.shipengine.com/docs/address-validation)  ShipEngine supports address validation for over 180 countries, including the United States, Canada, Great Britain, Australia, Germany, France, Norway, Spain, Sweden, Israel, Italy, and more. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: sales@shipengine.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Shipment } from './shipment';


export interface PurchaseLabelRequestBody { 
    shipment?: Shipment;
    is_return_label?: boolean;
    rma_number?: string;
    /**
     * The possible validate address values
     */
    validate_address?: PurchaseLabelRequestBody.ValidateAddressEnum;
    label_layout?: string;
    /**
     * The possible file formats in which shipping labels can be downloaded.  We recommend `pdf` format because it is supported by all carriers, whereas some carriers do not support the `png` or `zpl` formats.  |Label Format  | Supported Carriers |--------------|----------------------------------- |`pdf`         | All carriers |`png`         | `fedex` <br> `stamps_com` <br> `ups` <br> `usps` |`zpl`         | `access_worldwide` <br> `apc` <br> `asendia` <br> `dhl_global_mail` <br> `dhl_express` <br> `dhl_express_australia` <br> `dhl_express_canada` <br> `dhl_express_worldwide` <br> `dhl_express_uk` <br> `dpd` <br> `endicia` <br> `fedex` <br> `fedex_uk` <br> `firstmile` <br> `globegistics` <br> `imex` <br> `newgistics` <br> `ontrac` <br> `rr_donnelley` <br> `stamps_com` <br> `ups` <br> `usps` 
     */
    label_format?: PurchaseLabelRequestBody.LabelFormatEnum;
    /**
     * There are two different ways to [download a label](https://docs.shipengine.com/docs/download-a-label):  |Label Download Type | Description |--------------------|------------------------------ |`url`               |You will receive a URL, which you can use to download the label in a separate request. The URL will remain valid for 90 days.<br><br>This is the default if `label_download_type` is unspecified. |`inline`            |You will receive the Base64-encoded label as part of the response. No need for a second request to download the label. 
     */
    label_download_type?: PurchaseLabelRequestBody.LabelDownloadTypeEnum;
}
export namespace PurchaseLabelRequestBody {
    export type ValidateAddressEnum = 'no_validation' | 'validate_only' | 'validate_and_clean';
    export const ValidateAddressEnum = {
        NoValidation: 'no_validation' as ValidateAddressEnum,
        ValidateOnly: 'validate_only' as ValidateAddressEnum,
        ValidateAndClean: 'validate_and_clean' as ValidateAddressEnum
    };
    export type LabelFormatEnum = 'pdf' | 'png' | 'zpl';
    export const LabelFormatEnum = {
        Pdf: 'pdf' as LabelFormatEnum,
        Png: 'png' as LabelFormatEnum,
        Zpl: 'zpl' as LabelFormatEnum
    };
    export type LabelDownloadTypeEnum = 'url' | 'inline';
    export const LabelDownloadTypeEnum = {
        Url: 'url' as LabelDownloadTypeEnum,
        Inline: 'inline' as LabelDownloadTypeEnum
    };
}
