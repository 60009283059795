import { GridOptions, RowDoubleClickedEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';


import { AgFns } from 'app/shared/ag-fns';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { DialogService } from './dialog.service';
import { AuthUser } from './auth.service';

export interface AuthUserSelectDialogData {
  authUsers: AuthUser[];
  rowSelection?: 'multiple' | 'single';
}

@Component({
  templateUrl: './auth-user-select-dialog.component.html'
})
export class AuthUserSelectDialogComponent {

  UtilFns = UtilFns;
  mainGridOptions : GridOptions;
  selectedItem: AuthUser;
  allItems: AuthUser[];
  isPageReady = false;

  static async show(matDialog: MatDialog, data: AuthUserSelectDialogData) {
    const r = await matDialog.open(AuthUserSelectDialogComponent, {
      disableClose: true,
      height: '530px',
      width: '800px',
      data: data
    })
      .afterClosed().toPromise();
    return <AuthUser[]> r;
    
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: AuthUserSelectDialogData, public dialogRef: MatDialogRef<AuthUserSelectDialogComponent>,
    private dialogService: DialogService) {

    this.prepare();
  }

  async prepare() {
    this.mainGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      onRowSelected: this.onRowSelected,
      onRowDoubleClicked: this.onRowDoubleClick,
      getRowId: p => (p.data as AuthUser).initials,
      onFirstDataRendered: p => p.api.sizeColumnsToFit(),
      singleClickEdit: true,
      rowSelection: this.data?.rowSelection ?? 'single',
      stopEditingWhenCellsLoseFocus: true,
    });

    
    
    this.allItems = _.orderBy(this.data.authUsers, au => au.displayName);
    this.isPageReady = true;
  }

  onGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Display Name', field: 'displayName' , checkboxSelection: this.data?.rowSelection == 'multiple' },
      { headerName: 'Initials', field: 'initials' },
    ];

    const sortModel = [
      { colId: 'displayName', sort: 'asc' as const },
    ];
    AgFns.initGrid(this.mainGridOptions, colDefs, sortModel);
  }

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    this.selectedItem = e.data as AuthUser;
  }

  onRowDoubleClick(e: RowDoubleClickedEvent) {
    if (!e.node.isSelected()) {
      return;
    }
    const item = e.data as AuthUser;
    this.dialogRef.close([item]);
  }

  ok() {
    const r = this.mainGridOptions.api.getSelectedRows()
    this.dialogRef.close(r);
  }

  cancel() {
    this.dialogRef.close([]);
  }

}
