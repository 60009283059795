import { DialogService } from 'app/shared/dialog.service';
import { Injectable } from '@angular/core';
import { BusyService } from 'app/shared/busy.service';
import { HttpClient } from '@angular/common/http';
import { UserWithRoles, IdentityData } from './identity-data';
import { environment } from 'environments/environment';
import { AuthUser } from 'app/shared/auth.service';

@Injectable({ providedIn: 'root' })
export class UserAccountsService {
  constructor(
    private busyService: BusyService,
    private http: HttpClient,
    private dialogService: DialogService
  ) { }

  async getIdentityData() {
    const url = environment.apiRoot + 'api/userAccount/GetIdentityData';
    const data = await this.http.get<IdentityData>(url).toPromise();
    return data;
  }

  async getAuthUsersWithRole(roleName: string) {
    const iddata = await this.getIdentityData();
    const users = iddata.Users.filter(x => x.Roles.includes(roleName));
    const authUsers = users.map(x => AuthUser.fromUserWithRole(x));
    return authUsers;
  }

  async saveUser(user: UserWithRoles) {
    const url = environment.apiRoot + 'api/userAccount/SaveUser';
    return this.busyService.busy(async () => {
      try {
        const uwr = await this.http.post<UserWithRoles>(url, user).toPromise();
        this.dialogService.toast(`Saved user ${uwr.User.UserName}`);
        return uwr;
      } catch(err) {
        const message = (err.error && err.error.Message) || err.message;
        this.dialogService.showOkMessage('Save Error', message);
        return null;
      }
    });
  }
}
