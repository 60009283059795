// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Account } from './account';
import { BarcodePrintStatus } from './barcode-print-status';
import { InvoiceHeader } from './invoice-header';
import { ItemBin } from './item-bin';
import { ItemDetailAddon } from './item-detail-addon';
import { ItemDetailStatus } from './item-detail-status';
import { ItemReplacement } from './item-replacement';
import { JoDetail } from './jo-detail';
import { PoDetail } from './po-detail';
import { PoTrx } from './po-trx';
import { Product } from './product';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
import { ProcessStatusCode } from '../enums/process-status-code';
import { ItemDetailStatusCode } from '../enums/item-detail-status-code';
/// </code-import>

export class ItemDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get isAccountOwned() { return this.accountId != null; }

  get isPendingScan() {
    return this.itemDetailStatusId == ItemDetailStatusCode.InProcessPendingAddon
    || this.itemDetailStatusId == ItemDetailStatusCode.InProcessPendingShipPrep
  }

  get isInProcess() {
    return this.itemDetailStatusId > ItemDetailStatusCode.InInventory
    && this.itemDetailStatusId < ItemDetailStatusCode.Shipped
  }

  // returns either the name of the current process for this itemDetail or 'Not Yet Started' or 'Completed'
  getCurrentProcess() {
    // const completedSet = new Set<number>();
    // const notYetSet = new Set<number>();
    // for (var itdm of this.itemDetailAddons) {
    //   const stationId = itdm.addon.addonStationId
    //   if (itdm.processStatusId == ProcessStatusCode.InProcess) {
    //     return { addonStationId: stationId, description: stationId }
    //   }
    //   if (itdm.processStatusId == ProcessStatusCode.Completed) {
    //     if (notYetSet.has(stationId)){
    //       return { addonStationId: stationId, description: stationId }
    //     }
    //     completedSet.add(stationId);
    //   }
    //   if (itdm.processStatusId == ProcessStatusCode.NotYetStarted) {
    //     if (completedSet.has(stationId)) {
    //       return { addonStationId: stationId, description: stationId }
    //     }
    //     notYetSet.add(stationId);
    //   }
    // }
    // if (completedSet.size > 0 && notYetSet.size > 0) {
    //   return { addonStationId: null, description: 'Partial' }
    // }
    // if (completedSet.size > 0) {
    //   return { addonStationId: null, description: 'Completed' }
    // }
    // if (notYetSet.size > 0) {
    //   return { addonStationId: null, description: 'Not Yet Started' }
    // }
    // return { addonStationId: null, description: 'No Mods Necessary' }
    return 'Not yet implemented'
    
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: string;
  accountId: number;
  barcodePrintStatusId: number;
  crtnTs: Date;
  invoiceHeaderId: string;
  itemBinId: number;
  itemDetailStatusId: number;
  joDetailId: number;
  lastScanTs: Date;
  poDetailId: number;
  poTrxId: number;
  productId: number;
  rowVersion: any;
  account: Account;
  barcodePrintStatus: BarcodePrintStatus;
  invoiceHeader: InvoiceHeader;
  itemBin: ItemBin;
  itemDetailAddons: ItemDetailAddon[];
  itemDetailStatus: ItemDetailStatus;
  itemReplacement: ItemReplacement;
  joDetail: JoDetail;
  poDetail: PoDetail;
  poTrx: PoTrx;
  product: Product;
}

