<div id="login" uni-flex-col>

    <div id="login-form-wrapper" uni-flex-col style="align-content: center; justify-content: center;" >

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/fuse.svg">
            </div>

            <div class="title">LOGIN TO YOUR ACCOUNT</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput id="email" name="email" formControlName="email" (keyup.enter)="onSubmit()">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error
                        *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput id="password" name="password" type="password" formControlName="password" (keyup.enter)="onSubmit()">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>

                <div uni-flex-row class="remember-forgot-password" >
                    <mat-checkbox class="remember-me" formControlName="persist" aria-label="Remember Me">
                        Keep me logged in
                    </mat-checkbox>
                </div>

                <div *ngIf="!!error">
                    <mat-error>{{error}}</mat-error>
                </div>

                <button type="submit" mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                        [disabled]="loginForm.invalid">
                    LOGIN
                </button>

            </form>

        </div>

    </div>

</div>
