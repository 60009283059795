<div *ngIf="isPageReady" id="printable-invoice" class="standard-page page-layout carded fullwidth inner-scroll accent-blue">

  <div class="center no-print p-8" style="max-width: 8.5in; min-width: 8.5in">

    <div *ngIf="!invoiceHeader" #printableArea class="content-card theme-default screen-p-20">
      <h2>Invoice {{invoiceId}} not found; it may have been undone.</h2>
    </div>
    <div *ngIf="invoiceHeader" #printableArea class="content-card theme-default screen-p-20" style="overflow: auto">
      <ng-container *ngIf="!isAllInvoices">
        <app-printable-invoice-content [invoiceHeader]="invoiceHeader"></app-printable-invoice-content>
      </ng-container>

      <ng-container *ngIf="isAllInvoices">
        <ng-container *ngFor="let invoice of invoiceHeaders">
          <div class="break"></div>
          <app-printable-invoice-content [invoiceHeader]="invoice"></app-printable-invoice-content>
        </ng-container>
      </ng-container>

    </div>

    <div uni-flex-row class="accent no-print mb-12" >
    
      <div class="left-buttons">
        <button mat-raised-button [disabled]="!invoiceHeader" (click)="showPrintDialog()">
          <span>Print / Email</span>
        </button>
        <!-- Not used for now -->
        <!-- <mat-checkbox class="ml-8" placeholder="All invoices for shipment" [(ngModel)]="isAllInvoices" (ngModelChange)="onAllInvoicesChange($event)">All Invoices
        </mat-checkbox>         -->
        <span class="ml-8">{{email?.status}} {{email?.statusTs | date:'long'}}</span>
      </div>

      <div>
        <button mat-raised-button [disabled]="!invoiceHeader" (click)="goToInvoice()">
          <span>Go to Invoice</span>
        </button>
        <button mat-raised-button (click)="goBack()">
          <span>Go Back</span>
        </button>
      </div>
    </div>
  </div>
  <!-- / CENTER -->
</div>
