import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular-ivy';
import { DialogService } from './dialog.service';
import { UtilFns } from './util-fns';

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.

  constructor(private injector: Injector) {
    super();
  }

  async handleError(error: Error | HttpErrorResponse | any) {
    const dialogService = this.injector.get(DialogService);
    const location = this.injector.get(Location);
    // const logger = this.injector.get(ErrorLogger);
    // const ngZone = this.injector.get(NgZone);

    if (error.rejection) {
      // extract error from promise rejection
      error = error.rejection;
    }

    // Report via Sentry, and log to the console
    super.handleError(error);

    // Doesn't seem to help with template error change detection infinite loop.
    // const debugCtx = error['ngDebugContext'];
    // const changeDetectorRef = debugCtx && debugCtx.injector.get(ChangeDetectorRef);
    // if (changeDetectorRef) {
    //   changeDetectorRef.detach();
    // }

    if (error instanceof HttpErrorResponse) {
      // Server Error
    } else {
      // Client Error
    }

    // Errors are logged by Sentry, above
    // logger.log(error.message);
    // console.error(error);

    if (/401.*Unauthorized/.test(error.message)) {
      window.location.reload();
      return;
    }

    const errorType = this.getErrorType(error);
    if (!!errorType) {
      await dialogService.showOkMessage(errorType.title, errorType.message);
      return;
    }

    const ynResult = await dialogService.showGlobalError(error);
    if (ynResult && ynResult.index === 0) {
      dialogService.toast('Reloading...');
      UtilFns.errorCount = 0;
      window.location.reload();

    }

  }

  /** Get type name to be used as dialog title for friendly, expected, or external errors */
  getErrorType(error: Error) {
    if (!error || !error.message) { return; }
    const message = error.message.toLowerCase();

    if (message.includes("java.io") || error.stack?.includes("_qz.")) {
      return this.getQzMessage(error);
    }
    if (message.includes("/shipping/") || message.includes("shipengine")) {
      return this.getShipMessage(error);
    }
    if (message.includes("/mail/")) {
      return this.getMailMessage(error);
    }
    if (message.includes("/quickbooks/")) {
      return this.getQuickbooksMessage(error);
    }
  }

  innerMessage(error: Error | HttpErrorResponse | any) {
    const message = error.error?.Message || error.message;
    return message.substring(0, 500);
  }

  private getQzMessage(error: Error) {
    return { title: 'Printing Error', message:
    `Error from QZ Print Service: <p class="error-hint">${this.innerMessage(error)}</p> Please try again.` };
  }

  private getShipMessage(error: Error) {
    return { title: 'Shipping Service Error', message:
    `Error from Shipping Service: <p class="error-hint">${this.innerMessage(error)}</p> Please try again.` };
  }

  private getMailMessage(error: Error) {
    return { title: 'Mail Service Error', message:
    `Error from Mail Service: <p class="error-hint">${this.innerMessage(error)}</p> Please try again.` };
  }

  private getQuickbooksMessage(error: Error) {
    return { title: 'QuickBooks Error', message:
    `Error from QuickBooks Service: <p class="error-hint">${this.innerMessage(error)}</p> Please try again.` };
  }
}
